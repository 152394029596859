import {BaseService} from './../base-service';
import {
    HEADER_REQUEST,
    BASE_URL,
    LOGIN,
    USER_ADDRESSES,
    SOCIAL_LOGIN,
    USER_MENU,
    MANAGE_WISHLISTS,
    USER_BILLING_ADDRESSES,
    USER_BILLING_ADD_ADDRESS,
    USER_BILLING_EDIT_ADDRESS,
    UTILS_PROVINCE,
    UTILS_STATI,
    USER_LOGOUT,
    FAQFORM,
    USER_DELIVERY_ADD_ADDRESS,
    USER_DELIVERY_EDIT_ADDRESS,
    FAQLIST,
    CHECK_OTP,
    LOGIN_CONFIRM,
    CHECKOUT_USER_INFO,
    CHECK_MODIFY_GUEST_ORDER_EMAIL
} from '../config';

export class UserService extends BaseService {

    constructor() {
        super();
    }

    doLogin(success, error, body): void {
        this.post(BASE_URL + LOGIN, success, error, body, HEADER_REQUEST);
    }

    doLoginConfirm(success, error, body): void {
        this.postAxios(BASE_URL + LOGIN_CONFIRM, success, error, body, HEADER_REQUEST);
    }

    doSocialLogin(success, error, body) {
        this.post(BASE_URL + SOCIAL_LOGIN, success, error, body, HEADER_REQUEST);
    }

    doLogout(succes, error): void {
        this.get(BASE_URL + USER_LOGOUT, succes, error, HEADER_REQUEST)
    }

    getUserAddress(success, error): void {
        this.get(BASE_URL + USER_ADDRESSES, success, error, HEADER_REQUEST);
    }

    getUserMenu(success, error): void {
        this.get(BASE_URL + USER_MENU, success, error, HEADER_REQUEST);
    }

    manageUserWishlists(success, error, body): void {
        this.postAxios(BASE_URL + MANAGE_WISHLISTS, success, error, body, HEADER_REQUEST);
    }

    getUserBillingAddress(success, error): void {
        this.get(BASE_URL + USER_BILLING_ADDRESSES, success, error, HEADER_REQUEST);
    }

    addUserBillingAddress(success, error, body): void {
        this.postAxios(BASE_URL + USER_BILLING_ADD_ADDRESS, success, error, body, HEADER_REQUEST);
    }

    editUserBillingAddress(success, error, body): void {
        this.postAxios(BASE_URL + USER_BILLING_EDIT_ADDRESS, success, error, body, HEADER_REQUEST);
    }

    getProvince(success, error): void {
        this.get(BASE_URL + UTILS_PROVINCE, success, error, HEADER_REQUEST);
    }

    getStati(success, error): void {
        this.get(BASE_URL + UTILS_STATI, success, error, HEADER_REQUEST);
    }

    sendFaqForm(success, error, body): void{
        this.postAxios(BASE_URL + FAQFORM, success, error, body, HEADER_REQUEST);
    }

    addUserDeliveryAddress(success, error, body): void {
        this.postAxios(BASE_URL + USER_DELIVERY_ADD_ADDRESS, success, error, body, HEADER_REQUEST);
    }

    editUserDeliveryAddress(success, error, body): void {
        this.postAxios(BASE_URL + USER_DELIVERY_EDIT_ADDRESS, success, error, body, HEADER_REQUEST);
    }

    getFaqList(success, error, query): void{
        this.get(BASE_URL + FAQLIST + '?query=' + query, success, error, HEADER_REQUEST);
    }

    /* valida anche per utenti non guest */
    checkOtp(success, error, body): void {
        this.postAxios(BASE_URL + CHECK_OTP, success, error, body, HEADER_REQUEST);
    }

    /* RIMOZIONE OTP - PRIMA CI ASPETTAVAMO L'OTP ALLA SUCCESS */
    checkoutUserInfo(success, error, body): void {
        this.postAxios(BASE_URL + CHECKOUT_USER_INFO, success, error, body, HEADER_REQUEST);
    }

    verifyModifyGuestOrderEmail(success, error, body): void {
        this.postAxios(BASE_URL + CHECK_MODIFY_GUEST_ORDER_EMAIL, success, error, body, HEADER_REQUEST);
    }

}
