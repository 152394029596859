import Alpine from 'alpinejs';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, i18n } from '../../../../../../libs/base-utils';
import { logoutFlow } from '../../../../../../libs/common-flows';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';

Alpine.data('ec8x0xProfile', () => ({
    init() {
        sendAnalytics(BASE_ECOMMERCE_DATA.PAGE_VIEW_PA_PROFILE);
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    // main component functions
    async logout() {
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_PROFILE_LEFT_SIDE;
            ecommerceData.funnel.stepFunnel = 'Logout'
            return ecommerceData;
        })());

        logoutFlow(this);
    },
    // only analytics functions
    trackPABox(ctaLabel: string) {
        let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_PROFILE_LEFT_SIDE;
        ecommerceData.funnel.stepFunnel = ctaLabel;
        sendAnalytics(ecommerceData);
    }
}));
