import { _$ } from '../../main';

export class Feedback {
    constructor() {}

    nkOnInit() {}

    closeFeedback(e) {
        let newsFull = _$(e).parent();
        let idNews = newsFull.attr('data-newsid');
        if (idNews) {
            document.cookie = 'ecNewsId=' + idNews;
        }
    }
}
