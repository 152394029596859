import { ValidateOptions } from "../types";

export const isADate = async ({ el }: ValidateOptions): Promise<boolean> => {
    const value = el.value;
    if (value && value.split('/').length == 3) {
        const split = value.split('/');
        const day = parseInt(split[0]);
        const month = parseInt(split[1]) - 1;
        const year = parseInt(split[2]);
        const date = new Date(year, month, day);

        // If the date object is invalid it
        // will return 'NaN' on getTime()
        // and NaN is never equal to itself
        return date.getTime() === date.getTime();
    }
    return false;
};


