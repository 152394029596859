import Alpine from 'alpinejs';
import { FlowStore } from '../../../../../../cfamily/types';
import { formatC, i18n } from '../../../../../../libs/base-utils';

Alpine.data('popup12x3xMessageWithImage', (args: Record<string, any>) => ({
    inizialized: false as boolean,
    title: '',
    description: '',
    warningAlert: '',
    init() {
        this._initializeFields();
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    async _initializeFields() {
        const flow = (await <FlowStore>window.Alpine.store('cFlow'));
        const formatLabels: Record<string, string[]> = flow?.data?.formatLabels;
        if (!formatLabels)
            console.warn('warning: formatLabels not provided');
        this.title = formatC(args.titleFormat, ...(formatLabels?.titleArgs || []));
        this.description = formatC(args.descriptionFormat, ...(formatLabels?.descriptionArgs || []));
        this.warningAlert = formatC(args.warningAlertFormat, ...(formatLabels?.warningAlertArgs || []));
        this.inizialized = true;
    },
    // main component functions
    async confirm() {
        const flow = (await <FlowStore>window.Alpine.store('cFlow'));
        flow.data.result = {
            state: 'confirmed'
        };
        window.cFlowManager.complete();
    },
    async xClose() {
        this.confirm();
    }
}));
