import { Window } from '../../main';

export class CardReview {
    constructor() {}

    nkOnInit() {
        Window.randomize = function () {
            Window.nk$('.ko-progress-circle').attr('data-progress', 100);
        };
        setTimeout(Window.randomize, 200);
        Window.nk$('.ko-progress-circle').click(Window.randomize);
    }
}
