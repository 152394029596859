import { _Uikit, Window, _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';

export class CardsHowItWork{

    swiperHowItWorkOption: SwiperOptions = {
        observer: true,
        mousewheel: {
            invert: true,
            releaseOnEdges: true,
            forceToAxis: true,
        },
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpointsInverse: true,
        breakpoints: {
            1024: {
                freeMode: true,
                direction: 'vertical',
                preventInteractionOnTransition: true
            },
        },
    };

    constructor(){}

    nkOnInit(){
        if(_$(window).width() < 1024){
            let swiperHowItWork = new Swiper('.swiper-how-it-work', this.swiperHowItWorkOption);
        }
    }
    
}