 
import { Utils } from "../../../src/services/utils/utils";
import Swiper from "swiper";
import {_$, _Uikit, _zoom} from "../../main";
export class ModalDetailProduct {
   
    swiperModalDetailProductParams = {
        pagination: {
            el: '.pagination-product',
            clickable: true,
        },
        slidesPerView: 1,
        updateOnWindowResize: true,
        freeMode: false,
        breakpointsInverse: true,
        observer: true,
        resizeObserver: true,
        observeParents: true,
        observeSlideChildren: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            992: {
                slidesPerView: 5,
                watchSlidesVisibility: true,
                spaceBetween: 3,
                //@ts-ignore //TODO: verificare swiper pagination
                pagination: false,
                navigation: {
                    nextEl: '.next-product',
                    prevEl: '.prev-product',
                }
            },
            1200: {
                slidesPerView: 7,
                watchSlidesVisibility: true,
                navigation: {
                    nextEl: '.next-product',
                    prevEl: '.prev-product',
                }
            }
        },
        on: {
            click: (e) => {
                const _this = this.swiperModalDetailProduct;
                /* _$('#previewImageContainer').prepend('<div class="lds-ring lds-ring-1 orange uk-position-center" uk-spinner="ratio: 1.4"></div>'); */
                let img = _$(_this.clickedSlide).find('img')[0];
                this._utils.initZoom('NZoomContainer','NZoomImg', img);

                _$(_this.slides).removeClass('active');
                _$(_this.clickedSlide).addClass('active');
            },
            //@ts-ignore
            doubleClick: (e) => {
                const _this = this.swiperModalDetailProduct;
                /* _$('#previewImageContainer').append('<div class="lds-ring lds-ring-1 orange uk-position-center" uk-spinner="ratio: 1.4"></div>'); */
                let img = _$(_this.clickedSlide).find('img')[0];
                this._utils.initZoom('NZoomContainer','NZoomImg', img);

                _$(_this.slides).removeClass('active');
                _$(_this.clickedSlide).addClass('active');
            },
            init: function () {
                //console.log('swiper initialized');
            },
            update: function () {
                const _this = this;
                _this.pagination.update()
            }
        }
    }

    swiperModalDetailProduct;

    swiperModalDetailProductImageMobileParams = {
        slidesPerView: 'auto',
        updateOnWindowResize: true,
        freeMode: false,
        breakpointsInverse: true,
        observer: true,
        //@ts-ignore
        resizeObserver: true,
        observeParents: true,
        observeSlideChildren: true,
        centerInsufficientSlides: true,
        navigation: {
            nextEl: '.next-product',
            prevEl: '.prev-product',
        },
        on: {
            click: (e) => {
                const _this = this.swiperModalDetailProductImageMobile;
                /* _$('#previewImageContainerModal div:first').prepend('<div class="lds-ring lds-ring-1 orange uk-position-center" uk-spinner="ratio: 1.4"></div>'); */
                let img = _$(_this.clickedSlide).find('img')[0];
                this._utils.initZoom('NZoomContainerMobile', 'NZoomImgMobile',img);

                _$(_this.slides).removeClass('active');
                _$(_this.clickedSlide).addClass('active');
            },
            //@ts-ignore
            doubleClick: (e) => {
                const _this = this.swiperModalDetailProduct;
                /* _$('#previewImageContainerModal div:first').append('<div class="lds-ring lds-ring-1 orange uk-position-center" uk-spinner="ratio: 1.4"></div>'); */
                let img = _$(_this.clickedSlide).find('img')[0];
                // this._utils.initZoom('NZoomContainerMobile', 'NZoomImgMobile',img);

                _$(_this.slides).removeClass('active');
                _$(_this.clickedSlide).addClass('active');
            },
            init: function () {
                console.log('initializing swiper');
                
            },
            update: function () {
                const _this = this;
                _this.pagination.update();
            }
        }
    };
    swiperModalDetailProductImageMobile;

    constructor(
        private _utils: Utils,
    ) { }
    
    nkOnInit() { 

        /* _Uikit.util.on('#modalProductImages', 'show', () => {
            this._utils.initZoom('NZoomContainerMobile', 'NZoomImgMobile');
        });

        _Uikit.util.on('#offcanvas-detail-product', 'show', () => {
            this.swiperModalDetailProduct;
            this._utils.initZoom('NZoomContainer', 'NZoomImg');
        }) */
    }

    getImageZoom(e) {
        /* _Uikit.modal('#modalProductImages').show();
        _Uikit.util.on('#modalProductImages', 'show', () => {
            this.swiperModalDetailProductModal;
        }); */
    }
 }