import { Alpine } from 'alpinejs';
import { CChangeDetail } from '../types';

export const cEnabledOn = (Alpine: Alpine) => {
    Alpine.directive('cenabledon', (el: HTMLElement, { expression }, { cleanup }) => {
        const realEl = el as HTMLInputElement;

        const cScope = Alpine.reactive({ $cenabledon: false });
        Alpine.addScopeToNode(el, cScope);

        if (!expression) {
            cScope.$cenabledon = true;
            return;
        }

        const form = realEl.closest('form');
        if (!form) {
            cScope.$cenabledon = true;
            return;
        }

        const dependencyEl = form.querySelector(`[name='${expression}']`) as HTMLInputElement;
        if (!dependencyEl) {
            cScope.$cenabledon = true;
            return;
        }

        const dependValue = realEl.dataset.enabledOnValue;

        const changeHandler = (e: Event) => {
            const detail = (e as CustomEvent).detail as CChangeDetail;
            const valid = detail.validity;

            const inputTarget = e.target as HTMLInputElement;
            const enabled =
                valid &&
                !!inputTarget.value &&
                (inputTarget.type != 'checkbox' || inputTarget.checked) &&
                (!dependValue || inputTarget.value == dependValue);
            cScope.$cenabledon = enabled;
            if (enabled) {
                realEl.removeAttribute('disabled');
            } else {
                realEl.setAttribute('disabled', '');
            }
        };

        dependencyEl.addEventListener('cchange', changeHandler);
        cleanup(() => {
            dependencyEl.removeEventListener('cchange', changeHandler);
        });
    }).before('bind');
};
