import { Analytics } from "src/services/analytics/analytics";
import { _$ } from '../../main';

export class DotCard{
   
    constructor(private _analytics: Analytics) { }
    
    nkOnInit() { }   

    async sendAnalytics(eventType, e?){
        try {
            let nkPage = _$('body').attr('nkPage');

            //6.5.3
            if(nkPage == 'Search'){
                let analyticsData;

                analyticsData = {
                    event: eventType,
                    funnel: {
                        nomeFunnel: 'Scelta prodotto',
                        stepFunnel: 'Listing page'
                    },
                    prodotto: (await this._analytics.getProductData(_$(e).closest('.dot-card'))),
                    sponsor: [{
                        brand: '',
                        campagna: _$(e).closest('.banner-hotspot').length ? _$(e).closest('.banner-hotspot').find('.text-title').text() : '',
                        formato: 'Header interattivo',
                        landing: _$(e).attr('href'),
                        posizioneSlide: '0'
                    }]
                }

                this._analytics.sendAnalytics(analyticsData, '', false);
            } else if (nkPage == 'Homepage') {
                let analyticsData;

                analyticsData = {
                    event: eventType,
                    funnel: {
                        nomeFunnel: 'Scelta prodotto',
                        stepFunnel: 'Listing page'
                    },
                    prodotto: (await this._analytics.getProductData(_$(e).closest('.dot-card'))),
                    sponsor: [{
                        brand: '',
                        campagna: _$(e).closest('.banner-hotspot').length ? _$(e).closest('.banner-hotspot').find('.text-title').text() : '',
                        formato: 'Header interattivo',
                        landing: _$(e).attr('href'),
                        posizioneSlide:'0'
                    }]
                }

                if (_$(e).closest('.component-BannerListing').attr('data-promo')) {
                    let dataPromo = JSON.parse(_$(e).closest('.component-BannerListing').attr('data-promo'));
                    this._analytics.sendAnalytics(analyticsData, '', false, true, dataPromo)
                } else {
                    this._analytics.sendAnalytics(analyticsData, '', false)
                }
            }
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }
 }