/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCartRequest } from '../models/AddCartRequest';
import type { CancelOrderRequest } from '../models/CancelOrderRequest';
import type { Cart } from '../models/Cart';
import type { CloseCustomerCaseRequest } from '../models/CloseCustomerCaseRequest';
import type { CouponsResponse } from '../models/CouponsResponse';
import type { CustomerCareAttachments } from '../models/CustomerCareAttachments';
import type { CustomerCareResponse } from '../models/CustomerCareResponse';
import type { GetOrderHistoryRequest } from '../models/GetOrderHistoryRequest';
import type { ManageWishlistsServletRequest } from '../models/ManageWishlistsServletRequest';
import type { Orders } from '../models/Orders';
import type { Pagination } from '../models/Pagination';
import type { PaginationRequest } from '../models/PaginationRequest';
import type { ProductCardType } from '../models/ProductCardType';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
    /**
     * Get the i18n dictionary
     * Returns a map string -> string
     * @returns any Successful response
     * @throws ApiError
     */
    public static getApiEcommerceItItDictionaryJson(): CancelablePromise<{
        data?: Record<string, any>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ecommerce/it-it.dictionary.json',
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Get order history for an user
     * Returns a list of orders
     * @param requestBody
     * @returns any Successful response
     * @throws ApiError
     */
    public static postApiEcommerceItItGetOrderHistoryJson(
        requestBody?: GetOrderHistoryRequest,
    ): CancelablePromise<{
        data?: {
            orders?: Orders;
            pagination?: Pagination;
            totalOrders?: number;
        };
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ecommerce/it-it.get-order-history.json',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Delete current cart
     * Delete current cart
     * @returns any Successful response
     * @throws ApiError
     */
    public static postApiEcommerceItItDeleteCartJson(): CancelablePromise<{
        data?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ecommerce/it-it.delete-cart.json',
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Get user wishlists
     * Returns a list of wishlists
     * @returns any Successful response
     * @throws ApiError
     */
    public static postApiEcommerceItItGetWishlistsJson(): CancelablePromise<{
        data?: Array<any>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ecommerce/it-it.get-wishlists.json',
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Manage user wishlists
     * Add/remove products from/to a user wishlist
     * @param requestBody
     * @returns any Successful response
     * @throws ApiError
     */
    public static postApiEcommerceItItManageWishlistsJson(
        requestBody?: ManageWishlistsServletRequest,
    ): CancelablePromise<{
        data?: any;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ecommerce/it-it.manage-wishlists.json',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Get wishlists product cards
     * Returns an html with product cards
     * @param contentRedirectDirective Directive for content redirection
     * @param wishlistId Name of the wishlist ('default' if not specified)
     * @param price Price range of the product cards
     * @param pageSize Number of items per page (if pageSize != 0 enable pagination)
     * @param page Page index (the first page is 0)
     * @param cardType Type of card to return. Default: 'PRODUCT_CARD'
     * @param errorsAsServlet If true the related model returns errors as a servlet
     * @returns string Successful response
     * @throws ApiError
     */
    public static getWishlistJcrContentRootWishlistListHtml(
        contentRedirectDirective: string,
        wishlistId?: string,
        price?: 'incr' | 'decr',
        pageSize?: number,
        page?: number,
        cardType?: ProductCardType,
        errorsAsServlet?: boolean,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '{content-redirect-directive}/wishlist/_jcr_content/root/wishlist.list.html',
            path: {
                'content-redirect-directive': contentRedirectDirective,
            },
            query: {
                'wishlistId': wishlistId,
                'price': price,
                'pageSize': pageSize,
                'page': page,
                'cardType': cardType,
                'errorsAsServlet': errorsAsServlet,
            },
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Generate a cart starting from already existent order
     * Returns the new cart
     * @param requestBody
     * @returns any Successful response
     * @throws ApiError
     */
    public static postApiMyconadItItAddCartJson(
        requestBody?: AddCartRequest,
    ): CancelablePromise<{
        data?: Cart;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/myconad/it-it.addCart.json',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Cancel an existing order
     * Returns true if the order is canceled successfully
     * @param requestBody
     * @returns any Successful response
     * @throws ApiError
     */
    public static postApiMyconadItItCancelOrderJson(
        requestBody?: CancelOrderRequest,
    ): CancelablePromise<{
        data?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/myconad/it-it.cancelOrder.json',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Get user valid coupons
     * Get user valid coupons (only ecommerce coupons)
     * @param requestBody
     * @returns any Successful response
     * @throws ApiError
     */
    public static postApiMyconadItItCouponsJson(
        requestBody?: PaginationRequest,
    ): CancelablePromise<{
        data?: CouponsResponse;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/myconad/it-it.coupons.json',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Get user customer care requests
     * Returns a list of user customer care request
     * @returns any Successful response
     * @throws ApiError
     */
    public static getApiMyconadItItGetCustomerCareRequestJson(): CancelablePromise<{
        data?: CustomerCareResponse;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/myconad/it-it.getCustomerCareRequest.json',
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Get customer care request's attachments
     * Returns a list of customer care request's attachments
     * @param code Code of the customer care request
     * @returns any Successful response
     * @throws ApiError
     */
    public static getApiMyconadItItGetCaseAttachmentsListJson(
        code: string,
    ): CancelablePromise<{
        data?: CustomerCareAttachments;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/myconad/it-it.getCaseAttachmentsList.json',
            query: {
                'code': code,
            },
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
    /**
     * Close customer care request
     * Close customer care request
     * @param requestBody
     * @returns any Successful response
     * @throws ApiError
     */
    public static postApiMyconadItItCloseCustomerCareRequestJson(
        requestBody?: CloseCustomerCaseRequest,
    ): CancelablePromise<{
        data?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/myconad/it-it.closeCustomerCareRequest.json',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Unexpected error`,
                500: `Unexpected error`,
            },
        });
    }
}
