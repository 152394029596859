import { _$, _Uikit, Window, _draggable, _draggabilly } from '../../../main';
import { LOADER_POSITION, Utils } from '../utils';

export class DynamicTsManager{

    constructor(public utils: Utils){
        this.handleProgressClick();
    }
    
    nkOnInit(){
    }

    public dropzone;
    public slidingMobile = false;
    private touchstartX;
    private touchendX;
    private medianHour = 12;
    private distanceBetweenTimeslotsRatio;

    async createDraggableTSD(arraySlots, findPreselected){

        _$('.component-ModalTimeslot .alert-fascia-non-disponibile').removeClass('unavailable-timeslot-selected');
        let timeslotArray = arraySlots.timeSlots;
        let firstTimeslot = {
            firstTimeslotHour: timeslotArray[0] != null && timeslotArray[0].startTime != null && timeslotArray[0].startTime.hour != null ? timeslotArray[0].startTime.hour : 8,
            firstTimeslotMinutes : timeslotArray[0] != null && timeslotArray[0].startTime != null && timeslotArray[0].startTime.minute != null ? timeslotArray[0].startTime.minute : 0,
            formattedHour: timeslotArray[0] != null && timeslotArray[0].startTime != null && timeslotArray[0].startTime.formattedHour != null ? timeslotArray[0].startTime.formattedHour : '8:00',
            firstTimeslotEndHour: timeslotArray[0] != null && timeslotArray[0].endTime != null && timeslotArray[0].endTime.hour != null ? timeslotArray[0].endTime.hour : 22,
            firstTimeslotEndMinutes: timeslotArray[0] != null && timeslotArray[0].endTime != null && timeslotArray[0].endTime.minute != null ? timeslotArray[0].endTime.minute : 0,
            slotId: timeslotArray[0] != null && timeslotArray[0].slotId != null ? timeslotArray[0].slotId : ''
        }

        let lastTimeslot = {
            lastTimeslotHour : timeslotArray.slice(-1)[0] != null && timeslotArray.slice(-1)[0].endTime != null && timeslotArray.slice(-1)[0].endTime.hour != null ? timeslotArray.slice(-1)[0].endTime.hour : 22,
            lastTimeslotMinutes : timeslotArray.slice(-1)[0] != null && timeslotArray.slice(-1)[0].endTime != null && timeslotArray.slice(-1)[0].endTime.minute != null ? timeslotArray.slice(-1)[0].endTime.minute : 0,
            formattedHour : timeslotArray.slice(-1)[0] != null && timeslotArray.slice(-1)[0].endTime != null && timeslotArray.slice(-1)[0].endTime.formattedHour != null ? timeslotArray.slice(-1)[0].endTime.formattedHour : '22:00',
            slotId: timeslotArray.slice(-1)[0] != null && timeslotArray.slice(-1)[0].slotId != null ? timeslotArray.slice(-1)[0].slotId : ''
        }

        if (_$(window).width() >= 1024) {
            let timeslotArray = arraySlots.timeSlots;
            _$('.list-tsd-desktop').html('');
            let preselectedIndex = 0;
            let anyPromo = 0;
            timeslotArray.forEach((index, element) => {
                let timeslotData = timeslotArray[element];
                if(timeslotData.discountedDeliveryCost != null){
                    anyPromo += 1;
                }
                if(findPreselected){
                    if(Window.selectedTimeslot.slotId == timeslotData.slotId){
                        preselectedIndex = element;
                        _$('.list-tsd-desktop').append(`<div nkPage="SliderTimeslotTsd" class="component-SliderTimeslotTsd drag-dropzone draggable-dropzone--occupied active" data-timeslot-id=${timeslotData.slotId} data-hour="${timeslotData.startTime.formattedHour}-${timeslotData.endTime.formattedHour}"></div>`);
                    }else{
                        _$('.list-tsd-desktop').append(`<div nkPage="SliderTimeslotTsd" class="component-SliderTimeslotTsd drag-dropzone" data-timeslot-id=${timeslotData.slotId} data-hour="${timeslotData.startTime.formattedHour}-${timeslotData.endTime.formattedHour}"></div>`);
                    }

                    if(element == 0){
                        _$('.list-tsd-desktop .component-SliderTimeslotTsd').first().append(`<div class="balloon-timeslot ${timeslotData.available ? '' : 'not-available'}">
                                                                                            <div class="timespan">
                                                                                                <span class="start-time">${timeslotData.startTime.formattedHour}</span>
                                                                                                -
                                                                                                <span class="end-time">${timeslotData.endTime.formattedHour}</span>
                                                                                            </div>
                                                                                            <div class="price">
                                                                                                <div class="normal">${timeslotData.deliveryCost ? timeslotData.deliveryCost + '€' : 'Gratuita'}</div>
                                                                                                <div class="not-available">Non disponibile</div>
                                                                                                <div class="promo">${timeslotData.discountedDeliveryCost ? timeslotData.discountedDeliveryCost + '€' : 'Gratuita'}</div>
                                                                                            </div>
                                                                                            <button class="go-prev slider-arrow left" onclick="DynamicTsManager.dropPrev()"><i class="icon-prev"></i></button>
                                                                                            <button class="go-next slider-arrow right" onclick="DynamicTsManager.dropNext()"><i class="icon-next"></i></button>
                                                                                            <div class="triangle-cloud"></div>
                                                                                        </div>`)
                    }
                }else{
                    if(element == 0){
                        _$('.list-tsd-desktop').append(`<div nkPage="SliderTimeslotTsd" class="component-SliderTimeslotTsd drag-dropzone draggable-dropzone--occupied active" data-timeslot-id=${timeslotData.slotId} data-hour="${timeslotData.startTime.formattedHour}-${timeslotData.endTime.formattedHour}">
                                                        <div class="balloon-timeslot ${timeslotData.available ? '' : 'not-available'}">
                                                            <div class="timespan">
                                                                <span class="start-time">${timeslotData.startTime.formattedHour}</span>
                                                                -
                                                                <span class="end-time">${timeslotData.endTime.formattedHour}</span>
                                                            </div>
                                                            <div class="price">
                                                                <div class="normal">${timeslotData.deliveryCost ? timeslotData.deliveryCost + '€' : 'Gratuita'}</div>
                                                                <div class="not-available">Non disponibile</div>
                                                                <div class="promo">${timeslotData.discountedDeliveryCost ? timeslotData.discountedDeliveryCost + '€' : 'Gratuita'}</div>
                                                            </div>
                                                            <button class="go-prev slider-arrow left" onclick="DynamicTsManager.dropPrev()"><i class="icon-prev"></i></button>
                                                            <button class="go-next slider-arrow right" onclick="DynamicTsManager.dropNext()"><i class="icon-next"></i></button>
                                                            <div class="triangle-cloud"></div>
                                                        </div>
                                                    </div>`);
                    }else{
                        _$('.list-tsd-desktop').append(`<div nkPage="SliderTimeslotTsd" class="component-SliderTimeslotTsd drag-dropzone" data-timeslot-id=${timeslotData.slotId} data-hour="${timeslotData.startTime.formattedHour}-${timeslotData.endTime.formattedHour}"></div>`);
                    }
                }

                _$('.list-tsd-desktop').find('.component-SliderTimeslotTsd').last().attr('data-timeslot', JSON.stringify(timeslotData));
            });

            _$('.list-tsd-desktop .drag-dropzone').first().addClass('hide-prev');
            _$('.list-tsd-desktop .drag-dropzone').last().addClass('hide-next');

            if(anyPromo > 0){

                _$('.balloon-timeslot').addClass('promo');

                if(anyPromo == timeslotArray.length){
                    _$('.balloon-timeslot').addClass('all-promo');
                }

                this.manageBalloonTimeslot(JSON.parse(_$('.component-SliderTimeslotTsd.draggable-dropzone--occupied').attr('data-timeslot')));
                
            }

            let overlapInHalfHours = arraySlots.distance < arraySlots.durationMinutes ? Math.ceil((arraySlots.durationMinutes - arraySlots.distance) / 30) : 0;
            let marginLeft = -6 - (overlapInHalfHours * 15 + (Math.floor(overlapInHalfHours / 2) * 6));
            document.documentElement.style.setProperty('---tsd-margin-left-overflow', marginLeft + 'px');
            this.createDesktopTimeline(firstTimeslot, lastTimeslot, arraySlots.durationMinutes, arraySlots.distance, timeslotArray);
            let listTsdDesktopOffsetWidth = 115 - 18 - (arraySlots.durationMinutes != null && arraySlots.durationMinutes / 60 > 1 ? ((arraySlots.durationMinutes / 60) - 1) * 18 : 0);;
            let dragzoneLength = parseInt(document.documentElement.style.getPropertyValue('---tsd-timeline-width-desktop-type-a')) + (2 * listTsdDesktopOffsetWidth);
            document.documentElement.style.setProperty('---tsd-dragzone-width-desktop-type-a', dragzoneLength + 'px');


            let droppableCentersOffset = [];
            let boundingOffset = _$('.component-SliderTimeslotTsd').first().offset().left;

            _$('.component-SliderTimeslotTsd').each((index,value) => {
                let offset = _$(value).offset();
                let width = _$(value).width();
                var centerX = offset.left + width / 2;
                droppableCentersOffset[index] = centerX - boundingOffset;
            });

            var draggabilly = new _draggabilly('.balloon-timeslot',
            {
                containment: '.list-tsd-desktop',
                axis: 'x'
            })

            let manageBalloonDragEnd = (event, pointer, moveVector) => {
                let width = _$('.balloon-timeslot').width();
                var centerX = draggabilly.position.x + width / 2;

                var counts = droppableCentersOffset,
                goal = centerX;

                var closest = counts.reduce(function(prev, curr) {
                return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
                });

                draggabilly.setPosition(closest - (width / 2));
                
                let activeDropzone = droppableCentersOffset.indexOf(closest);
                _$('.component-SliderTimeslotTsd').removeClass('active');
                this.manageBalloonTimeslot(JSON.parse(_$('.component-SliderTimeslotTsd').eq(activeDropzone).attr('data-timeslot')));
                _$('.component-SliderTimeslotTsd').eq(activeDropzone).addClass('active');
            }

            let manageBalloonDragMove = (event, pointer, moveVector) => {
                _$('.component-SliderTimeslotTsd').removeClass('active');
                let width = _$('.balloon-timeslot').width();
                var centerX = draggabilly.position.x + width / 2;

                var counts = droppableCentersOffset,
                goal = centerX;

                var closest = counts.reduce(function(prev, curr) {
                return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
                });
                
                let activeDropzone = droppableCentersOffset.indexOf(closest);
                this.manageBalloonTimeslot(JSON.parse(_$('.component-SliderTimeslotTsd').eq(activeDropzone).attr('data-timeslot')));
            }

            draggabilly.on('dragEnd', manageBalloonDragEnd);
            draggabilly.on('dragMove', manageBalloonDragMove);
            
            draggabilly.setPosition(droppableCentersOffset[preselectedIndex] - (_$('.balloon-timeslot').width() / 2));
            this.manageBalloonTimeslot(JSON.parse(_$('.component-SliderTimeslotTsd.active').attr('data-timeslot')));

            /* if(this.dropzone != null){
                this.dropzone.destroy();
            }
            
            this.dropzone = new _draggable.Droppable(document.querySelectorAll('.list-tsd-desktop'), {
                draggable: '.balloon-timeslot',
                dropzone: '.drag-dropzone',
                mirror: {
                    yAxis: false
                }
            });
    
            this.dropzone.on('mirror:created', (evt) => {
                _$('.balloon-timeslot').css('visibility', 'hidden');
            });
    
    
            this.dropzone.on('drag:stop', () => {
                _$('.balloon-timeslot').css('visibility', 'visible');
                _$('.component-SliderTimeslotTsd:not(.draggable-dropzone--occupied)').find('.balloon-timeslot').remove();
                this.utils.scrollbarList['scrollable-section-timeslot'].destroy();
                this.utils.customScrollbar();
            });

            this.dropzone.on('drag:start', (event) => {
                _$('.component-ModalTimeslot .alert-fascia-non-disponibile').removeClass('unavailable-timeslot-selected');
                this.utils.scrollbarList['scrollable-section-timeslot'].destroy();
                this.utils.customScrollbar(true);
            });
            
    
            this.dropzone.on('droppable:dropped', () => {
                setTimeout(() => {
                    this.manageBalloonTimeslot(JSON.parse(_$('.component-SliderTimeslotTsd.draggable-dropzone--occupied.draggable-dropzone--active').attr('data-timeslot')));
                    _$('.component-SliderTimeslotTsd:not(.draggable-dropzone--occupied)').find('.balloon-timeslot').remove(); 
                }, 1);
            });

            this.dropzone.on('droppable:returned', () => {
                setTimeout(() => {
                    this.manageBalloonTimeslot(JSON.parse(_$('.component-SliderTimeslotTsd.draggable-dropzone--occupied').attr('data-timeslot')));
                    _$('.component-SliderTimeslotTsd:not(.draggable-dropzone--occupied)').find('.balloon-timeslot').remove();
                }, 1)
            });

            this.dropzone.on('drag:move', (event) => {
                let mirror = _$('.draggable-mirror');
                let currentPointer = event.sensorEvent.clientX;
                let gameContainer = document.getElementById('tsd-slider-container')
                let bounds = gameContainer.getBoundingClientRect()
                // To give a little extra room around the edges:
                let boundsPaddingX = -5;
                let minX = (bounds.x - boundsPaddingX)
                let maxX = (bounds.x + bounds.width + boundsPaddingX);

                let mirrorCenter = mirror.offset().left + (mirror.width() / 2);

                if(currentPointer > minX && currentPointer < maxX){
                    _$(mirror).offset({left: currentPointer - mirror.width() / 2})
                }else if(mirrorCenter < minX || mirrorCenter > maxX){
                    event.cancel();
                }
              }) */
        }else{
            

            let distanceBetweenTimeslots = arraySlots.distance/60;
            this.distanceBetweenTimeslotsRatio = distanceBetweenTimeslots;
            _$('.ts-dinamico-hours-mobile .container-timeslots').html('');
            if(findPreselected){
                _$('.ts-dinamico-hours-mobile .container-timeslots, .ts-dinamico-hours-mobile .container-scrollbar').css('display', 'none');
            }
            
            timeslotArray.forEach((index, element) => {
                let timeslotData = timeslotArray[element];
                _$('.ts-dinamico-hours-mobile .container-timeslots').append(`
                    <div nkPage="SliderTimeslotTsd" class="component-SliderTimeslotTsd slider-timeslot ${timeslotData.available ? '' : 'not-available'} 
                        ${element == 0 ? 'active' : ''}" data-timeslot-id=${timeslotData.slotId} data-hour="${timeslotData.startTime.formattedHour}-${timeslotData.endTime.formattedHour}">
                        <div class="timespan">
                            <span class="start-time">${timeslotData.startTime.formattedHour}</span>
                            -
                            <span class="end-time">${timeslotData.endTime.formattedHour}</span>
                        </div>
                        <div class="price">
                            <div class="normal">${timeslotData.deliveryCost ? timeslotData.deliveryCost + '€' : '0€'}</div>
                            <div class="not-available">Non disponibile</div>
                            <div class="promo">${timeslotData.discountedDeliveryCost ? timeslotData.discountedDeliveryCost + '€' : '0€'}</div>
                        </div>
                        <button class="slider-arrow left" onclick="DynamicTsManager.slideLeft(${distanceBetweenTimeslots})"><i class="icon-prev"></i></button>
                        <button class="slider-arrow right" onclick="DynamicTsManager.slideRight(${distanceBetweenTimeslots})"><i class="icon-next"></i></button>
                    </div>
                `)

                _$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd').last().attr('data-timeslot', JSON.stringify(timeslotArray[element]))
                
                if(!_$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd.active').length){
                    _$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd').first().addClass('active');
                }
            });

            this.createMobileTimeline(firstTimeslot, lastTimeslot, arraySlots.durationMinutes, arraySlots.distance, timeslotArray);
            this.mobileUpdateSliderArrows();
            this.mobileCheckPromotionsTypeA(timeslotArray);
            this.manageSwipesMobile();

            if(findPreselected){
                this.utils.showLoader('', true);
                for(let i = 0; i <= _$(`.component-SliderTimeslotTsd[data-timeslot-id=${Window.selectedTimeslot.slotId}]`).index(); i++){
                    console.log('for loop')
                    await new Promise(resolve => setTimeout(() => {
                        if(i == _$(`.component-SliderTimeslotTsd[data-timeslot-id=${Window.selectedTimeslot.slotId}]`).index()){
                            _$('.ts-dinamico-hours-mobile .container-timeslots').css('display', 'block');
                            _$('.ts-dinamico-hours-mobile .container-scrollbar').css('display', 'flex');
                            this.utils.showLoader('', false);
                            resolve('');
                        }else{
                            this.slideRight();
                            resolve('');
                        }
                    }, 300));
                }
            }
        }
    }

    createMobileTimeline(firstTimeslot, lastTimeslot, durationMinutes, distance, arraySlots){
        _$('.ts-dinamico-hours-mobile .timeline-tsd').html('');
        let firstHour = firstTimeslot.firstTimeslotHour;
        let lastHour = lastTimeslot.lastTimeslotHour;
        let firstMinutes = firstTimeslot.firstTimeslotMinutes;
        let lastMinutes = lastTimeslot.lastTimeslotMinutes;
        if(firstTimeslot.slotId != lastTimeslot.slotId){
            _$('.ts-dinamico-hours-mobile .timeline-tsd').removeClass('full-day').addClass('partial-day');
            for(let i = firstHour; i <= lastHour; i++){
                _$('.ts-dinamico-hours-mobile .timeline-tsd').append(`<div class="timeslot-hour ${firstMinutes != 0 ? 'with-half-hour' : ((durationMinutes%60 != 0 || distance%60 != 0) ? 'show-half-hour' : '')} ${firstHour != this.medianHour && i == this.medianHour ? 'median-hour' : ''}" data-hour=${i} data-minutes=${firstMinutes}>
                                                                        <div class="timeslot-hour-dot"></div>
                                                                    </div>`)
            }

            _$(`.ts-dinamico-hours-mobile .timeslot-hour[data-hour="${firstHour}"`).attr('data-formatted-hour', firstTimeslot.formattedHour);
            _$(`.ts-dinamico-hours-mobile .timeslot-hour[data-hour="${lastHour}"`).attr('data-formatted-hour', lastTimeslot.formattedHour);
            if(arraySlots != null && arraySlots[0] != null && arraySlots[0].tipologiaFascia != null && arraySlots[0].tipologiaFascia != "TYPE4"){
                _$(`.ts-dinamico-hours-mobile .timeslot-hour.median-hour`).attr('data-formatted-hour', firstMinutes != 0 ? this.medianHour + ':30' : this.medianHour + ':00');
            }
            let hourDots = (lastHour - firstHour + 1) + (lastMinutes != firstMinutes ? 1 : 0);
            let tsdTimelineWidth = (6 * hourDots) + (hourDots - 1) * 34;

            document.documentElement.style.setProperty('---tsd-timeline-width-mobile-type-a', tsdTimelineWidth + 'px');
            document.documentElement.style.setProperty('---tsd-active-timeslot-mobile-width-type-a', (durationMinutes/60) * 34 + ((durationMinutes/60) + 1) * 6 + 'px');
        }else{
            _$('.ts-dinamico-hours-mobile .timeline-tsd').append(`<div class="timeslot-hour" data-hour=${firstTimeslot.firstTimeslotHour} data-minutes=${firstTimeslot.firstTimeslotMinutes}>
                                                                    <div class="timeslot-hour-dot"></div>
                                                                </div>`)
            _$('.ts-dinamico-hours-mobile .timeline-tsd').append(`<div class="timeslot-hour" data-hour=${firstTimeslot.firstTimeslotEndHour} data-minutes=${firstTimeslot.firstTimeslotEndMinutes}>
                                                                <div class="timeslot-hour-dot"></div>
                                                            </div>`)

            _$(`.ts-dinamico-hours-mobile .timeslot-hour[data-hour="${firstHour}"`).attr('data-formatted-hour', firstTimeslot.formattedHour);
            _$(`.ts-dinamico-hours-mobile .timeslot-hour[data-hour="${lastHour}"`).attr('data-formatted-hour', lastTimeslot.formattedHour);

            _$('.ts-dinamico-hours-mobile .timeline-tsd').addClass('full-day').removeClass('partial-day');
            document.documentElement.style.setProperty('---tsd-timeline-width-mobile-type-a', '311px');
            document.documentElement.style.setProperty('---tsd-active-timeslot-mobile-width-type-a', '311px');
        } 
    }

    createDesktopTimeline(firstTimeslot, lastTimeslot, durationMinutes, distance, arraySlots){
        let firstHour = firstTimeslot.firstTimeslotHour;
        let lastHour = lastTimeslot.lastTimeslotHour;
        let firstMinutes = firstTimeslot.firstTimeslotMinutes;
        let lastMinutes = lastTimeslot.lastTimeslotMinutes;

        let hourDots = (lastHour - firstHour + 1) + (lastMinutes != firstMinutes ? 1 : 0);
        let tsdTimelineWidth = (6 * hourDots) + (hourDots - 1) * 30;
        _$('.ts-dinamico-hours-desktop .timeslot-hour-container').html('');
        for(let i = firstHour; i <= lastHour; i++){
            _$('.ts-dinamico-hours-desktop .timeslot-hour-container').append(`<div class="timeslot-hour ${firstMinutes != 0 ? 'with-half-hour' : ((durationMinutes%60 != 0 || distance%60 != 0) ? 'show-half-hour' : '')} ${firstHour != this.medianHour && i == this.medianHour ? 'median-hour' : ''}" data-hour=${i} data-minutes=${firstMinutes}>
                                                                                <div class="timeslot-hour-dot"></div>
                                                                            </div>`)
        }
        document.documentElement.style.setProperty('---tsd-timeline-width-desktop-type-a', tsdTimelineWidth + 'px');
        _$(`.ts-dinamico-hours-desktop .timeslot-hour-container .timeslot-hour[data-hour="${firstHour}"`).attr('data-formatted-hour', firstTimeslot.formattedHour);
        _$(`.ts-dinamico-hours-desktop .timeslot-hour-container .timeslot-hour[data-hour="${lastHour}"`).attr('data-formatted-hour', lastTimeslot.formattedHour);
        if(arraySlots != null && arraySlots[0] != null && arraySlots[0].tipologiaFascia != null && arraySlots[0].tipologiaFascia != "TYPE4"){
            _$(`.ts-dinamico-hours-desktop .timeslot-hour-container .timeslot-hour.median-hour`).attr('data-formatted-hour', firstMinutes != 0 ? this.medianHour + ':30' : this.medianHour + ':00');
        }
        document.documentElement.style.setProperty('---tsd-active-timeslot-desktop-width-type-a', (durationMinutes/60) * 30 + ((durationMinutes/60) + 1) * 6 + 'px');
        document.documentElement.style.setProperty('---tsd-drag-dropzone-width', (durationMinutes/60) * 30 + ((durationMinutes/60) + 1) * 6 + 'px');
    }

    dropNext(){
        _$('.component-ModalTimeslot .alert-fascia-non-disponibile').removeClass('unavailable-timeslot-selected');
        let currentDropzone = _$('.component-SliderTimeslotTsd.active');
        let nextDropzone = _$(currentDropzone).next().first();
        _$('.balloon-timeslot').addClass('slide-draggable-right');
        _$(currentDropzone).removeClass('active');
        _$(nextDropzone).addClass('active');
        setTimeout(() => {
            this.manageBalloonTimeslot(JSON.parse(_$(nextDropzone).attr('data-timeslot')));
            _$('.balloon-timeslot').css('left', '+=36');
            _$('.balloon-timeslot').removeClass('slide-draggable-right');
        }, 200);
    }

    dropPrev(){
        _$('.component-ModalTimeslot .alert-fascia-non-disponibile').removeClass('unavailable-timeslot-selected');
        let currentDropzone = _$('.component-SliderTimeslotTsd.active');
        let prevDropzone = _$(currentDropzone).prev().first();
        _$('.balloon-timeslot').addClass('slide-draggable-left');
        _$(currentDropzone).removeClass('active')
        _$(prevDropzone).addClass('active');
        setTimeout(() => {
            this.manageBalloonTimeslot(JSON.parse(_$(prevDropzone).attr('data-timeslot')));
            _$('.balloon-timeslot').css('left', '-=36');
            _$('.balloon-timeslot').removeClass('slide-draggable-left');
        }, 200);
    }

    slideLeft(iterations = 1){
        _$('.component-ModalTimeslot .alert-fascia-non-disponibile').removeClass('unavailable-timeslot-selected');
        if(!this.slidingMobile && this.mobilePrevExists()){
            this.slidingMobile = true;
            /* distanza in px tra le startTime dei timeslot, uguale per tutti, da calcolare dinamicamente */
            /* dinamico. dipende dalla distanza tra i timeslot, multipli di mezzo dot(3px) + mezzo gap(17px) */
            let distanceBetweenTimeslots = 40;
            let distancePx = distanceBetweenTimeslots * iterations;
            _$('.timeline-tsd').animate({
                
                'left': "+=" + distancePx + "px"
            }, 300);

            let currentActive = _$('.slider-timeslot.active');
            let currentPrev = _$('.slider-timeslot.prev');
            let currentNext = _$('.slider-timeslot.next');
            let newPrev =  _$(currentPrev).prev().length ?  _$(currentPrev).prev() : null;
            _$('.slider-timeslot.active, .slider-timeslot.next, .slider-timeslot.prev').addClass('slide-slider-timeslot-left');
            setTimeout(() => {
                _$(currentActive).removeClass('active').addClass('next');
                _$(currentPrev).removeClass('prev').addClass('active');
                _$(currentNext).removeClass('next');
                if(newPrev != null){
                    _$(newPrev).addClass('prev');
                }
                _$('.slide-slider-timeslot-left').removeClass('slide-slider-timeslot-left');
                this.slidingMobile = false;
                this.mobileUpdateSliderArrows();
            }, 300);
        }
    }

    slideRight(iterations = 1){
        _$('.component-ModalTimeslot .alert-fascia-non-disponibile').removeClass('unavailable-timeslot-selected');
        if(!this.slidingMobile && this.mobileNextExists()){
            this.slidingMobile = true;
            /* distanza in px tra le startTime dei timeslot, uguale per tutti, da calcolare dinamicamente */
            /* dinamico. dipende dalla distanza tra i timeslot, multipli di mezzo dot(3px) + mezzo gap(17px) */
            let distanceBetweenTimeslots = 40;
            let distancePx = distanceBetweenTimeslots * iterations;
            _$('.timeline-tsd').animate({
                /* dinamico. dipende dalla distanza tra i timeslot, multipli di mezzo dot + mezzo gap */
                'left': "-=" + distancePx +"px"
            }, 300);

            let currentActive = _$('.slider-timeslot.active');
            let currentPrev = _$('.slider-timeslot.prev');
            let currentNext = _$('.slider-timeslot.next');
            let newNext = _$(currentNext).next().length ?  _$(currentNext).next() : null;
            _$('.slider-timeslot.active, .slider-timeslot.next, .slider-timeslot.prev').addClass('slide-slider-timeslot-right');

            setTimeout(() => {
                this.slidingMobile = false;
                _$(currentActive).removeClass('active').addClass('prev');
                _$(currentPrev).removeClass('prev');
                _$(currentNext).removeClass('next').addClass('active');
                if(newNext != null){
                    _$(newNext).addClass('next');
                }
                _$('.slide-slider-timeslot-right').removeClass('slide-slider-timeslot-right');
                this.mobileUpdateSliderArrows();
            }, 300);
        }
    }

    manageSwipesMobile(){
        let touchableElement = _$('.ts-dinamico-hours-mobile .slider-timeslot');
        _$(touchableElement).on('touchstart', (event) => {
            this.touchstartX = event.changedTouches[0].screenX;
        });
        
        _$(touchableElement).on('touchend', (event) => {
            this.touchendX = event.changedTouches[0].screenX;
            this.handleGesture();
        });
    }

    handleGesture() {
        if (this.touchendX < this.touchstartX) {
            this.slideRight(this.distanceBetweenTimeslotsRatio);
        }else if (this.touchendX > this.touchstartX) {
            this.slideLeft(this.distanceBetweenTimeslotsRatio);
        }
    }

    processDeliveryBands(): object{
        let jsonBands;
        return jsonBands;
    }

    handleProgressClick(){
        _$('.timeline-tsd').on('click', (event) => {
            let center = _$(Window).width() / 2;
            if (event.clientX > center) {
              this.slideRight();
            } else {
              this.slideLeft();
            }
        });
    }

    /* verifica se esiste un timeslot successivo */
    mobileNextExists(): boolean{
        return _$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd.active').next().length ? true : false;
    }

    /* verifica se esiste un timeslot precedente */
    mobilePrevExists(): boolean{
        return _$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd.active').prev().length ? true : false;
    }

    mobileUpdateSliderArrows(){
        if(this.mobilePrevExists()){
            _$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd.active').prev().addClass('prev');
            _$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd.active').find('.slider-arrow.left').removeClass('uk-hidden');
        }else{
            _$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd.active').find('.slider-arrow.left').addClass('uk-hidden');
        }

        if(this.mobileNextExists()){
            _$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd.active').next().addClass('next');
            _$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd.active').find('.slider-arrow.right').removeClass('uk-hidden')
        }else{
            _$('.ts-dinamico-hours-mobile .container-timeslots .component-SliderTimeslotTsd.active').find('.slider-arrow.right').addClass('uk-hidden')
        }
    }

    mobileCheckPromotionsTypeA(timeSlots){
        let timeslotsInPromo = 0;
        let timeslotsLength = timeSlots.length;
        timeSlots.forEach((index, element) => {
            let timeslotData = timeSlots[element];
            if(timeslotData.available && timeslotData.deliveryCost != null){
                let discountedCost = timeslotData.discountedDeliveryCost;
                let cost = timeslotData.deliveryCost;
                if(discountedCost != null && discountedCost == 0){
                    /* Gratuita */
                    _$(`.component-SliderTimeslotTsd[data-timeslot-id=${timeslotData.slotId}] .price .promo`).html('Gratuita');
                    _$(`.component-SliderTimeslotTsd[data-timeslot-id=${timeslotData.slotId}]`).addClass('promo in-promo');
                    timeslotsInPromo += 1;
                }else if(discountedCost != null && discountedCost != cost){
                    /* scontato */
                    _$(`.component-SliderTimeslotTsd[data-timeslot-id=${timeslotData.slotId}] .price .promo`).html(discountedCost + '€');
                    _$(`.component-SliderTimeslotTsd[data-timeslot-id=${timeslotData.slotId}]`).addClass('promo in-promo');
                    timeslotsInPromo += 1;
                }
            }
        });

        if(timeslotsInPromo > 0){
            if(timeslotsInPromo == timeslotsLength){
                _$('.component-SliderTimeslotTsd.in-promo').removeClass('in-promo').addClass('all-promo');
            }else{
                _$('.component-SliderTimeslotTsd:not(.in-promo)').addClass('promo out-promo');
            }
        }
    }

    manageCardOpzioniConsegnaPromotions(bands){
        let types = Object.keys(bands.deliveryBandMap);
        types.forEach((index, element) => {
            let band = bands.deliveryBandMap[types[element]];
            let dataBandId = types[element];
            let timeslots = band.timeSlots;
            let timeslotsLength = timeslots.length;
            let timeslotsInPromo = 0;
            let mostExpensiveDiscountedTimeslot = 0;
            let mostExpensiveNonDiscountedTimeslot = 0;
            timeslots.forEach((index, element) => {
                if(timeslots[element].discountedDeliveryCost != null){
                    timeslotsInPromo += 1;
                    if(mostExpensiveDiscountedTimeslot < timeslots[element].discountedDeliveryCost){
                        mostExpensiveDiscountedTimeslot = timeslots[element].discountedDeliveryCost;
                        mostExpensiveNonDiscountedTimeslot = timeslots[element].deliveryCost;
                    }
                }else{
                    mostExpensiveNonDiscountedTimeslot = timeslots[element].deliveryCost;
                }
            });

            if(mostExpensiveDiscountedTimeslot == 0 && timeslotsInPromo == timeslotsLength && timeslotsInPromo > 0){
                /* tutti gratuiti */
                _$(`.component-CardOpzioneConsegna[data-band-id=${dataBandId}]`).removeClass('normal').removeClass('promo').addClass('free');
            }else if(mostExpensiveDiscountedTimeslot > 0 && timeslotsInPromo == timeslotsLength && timeslotsInPromo > 0){
                /* tutti scontati, prendere il meno scontato */
                _$(`.component-CardOpzioneConsegna[data-band-id=${dataBandId}]`).removeClass('normal').removeClass('free').addClass('promo');
                _$(`.component-CardOpzioneConsegna[data-band-id=${dataBandId}]`).find('.band-price .promo .old').html(mostExpensiveNonDiscountedTimeslot + '€');
                _$(`.component-CardOpzioneConsegna[data-band-id=${dataBandId}]`).find('.band-price .promo .new').html(mostExpensiveDiscountedTimeslot + '€');
            }else{
                /* non scontati oppure non tutti scontati, usare il prezzo piu alto del delivery cost */
                _$(`.component-CardOpzioneConsegna[data-band-id=${dataBandId}]`).removeClass('free').removeClass('promo').addClass('normal');
                _$(`.component-CardOpzioneConsegna[data-band-id=${dataBandId}]`).find('.band-price .normal').html(band.deliveryCost + '€');
            }
        })
    }

    manageBalloonTimeslot(dataTimeslot){
        _$('.balloon-timeslot .timespan .start-time').html(dataTimeslot.startTime.formattedHour);
        _$('.balloon-timeslot .timespan .end-time').html(dataTimeslot.endTime.formattedHour);
        _$('.balloon-timeslot .price .normal').html(dataTimeslot.deliveryCost != null && dataTimeslot.deliveryCost != 0 ? dataTimeslot.deliveryCost + '€' : 'Gratuita');
        _$('.balloon-timeslot .price .promo').html(dataTimeslot.discountedDeliveryCost != null && dataTimeslot.discountedDeliveryCost != 0 ? dataTimeslot.discountedDeliveryCost + '€' : 'Gratuita');
        if(_$('.balloon-timeslot').hasClass('promo') && dataTimeslot.deliveryCost != null && !_$('.balloon-timeslot').hasClass('all-promo')){
            let discountedCost = dataTimeslot.discountedDeliveryCost;
            let cost = dataTimeslot.deliveryCost;
            _$(`.balloon-timeslot`).removeClass('in-promo out-promo');
            if(discountedCost != null && discountedCost == 0){
                /* Gratuita */
                _$(`.price .promo`).html('Gratuita');
                _$(`.balloon-timeslot`).addClass('in-promo');
            }else if(discountedCost != null && discountedCost != cost){
                /* scontato */
                _$(`.price .promo`).html(discountedCost + '€');
                _$(`.balloon-timeslot`).addClass('in-promo');
            }else{
                _$(`.balloon-timeslot`).addClass('out-promo');
            }
        }

        if(!dataTimeslot.available){
            _$('.balloon-timeslot').addClass('not-available');
            if(_$('.balloon-timeslot').hasClass('promo') && !_$('.balloon-timeslot').hasClass('all-promo')){
                _$('.balloon-timeslot').removeClass('in-promo').addClass('out-promo');
            }
        }else{
            _$('.balloon-timeslot').removeClass('not-available');
        }
        if(!_$(`.component-SliderTimeslotTsd[data-timeslot-id=${dataTimeslot.slotId}]`).hasClass('hide-next')){
            _$('.balloon-timeslot .slider-arrow.go-next').css('display', 'flex');
        }else{
            _$('.balloon-timeslot .slider-arrow.go-next').css('display', 'none');
        }

        if(!_$(`.component-SliderTimeslotTsd[data-timeslot-id=${dataTimeslot.slotId}]`).hasClass('hide-prev')){
            _$('.balloon-timeslot .slider-arrow.go-prev').css('display', 'flex');
        }else{
            _$('.balloon-timeslot .slider-arrow.go-prev').css('display', 'none');
        }

    }
}