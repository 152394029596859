import { BaseService } from "../base-service";
import { Window } from '../../main';

import {
    HEADER_REQUEST,
    BASE_URL,
    STORES,
    TIMESLOT_STORE,
    CHOSEN_STORE,
    RESERVE_TIMESLOT,
    EXTEND_ADDRESSES,
    OLDPLATFORM_PDV_HP,
    CLEAN_ECACCESS,
    RESERVE_TIMESLOT_ON_CART,
    EXTEND_TIMESLOT,
    FIRST_TIMESLOT_BY_STORES,
} from "../config";

export class StoreService extends BaseService {
    constructor() {
        super();
    }

    nkOnInit() {}

    getAddress(success, error, body): void {
        this.post(BASE_URL + STORES, success, error, body, HEADER_REQUEST);
    }

    getAvailableTimeSlot(success, error, body): void {
        this.post(BASE_URL + TIMESLOT_STORE, success, error, body, HEADER_REQUEST);
    }

    async setChosenStore(success, error, body): Promise<void> {
        try {
            let protection = true;
            if (Window.pointOfService?.name == body?.pointOfServiceId) {
                protection = false;
            }
            if (protection) body["protectionToken"] = await window.gpGetProtectionToken("entryaccess");
            this.post(BASE_URL + CHOSEN_STORE, success, error, body, HEADER_REQUEST);
        } catch (e) {
            console.error("Something goes wrong", e);
            error();
        }
    }

    async reserveTimeSlot(success, error, body): Promise<void> {
        try {
            let protection = true;
            if (Window.pointOfService?.name == body?.pointOfServiceId) {
                protection = false;
            }
            if (protection) body["protectionToken"] = await window.gpGetProtectionToken("entryaccess");
            this.postAxios(BASE_URL + RESERVE_TIMESLOT, success, error, body, HEADER_REQUEST);
        } catch (e) {
            console.error("Something goes wrong", e);
            error();
        }
    }

    extendAddresses(success, error, body): void {
        this.post(BASE_URL + EXTEND_ADDRESSES, success, error, body, HEADER_REQUEST);
    }

    getOldPdv(success, error, body): void {
        this.post(BASE_URL + OLDPLATFORM_PDV_HP, success, error, body, HEADER_REQUEST);
    }

    cleanEcaccess(success, error, body): void {
        this.post(BASE_URL + CLEAN_ECACCESS, success, error, body, HEADER_REQUEST);
    }

    reserveTimeSlotOnCart(success, error, body): void {
        this.postAxios(BASE_URL + RESERVE_TIMESLOT_ON_CART, success, error, body, HEADER_REQUEST);
    }

    extendTimeslot(success, error, body): void {
        this.postAxios(BASE_URL + EXTEND_TIMESLOT, success, error, body, HEADER_REQUEST);
    }

    getFirstAvailableTimeslot(success, error, body): void {
        this.post(BASE_URL + FIRST_TIMESLOT_BY_STORES, success, error, body, HEADER_REQUEST);
    }

    extractStoreIds(stores): Array<string> {
        return this.extractStoreValue(stores, "name");
    }

    extractDeliveryAreas(stores): Array<string> {
        return this.extractStoreValue(stores, "selectedDeliveryId");
    }

    extractStoreValue(stores, value) {
        let values = [];
        for (let i = 0; i < stores.length; i++) {
            if (stores[i][value]) {
                values.push(stores[i][value]);
            } else {
                values.push(null);
            }
        }
        return values;
    }
}
