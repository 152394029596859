import { Utils } from "../../services/utils/utils";
import Swiper, { SwiperOptions } from 'swiper';
import { _Uikit, Window, _$ } from '../../main';
import { SwiperConfigInitializer } from "../../services/utils/swiper-config-initializer";

export class PromoComponent {
   
    constructor(
        private _utils: Utils,
        private _swiperConfigInitializer: SwiperConfigInitializer
    ) { 
        this._utils.randomizeCards('.component-PromoComponent');

        for(let i = 0; i < _$('.swiper-promo-idm').length; i++){
            if(_$('.swiper-promo-idm').eq(i).find('.swiper-slide').length > 1){
                let json = this.swiperPromoIdmOptionsTemplate;
                json.pagination.el = '.pagination-promo-idm'+ '-'+i;
                _$('.swiper-promo-idm').eq(i).find('.pagination-promo-idm').addClass('pagination-promo-idm'+'-'+i);
                const swiperPromoIdm = new Swiper(_$('.swiper-promo-idm')[i], this._swiperConfigInitializer.initConfig(['autoplay'], _$('.swiper-promo-idm')[i].closest('.component-PromoComponent'), json));
            }else{
                const swiperPromoIdm = new Swiper(_$('.swiper-promo-idm')[i], this.swiperPromoIdmOptionsNoPagination);
            }
        }
    }
    
    nkOnInit() { 
    }  

    swiperPromoIdmOptionsArray = [];
    swiperPromoIdmOptionsNoPaginationArray = [];

    swiperPromoIdmOptionsTemplate: SwiperOptions = {
        freeMode: false,
        loop: true,
        autoplay: {delay: 5000, disableOnInteraction: false},
        spaceBetween: 10,
        slidesPerGroup: 1,
        slidesPerView: 1,
        pagination: {
            el: '.pagination-promo-idm',
            clickable: true,
        },
        breakpointsInverse: true
    }

    swiperPromoIdmOptionsNoPagination: SwiperOptions = {
        freeMode: false,
        loop: true,
        spaceBetween: 10,
        slidesPerGroup: 1,
        slidesPerView: 1,
        allowSlideNext: false,
        allowSlidePrev: false,
        breakpointsInverse: true
    };

    
 }