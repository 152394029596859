import { LOADER_POSITION, Utils } from "../../services/utils/utils";
import {_$, _Uikit, Window, _dropzone} from "../../main";
import { UserService } from "../../services/user-service/user-service";
import { GoogleUtils } from '../../services/utils/google-utils';
import { Analytics } from "src/services/analytics/analytics";
import { LocalStorage } from "src/services/local-storage/local-storage";
export class FAQ {

    public maxFilesSize = 20; //in MB
    public faqForm = _$('#faqForm');
    public myDropzone;
    public formType = '';
    public orderPattern = 'o-[a-z]{1}-[A-Z]{1}-[0-9]{2}-[0-9]{8}';

    constructor(
        private _userService: UserService,
        private _utils: Utils,
        private _google: GoogleUtils,
        private _analytics: Analytics,
        private localStorage: LocalStorage
    ) { }
    
    nkOnInit() { 

        let url = Window.location.href;
        if (url.indexOf('?') != -1) {
            let params = url.toString().split('?')[1];
            let agType = '';
            let formType = params.split('&');
            if(formType.length){
                for(let i = 0; i < formType.length; i++){
                    if(formType[i].split('=').length == 2){
                        if(formType[i].split('=')[0] == 'agType' || formType[i].split('=')[0] == 'formType'){
                            agType = formType[i].split('=')[1]; 
                        }
                    }
                }
            }

            switch(agType) {
                case 'over':
                    this.enableFaqForm(_$('#cardAgOver'));
                    break;
                case 'inv':
                    this.enableFaqForm(_$('#cardAgInv'));
                    break;
                case 'generic':
                    this.enableFaqForm(_$('#cardFaqGeneric'))
                    break;
                default:
                    break;
            }
        }

        if (_$("main").find('div').attr("nkPage") == 'faqSectionMain') {
            this.sendAnalytics('pageview_faq_section_main');
        } else {
            this.sendAnalytics('pageview');
        }

        var self = this;
        _$( '.component-faqSectionFormMain .card' ).on( 'click', function () {
            self.sendAnalytics("clickSelezioneRichiestaInformazioni", this);
        });

        _$('#offcanvasFaqForm').on('show', function () { 
        if(_$( "#offcanvasFaqForm" ).attr( "type") == "generic") {
            self.sendAnalytics('pageview_modale_generic'); 
        } else {
            self.sendAnalytics('pageview_modale'); 
        }
        });
        _$('#submitFaqForm').on('click', function () { 
        if(_$( "#offcanvasFaqForm" ).attr( "type") == "generic") {
            self.sendAnalytics('clickInviaRichiestaInformazioni_generic'); 
        } else {
            self.sendAnalytics('clickInviaRichiestaInformazioni'); 
        }            
        });
        _$('#modalFormUploadSuccess').on('show', function () { self.sendAnalytics('pageview_modale_success'); });
        _$('#modalFormUploadSuccess').find('.uk-button').on('click', function () { self.sendAnalytics('chiusuraBannerRichiestaInviataSuccesso'); })

        _$( '#faq_query' ).siblings('.submitButton').on( "click", function () {
            self.sendAnalytics("clickRicercaProblema", this);
        });
        _$( '.component-CardSimple' ).on( "click", function () {
            self.sendAnalytics("clickRispostaCategoria", this);
        });
        _$( '.component-faqSectionContattaci .card' ).on( "click", function () {
            self.sendAnalytics("clickBannerContatto", this);
        });
        _$('#offcanvasFaqForm input').on( "focusout", () => {
            setTimeout(() => {
                this.sendAnalytics('erroreForm', _$('#offcanvasFaqForm').find('.uk-form-danger'));
            }, 100);
        } );

        _$('#offcanvasAgevolazioneInvalidita input').on( "focusout", () => {
            setTimeout(() => {
                this.sendAnalytics('erroreForm', _$('#offcanvasAgevolazioneInvalidita').find('.uk-form-danger'), _$('#offcanvasAgevolazioneInvalidita'));
            }, 100);
        } );

        if(_$('.component-faqSectionDomande').length){
            let headerHeight = _$('.component-header').height();
            if(window.location.hash != undefined && window.location.hash != '' && _$(window.location.hash).length){
                _$(window.location.hash).find('a').attr('aria-expanded', 'true');
                _$(window.location.hash).addClass('uk-open');
            }
            _$('.component-faqSectionDomande .section-main li').css('scroll-margin-top', headerHeight);
            
        }
    }   


    showBanChiama(e: object) {
        _$(e).css("display","none");
        _$(e).parent().find(".info-chiama").css("display","flex");
    }

    showQuestionYes(e: object) {
        _$(e).parent().parent().css("display","none");
        _$(e).parent().parent().parent().find(".question-yes").css("display","flex");
    }

    showQuestionNo(e: object) {
        _$(e).parent().parent().css("display","none");
        _$(e).parent().parent().parent().find(".question-no").css("display","block");
    }

    enableCardsForm(e: object){
        _$('.component-faqSectionFormMain .button-switcher button').removeClass('active');
        _$(e).addClass('active');
        let type = _$(e).prop('name');
        let subtitle = _$('.component-faqSectionFormMain .subtitle');
        let cards = _$('.component-faqSectionFormMain .card-container .card');
        let enabledCards;

        let filters = {
            delivery: '"Consegna"',
            prices: '"Prezzi, buoni, punti e bollini"',
            products: '"Prodotti"',
            orders: '"Ordini"',
            facility: '"Agevolazioni"',
            siteNavigation: '"Problemi navigazione sito"'
        }

        if (type == 'all') {
            cards.removeClass('uk-hidden');
            enabledCards = cards;
            subtitle.text('Tutti gli argomenti (' + enabledCards.length +')');
        } else {
            cards.addClass('uk-hidden');
            enabledCards = _$('.component-faqSectionFormMain .card-container .card[data-type|='+type+']');
            enabledCards.removeClass('uk-hidden');
            subtitle.text('Argomenti per ' + filters[type] + ' (' + enabledCards.length + ')');
        }
    }

    enableFaqForm(e: object){
        let formType = _$(e).attr('name');

        switch(formType) {
            case 'agevolazioneOver':
                if (!Window.user) {
                    let url = Window.location.href + '?agType=over';
                    this._utils.goToMyConad('login', url);
                    break;
                }
                _Uikit.offcanvas('#offcanvasAgevolazioneOver').show();
                break;
            case 'agevolazioneInvalidita':
                if (!Window.user) {
                    let url = Window.location.href + '?agType=inv';
                    this._utils.goToMyConad('login', url);
                    break;
                }
                _Uikit.offcanvas('#offcanvasAgevolazioneInvalidita').show();
                break;
            case 'generic':
                Window['lastFaq'] = _$(e);
                _$(() => {
                    _$( "#offcanvasFaqForm" ).attr( "type", "generic" );
                    _Uikit.offcanvas('#offcanvasFaqForm').show();
                })
                break;                
            default:
                Window['lastFaq'] = _$(e);
                _$(() => {
                    _$( "#offcanvasFaqForm" ).attr( "type", "not_generic" );
                    _Uikit.offcanvas('#offcanvasFaqForm').show();
                })
                break;
        }
    }

    searchFaqs(e: object){
        _$('.section-main-domande .section-content').html(`
        <span class="searching">
            Ricerca..
        </span>`);
        
        let success = (res) => {
            if(res.data.length){
                let faqList = '<ul uk-accordion="multiple: true" class="uk-flex-first uk-child-width-1-1" uk-grid>'
                res.data.forEach(element => {
                    faqList = faqList + 
                    `<li>
                        <a class="uk-accordion-title title" href="#" aria-expanded="false">${element.title}</a>
                        <div class="uk-accordion-content">
                                ${element.description}
                        </div>
                    </li>`
                });
                faqList = faqList + '</ul>'
                _$('.section-main-domande .section-content').html(faqList);
            }else{
                _$('.section-main-domande .section-content').html(`
                <span class="searching">
                    Nessun risultato trovato.
                </span>`);
            }
        }

        let error = (err) => {
            _$('.section-main-domande .section-content').html(`
                <span class="searching">
                    Errore nella ricerca..Riprova..
                </span>`);
        }

        let body = _$(e).parent().find('#faq_query').val();

        this._userService.getFaqList(success, error, body);
    }

    sendAnalytics(eventType: string, e?: object, extra?){
        try {
            let analyticsData;
            let funnelDict;

            switch (eventType) {
                case 'pageview':
                    //4.1.1
                    analyticsData = {
                        event: eventType,
                    }

                    funnelDict = 'FUNNEL_DATA_PAGINA_SUPPORTO_PAGEVIEW';
                    break;
                case 'clickSelezioneRichiestaInformazioni':
                    //4.1.2
                    let assistenza = {
                        dettaglioScrivici: _$(e).text().trim()
                    };

                    analyticsData = {
                        event: eventType,
                        assistenza: assistenza
                      }

                    this.localStorage.setItem('assistenza', JSON.stringify(assistenza));

                    funnelDict = 'FUNNEL_DATA_PAGINA_SUPPORTO_CLICK_RICHIESTA_INFO';
                    break;
                case 'pageview_modale':
                    //4.1.3
                    analyticsData = {
                        event: 'pageview',
                        assistenza: this.localStorage.getFromStorage('assistenza') ? this.localStorage.getFromStorage('assistenza') : '',
                    }
    
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_INFORMAZIONI';
                    break;
                case 'clickInviaRichiestaInformazioni':
                    //4.1.4
                    analyticsData = {
                        event: eventType,
                        assistenza: this.localStorage.getFromStorage('assistenza') ? this.localStorage.getFromStorage('assistenza') : '',
                    }
    
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_INFORMAZIONI_INVIO_RICHIESTA';
                    break;   
                case 'pageview_modale_success':
                    //4.1.5
                    analyticsData = {
                        event: 'pageview',
                        assistenza: this.localStorage.getFromStorage('assistenza') ? this.localStorage.getFromStorage('assistenza') : '',
                    }
        
                    funnelDict = 'FUNNEL_DATA_MODALE_SUCCESS_REQUEST';
                    break; 
                case 'chiusuraBannerRichiestaInviataSuccesso':
                    //4.1.6
                    analyticsData = {
                        event: eventType,
                        assistenza: this.localStorage.getFromStorage('assistenza') ? this.localStorage.getFromStorage('assistenza') : '',
                    }
            
                    funnelDict = 'FUNNEL_DATA_MODALE_SUCCESS_CHIUSURA';
                    break;  
                case 'erroreForm':
                    //4.1.7
                    let labels = [];
                    _$(e).each(function() {
                        if(_$(this).parent().find('label').text()){
                            labels.push(_$(this).parent().find('label').text().trim());
                        }
                    });

                    let filesError = []
                    if(extra != null && extra.length){
                        filesError = this._utils.formFindErrorFiles('agevolazione', _$(extra));
                    }else{
                        if(_$('#offcanvasFaqForm').find('.container-allegati .dz-error').length){
                            filesError = ['File allegati'];
                        }else if(_$('#offcanvasFaqForm').find('.alert-allegati:visible').length){
                            filesError = ['File allegati']
                        }
                    }
                    
                    labels = labels.concat(filesError);

                    analyticsData = {
                        event: eventType,
                        assistenza: this.localStorage.getFromStorage('assistenza') ? this.localStorage.getFromStorage('assistenza') : '',
                        form: {
                            campoErrore: labels
                        }
                    }
            
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_INFORMAZIONI';
                    if (labels.length) {
                        this._analytics.sendAnalytics(analyticsData, funnelDict, true, false);
                    }
                    return;                   
                case 'pageview_modale_generic':
                    //5.1.3
                    analyticsData = {
                        event: 'pageview'
                    }
        
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_INFORMAZIONI_GENERICA';
                    break;
                case 'clickInviaRichiestaInformazioni_generic':
                    //5.1.4
                    analyticsData = {
                        event: 'inviaRichiestaFormGenerico',
                    }
        
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_INFORMAZIONI_GENERICA_INVIO_RICHIESTA';
                    break;                       
                case 'pageview_faq_section_main':
                    //9.5.1
                    analyticsData = {
                        event: 'pageview',
                    }
    
                    funnelDict = 'FUNNEL_DATA_RISOLUZIONE_PROBLEMI';
                    break; 
                case 'clickRispostaCategoria':
                    //9.5.2
                    analyticsData = {
                        event: eventType,
                        assistenza: {
                            rispostaCategoria:  _$(e).find('.card').find('span').text().trim()
                        },
                    }
            
                    funnelDict = 'FUNNEL_DATA_RISOLUZIONE_PROBLEMI_RISPOSTA_CATEGORIA';
                    break; 
                case 'clickBannerContatto':
                    //9.5.3
                    analyticsData = {
                        event: eventType,
                        assistenza: {
                            bannerContatto: _$(e).find('.card-title').text().trim()
                        },
                    }
            
                    funnelDict = 'FUNNEL_DATA_RISOLUZIONE_PROBLEMI_CLICK_BANNER';
                    break;                     
                case 'clickRicercaProblema':
                    //9.5.4
                    analyticsData = {
                        event: eventType,
                        assistenza: {
                            bannerContatto: ''
                        },
                    }
        
                    funnelDict = 'FUNNEL_DATA_RISOLUZIONE_PROBLEMI_RICERCA';
                    break;                     
            }

            this._analytics.sendAnalytics(analyticsData, funnelDict, true, false);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

}