import Alpine from 'alpinejs';
import * as WishlistUtils from '../../../../../libs/wishlists-utils';
import { notUndefinedAndTrue, getQueryParamFromUrl } from '../../../../../libs/base-utils';
import { sendAnalytics, populateAnalyticsProduct } from '../../../../../libs/tracking-utils';
import { BASE_ECOMMERCE_DATA } from '../../../../../constants';
import { _Uikit } from '../../../../../main'; // old-fe import

Alpine.data('ap203xLikeButton', () => ({
    initialized: false as boolean,
    isWishlistProduct: false as boolean,
    init() {
        if (!this.$store.user || !this.$store.user.utenzaId) return;
        this._checkWishlistProduct();
    },
    // utils
    notUndefinedAndTrue,
    // internal functions
    async _checkWishlistProduct() {
        if (!this.$store.user || !this.$store.user.utenzaId) return;
        this.isWishlistProduct = (await WishlistUtils.isWishListProduct(this.productData?.code));
        this.initialized = true;
    },
    // main component functions
    async favoriteButtonClickAction() {
        if (!this.$store.user || !this.$store.user.utenzaId) {
            _Uikit.offcanvas('#modalReservedFunctionality').show(); // old-fe call
            return;
        }

        if (!this.productData?.code) return;
        let flowOutputData: any = await window.cFlowManager.startAsyncFlow({
            name: 'wishlists-add-product-flow',
            steps: [
                { name: 'wishlists-add-product' },
                { name: 'wishlists-create-and-add-product' },
                { name: 'wishlists-confirm-create-and-add' },
            ],
            initialData: {
                productData: this.productData
            }
        });

        if (flowOutputData?.result?.state === 'confirmed') {
            const previousFavourite = this.isWishlistProduct;
            this.isWishlistProduct = (await WishlistUtils.isWishListProduct(this.productData?.code));

            // analytics
            sendAnalytics(await (async () => {
                let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_LIKE_BTN;
                await populateAnalyticsProduct(ecommerceData, this.productData);
                ecommerceData.funnel.stepFunnel = (() => {
                    if (!previousFavourite && this.isWishlistProduct)
                        return 'Aggiunta ai preferiti';
                    if (this.isWishlistProduct)
                        return 'Aggiunta/Rimozione dai preferiti';
                    return 'Rimozione dai preferiti';
                })();
                ecommerceData.funnel.listaVisualizzata = (window.pageTemplate === 'personal-area-page')
                    ? getQueryParamFromUrl('pre-selected-wishlist', WishlistUtils.DEFAULT_WISHLIST_NAME) : '';
                return ecommerceData;
            })());

            this.$dispatch('action-on-favorite', { productCode: this.productData?.code });
        }
    },
    async sameProductHeartEvent(eventDetail: Record<string, any>) {
        if (!this.$store.user || !this.$store.user.utenzaId) return;
        if (eventDetail?.productCode === this.productData?.code)
            this._checkWishlistProduct();
    }
}));