import { LOADER_POSITION, Utils } from "../../services/utils/utils";
import {_$, _Uikit, Window, _dropzone} from "../../main";
import { UserService } from "../../services/user-service/user-service";
import { ValidationUtils } from "../../services/utils/validation-utils";
import { Dropzone } from "dropzone";

export class ModalFaqForm {

    public maxFilesSize = 20; //in MB
    public faqForm = _$('#faqForm');
    public myDropzone;
    public formType = '';
    public orderPattern = 'o-[a-z]{1}-[A-Z]{1}-[0-9]{2}-[0-9]{8}';

    constructor(
        private _userService: UserService,
        private _utils: Utils,
        private _validationUtils: ValidationUtils
    ){
        _dropzone.Dropzone.autoDiscover = false;

        _Uikit.util.on('#offcanvasFaqForm', 'show', () => {
            let e = Window['lastFaq'];
            _$('#offcanvasFaqForm .form-title').text(_$(e).text());
            this.enableFaqForm(_$(e).attr('name'));
            //this._utils.checkFormValidator('submitFaqForm', 'faqForm');
        });
    }

    nkOnInit() {
        _$('#faqCodiceOrdine').prop('pattern', this.orderPattern);
        this.initDropzone();
    }

    setUserLogged(form){
        if(Window.user){
            switch (form) {
                case 'faqForm':
                    if(Window.user.nome){
                        _$('#faqNome').val(Window.user.nome);
                    }
                    if(Window.user.cognome){
                        _$('#faqCognome').val(Window.user.cognome);
                    }
                    if(Window.user.email){
                        _$('#faqEmail').val(Window.user.email);
                    }
                    if(Window.user.cartaFedelta){
                        _$('#faqNumeroCarta').val(Window.user.cartaFedelta);
                    }
                    if(Window.user.telefonoCellulare){
                        _$('#faqTelefono').val(Window.user.telefonoCellulare);
                    }
                    break;
            }
        }
    }

    enableFaqForm(e: string){
        let setTopicsLotto = ['damagedProduct'];
        let setTopicsNumOrdine = ['extraDeliveryTimeslot','noDelivery','lockerSupport','prices&discounts','stampsDispute','pointsDispute','damagedProduct','wrongProduct','orderInfo','orderModify','orderCancel','paymentProblem'];
        
        this.clearForm('faqForm');
        this.formType = e;

        _$('#offcanvasFaqForm input').prop('disabled', false);
        
        if (setTopicsNumOrdine.indexOf(this.formType) < 0) {
            _$('#faqCodiceOrdine').prop('required', false);
            _$('#faqCodiceOrdine').parent().addClass('uk-hidden');
        } else {
            _$('#faqCodiceOrdine').prop('required', true);
            _$('#faqCodiceOrdine').parent().removeClass('uk-hidden');
        }
        if (setTopicsLotto.indexOf(this.formType) < 0) {
            _$('#faqLotto').prop('required', false);
            _$('#faqLotto').parent().addClass('uk-hidden');
        } else {
            _$('#faqLotto').parent().removeClass('uk-hidden');
        }


        /* switch per impostare il placeholder del messaggio */
        switch(this.formType){
            /* Caso messaggio : "Scrivi qui una domanda o descrivi la tua problematica*" */
            case 'extraDeliveryTimeslot':
            case 'noDelivery':
            case 'prices&discounts':
            case 'stampsDispute':
                _$('#faqDescription').prop('placeholder', 'Scrivi qui una domanda o descrivi la tua problematica');
                break;
            /* Caso messaggio : "TBD" */
            case 'pointsDispute':
            case 'paymentProblem':
            case 'orderInfo':
            case 'orderModify':
            case 'orderCancel':
            case 'ecommerceInfo':
            case 'productsInfo':
            case 'browseSearchProducts':
            case 'signUpAccount':
            case 'generic':
                _$('#faqDescription').prop('placeholder', 'Scrivi qui una domanda o descrivi la tua problematica');
                break;
            /* Caso messaggio : "Scrivi qui una domanda o descrivi la tua problematica. Nel caso di prodotti scaduti, indicaci la data di scadenza" */
            case 'damagedProduct':
                _$('#faqDescription').prop('placeholder', 'Scrivi qui una domanda o descrivi la tua problematica. Nel caso di prodotti scaduti, indicaci la data di scadenza');
                break;
            case 'wrongProduct':
                _$('#faqDescription').prop('placeholder', 'Indicaci i prodotti errati o da rendere ed eventuali ulteriori dettagli sul motivo del reso');
                break;
            default:
                console.log('no form type found');
                break;
        }
        this.setUserLogged('faqForm');
    }

    clearForm(form){
        switch (form) {
            case 'faqForm':
                _$('#faqForm input, #faqForm textarea').val('');
                this.resetDropzone('dropzone-div');
                this.setUserLogged('faqForm');
                break;
        }
    }

    sendForm(e: object){
        //@ts-ignore
        let form = new FormData(this.faqForm.get(0));
        
        this._utils.showLoader(_$(e), true, LOADER_POSITION.BUTTON);
    
        let success = (res) => {
            console.log(res);
            this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
            _Uikit.modal('#modalFormUploadSuccess').show();
            this.clearForm('faqForm');
        }

        let error = (err) => {
            console.log(err);
            this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
            _Uikit.modal('#modalFormUploadError').show();
        }

        let dropzone = _dropzone.Dropzone.forElement('#dropzone-div');
        dropzone.files.forEach(element => {
            form.append('file', element);
        });
        form.append('tipo', this.formType);

        let params = Window.location.search;
        let queryParams = new URLSearchParams(params);
        if(queryParams && queryParams.get('utm_source') && queryParams.get('utm_source') == 'app_store'){
            form.append('origineCase', 'APPSTORE');
        }
        
        let body = form;

        this._userService.sendFaqForm(success, error, body);
    }

    validSingleField(element) {
        if (_$(element).hasClass('uk-textarea')) {
            let $textarea = _$(element);
            if ($textarea.val() == '' && $textarea.prop('required')) {
                $textarea.attr('data-valid','false');
                $textarea.siblings('.message-error').text('Campo obbligatorio.');
                $textarea.siblings('.message-error').removeClass('uk-hidden');
                $textarea.addClass('uk-form-danger');
            } else {
                $textarea.attr('data-valid','true');
                $textarea.siblings('.message-error').addClass('uk-hidden');
                $textarea.removeClass('uk-form-danger');
            }
        } else {
            this._validationUtils.newValidInput(element);
        }
    }

    validForm(submitButton) {
        let $form = _$(submitButton).closest('form');
        let $allInputs = $form.find('input, textarea');
        let validInputs = [];

        _$($allInputs).each((index, singleInput) => {
            let $input = _$(singleInput);
            this.validSingleField($input);
            if ($input.attr('data-valid') == 'false') {
                validInputs.push(false);
            } else {
                validInputs.push(true);
            }
        });

        return validInputs.indexOf(false) < 0;
    }

    /**
     * Checks input value on keyup
     * @param element
     * @param formId
     */
    controlValueFieldInput(element,  formId?: string, submitButtonID?: string) {
        /* _$(element).removeClass('uk-form-danger');
        let pattern =  _$(element).attr("pattern");
        if(_$(element).val()) {
            _$(element).parent().find('.error-required').addClass('uk-hidden');
            //@ts-ignore
            if (_$(element).val().match(pattern)) {
                _$(element).parent().find('.error-no-valid').addClass('uk-hidden');
                _$(element).attr('data-valid', 'true');
                _$(element).removeClass('uk-form-danger');
                _$(element).parent().css('margin-bottom','16px');
            } else {
                _$(element).attr('data-valid', 'false');
                _$(element).addClass('uk-form-danger');
                _$(element).parent().find('.error-no-valid').removeClass('uk-hidden');
                _$(element).parent().css('margin-bottom','27px');
            }
        } else {
            _$(element).attr('data-valid', 'false');
            _$(element).parent().find('.error-no-valid').addClass('uk-hidden');
            if (_$(element).prop('required')) {
                _$(element).addClass('uk-form-danger');
                _$(element).parent().find('.error-required').removeClass('uk-hidden');
                _$(element).parent().css('margin-bottom','27px');
            } else {
                _$(element).parent().find('.error-required').addClass('uk-hidden');
                _$(element).parent().css('margin-bottom','16px');
            }
           
        } */

        if (this.validForm(element)) {
            _$('#faqForm').find('#js_faqForm-sr-only').remove();
            if(this.checkDropzoneStatus('dropzone-div')){
                //this._utils.checkFormValidator(submitButtonID, formId);
                this.sendForm(element);
            }
        } else {
            if (!_$('#js_faqForm-sr-only').length) {
                _$('#faqForm').prepend('<div id="js_faqForm-sr-only" class="sr-only" role="alert">Sono presenti degli errori</div>');
            }
        }
    }

    initDropzone() {
        let fileSize = this.maxFilesSize;
        let previewsTemplate = `<div>
        <div class="dz-preview dz-file-preview well allegato">
                <div class="dz-details allegato-container">
                        <i class="icon-file-text"></i>
                        <div class="dz-filename nome-allegato uk-width-expand"><span data-dz-name></span></div>
                        <button class="cestino" aria-label="Rimuovi" data-dz-remove><i class="icon-trash"></i></button>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
            </div>
        </div>`

        
        
        let idForm = 'dropzone-div';
        let myDropzone = new Dropzone('#'+idForm, {
            url: '.',
            paramName: "file", // The name that will be used to transfer the file
            maxFilesize: this.maxFilesSize, // MB
            uploadMultiple: true,
            acceptedFiles: '.pdf,.jpg,.jpeg,.png',
            clickable: '.component-modal-faq-form .allega-file, .component-modal-faq-form .allega-file > *',
            autoProcessQueue: false,
            previewsContainer: '.dropzone-previews',
            previewTemplate: previewsTemplate,
            dictFileTooBig: "Il file è troppo grande ({{filesize}}MB). Massimo: {{maxFilesize}}MB.",
            dictInvalidFileType: "Il formato del documento non è ammesso. Carica un file in formato pdf, jpg, jpeg o png.",
            params: [fileSize],
            init: function(){
                function getQueuedFilesSize(dropzone){
                    var totalSize = 0;
                    dropzone.files.forEach(function(file){
                        totalSize = totalSize + file.size;
                    });
                    return totalSize;
                }

                /* Copiato dalle utils */
                function checkFormValidator(submitButtonID: string, formID: string) {
                    let textFields = _$('#'+formID).find('input[required], select[required], textarea[required]');
                    let isDisabled = false;
                    for (let i = 0; i < textFields.length; i++) {
                        if(!_$(textFields[i]).prop('required') || _$(textFields[i]).attr('data-valid') !== 'true') {
                            //_$('#'+submitButtonID).prop('disabled', false);
                            isDisabled = true;
                        }
                    }
                    //_$('#'+submitButtonID).prop('disabled', isDisabled);
                }

                let submitForm = _$('#faqForm').find('.button-submit');
                let submitFormID = _$(submitForm).attr('id');
                let alert = _$('#faqForm').find('.js-dropzone-alert-allegati');

                this.on('addedfile', file => {
                    idForm = 'faqForm';
                    if(getQueuedFilesSize(this) > 1024 * 1024 * fileSize){
                        //_$(submitForm).prop('disabled', true);
                        _$(alert).addClass('active');
                        setTimeout(() => {
                            _$('.dz-error .allegato-container > i').removeClass('icon-file-text').addClass('icon-alert uk-border-circle');
                        }, 20);
                    }else{
                        let valid = true;
                        let acceptedFiles = '.pdf,.jpg,.jpeg,.png';
                        this.files.forEach(function(file){
                            valid = acceptedFiles.indexOf(file.type.split('/')[1]) < 0 ? false : valid;
                        });
                        if (!valid) {
                            //_$(submitForm).prop('disabled', true);
                            setTimeout(() => {
                                _$('.dz-error .allegato-container > i').removeClass('icon-file-text').addClass('icon-alert uk-border-circle');
                            }, 20);
                        } else {
                            //_$(submitForm).prop('disabled', false);
                            _$(alert).removeClass('active');
                            checkFormValidator(submitFormID, idForm);
                        }
                    }

                });
 
                this.on('removedfile', file => {
                    idForm = 'faqForm'
                    if(getQueuedFilesSize(this) > 1024 * 1024 * fileSize){
                        //_$(submitForm).prop('disabled', true);
                        _$(alert).addClass('active');setTimeout(() => {
                            _$('.dz-error .allegato-container > i').removeClass('icon-file-text').addClass('icon-alert uk-border-circle');
                        }, 20);
                    }else{
                        //_$(submitForm).prop('disabled', false);
                        _$(alert).removeClass('active');
                        checkFormValidator(submitFormID, idForm);
                    }

                    myDropzone.files.forEach(function(file){
                        if(!file.accepted){
                            //_$(submitForm).prop('disabled', true);
                        }
                    });
                });

                this.on('error', file => {
                    if(!file.accepted){
                        //submitForm.prop('disabled', true);
                    }
                })
            }
        });  
        myDropzone.autoDiscover = false;
    }

    resetDropzone(formId){
        this.myDropzone = _dropzone.Dropzone.forElement('#'+ formId);
        this.myDropzone.removeAllFiles();
    }

    checkDropzoneStatus(formId): boolean {
        this.myDropzone = _dropzone.Dropzone.forElement('#'+ formId);
        var totalSize = 0;
        let acceptedFormats = true;
        this.myDropzone.files.forEach(function(file){
            totalSize = totalSize + file.size;
        });

        this.myDropzone.files.forEach(function(file){
            if(!file.accepted){
                acceptedFormats = false;
            }
        });

        return totalSize <= 1024 * 1024 * this.maxFilesSize && acceptedFormats;
    }
}