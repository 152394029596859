import { EcommerceData } from '../services/analytics/analytics-interface';
import * as WishlistUtils from './wishlists-utils';
import { TEMPLATE_NOMEFUNNEL_MAPPING } from '../constants';

function _populateServiceInfo(ecommerceData: EcommerceData) {
    if (!ecommerceData?.funnel) ecommerceData.funnel = {};
    ecommerceData.funnel.tipoServizio = window.typeOfService ?
        (window.typeOfService == 'HOME_DELIVERY' ? 'Ordina e ricevi' : 'Ordina e ritira') : '';
}

function _populateSlotInfo(ecommerceData: EcommerceData) {
    if (!ecommerceData?.funnel) ecommerceData.funnel = {};
    if (window.selectedTimeslotExpiration) {
        ecommerceData.funnel.sceltaSlot = {
            giorno: window.selectedTimeslot ? new Date(window.selectedTimeslot.date).toString() : '',
            fasciaOraria: window.pointOfService?.firstAvailableTimeSlot && window.selectedTimeslot ?
                (window.pointOfService.firstAvailableTimeSlot?.date == window.selectedTimeslot?.date ? 'SI' : 'NO') : '',
            giornoProposto: window.selectedTimeslot ?
                `${window.selectedTimeslot.startTime?.formattedHour} - ${window.selectedTimeslot.endTime?.formattedHour}` : ''
        };
    } else {
        ecommerceData.funnel.sceltaSlot = {
            giorno: '',
            fasciaOraria: '',
            giornoProposto: ''
        };
    }
}

function _populateStoreInfo(ecommerceData: EcommerceData) {
    if (!ecommerceData?.funnel) ecommerceData.funnel = {};
    ecommerceData.funnel.sceltaNegozio = {
        idNegozio: window.pointOfService?.name || '',
        cooperativaNegozio: window.pointOfService?.cooperativeId || '',
        disponibilitaNegozio: window.typeOfService && window.pointOfService ?
            (window.typeOfService == 'HOME_DELIVERY' ?
                (!window.pointOfService?.hdTemporaryUnavailable ? 'Disponibile' : 'Esaurita') :
                (!window.pointOfService?.ocTemporaryUnavailable ? 'Disponibile' : 'Esaurita')) : ''
    };
}

function _populateUserInfo(ecommerceData: EcommerceData) {
    const user = window.user;
    if (!user) return;
    ecommerceData.userInfo = {
        abitudiniAlimentariFamiglia: user.abitudiniAlimentariFamiglia ? user.abitudiniAlimentariFamiglia : '',
        accessoMinisito: user.accessoMinisito ? user.accessoMinisito : '',
        acquistiOnline: user.acquistiOnline ? user.acquistiOnline : '',
        animaliDomestici: user.animaliDomestici ? user.animaliDomestici : '',
        canaliDiContatto: (() => {
            if (!user.canaliDiContatto) return {};
            return user.canaliDiContatto.reduce((result, canale) => {
                result[canale.codice] = canale.consenso;
                return result;
            }, {});
        })(),
        cap: user.cap ? user.cap : '',
        cardistaAnteCPlus: user.cardistaAnteCPlus ? user.cardistaAnteCPlus : '',
        cartaDematerializzata: user.cartaDematerializzata ? user.cartaDematerializzata : '',
        cartaFedelta: user.cartaFedelta ? user.cartaFedelta : '',
        citta: user.citta ? user.citta : '',
        codiceCitta: user.codiceCitta ? user.codiceCitta : '',
        codiceComuneDiNascita: user.codiceComuneDiNascita ? user.codiceComuneDiNascita : '',
        codiceCooperativa: user.codiceCooperativa ? user.codiceCooperativa : '',
        codiceNazione: user.codiceNazione ? user.codiceNazione : '',
        codiceProvincia: user.codiceProvincia ? user.codiceProvincia : '',
        comuneNascita: user.comuneNascita ? user.comuneNascita : '',
        conadCard: user.conadCard ? user.conadCard : '',
        dataNascita: user.dataNascita ? user.dataNascita : '',
        dataNascitaConiuge: user.dataNascitaConiuge ? user.dataNascitaConiuge : '',
        dataNascitaFiglio1: user.dataNascitaFiglio1 ? user.dataNascitaFiglio1 : '',
        dataNascitaFiglio2: user.dataNascitaFiglio2 ? user.dataNascitaFiglio2 : '',
        dataNascitaFiglio3: user.dataNascitaFiglio3 ? user.dataNascitaFiglio3 : '',
        email: user.email ? user.email : '',
        hobbyFamiglia: user.hobbyFamiglia ? user.hobbyFamiglia : '',
        negozioPreferito: user.negozioPreferito ? user.negozioPreferito : '',
        negozioPreferitoCooperativa: user.negozioPreferitoCooperativa ? user.negozioPreferitoCooperativa : '',
        numeroComponentiNucleoFamiliare: user.numeroComponentiNucleoFamiliare ? user.numeroComponentiNucleoFamiliare : '',
        privacy1: user.privacy1 ? user.privacy1 : '',
        privacy2: user.privacy2 ? user.privacy2 : '',
        privacy3: user.privacy3 ? user.privacy3 : '',
        provenienza: user.provenienza ? user.provenienza : '',
        saldoPunti: user.saldoPunti ? user.saldoPunti : '',
        saldoPuntiCollectionPrecedente: user.saldoPuntiCollectionPrecedente ? user.saldoPuntiCollectionPrecedente : '',
        sesso: user.sesso ? user.sesso : '',
        socialRegistration: user.socialRegistration ? user.socialRegistration : '',
        stato: user.stato ? user.stato : '',
        totCoupons: user.totCoupons ? user.totCoupons : '',
        unreadMessages: user.unreadMessages ? user.unreadMessages : '',
        utenzaId: user.utenzaId ? user.utenzaId : ''
    };
}

async function _getProductFromProductData(productData: any) {
    if (!productData)
        return {};

    return {
        codiceVariante: '',
        codiceConart: productData.code ? productData.code : '',
        ID_promo: productData.customPromoSponsor ? productData.customPromoSponsor : [],
        marchio: productData.marchio ? productData.marchio : (productData.brand ? productData.brand : ''),
        sottomarchio: productData.sottomarchio ? productData.sottomarchio : '',
        preferito: productData?.code && (await WishlistUtils.isWishListProduct(productData?.code)) ? 'SI' : 'NO',
        ean: productData.ean ? productData.ean : '',
        categoriaPrimoLivello: productData.categoriaPrimoLivello ? productData.categoriaPrimoLivello : '',
        categoriaSecondoLivello: productData.categoriaSecondoLivello ? productData.categoriaSecondoLivello : '',
        categoriaTerzoLivello: productData.categoriaTerzoLivello ? productData.categoriaTerzoLivello : '',
        nome: productData.nome ? productData.nome : (productData.title ? productData.title : ''),
        prezzo: (() => {
            /* [Partially refactored]
               nota: potrebbe mancare la gestione dell'entry? es. prezzo scontato in caso di 3x2, 
               il prezzo finale potrebbe essere scontato, verificare il cartModifications?! */
            if (!productData.promo) return productData.basePrice?.toString() || '0';
            let returnPrice;
            productData.promo.forEach((p) => {
                if (!returnPrice && p.finalPrice) 
                    returnPrice = p.finalPrice.toString();
            });
            if (returnPrice) return returnPrice;
            productData.promo.forEach((p) => {
                if (!returnPrice && p.basePrice) 
                    returnPrice = p.basePrice.toString();
            });
            if (returnPrice) return returnPrice;
            return productData.basePrice?.toString() || '0';
        })(),
        quantita: (() => {
            /* [Partially refactored]
               nota: in determinati casi andrebbe preso il quantity relativo alla entry */
            return 1;
        })(),
        variante: (() => {
            /* [Partially refactored]
               nota: in determinati casi andrebbe preso la variante relativo alla entry, ad esempio quella aggiunta attualmente a carrello */
            let unit = '';
            if (productData.increment) {
                unit = productData.increment.unitOfMeasure?.toLowerCase() || '';
            } else if (productData.variations) {
                unit = productData.variations.unitOfMeasure?.toLowerCase() || '';
            }

            if (productData.entries && productData.entries.length > 0) { // minicart
                return productData.entries[0]?.scale ? `${productData.entries[0].scale.toString().split("-")[0]} ${unit}` : '';
            } else {
                return productData.variante ? `${productData.variante.toString().split("-")[0]} ${unit}` : '';
            }
        })(),
        promozione: {
            bassiFissi: productData.bassiFissi ? (productData.bassiFissi == true ? 'SI' : 'NO') : 'NO',
            tipo: productData.promo && productData.promo.length > 0 ? productData.promo.map(promo => promo.promoType) : [],
            dettaglio: (() => {
                if (!productData.promoBadges) return [];
                return productData.promoBadges;
            })()
        },
        cardista: productData.promo && productData.promo[0]?.customerType ? productData.promo[0].customerType : '',
        bolliniExtra: productData.promo && productData.promo[0]?.bolliniExtra ? productData.promo[0].bolliniExtra : 0,
        puntiExtra: productData.promo && productData.promo[0]?.puntiExtra ? productData.promo[0].puntiExtra : 0,
        pesante: productData.pesante ? (productData.pesante == true ? 'SI' : 'NO') : (productData.volume ? 'SI' : 'NO'),
        categoriaMarketing: (() => {
            if (!productData.marketingCategoryBadge) return '';
            switch (productData.marketingCategoryBadge) {
                case 'sponsorizzato':
                    return 'Sponsorizzato';
                case 'italiano':
                    return '100% Italiano';
                case 'territorio':
                    return 'Territorio';
                case 'sostenibilita':
                    return 'Sostenibilità';
                case 'benessere':
                    return 'Benessere';
                case 'popolare':
                    return 'Popolare';
                default:
                    return productData.marketingCategoryBadge;
            }
        })(),
        notePreparazione: {
            nota: ''
        }
    };
}

////////////////////////////////////////////////////////////////
///////////////////////// exported
////////////////////////////////////////////////////////////////

export function getNomeFunnelFromPage() {
    const currentTemplate = window.pageTemplate;
    const pageName = (() => {
        let name = window.location?.pathname?.split("/")?.pop();
        if (!name) return '';
        return name.endsWith('.html') ? name.slice(0, -5) : name;
    })();

    let nomeFunnel = ''
    if (currentTemplate in TEMPLATE_NOMEFUNNEL_MAPPING)
        nomeFunnel = TEMPLATE_NOMEFUNNEL_MAPPING[currentTemplate];
    if (currentTemplate === 'personal-area-page') {
        if (pageName === 'orders') nomeFunnel += ' - I miei Ordini';
        else if (pageName === 'wishlists') nomeFunnel += ' - Preferiti';
        else if (pageName === 'coupons') nomeFunnel += ' - Offerte e buoni';
        else if (pageName === 'assistance') nomeFunnel += ' - Assistenza';
        else nomeFunnel += ' - Profilo'; // root of personal area site section
    }
    return nomeFunnel;
}

export function sendAnalytics(ecommerceData: EcommerceData, populateStoreAndTimeslot: boolean = true) {
    try {
        if (!ecommerceData) {
            console.error('sendAnalytics error: ecommerceData undefined');
            return;
        }

        // populate nomefunnel
        if (!ecommerceData.funnel?.nomeFunnel || ecommerceData.funnel?.nomeFunnel?.startsWith('<'))
            ecommerceData.funnel.nomeFunnel = getNomeFunnelFromPage();
        // populate user
        if (!ecommerceData.userInfo) // not already set
            _populateUserInfo(ecommerceData);
        // populate service, store and timeslot 
        if (populateStoreAndTimeslot) {
            if (!ecommerceData.funnel?.tipoServizio) // not already set
                _populateServiceInfo(ecommerceData);
            if (!ecommerceData.funnel?.sceltaNegozio) // not already set
                _populateStoreInfo(ecommerceData);
            if (!ecommerceData.funnel?.sceltaSlot) // not already set
                _populateSlotInfo(ecommerceData);
        }
        // send analytics
        if (window.adobeDataLayer) {
            console.log('⚐ Tracking: ', ecommerceData);
            window.adobeDataLayer.push(ecommerceData);
        } else if (location?.hostname === 'localhost') {
            console.log('⚐ Tracking (local): ', ecommerceData);
        }
    } catch (e) {
        console.error('sendAnalytics error: ', e);
    }
}

export async function populateAnalyticsProduct(ecommerceData: EcommerceData, productData: any) {
    try {
        ecommerceData.prodotto = (await _getProductFromProductData(productData));
    } catch (e) {
        console.error('populateAnalyticsProduct error: ', e);
    }
}