import { _Uikit, Window, _$ } from '../../main';

export enum BANNER_TYPES {
    PROMO_COMPONENT,
    BANNER_SPONSOR,
    STRIP_BTF,
    CARD_OFFERTA_BTF,
    CARD_SHOP_IN_SHOP_BTF,
    BANNERS_TESTATA,
    CAROSELLO_SPONSOR,
    BANNER_LANDING_IDM,
    CARDS_IMAGE_TOP,
    PROMOTIONS_AND_VISIBILITIES,
    CONAD_BRANDS,
    BANNER_LISTING_CONAD_BRANDS,
    TILES_BRANDS,
    TILES_PARTNERS,
    HIGHLIGHTED_PRODUCTS,
    ISPIRAZIONALE,
    STORYTELLING_A,
    STORYTELLING_B
}

export enum PRODUCT_CARD_TYPES{
    PRODUCT_CARD
}

export class AnalyticsUtility{

    constructor(){

    }

    getBannerPageview(bannerType: BANNER_TYPES, sponsor: any, datapromoArray: any): [any, any]{
        let slides, slide, banner;
        switch(bannerType){
            case BANNER_TYPES.PROMO_COMPONENT:
                banner = _$('.component-PromoComponent').length ? _$('.component-PromoComponent') : '';
                
                if(banner){
                    if (banner.hasClass('type-A')) {
                        slides = banner ? banner.find(".section-carousel").find(".swiper-wrapper").find(".swiper-slide") : '';
                        for (let i = 0; i < slides.length; i++) {
                            slide = slides ? slides.eq(i) : '';
                            sponsor.push({
                                brand: slide ? slide.find('.section-title').children('b').html() : '',
                                campagna: slide ? slide.find('.promo-title').html() : '',
                                formato: 'Banner testata',
                                landing: slide ? slide.find('.button-scopri').attr('href') : '',
                                posizioneSlide: slides.length > 1 ? i + 1 : 0,
                            });
                            if (slide.find('div').first().attr('data-promo')) {
                                datapromoArray.push(JSON.parse(slide.find('div').first().attr('data-promo')));
                            } else {
                                datapromoArray.push("");
                            }                        
                        }
                    } else if (banner.hasClass('type-B')) {
                        slides = banner ? banner.find(".section-carousel").find(".swiper-wrapper").find(".swiper-slide") : '';
                        for (let i = 0; i < slides.length; i++) {
                            slide = slides ? slides.eq(i) : '';
                            sponsor.push({
                                brand: slide ? slide.find('.section-title').children('b').html() : '',
                                campagna: slide ? slide.find('.promo-title').html() : '',
                                formato: 'Banner testata SX',
                                landing: slide ? slide.find('.button-scopri').attr('href') : '',
                                posizioneSlide: slides.length > 1 ? i + 1 : 0,
                            });
                            if (slide.find('div').first().attr('data-promo')) {
                                datapromoArray.push(JSON.parse(slide.find('div').first().attr('data-promo')));
                            } else {
                                datapromoArray.push("");
                            }                        
                        }
                        slide = banner ? banner.children('.uk-container').children('.component-PromoIdm') : '';
                        sponsor.push({
                            brand: slide ? slide.find('.section-title').children('b').html() : '',
                            campagna: slide ? slide.find('.promo-title').html() : '',
                            formato: 'Banner testata DX',
                            landing: slide ? slide.find('.button-scopri').attr('href') : '',
                            posizioneSlide: 0,
                        });
                        if (slide.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(slide.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                    
                    } else {
                        slides = banner ? banner.children('.uk-container').children('.component-PromoIdm') : '';
                        
                        slide = slides ? slides.eq(0) : '';
                        sponsor.push({
                            brand: slide ? slide.find('.section-title').children('b').html() : '',
                            campagna: slide ? slide.find('.promo-title').html() : '',
                            formato: 'Banner testata SX',
                            landing: slide ? slide.find('.button-scopri').attr('href') : '',
                            posizioneSlide: 0,
                        });
                        if (slide.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(slide.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                    
        
                        slide = slides ? slides.eq(1) : '';
                        sponsor.push({
                            brand: slide ? slide.find('.section-title').children('b').html() : '',
                            campagna: slide ? slide.find('.promo-title').html() : '',
                            formato: 'Banner testata DX',
                            landing: slide ? slide.find('.button-scopri').attr('href') : '',
                            posizioneSlide: 0,
                        });
                        if (slide.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(slide.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                    
                    }
                }
                break;
            case BANNER_TYPES.BANNER_SPONSOR:
                banner = _$('.component-CarouselBannerSponsor');
                slides = banner.find('.swiper-slide');

                if(slides.length){
                    for (let i = 0; i < slides.length; i++){
                        slide = slides ? slides.eq(i) : '';
                        sponsor.push({
                            brand: "",
                            campagna: slide ? slide.find('.banner-title').html() : '',
                            formato: 'Banner BTF',
                            landing: slide ? slide.find('a').attr('href') : '',
                            posizioneSlide: slides.length > 1 ? i + 1 : 0
                        })
                        if (slide.find('div').first().attr('data-promo')) {
                            datapromoArray.push(JSON.parse(slide.find('div').first().attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                    
                    }
                }
                break;
            case BANNER_TYPES.STRIP_BTF:
                banner = _$('.component-CarouselBannerStripe') ? _$('.component-CarouselBannerStripe') : '';
                slides = banner ? banner.find(".swiper-carousel").find(".swiper-wrapper").find(".swiper-slide") : '';
    
                if (slides.length == 1) {
                    slide = slides ? slides.eq(0) : '';
                    sponsor.push({
                        brand: "",
                        campagna: slide ? slide.find('.banner-title').html() : '',
                        formato: 'Strip BTF',
                        landing: _$(slide).find('a').attr('href') ? _$(slide).find('a').attr('href') : '',
                        posizioneSlide: 0,
                    });
                    if (slide.find('div').first().attr('data-promo')) {
                        datapromoArray.push(JSON.parse(slide.find('div').first().attr('data-promo')));
                    } else {
                        datapromoArray.push("");
                    }                    
                } else if(slides.length) {
                    for (let i = 0; i < slides.length; i++) {
                        slide = slides ? slides.eq(i) : '';
                        sponsor.push({
                            brand: "",
                            campagna: slide ? slide.find('.banner-title').html() : '',
                            formato: 'Strip BTF',
                            landing: _$(slide).find('a').attr('href') ? _$(slide).find('a').attr('href') : '',
                            posizioneSlide: slides.length > 1 ? i + 1 : 0,
                        });

                        if (slide.find('div').first().attr('data-promo')) {
                            datapromoArray.push(JSON.parse(slide.find('div').first().attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }                 
                }
                break;
            case BANNER_TYPES.CARD_OFFERTA_BTF:
                banner = _$('.component-AreaOfferte').length ? _$('.component-AreaOfferte') : '';
                slides = banner ? banner.find(".section-carousel").find(".swiper-wrapper").find(".swiper-slide") : '';

                if(slides.length){
                    for (let i = 0; i < slides.length; i++) {
                        slide = slides ? slides.eq(i) : '';
                        sponsor.push({
                            brand: "",
                            campagna: slide ? slide.find('.card-offerta-title').html() : '',
                            formato: 'Card offerta BTF',
                            landing: slide ? slide.find('.link-card-offerta a').attr('href') : '',
                            posizioneSlide: slides.length > 1 ? i + 1 : 0
                        });
                        if (slide.find('div').first().attr('data-promo')) {
                            datapromoArray.push(JSON.parse(slide.find('div').first().attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                    
                    }
                }
                break;
            case BANNER_TYPES.CARD_SHOP_IN_SHOP_BTF:
                banner = _$('.main-content .component-ShopIdm');
                slides = banner.find(".swiper-slide");

                if(slides.length){
                    for (let i = 0; i < slides.length; i++) {
                        slide = slides ? slides.eq(i) : '';
                        sponsor.push({
                            brand: slide ? slide.find('span').html() : '',
                            campagna: '',
                            formato: 'Card Shop in Shop BTF',
                            landing: slide ? slide.find('a').attr('href') : '',
                            posizioneSlide: slides.length > 1 ? i + 1 : 0
                        });
                        if (slide.find('div').first().attr('data-promo')) {
                            datapromoArray.push(JSON.parse(slide.find('div').first().attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                    
                    }
                }
                break;
            case BANNER_TYPES.BANNERS_TESTATA:
                if(_$('.main-content').siblings('.component-BannerListing.offerte-video').find('.banner-video').length){
                    let bannerVideo = _$('.main-content').siblings('.component-BannerListing.offerte-video');
                    sponsor.push({
                        brand: '',
                        campagna: _$(bannerVideo).find('.component-BannerSponsor .banner-title').html(),
                        formato: 'Banner testata video',
                        landing: _$(bannerVideo).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                        posizioneSlide: 0
                    })
                    if (_$(bannerVideo).attr('data-promo')) {
                        datapromoArray.push(JSON.parse(_$(bannerVideo).attr('data-promo')));
                    } else {
                        datapromoArray.push("");
                    }                            
                }else if(_$('.main-content').siblings('.component-BannerListing.banner-simple').find('.banner').length){
                    let bannerSimple = _$('.main-content').siblings('.component-BannerListing.banner-simple');
                    sponsor.push({
                        brand: '',
                        campagna: _$(bannerSimple).find('.component-BannerSponsor .banner-title').html(),
                        formato: 'Banner testata semplice',
                        landing: _$(bannerSimple).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                        posizioneSlide: 0
                    })
                    if (_$(bannerSimple).attr('data-promo')) {
                        datapromoArray.push(JSON.parse(_$(bannerSimple).attr('data-promo')));
                    } else {
                        datapromoArray.push("");
                    }                            
                }else if(_$('.main-content').siblings('.component-BannerListing.banner-iniziative').length){
                    let bannerIniziative = _$('.main-content').siblings('.component-BannerListing.banner-iniziative');
                    sponsor.push({
                        brand: '',
                        campagna: _$(bannerIniziative).find('.component-BannerSponsor .banner-title').html(),
                        formato: 'Banner testata con categorie',
                        landing: _$(bannerIniziative).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                        posizioneSlide: 0
                    })
                    if (_$(bannerIniziative).attr('data-promo')) {
                        datapromoArray.push(JSON.parse(_$(bannerIniziative).attr('data-promo')));
                    } else {
                        datapromoArray.push("");
                    }                         
                }else if(_$('.main-content').siblings('.component-BannerListing.banner-hotspot').length){
                    let bannerHotspot = _$('.main-content').siblings('.component-BannerListing.banner-hotspot');
                    sponsor.push({
                        brand: '',
                        campagna: _$(bannerHotspot).find('.text .text-title').length ? _$(bannerHotspot).find('.component-BannerSponsor .text .text-title').html() : '',
                        formato: 'Banner testata con categorie',
                        landing: _$(bannerHotspot).find('.text-link').length ? _$(bannerHotspot).find('.text-link').attr('href') : '',
                        posizioneSlide: 0
                    })
                    if (_$(bannerHotspot).attr('data-promo')) {
                        datapromoArray.push(JSON.parse(_$(bannerHotspot).attr('data-promo')));
                    } else {
                        datapromoArray.push("");
                    }                         
                }
                break;
            case BANNER_TYPES.CAROSELLO_SPONSOR:
                if(_$('.component-CarouselBenessere').length){
                    let carouselBenessere = _$('.component-CarouselBenessere');
                    for(let i = 0; i < _$(carouselBenessere).length; i++){
                        sponsor.push(
                            {
                                brand: '',
                                campagna: _$(carouselBenessere).eq(i).find('.title-container .title').length ? _$(carouselBenessere).eq(i).find('.title-container .title').html() : '',
                                formato: 'Carosello sponsor',
                                landing: _$(carouselBenessere).eq(i).find('.content-container a').attr('href'),
                                posizioneSlide: 0
                            }
                        )
                        if (_$(carouselBenessere).eq(i).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(carouselBenessere).eq(i).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }     
                    }
                }
                break;
            case BANNER_TYPES.BANNER_LANDING_IDM:
                banner = _$('.component-BannerLandingIdm').length ? _$('.component-BannerLandingIdm') : '';

                if(banner.length){
                    let slide;
                    for (let i = 0; i < banner.length; i++) {
                        slide = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Top banner',
                            landing: slide && slide.find('.link-banner-sponsor a').length ? slide.find('.link-banner-sponsor a').attr('href') : '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0,
                        });
                        if (slide.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(slide.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                        
                    }
                }
                break;
            case BANNER_TYPES.CARDS_IMAGE_TOP:
                banner = _$('.cards-image-top .swiper-offers .component-card-image-top').length ? _$('.cards-image-top .swiper-offers .component-card-image-top') : '';

                if(banner.length){
                    let card;
                    for (let i = 0; i < banner.length; i ++){
                        card = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Card image top',
                            landing: card && card.find('a.uk-link').length ? card.find('a.uk-link').attr('href') : '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0
                        });

                        if (card.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(card.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
                }
                break;
            case BANNER_TYPES.PROMOTIONS_AND_VISIBILITIES:
                banner = _$('.component-PromoMdd .component-CardSimple').length ? _$('.component-PromoMdd .component-CardSimple') : '';

                if(banner.length){
                    let card;
                    for (let i = 0; i < banner.length; i ++){
                        card = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Promotions and Visibility',
                            landing: card && card.find('a').length ? card.find('a').attr('href') : '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0
                        });

                        if (card.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(card.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
                }
                break;
            case BANNER_TYPES.CONAD_BRANDS:
                banner = _$('.component-1-4-conad-brands .section-scopri-marchi-desktop').find('.component-TileBrand').length ? _$('.component-1-4-conad-brands .section-scopri-marchi-desktop').find('.component-TileBrand') : '';

                if(banner.length){
                    let card;
                    for (let i = 0; i < banner.length; i ++){
                        card = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Conad Brands',
                            landing: card && card.find('a').length ? card.find('a').attr('href') : '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0
                        });

                        if (card.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(card.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
                }
                break;
            case BANNER_TYPES.BANNER_LISTING_CONAD_BRANDS:
                banner = _$('.general-tile-container').find('.component-TilePartner').length ? _$('.general-tile-container').find('.component-TilePartner') : '';

                if(banner.length){
                    let card;
                    for (let i = 0; i < banner.length; i ++){
                        card = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Card Shop in Shop BTF',
                            landing: card && card.find('a').length ? card.find('a').attr('href') : '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0
                        });

                        if (card.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(card.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
                }
                break;
            case BANNER_TYPES.TILES_BRANDS:
                banner = _$('.general-tile-container').find('.component-TileBrand').length ? _$('.general-tile-container').find('.component-TileBrand') : '';

                if(banner.length){
                    let card;
                    for (let i = 0; i < banner.length; i ++){
                        card = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Card Shop in Shop BTF',
                            landing: card && card.find('a').length ? card.find('a').attr('href') : '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0
                        });

                        if (card.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(card.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
                }
                break;
            case BANNER_TYPES.TILES_PARTNERS:
                banner = _$('.general-tile-container').find('.component-TilePartner').length ? _$('.general-tile-container').find('.component-TilePartner') : '';

                if(banner.length){
                    let card;
                    for (let i = 0; i < banner.length; i ++){
                        card = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Card Shop in Shop BTF',
                            landing: card && card.find('a').length ? card.find('a').attr('href') : '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0
                        });

                        if (card.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(card.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
                }
                break;
            case BANNER_TYPES.HIGHLIGHTED_PRODUCTS:
                banner = _$('.component-ProdottiEvidenza').length ? _$('.component-ProdottiEvidenza') : '';
                if(banner.length){
                    let card;
                    for (let i = 0; i < banner.length; i ++){
                        card = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Highlighted Products',
                            landing: card && card.find('a.link-prod-evid').length ? card.find('a.link-prod-evid').attr('href') : '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0
                        });

                        if (card.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(card.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
                }
                break;
            case BANNER_TYPES.ISPIRAZIONALE:
                banner = _$('.component-Ispirazionale').length ? _$('.component-Ispirazionale') : '';

                if(banner.length){
                    let card;
                    for (let i = 0; i < banner.length; i ++){
                        card = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Get inspired',
                            landing: '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0
                        });
                        

                        if (card.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(card.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
                }
                break;
            case BANNER_TYPES.STORYTELLING_A:
                banner = _$('.component-StorytellingA').length ? _$('.component-StorytellingA') : '';
                if(banner.length){
                    let card;
                    for (let i = 0; i < banner.length; i ++){
                        card = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Storytelling',
                            landing: '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0
                        });

                        if (card.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(card.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
                }
                break;
            case BANNER_TYPES.STORYTELLING_B:
                banner = _$('.component-StorytellingB').length ? _$('.component-StorytellingB') : '';
                if(banner.length){
                    let card;
                    for (let i = 0; i < banner.length; i ++){
                        card = banner ? banner.eq(i) : '';
                        sponsor.push({
                            formato: 'Storytelling',
                            landing: '',
                            posizioneSlide: banner.length > 1 ? i + 1 : 0
                        });

                        if (card.attr('data-promo')) {
                            datapromoArray.push(JSON.parse(card.attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
                }
                break;
            default:
                break;
        }

        return [sponsor, datapromoArray];
    }

    getBannerClickEvent(bannerType: BANNER_TYPES, analyticsData: {}, banner): any{
        let slides, slide;
        switch(bannerType){
            case BANNER_TYPES.BANNER_LANDING_IDM:
                analyticsData = {
                    event: 'advClick',
                    sponsor: [{
                        formato: 'Top banner',
                        landing: banner.find('.link-banner-sponsor a').length ? banner.find('.link-banner-sponsor a').attr('href') : '',
                        posizioneSlide: _$(banner).closest('.component-BannerLandingIdm').index() + 1,
                    }]
                }
                break;
            case BANNER_TYPES.PROMO_COMPONENT:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            brand: banner ? banner.find('.section-title').children('b').html() : '',
                            campagna: banner ? banner.find('.promo-title').html() : '',
                            formato: 'Banner testata',
                            landing: banner ? banner.find('.button-scopri').attr('href') : '',
                            posizioneSlide: _$(banner).closest('.component-PromoIdm').index() + 1
                        }]
                    }
                }
            case BANNER_TYPES.BANNER_SPONSOR:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            brand: "",
                            campagna: banner ? banner.find('.banner-title').html() : '',
                            formato: 'Banner BTF',
                            landing: banner ? banner.find('a').attr('href') : '',
                            posizioneSlide: _$(banner).closest('.component-BannerSponsor').index() + 1
                        }]
                    }
                }
                break;
            case BANNER_TYPES.STRIP_BTF:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            brand: "",
                            campagna: banner ? banner.find('.banner-title').html() : '',
                            formato: 'Strip BTF',
                            landing: _$(banner).find('a').attr('href') ? _$(banner).find('a').attr('href') : '',
                            posizioneSlide: _$(banner).closest('div[nkPage="BannerStripe"]').index() + 1
                        }]
                    }
                }
            case BANNER_TYPES.CARD_OFFERTA_BTF:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            brand: "",
                            campagna: banner ? banner.find('.card-offerta-title').html() : '',
                            formato: 'Card offerta BTF',
                            landing: banner ? banner.find('.link-card-offerta a').attr('href') : '',
                            posizioneSlide: _$(banner).closest('.component-OfferCard').index() + 1
                        }]
                    }
                }
                break;
            case BANNER_TYPES.CARD_SHOP_IN_SHOP_BTF:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            brand: banner ? banner.find('span').html() : '',
                            campagna: '',
                            formato: 'Card Shop in Shop BTF',
                            landing: banner ? banner.find('a').attr('href') : '',
                            posizioneSlide: _$(banner).closest('.component-ShopIdm').index() + 1
                        }]
                    }
                }
                break;
            case BANNER_TYPES.BANNERS_TESTATA:
                let formato;
                if(_$(banner).closest('.component-BannerListing.offerte-video').length){
                    formato = 'Banner testata video';
                }else if(_$(banner).closest('.component-BannerListing.banner-simple').length){
                    formato = 'Banner testata semplice';
                }else if(_$(banner).closest('.component-BannerListing.banner-iniziative').length ||
                _$(banner).closest('.component-BannerListing.banner-hotspot').length){
                    formato = 'Banner testata con categorie';
                }

                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            brand: '',
                            campagna: _$(banner).find('.banner-title').html(),
                            formato: formato,
                            landing: _$(banner).find('.link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }
                }
                break;
            case BANNER_TYPES.CAROSELLO_SPONSOR:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            brand: '',
                            campagna: _$(banner).find('.title-container .title').length ? _$(banner).find('.title-container .title').html() : '',
                            formato: 'Carosello sponsor',
                            landing: _$(banner).find('.content-container a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }
                }
                break;
            case BANNER_TYPES.CARDS_IMAGE_TOP:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            formato: 'Card image top',
                            landing: _$(banner).find('a.uk-link').attr('href'),
                            posizioneSlide: 0
                        }]
                    }
                }
                break;
            case BANNER_TYPES.PROMOTIONS_AND_VISIBILITIES:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            formato: 'Promotions and Visibility',
                            landing: _$(banner).find('a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }
                }
                break;
            case BANNER_TYPES.CONAD_BRANDS:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            formato: 'Conad Brands',
                            landing: _$(banner).find('a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }
                }
                break;
            case BANNER_TYPES.TILES_BRANDS:
            case BANNER_TYPES.TILES_PARTNERS:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            formato: 'Card Shop in Shop BTF',
                            landing: _$(banner).find('a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }
                }
                break;
            case BANNER_TYPES.HIGHLIGHTED_PRODUCTS:
                if(banner){
                    analyticsData = {
                        event: _$(banner).length && _$(banner).hasClass('link-prod-evid') ? 'advVediTutti' : 'advClick',
                        sponsor: [{
                            formato: 'Highlighted Products',
                            landing: _$(banner).closest('.component-ProdottiEvidenza').find('a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }
                }
                break;
            case BANNER_TYPES.ISPIRAZIONALE:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            formato: 'Get inspired',
                            landing: '',
                            posizioneSlide: 0
                        }]
                    }
                }
                break;
            case BANNER_TYPES.STORYTELLING_A:
            case BANNER_TYPES.STORYTELLING_B:
                if(banner){
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            formato: 'Storytelling',
                            landing: '',
                            posizioneSlide: 0
                        }]
                    }
                }
                break;
            default:
                break;
        }
        return analyticsData;
    }


}