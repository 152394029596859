import { _$, _Uikit, Window } from '../../../main';
import { GoogleAddressUtils } from 'src/services/utils/google-address-utils';
import { GoogleUtils } from 'src/services/utils/google-utils';
import { GoogleAddress } from 'src/services/utils/google-address-interface';
import { LOADER_POSITION, Utils } from '../utils'; 
import { LocalStorage } from "../../local-storage/local-storage";
import { OnboardingManager, MODAL_ONBOARDING_STATUS } from '../onboarding-manager';
import { getGMapsManager } from './google-maps-manager';

export class GoogleInputManager{

    private geocodedAddress;
    private googleInputs = {};
    private addressData = {};
    private autocompleteInit = {};
    private detectPositionBox = _$(".pac-container-custom");
    private currentAddress : GoogleAddress;

    /* Variabili temporanee di indirizzi attualmente in ricerca e non inerenti e già associato al carrello */
    private temporaryAddress: GoogleAddress;
    private temporaryLat;
    private temporaryLong;
    private temporaryPartialAddress: boolean;
    private gMapsManager;
    private ricercaAnalytics = {
        tipoRicerca : '',
        indirizzoRicerca: ''
    }

    constructor(private _googleAddressUtils: GoogleAddressUtils, private _utils: Utils, private _localStorage: LocalStorage, private _googleUtils: GoogleUtils, private _onboardingManager: OnboardingManager){
        this.onInput(_$('.google-input-line1'));
        this.onInputLine2(_$('.google-input-line2'));
        this.setGoogleInputOnClicks();
        this.gMapsManager = getGMapsManager();
        this.initGoogleAutocomplete();
    }

    nkOnInit(){
        
    }

    setGoogleInputOnClicks(){
        _$('.google-input .submitButton').on('click', (val) => {
            this.doGeocode(_$(val.target));
        })

        _$('.google-input').find('button:first').on('click', (val) => {
            this.resetGoogleInput(_$(val.target));
        });

        _$('#googleInputOnboardingCheckoutLine1, #googleInputOnboardingCheckoutLine2').on('input', () => {
            if(this._onboardingManager.modalStatus == 'step2Checkout'){
                _$('#modal-onboarding').addClass('on-loading address-edit');
            }
        });

        _$('#googleInputOnboardingCheckoutLine1').closest('.google-input').find('button:first').on('click', () => {
            if(this._onboardingManager.modalStatus == 'step2Checkout'){
                _$('#modal-onboarding').addClass('on-loading address-edit');
            }
        });

        _$('.device_mobile #googleInputOnboardingCheckoutLine1, #googleInputOnboardingCheckoutLine2, .device_tablet #googleInputOnboardingCheckoutLine1, #googleInputOnboardingCheckoutLine2').on('focusin', () => {
            _$('#googleInputOnboardingCheckoutLine1').closest('.google-input').addClass('focused');
            _$('#googleInputOnboardingCheckoutLine1').siblings('.submitButton').fadeIn();
        });

        _$('.device_mobile #googleInputOnboardingCheckoutLine1, #googleInputOnboardingCheckoutLine2, .device_tablet #googleInputOnboardingCheckoutLine1, #googleInputOnboardingCheckoutLine2').on('focusout', () => {
            _$('#googleInputOnboardingCheckoutLine1').closest('.google-input').removeClass('focused');
            _$('#googleInputOnboardingCheckoutLine1').siblings('.submitButton').hide();
        });
    }

    async initGoogleAutocomplete(){
        
        const gmaps = await this.gMapsManager.getGMaps();
        const options = {
            componentRestrictions: {
                country: 'it'
            }
        };

        /* Inserire gli id di ogni google input line1 */
        const googleInputsIds = ['googleInputEntrypageLine1', 'googleInputOnboardingStep0Line1', 'googleInputLandingpageLine1', 'googleInputLPOnboardingLine1', 'googleInputOnboardingCheckoutLine1']

        for(let i = 0; i < googleInputsIds.length; i++){
            this.googleInputs[googleInputsIds[i]] = document.getElementById(googleInputsIds[i]) as HTMLInputElement;
        }

        if(_$('#googleInputEntrypageLine1').length)
            this.autocompleteInit['googleInputEntrypageLine1'] = new gmaps.places.Autocomplete(this.googleInputs['googleInputEntrypageLine1'], options);
        if(_$('#googleInputOnboardingStep0Line1').length)
            this.autocompleteInit['googleInputOnboardingStep0Line1'] = new gmaps.places.Autocomplete(this.googleInputs['googleInputOnboardingStep0Line1'], options)
        if(_$('#googleInputLandingpageLine1').length)
            this.autocompleteInit['googleInputLandingpageLine1'] = new gmaps.places.Autocomplete(this.googleInputs['googleInputLandingpageLine1'], options)
        if(_$('#googleInputLPOnboardingLine1').length)
            this.autocompleteInit['googleInputLPOnboardingLine1'] = new gmaps.places.Autocomplete(this.googleInputs['googleInputLPOnboardingLine1'], options)
        if(_$('#googleInputOnboardingCheckoutLine1').length)
            this.autocompleteInit['googleInputOnboardingCheckoutLine1'] = new gmaps.places.Autocomplete(this.googleInputs['googleInputOnboardingCheckoutLine1'], options);

        this.getSelectedAddressData(this.autocompleteInit)
        this._utils.hideBox(this.detectPositionBox);
        this._utils.showBoxOnPermit(_$('.google-input-line1'));
        this.detectUserPosition(this.detectPositionBox);
        
    }

    onInput(googleInputLine1){
        _$(googleInputLine1).on('input', (val) => {
            this.resetLine2(_$(val.target).siblings('.google-input-line2'));
            this.hideLine2(_$(val.target).siblings('.google-input-line2'));
            if(_$(val.target).val() != ''){
                _$(val.target).closest('.google-input').find('button:first').css('display', 'block');
            }else{
                _$(val.target).closest('.google-input').find('button:first').css('display', 'none');
            }
        });
    }

    onInputLine2(googleInputLine2){
        _$(googleInputLine2).on('keyup', (e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
                this.doGeocode(_$(e.currentTarget))
            }
        });
    }

    hideLine2(googleInputLine2){
        _$(googleInputLine2).addClass('uk-hidden');
        _$(googleInputLine2).next().addClass('uk-hidden');
    }

    resetLine2(googleInputLine2){
        _$(googleInputLine2).val('');
    }

    resetLine1(googleInputLine1){
        _$(googleInputLine1).val('');
        
    }

    showLine2(googleInputLine2){
        _$(googleInputLine2).removeClass('uk-hidden');
    }

    setLine1(googleInputLine1Id, addressNoStreetNumber){
        _$('#' + googleInputLine1Id).val(addressNoStreetNumber);
    }

    setLine2(googleInputLine1Id, streetNumber){
        _$('#' + googleInputLine1Id).siblings('.google-input-line2').removeClass('uk-hidden');
        _$('#' + googleInputLine1Id).siblings('.google-input-line2').next().removeClass('uk-hidden');
        _$('#' + googleInputLine1Id).siblings('.google-input-line2').val(streetNumber);
    }

    resetGoogleInput(googleInput){
        let googleInputForm = _$(googleInput).closest('.google-input');
        let googleInputLine1 = _$(googleInputForm).find('.google-input-line1');
        let googleInputLine2 = _$(googleInputForm).find('.google-input-line2');
        _$(googleInputForm).find('button:first').css('display', 'none');
        this.resetLine1(googleInputLine1);
        this.resetLine2(googleInputLine2);
        this.hideLine2(googleInputLine2);
    }

    fillGoogleInputAddress(googleAddress: GoogleAddress, inputAddressId: string){
        this.setLine1(inputAddressId, googleAddress.formatted_address_no_street_number);
        if(googleAddress != null && googleAddress.isStreetAddress){
            this.setLine2(inputAddressId, googleAddress.street_number);
        }
    }

    doGeocode(input){
        let inputAddress = _$(input).closest('.google-input').find('.google-input-line1');
        let inputCivico = _$(inputAddress).siblings('.google-input-line2');
        let messageError = _$(input).parents('.google-input').find('.message-error')[0];
        let inputText = '';
        if(_$(inputCivico) && _$(inputCivico).val() != null && _$(inputAddress) && _$(inputAddress).val() != null){
            inputText = [_$(inputCivico).val().toString(), _$(inputAddress).val().toString()].join(' ');
        }else if(_$(inputAddress) && _$(inputAddress).val() != null){
            inputText = _$(inputAddress).val().toString();
        }
        this.manageGeocode(inputText, _$(inputAddress).prop('id'));
    }

    async manageGeocode(inputText, caseNumber){
        const gMaps = await this.gMapsManager.getGMaps();

        let geocoder = new gMaps.Geocoder();
        var geocodedAddress;
        geocoder.geocode({'address': inputText}, async (results, status) => {
            if (status === gMaps.GeocoderStatus.OK && results.length > 0) {
                /* Priorità agli indirizzi di tipo street_address, altrimenti si prende il primo */
                let streetAddressList = results.filter(obj => {
                    return obj.types.includes('street_address');
                });

                if(streetAddressList.length){
                    geocodedAddress = streetAddressList[0];
                }else{
                    geocodedAddress = results[0];
                }

                this.manageGeocodedAddress(geocodedAddress, caseNumber);
            }else{
                _Uikit.modal('#modal-onboarding').show();
            }
        });
    }

    preheaderModifyService(){
        let formattedAddress = '';
        if(Window.typeOfService == "HOME_DELIVERY"){
            formattedAddress = Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress.formattedAddress ? Window.cart.deliveryAddress.formattedAddress : '';
            this._utils.showLoader('', true);
            this.manageGeocode(formattedAddress, 'modifyServiceFromPreheader');
        }else if(Window.typeOfService == "ORDER_AND_COLLECT"){
            formattedAddress = this._localStorage.getFromStorage('selectedAddressOrderAndCollect') != null &&
                                                    this._localStorage.getFromStorage('selectedAddressOrderAndCollect').formattedAddress != null ? this._localStorage.getFromStorage('selectedAddressOrderAndCollect').formattedAddress : (Window.pointOfService && Window.pointOfService.address && Window.pointOfService.address.formattedAddress ? Window.pointOfService.address.formattedAddress : '');
            this._utils.showLoader('', true);
            this.manageGeocode(formattedAddress, 'modifyServiceFromPreheader');
        }
        this._onboardingManager.sendAnalytics('1.5.3', 'modificaServizio');

    }

    preheaderModifyPdv(){
        let formattedAddress = '';
        if(Window.typeOfService == "HOME_DELIVERY"){
            if(Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress && !Window.cart.deliveryAddress.notCompleted){
                formattedAddress = Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress.formattedAddress ? Window.cart.deliveryAddress.formattedAddress : '';
                this._utils.showLoader('', true);
                this.manageGeocode(formattedAddress, 'modifyPDVFromPreheaderHD');
            }else{
                this.preheaderModifyService();
            }
        }else if(Window.typeOfService == "ORDER_AND_COLLECT"){
            formattedAddress = this._localStorage.getFromStorage('selectedAddressOrderAndCollect') != null &&
                                                    this._localStorage.getFromStorage('selectedAddressOrderAndCollect').formattedAddress != null ? this._localStorage.getFromStorage('selectedAddressOrderAndCollect').formattedAddress : (Window.pointOfService && Window.pointOfService.address && Window.pointOfService.address.formattedAddress ? Window.pointOfService.address.formattedAddress : '');
            this._utils.showLoader('', true);
            this.manageGeocode(formattedAddress, 'modifyPDVFromPreheaderOC');
        }
        this._onboardingManager.sendAnalytics('1.5.4', 'modificaIndirizzo');

    }

    async getSelectedAddressData(autocompleteObject: object){
        const gmaps = await this.gMapsManager.getGMaps();
        this.ricercaAnalytics.tipoRicerca = 'Manuale';
        for (const [key, value] of Object.entries(autocompleteObject)) {
            gmaps.event.addListener(value, 'place_changed', () => {
                let place = value.getPlace();

                /* select su un risultato */
                if(place.geometry){
                    this.manageGeocodedAddress(place, key);
                }
                /* invio da tastiera */
                else{
                    let itemFull = _$(".pac-container:visible .pac-item:first").text();
                    
                    let itemQuery = _$(".pac-container:visible .pac-item:first .pac-item-query").text();
                    
                    let firstResult = itemQuery + ' ' + itemFull.substring(itemQuery.length);
                    
                    let inputValue;
                    let inputCivico;
                    
                    switch(key){
                        case 'googleInputEntrypageLine1':
                        case 'googleInputOnboardingStep0Line1':
                        case 'googleInputLandingpageLine1':
                        case 'googleInputLPOnboardingLine1':
                        case 'googleInputOnboardingCheckoutLine1':
                            inputValue = firstResult;
                            inputCivico = _$('#'+key).closest('.google-input').find('.google-input-line2');
                            _$('#'+key).trigger('blur');
                            break;
                    }

                    let geocoder = new gmaps.Geocoder();
                    var geocodedAddress;
                    geocoder.geocode({'address': inputValue}, async (results, status) => {
                        if (status === gmaps.GeocoderStatus.OK && results.length > 0) {

                            /* Priorità agli indirizzi di tipo street_address, altrimenti si prende il primo */
                            let streetAddressList = results.filter(obj => {
                                return obj.types.includes('street_address');
                            });

                            if(streetAddressList.length){
                                geocodedAddress = streetAddressList[0];
                            }else{
                                geocodedAddress = results[0];
                            }

                            this.manageGeocodedAddress(geocodedAddress, key);
                        }else{
                            console.log('geocoded address');
                        }
                    });
                }
            })
        }
    }

    async manageGeocodedAddress(address: any, inputId: string, modifyPdvService?: string){
        this._googleAddressUtils.googleAddressSearched = this._googleAddressUtils.createGoogleAddressObject(address);
        this.temporaryAddress = this._googleAddressUtils.googleAddressSearched;
        this.fillGoogleInputAddress(this.temporaryAddress, inputId);
        this._localStorage.setItem('step2Flow', JSON.stringify('loadStep2'));
        this.setCoordinates(address.geometry.location.lat(), address.geometry.location.lng(), address.address_components);
        this._onboardingManager.addressLoadedOnStep0 = this.addressData;
        let stores;
        this.ricercaAnalytics.indirizzoRicerca = address.formatted_address;
        this._localStorage.setItem('ricercaAnalytics', JSON.stringify(this.ricercaAnalytics));
        let fetchStoresResult;
        switch(inputId){
            case 'googleInputEntrypageLine1':
            case 'googleInputOnboardingStep0Line1':
            case 'googleInputLandingpageLine1':
            case 'googleInputLPOnboardingLine1':
            case 'modifyServiceFromPreheader':
                this._updateAddressData(address);
                this._localStorage.setItem('selectedAddress', JSON.stringify(address));
                stores = await this._googleUtils.fetchStores(this.addressData, this.addressData['notCompleted'] ? 'ORDER_AND_COLLECT' : null);
                if(stores != null && stores == 'true'){
                    if(this._onboardingManager.checkDataStatusIncompleteAddress()){
                        this._googleUtils.loadStores('HOME_DELIVERY', false);
                    }else{
                        this._onboardingManager.manageModalSteps('step1', this.addressData['notCompleted'] ? 'ORDER_AND_COLLECT' : null, this.addressData);
                    }
                }else if(stores != null && stores == 'false'){
                    this._onboardingManager.manageModalSteps('step1', this.addressData['notCompleted'] ? 'ORDER_AND_COLLECT' : null, this.addressData);
                }
                break;
            case 'modifyPDVFromPreheaderHD':
                this._updateAddressData(address);
                this._localStorage.setItem('selectedAddress', JSON.stringify(address));
                fetchStoresResult = await this._googleUtils.fetchStores(this.addressData, this.addressData['notCompleted'] ? 'ORDER_AND_COLLECT' : null);
                if(fetchStoresResult != null && fetchStoresResult != 'error'){
                    this._googleUtils.loadStores('HOME_DELIVERY', true);
                    if(Window.typeOfService && Window.pointOfService && Window.pointOfService.name){
                        this._onboardingManager.togglePreselectedPdv(Window.typeOfService, Window.pointOfService.name);
                    }
                }
                break;
            case 'modifyPDVFromPreheaderOC':
                this._updateAddressData(address);
                this._localStorage.setItem('selectedAddress', JSON.stringify(address));
                _$('#modal-onboarding .section-address .section-address-complete').find('span').html(address.formatted_address);
                fetchStoresResult = await this._googleUtils.fetchStores(this.addressData, this.addressData['notCompleted'] ? 'ORDER_AND_COLLECT' : null);
                if(fetchStoresResult != null && fetchStoresResult != 'error'){
                    this._googleUtils.loadStores('ORDER_AND_COLLECT', true);
                    if(Window.typeOfService && Window.pointOfService && Window.pointOfService.name){
                        this._onboardingManager.togglePreselectedPdv(Window.typeOfService, Window.pointOfService.name);
                    }
                }
                break;
            case 'googleInputOnboardingCheckoutLine1':
                this._updateAddressData(address);
                this._localStorage.setItem('selectedAddress', JSON.stringify(address));
                _$('#modal-onboarding').addClass('on-loading');
                let stepCheckout = true;
                stores = await this._googleUtils.fetchStores(this.addressData, Window.typeOfService, stepCheckout);
                _$('#modal-onboarding').removeClass('on-loading');
                if(stores != null && stores == 'true'){
                    this._googleUtils.loadStores(Window.typeOfService, false, stepCheckout, this.addressData);
                }else if(stores != null && stores == 'false'){
                    this._onboardingManager.manageModalSteps('step1Checkout', Window.typeOfService, this.addressData);
                }
                break;
        }

        /* Analytics */

        switch(inputId){
            case 'googleInputEntrypageLine1':
                //1.1.2
                this._onboardingManager.sendAnalytics('1.1.2','ricercaIndirizzo');
                break;
            case 'googleInputLandingpageLine1':
                this._onboardingManager.sendAnalytics('1.6.1', 'ricercaIndirizzo');
                break;
            case 'googleInputOnboardingStep0Line1':
                if(this._onboardingManager.checkDataStatusIncompleteAddress()){
                    this._onboardingManager.sendAnalytics('1.4.2','ricercaIndirizzo')
                }else{
                    this._onboardingManager.sendAnalytics('1.2.4','ricercaIndirizzo')
                }
                break;
            case 'googleInputLPOnboardingLine1':
                this._onboardingManager.sendAnalytics( '1.7.3','ricercaIndirizzo');
                break;
            case 'googleInputOnboardingCheckoutLine1':
                this._onboardingManager.sendAnalytics('1.8.10', 'ricercaIndirizzo');
                break;
        }

        if(this._onboardingManager.checkDataStatusIncompleteAddress()){
            _$('#modal-onboarding').attr('data-status', MODAL_ONBOARDING_STATUS.DEFAULT);
        }
    }

    /**
     * Imposta le variabili lat e lang generiche e i componenti dell'indirizzo
     * @param lat
     * @param lng
     * @param addressComponents
     */
    setCoordinates(lat, lng, addressComponents) {
        this.temporaryLat = lat;
        this.temporaryLong = lng;
        this.temporaryPartialAddress = true;
        
        if(addressComponents){
            for(let i=0; i<addressComponents.length; i++) {
                const element = addressComponents[i]
                if(element.types && element.types.indexOf('street_number') != -1) {
                    this.temporaryPartialAddress = false;
                }
            }
        }
    }

    /**
     * Rileva automaticamente la posizione dell'utente - lanciata all'init delle pagine
     * @param detectPositionBox
     */
    async detectUserPosition(detectPositionBox) {
        const _this = this;
        const gmaps = await this.gMapsManager.getGMaps();
        _$(".pac-item").click((e) => {
            this.ricercaAnalytics.tipoRicerca = 'Geolocalizzazione';
            const parentInput = _$(this).parent().parent().find('.google-input-line1');
            const geocoder = new gmaps.Geocoder();
            if (navigator.geolocation) {
                detectPositionBox.hide();
                this._utils.showLoader(parentInput, true, LOADER_POSITION.GEOLOCALIZATION_INPUT);
                navigator.geolocation.getCurrentPosition((position) => {
                    this._utils.showLoader(parentInput, false, LOADER_POSITION.GEOLOCALIZATION_INPUT);
                    const latlng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    this.setCoordinates(latlng.lat, latlng.lng, [{"types" : [ "street_number" ]}]);

                    this._setGeocode(geocoder, latlng, e);

                }, err => {
                    this._utils.showLoader(parentInput, false, LOADER_POSITION.GEOLOCALIZATION_INPUT);
                    console.log("error fetching user's current position: ", err);
                    this._utils.showNotification('error fetching user\'s current position:' + err.message, 'primary');
                }, {enableHighAccuracy: true})
            }
        })
    }

    /**
     * Sets geocoder
     * @param geocoder
     * @param latlng
     * @param e
     */
    private _setGeocode(geocoder: google.maps.Geocoder, latlng: { lat: number; lng: number; }, e: any) {
        geocoder.geocode({location: latlng}, (results, status) => {
            if (status === "OK") {
                // formatto il risultato di google per formato SAP + aggiungo a localstorage
                this._updateAddressData(results[0]);
                this._localStorage.setItem('selectedAddress', JSON.stringify(results[0]));
                _$(e.target).closest('.google-input').find('.google-input-line1').val(results[0].formatted_address);
                _$(e.target).closest('.google-input').find('.submitButton').trigger('click');
            } else {
                console.warn("Geocoder failed due to: " + status)
            }
        });
    }

    /**
     * Conversione da Indirizzo di Google a indirizzo per SAP
     * @param value
     */
    _updateAddressData(value) {
        if (!value || !value.address_components) return;
        const addressComponents = value.address_components;
        const locality = `${this._googleAddressUtils._getAddressComp(addressComponents, 'locality', 'long_name')}`.trim();
        if(this._googleAddressUtils._getAddressComp(addressComponents, 'route', 'long_name')){
            this.addressData['line1'] = [this._googleAddressUtils._getAddressComp(addressComponents, 'route', 'long_name'), this._googleAddressUtils._getAddressComp(addressComponents, 'postal_code', 'long_name'), this._googleAddressUtils._getAddressComp(addressComponents, 'locality', 'long_name'), this._googleAddressUtils._getAddressComp(addressComponents, 'country', 'long_name')].join(', ').trim();
        }else{
            this.addressData['line1'] = '';
        }
        this.addressData['formattedAddress'] = value.formatted_address;
        this.addressData['town'] = `${this._googleAddressUtils._getAddressComp(addressComponents, 'administrative_area_level_3', 'long_name')}`.trim();
        this.addressData['line2'] = this._googleAddressUtils._getAddressComp(addressComponents, 'street_number', 'long_name');
        this.addressData['postalCode'] = `${this._googleAddressUtils._getAddressComp(addressComponents, 'postal_code', 'long_name')}`.trim();
        this.addressData['district'] = `${this._googleAddressUtils._getAddressComp(addressComponents, 'administrative_area_level_2', 'short_name')}`.trim();
        this.addressData['country'] = {
            isocode: `${this._googleAddressUtils._getAddressComp(addressComponents, 'country', 'short_name')}`.trim(),
            name: `${this._googleAddressUtils._getAddressComp(addressComponents, 'country', 'long_name')}`.trim()
        };
        if (value.geometry.location.lat()) this.addressData['latitude'] = value.geometry.location.lat();
        if (value.geometry.location.lng()) this.addressData['longitude'] = value.geometry.location.lng();
        if (value.formatted_address) this.addressData['formattedAddress'] = value.formatted_address;
        this.addressData['notCompleted'] = this.addressData['line2'] != null && this.addressData['line2'] != '' ? false : true;
        this._localStorage.setItem('selectedAddress_SAP_Format', JSON.stringify(this.addressData));
    }
}