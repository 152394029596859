import { _$, _Uikit } from '../../main';
import { Utils } from 'src/services/utils/utils';
export class ReplacementMode {
    constructor() {}

    nkOnInit() {
        //this.toggleStyle();
    }

    showOptionDesc(e: object) {
        let activeIndex = _$(e).find('input').index(_$(e).find('input:checked'));
        _$(e).siblings('.footer').find('.desc').css('display', 'none');
        _$(e).siblings('.footer').find(`.desc:eq(${activeIndex})`).css('display', 'block');
    }

    showScopriDiPiu(e: object) {
        _$(e).find('a').removeClass('uk-hidden');
    }

    toggleStyle(e: object) {
        _$('.replacement-mode-desktop').on('beforeshow', function () {
            _$('.replacement-mode-desktop').removeClass('selected');
        });

        _$('.replacement-mode-desktop').on('hidden', function () {
             _$('.replacement-mode-desktop').addClass('selected');
        });
    }
}
