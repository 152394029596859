import { _$, _Uikit, _moment, Window } from '../../main';
import { CartService } from '../../services/cart-service/cart-service';
import { LOADER_POSITION, Utils } from '../../services/utils/utils';
import { LocalStorage } from '../../services/local-storage/local-storage';

export class LockerUtils{
    constructor(private utils: Utils,
                private cartService: CartService){

    }

    nkOnInit(){

    }

    checkInvalidUnitObjectsPresence(err): boolean{
        return err && err.response && err.response.data && err.response.data.message && this.parseLockerErrorMessageAddToCart(err.response.data.message) &&
        this.parseLockerErrorMessageAddToCart(err.response.data.message)['invalidUnitObjects'] != null && this.parseLockerErrorMessageAddToCart(err.response.data.message)['invalidUnitObjects'].length
    }

    getInvalidUnitObjects(err): object{
        if(err && err.response && err.response.data && err.response.data.message && this.parseLockerErrorMessageAddToCart(err.response.data.message) &&
        this.parseLockerErrorMessageAddToCart(err.response.data.message)['invalidUnitObjects'] != null && this.parseLockerErrorMessageAddToCart(err.response.data.message)['invalidUnitObjects'].length){
            return this.parseLockerErrorMessageAddToCart(err.response.data.message)['invalidUnitObjects']
        }else{
            return null;
        }
    }

    checkTooManyUnitsPresence(err): boolean{
        return err && err.response && err.response.data && err.response.data.message && this.parseLockerErrorMessageAddToCart(err.response.data.message) &&
        this.parseLockerErrorMessageAddToCart(err.response.data.message)['errorMessage'] != null && this.parseLockerErrorMessageAddToCart(err.response.data.message)['errorMessage'].includes('ConadLockerTooManyUnitsError')
    }

    createCustomCardLockerError(item): string{
        let card = `
                <div class="invalid-product">
                    <span class="description">
                        ${item.name}
                    </span>
                    <span class="price f-roboto">
                        ${item.entryTotalPrice}€
                    </span>
                </div>`;
        
        return card;
    }

    parseLockerErrorMessageAddToCart(message): JSON{
        try{
            let messageError = message.split('---')[1].trim();
            let jsonError = JSON.parse(messageError);
            return jsonError;
        }catch{
            return null;
        }
        
    }

    /* Gestione modale contingenza locker */
    manageLockerContingencyModal(contingencyValue, productPz: boolean, productKg: boolean){

        if(productPz){
            _$('#modalContingentamentoLockerAddToCart .locker-max-quantity').html(contingencyValue+ ' pezzi');
        }else if(productKg){
            let kg = contingencyValue < 1 ? contingencyValue * 1000 : parseFloat(contingencyValue);
            let text = contingencyValue < 1 ? kg + ' g' : kg + ' kg';
            _$('#modalContingentamentoLockerAddToCart .locker-max-quantity').html(text);
        }
        if(contingencyValue == 0){
            _$('#modalContingentamentoLockerAddToCart .max-contingency-gt-zero').addClass('uk-hidden');
            _$('#modalContingentamentoLockerAddToCart .max-contingency-zero').removeClass('uk-hidden');
        }else if(contingencyValue > 0){
            _$('#modalContingentamentoLockerAddToCart .max-contingency-gt-zero').removeClass('uk-hidden');
            _$('#modalContingentamentoLockerAddToCart .max-contingency-zero').addClass('uk-hidden');
        }

        _Uikit.modal('#modalContingentamentoLockerAddToCart').show();
    }
}