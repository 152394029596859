import { _$, _Uikit, Window } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
import { PageUpdateService  } from '../../services/page-update-service/page-update-service';
import { Utils } from '../../services/utils/utils';
import { CarouselBenessere } from '../1-15-carousel-benessere/1-15-carousel-benessere';
import { CarouselBannerSponsor } from '../1-7-carousel-banner-sponsor/1-7-carousel-banner-sponsor';
export class ModalSearchFilter {
    constructor(
        private _analytics: Analytics,
        private _pageUpdateService: PageUpdateService,
        private _utils: Utils,
        private _carouselBenessere: CarouselBenessere,
        private _carouselBannerSponsor: CarouselBannerSponsor
    ) {}
    
    private filterTitles = {
        'bassi': 'bassiFissi',
        'brand': 'marchi',
        'group-esigenze': 'esigenzeAlimentari',
        'group-denominazione': 'denominazione',
        'order': 'ordinamento',
        'promoType': 'promozioni',
        'promoUser': 'offertePer',
        'region': 'regione'
    }

    private currentQueryParams = '';
    private updateColMainTimer = 100;
    private lastFocusedFilter = 'brand';
    private lastFocusedFilterContainer = '.component-SearchFilter';
    private previousCatAccordion;
    private nextCatAccordion;
    
    nkOnInit() {

        let tpl = document.getElementById("search-modal-filters-template");
        let dest = document.getElementById("offcanvasSearchFilter");
        if(tpl && dest){
            dest.innerHTML = tpl.innerHTML;
        }
        this.checkBoxOnPageLoad();
        this.onFilterClose(this);
        this.onAccordionToggle(this);
        this.setPaginationClickEvent();

        window.onpopstate = (event => {
            if(_$('.col-main').length && window.location.search != null){
                let historyBack = true;
                this.updateColMain(window.location.search, false, null, historyBack);
            }
        });

        if(_$('.component-categoryList').length){
            _$('.component-categoryList').find('a').on('click', (event) => {
                if(_$(event.target).attr('data-rc-value') != null){
                    this.previousCatAccordion =  _$('.component-categoryList').find('a.active');
                    let dataValue;
                    let dataValueLevel = _$(event.target).attr('data-rc-level') != null ? _$(event.target).attr('data-rc-level') : '';
                    switch(dataValueLevel){
                        case '1':
                        case '2':
                            this.nextCatAccordion = _$('.component-categoryList').find(`a[data-rc-value="${_$(event.target).attr('data-rc-value')}"]`);
                            break;
                        case 'all':
                        default:
                            this.nextCatAccordion = _$('.component-categoryList').find(`a[data-rc-value="all"]`)
                            break;
                    }
                    this.manageAccordionClick(event.target);
                }
            })
        }
    }

    handleChange(e: object) {
        _$(e).parents().find('.filter-no-subcat').removeClass('active');
        if (_$(e).is(':checked')) {
            _$(e).parents().find('.filter-no-subcat').addClass('active');
        }
    }

    onFilterClose(functions) {
        _Uikit.accordion(_$('.list-accordion .filter-div'), { animation: false });
        _$('.filter-dropdown').on('hide', function () {
            _$(this)
                .find('.confirmed-selected')
                .filter(':not(.selected)')
                .each(function () {
                    if(_$(this).is('input[type="checkbox"]')) {
                        _$(this).addClass('selected');
                    }
                    if (_$(this).is('input')) {
                        _$(this).prop('checked', true);
                    } else {
                        _$(this).siblings('input').prop('checked', true);
                    }
                    functions.updateFilterStatus(_$(this).closest('.filter-div'));
                });
            _$(this)
                .find('.selected')
                .filter(':not(.confirmed-selected)')
                .each(function () {
                    _$(this).removeClass('selected');
                    _$(this).prop('checked', false);
                    _$(this).siblings('input').prop('checked', false);
                    functions.updateFilterStatus(_$(this).closest('.filter-div'));
                });

            //controllo su categoria senza filtro 3° liv (cat_lev3)
            if(!_$(this).find('input.confirmed-selected').length && !_$(this).find('.checkmark.confirmed-selected').length) {
                if(_$(this).find('input').is(':checked') && !_$(this).hasClass('filter-sort')) {
                    _$(this).find('input').prop('checked', false);
                    functions.updateFilterStatus(_$(this).closest('.filter-div'));
                }
            }

            functions.resetOneOrOther();
            functions.countTotalFilters(_$('.filters-container'));

            let parents = _$('.component-SearchFilter').find('.filter-div').not('.filter-order');
            let inputs = _$(parents).find('input').filter(':checked').length;
            _$(parents).each(function(e) {
                if(!_$(this).hasClass('filter-order')) {
                    if (inputs > 0) {
                        _$('#deleteFilterCTA').removeClass('uk-hidden');
                        _$('.delete-filters-button').removeClass('uk-hidden');
                    } else {
                        _$('#deleteFilterCTA').addClass('uk-hidden');
                        _$('.delete-filters-button').addClass('uk-hidden');
                    }
                }
            });
        });
    }

    onAccordionToggle(functions) {
        _$('#offcanvasSearchFilter .list-accordion .uk-accordion-title').on('click', function () {
            if (!_$('#offcanvasSearchFilter .uk-offcanvas-header').hasClass('uk-hidden')) {
                _$('#offcanvasSearchFilter .uk-offcanvas-header').addClass('uk-hidden');
                _$('#offcanvasSearchFilter .uk-offcanvas-body').find('.filter-div').not('.uk-open').addClass('uk-hidden');
                _$(this).parent().removeClass('uk-hidden');
                _$(this).find('.accordion-footer.uk-hidden').removeClass('uk-hidden');
            } else {
                _$('#offcanvasSearchFilter .uk-offcanvas-header').removeClass('uk-hidden');
                _$('#offcanvasSearchFilter .uk-offcanvas-body').find('.filter-div.uk-hidden').removeClass('uk-hidden');
                _$(this).parent().removeClass('uk-hidden');
                functions.countTotalFilters(_$('#offcanvasSearchFilter'));
            }
        });
        _$('#offcanvasSearchFilter .list-accordion .filter-div').on('show', function () {
            _$(this).find('.uk-badge-custom').addClass('uk-hidden');
        });

        _$('#offcanvasSearchFilter .list-accordion .filter-div').on('hidden', function () {
            functions.updateBadge(_$(this), _$(this).find('input').filter(':checked').length);
        });
    }

    /* Aggiornamento filtri preselezionati */
    /* Aggiungere la classe confirmed-selected sulla classe checkmark per la preselezione*/
    checkBoxOnPageLoad() {
        _$('.checkmark.confirmed-selected').siblings('input').prop('checked', true);
        _$('input:checked.uk-radio').addClass('confirmed-selected');
        _$('button .checkmark.confirmed-selected').prop('checked', true);

        this.resetOneOrOther();
        let modalFunctions = this;
        /* MOBILE */
        _$('.checkmark.confirmed-selected, input:checked.confirmed-selected')
            .closest('.filter')
            .each(function () {
                modalFunctions.updateFilterStatus(this);
            });
        this.showResults(this);


        /* DESKTOP */
        _$('.checkmark.confirmed-selected, input:checked.confirmed-selected')
            .closest('.filter-div')
            .each(function () {
                modalFunctions.checkBoxBassiFissi(_$('.bassi-fissi input'));
                modalFunctions.updateFilterStatus(this);
            });
        if (_$('.checkmark.confirmed-selected').length > 0) {
            _$('#deleteFilterCTA').removeClass('uk-hidden');
            _$('.delete-filters-button').removeClass('uk-hidden');
        }
        
        if(this.getQueryValue() != null){
            let params = new URLSearchParams(Window.location.search);
            
            let isQuery = false;
            let showCancelAll = false;
            
            if(params != null){
                params.forEach((value, key) => {
                    if(key == 'query'){
                        isQuery = true;
                    };
                    if(key != 'query' && key != 'order'){
                        showCancelAll = true;
                    }
                });
            }
            
            if(isQuery && showCancelAll){
                _$('#deleteFilterCTA').removeClass('uk-hidden');
                _$('.delete-filters-button').removeClass('uk-hidden');
            }
        }
        
    }

    checkBoxBassiFissi(e: object) {
        if (_$(e).is(':checked')) {
            _$(e).siblings('.checkmark').addClass('selected confirmed-selected');
        } else {
            _$(e).siblings('.checkmark').removeClass('selected confirmed-selected');
        }
    }

    singleCheckBox(e: object) {
        if (_$(e).find('input:checked').length > 1) {
            _$(e).find('.checkmark.selected').siblings('input:checked').prop('checked', false);
            _$(e).find('input:checked').siblings('.checkmark').addClass('selected');
            _$(e).find('input:not(:checked)').siblings('.checkmark').removeClass('selected');
        } else {
            _$(e).find('input:checked').siblings('.checkmark').addClass('selected');
            _$(e).find('input:not(:checked)').siblings('.checkmark').removeClass('selected');
        }
    }

    updateBadge(e: object, selectedFilters: number) {
        let badge = _$(e).find('.uk-badge-custom');
        if (selectedFilters > 0 && !_$(e).hasClass('uk-open')) {
            badge.removeClass('uk-hidden');
            badge.html(selectedFilters.toString());
        } else {
            badge.addClass('uk-hidden');
        }
    }

    updateFilterStatus(e: object) {
        let selectedFilters = _$(e).find('input').filter(':checked').length;
        
        if (_$(e).attr('class') && _$(e).attr('class').indexOf('filter') != -1) {
            if (selectedFilters > 0) {
                _$(e).addClass('enabled-filter');
                this.enableSingleFilterButtons(e);
            } else if (_$(e).find('.checkmark.confirmed-selected').length == 0) {
                _$(e).removeClass('enabled-filter');
                this.disableSingleFilterButtons(e);
            }
        } else {
            if (selectedFilters > 0) {
                _$(e).find('.filter').addClass('enabled-filter');
                this.enableSingleFilterButtons(e);
            } else if (_$(e).find('.checkmark.confirmed-selected').length == 0) {
                _$(e).find('.filter').removeClass('enabled-filter');
                this.disableSingleFilterButtons(e);
            }
        }

        this.updateBadge(_$(e), selectedFilters);

        if(!_$(e).hasClass('filter-order')) {
            if (selectedFilters > 0) {
                _$('#deleteFilterCTA').removeClass('uk-hidden');
                _$('.delete-filters-button').removeClass('uk-hidden');
            } else {
                _$('#deleteFilterCTA').addClass('uk-hidden');
                _$('.delete-filters-button').addClass('uk-hidden');
            }
        }
    }


    /* Reset della classe 'scroll' per il box-shadowing allo scroll */
    resetFilterSearchScroll(e) {
        _$(e).children().removeClass('scroll');
        _$(e).find('#search-bar-modal').removeClass('scroll');
    }


    countTotalFilters(e: object) {
        let checkedFilters = _$(e).find('input').filter(':checked').length;
        if (_$('.filter-order').find('input').filter(':checked').length) {
            checkedFilters -= 1;
        }
        if (checkedFilters > 0) {
            _$('.delete-filters-button').removeClass('uk-hidden');
            /* this.enableButton("delete-filters-button");
            this.enableButton("show-results-button");
            _$(".delete-filters-button").html(`Cancella filtri [${enabledFilters}]`); */
            _$('#deleteFilterCTA').removeClass('uk-hidden');
        } else {
            _$('.delete-filters-button').addClass('uk-hidden');
            /* this.disableButton("delete-filters-button");
            this.disableButton("show-results-button"); */
            _$('#deleteFilterCTA').addClass('uk-hidden');
        }
    }

    enableSingleFilterButtons(e: object) {
        _$(e).find('.single-filter-reset').prop('disabled', false);
        _$(e).find('.single-filter-apply').prop('disabled', false);
    }

    disableSingleFilterButtons(e: object) {
        _$(e).find('.single-filter-reset').prop('disabled', true);
        _$(e).find('.single-filter-apply').prop('disabled', true);
    }

    /* Resetta filtri bassifissi e offertepromozioni */
    resetOneOrOther() {
        if (_$('.bassi-fissi').length) {
            this.disableFilter(_$('.bassi-fissi'));
        }
        if (_$('.offerte-promozioni').length) {
            this.disableFilter(_$('.offerte-promozioni'));
        }
    }

    deleteAllFilters(e: object) {

        if(_$('.main-content > .uk-container').attr('data-reset')){
            window.location.href = _$('.main-content > .uk-container').attr('data-reset');
        }else{
            _$('.filters-container').find('.checkmark.selected').removeClass('confirmed-selected');
            _$('.filters-container').find(':checkbox').filter(':checked').prop('checked', false);
            /* Serve per uncheckare bassi-fissi nella versione desktop */
            _$('.bassi-fissi').find('.checkmark.selected').removeClass('confirmed-selected');
            _$('.bassi-fissi').find(':checkbox').filter(':checked').prop('checked', false);

            this.updateFilterStatus(_$('.enabled-filter'));

            /* Reset styling bassi e fissi, offerte e promozioni */
            this.handleChange(_$(e).parents());
            this.resetOneOrOther();

            /* Reset order filter */
            _$('.single-choice-radio').find(':checked').prop('checked', false);
            _$('.single-choice-radio').find('.default-ordering').prop('checked', true);

            //DESKTOP
            _$('#deleteFilterCTA').addClass('uk-hidden');
            _$('.single-filter-reset').prop('disabled', true);
            _$('.single-filter-apply').prop('disabled', true);

            let queryValue = this.getQueryValue();
            if (queryValue) {
                this.updateColMain('?query=' + queryValue, false, null, false, true);
            } else {
                this.updateColMain(window.location.search, false, null, false, true);
            }
        }
    }

    resetSingleFilter(e: object) {
        _$(e).closest('.enabled-filter').removeClass('enabled-filter');

        if(_$(e).closest('.filter-div').find('input').hasClass('confirmed-selected') || _$(e).closest('.filter-div').find('.checkmark').hasClass('confirmed-selected')) {
            /* Chiamata per ricaricare la querystring */
            if(_$('.main-content > .uk-container').attr('data-reset') && _$(e).closest('.filter-div').attr('data-title') == 'cat_lev3'){
                /* bassi-fissi input non esiste, nel check dell'applysinglefilter partirà l'else */
                this.applySingleFilter(_$(e), _$('.main-content > .uk-container').attr('data-reset'));
            }else{
                this.applySingleFilter(_$(e));
            }
        }

        _$(e).closest('.filter-div').find('.checkmark, input:checked.uk-radio').removeClass('selected confirmed-selected');

        _$(e).closest('.filter-div').find(':checkbox').filter(':checked').prop('checked', false);
        _$(e).closest('.filter-div').find('input:checked.uk-radio').prop('checked', false);
        this.disableSingleFilterButtons(_$(e).parent());
        this.updateBadge(_$(e).closest('.filter-div'), 0);
        this.countTotalFilters(_$(e).parents());

        let className = _$(e).parents().closest('.one-or-other-filter').attr('class');
        if (className && className.indexOf('.offerte-promozioni') != -1) {
            _$('.bassi-fissi').removeClass('uk-disabled');
        }

        let lengthConfirmSelected = _$('.filters-container .filter-div').find('.confirmed-selected').length - 1;
        if(lengthConfirmSelected == 1) {
            _$('#deleteFilterCTA').addClass('uk-hidden');
        }
    }

    async sendAnalytics(eventType: string, e: object){
        try {
            let analyticsData;
            let filtro = {
                bassiFissi: '',
                denominazione: '',
                esigenzeAlimentari: '',
                marchi: '',
                offertePer: '',
                ordinamento: '',
                promozioni: '',
                regione: ''
            };

            let filterContainer = _$(window).width() > 1023 ? _$('.component-SearchFilter') : _$('.component-ModalSearchFilter');

            let filters = filterContainer.find('.filter-div .confirmed-selected, .filter-div .selected');

            filtro['ordinamento'] = '|' + filterContainer.find('.filter-order input:checked').siblings('span').text();

            for (let i = 0; i < filters.length; i++) {
                let filter = filters.eq(i);
                let keyFiltro = filter.closest('.filter-div').attr('data-title');
                let valueFiltro = '';
                
                switch (keyFiltro) {
                    case 'bassi':
                        valueFiltro = '|SI';
                        break;
                    case 'order':
                        break;
                    default:
                        valueFiltro = '|' + filter.siblings('.label-text').text();
                }
                
                if(this.filterTitles[keyFiltro]) {
                    filtro[this.filterTitles[keyFiltro]] = filtro[this.filterTitles[keyFiltro]] + valueFiltro;
                }
                
            }

            for (let filter in filtro) {
                filtro[filter] = filtro[filter].length ? filtro[filter].substring(1) : filtro[filter];
            }
           
            filtro['bassiFissi'] = filtro['bassiFissi'] == '' ? 'NO' : filtro['bassiFissi'];

            let prodotto = []
            let productCard = _$('.component-ProductCard');

            for (var i = 0; i < productCard.length; i++) {
                prodotto.push((await this._analytics.getProductData(productCard.eq(i))))
            }

            let productCardLastView = _$('.component-ProductCardLastView');
            for (var i = 0; i < productCardLastView.length; i++) {
                prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))))
            }

            let cardSuggest = _$('.component-CardSuggest');
            for (var i = 0; i < cardSuggest.length; i++) {
                prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))))
            }

            analyticsData = {
                event: eventType,
                filtro,
                prodotto
            }
            let step = eventType == 'applicaFiltro' ? 'FUNNEL_DATA_LISTINGPAGE' : 'FUNNEL_DATA_SEARCH';
            if (step == 'FUNNEL_DATA_LISTINGPAGE') {
                //2.3.2
                analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
            }
            this._analytics.sendAnalytics(analyticsData, step);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    applySingleFilter(e: object, level2link?: string) {
        if(_$(e).closest('.filter-div').attr('data-title') == 'cat_lev3' && _$(e).closest('.filter-div').find('label').attr('data-linklevel3')){            
            var queryParams = {};
            var queryString = this.getQueryValue() ? `query=${this.getQueryValue()}&` : '';
            var ordering = _$(e)
                    .closest('.filters-container')
                    .find('.filter-order .uk-radio:checked')
                    .attr('data-ordering');
            var titleOrder = _$(e).closest('.filters-container').find('.filter-order').attr('data-title');
            queryString = queryString + titleOrder + '=' + ordering;
            if (_$(e).closest('.filter-div').hasClass('bassi-fissi')) {
                this.checkBoxBassiFissi(_$(e));
                this.updateFilterStatus(_$(e).closest('.bassi-fissi'));
            } else {
                _$(e).closest('.filter-div').find('.confirmed-selected').removeClass('confirmed-selected');
                _$(e).closest('.filter-div').find('.checkmark.selected').addClass('confirmed-selected');
            }
            /* Mobile e Desktop */
            let selectedFilters = _$(e)
                .closest('.filters-container')
                .find('.filter-div.enabled-filter, .filter-div .enabled-filter');
            selectedFilters.each(function () {
                let filterType = _$(this).attr('data-title');

                let selectedArray = _$(this).find('.confirmed-selected').siblings('.label-text');
                let selected = new Array();

                for(let i = 0; i < selectedArray.length; i++){
                    selected[i] = _$(selectedArray[i]).attr('data-value') ? _$(selectedArray[i]).attr('data-value') : _$(selectedArray[i]).text();
                    selected[i] = selected[i].replace('&', '%26');
                }

                if (_$(this).hasClass('bassi-fissi')) {
                    queryParams[filterType] = 'true';
                }
                if (selected.length) {
                    queryParams[filterType] = selected.join('||');
                }
            });

            if (Object.keys(queryParams).length) {
                queryString =
                    queryString +
                    '&' +
                    Object.keys(queryParams)
                        .map((key) => key + '=' + queryParams[key])
                        .join('&');
            }

            if(queryString){
                queryString = queryString.replace(/\s/g, '+');
                window.location.href = (level2link ? level2link : _$(e).closest('.filter-div').find('input:checked').closest('label').attr('data-linklevel3')) + '?' + queryString;
            }else{
                window.location.href = level2link ? level2link : _$(e).closest('.filter-div').find('input:checked').closest('label').attr('data-linklevel3');
            }

            
        }else{
            
            var queryParams = {};
            var queryString = this.getQueryValue() ? `query=${this.getQueryValue()}&` : '';
            if (_$(e).closest('.filter-div').hasClass('filter-category')) {
                _$(e).closest('.filter-div').find('.confirmed-selected').removeClass('confirmed-selected');
                _$(e).closest('.filter-div').find('.checkmark.selected').addClass('confirmed-selected');
                /* Per la versione MOBILE radio form */
                _$(e).closest('.filter-div').find('input:checked').addClass('confirmed-selected');

                let categoryApplied;
                if(_$(e).closest('.filter-div').find('.confirmed-selected').siblings('.label-text').attr('data-value')){
                    categoryApplied = _$(e)
                    .closest('.filter-div')
                    .find('.confirmed-selected')
                    .siblings('.label-text')
                    .attr('data-value')
                }else{
                    categoryApplied = _$(e)
                    .closest('.filter-div')
                    .find('.confirmed-selected')
                    .siblings('.label-text')
                    .text()
                }
                categoryApplied = categoryApplied
                    .replace('%', '%25')
                    .replace('/', '%2F')
                    .replace('(', '%28')
                    .replace(')', '%29')
                    .replace('&', '%26');
                
                const labelCategory = _$(e).closest('.filter-div').attr('data-title');
                queryString = queryString + `${labelCategory}=${categoryApplied}`;
            } else {
                var ordering = _$(e)
                    .closest('.filters-container')
                    .find('.filter-order .uk-radio:checked')
                    .attr('data-ordering');
                var titleOrder = _$(e).closest('.filters-container').find('.filter-order').attr('data-title');
                queryString = queryString + titleOrder + '=' + ordering;
                if (_$(e).closest('.filter-div').hasClass('bassi-fissi')) {
                    this.checkBoxBassiFissi(_$(e));
                    this.updateFilterStatus(_$(e).closest('.bassi-fissi'));
                } else {
                    _$(e).closest('.filter-div').find('.confirmed-selected').removeClass('confirmed-selected');
                    _$(e).closest('.filter-div').find('.checkmark.selected').addClass('confirmed-selected');
                }
                /* Mobile e Desktop */
                let selectedFilters = _$(e)
                    .closest('.filters-container')
                    .find('.filter-div.enabled-filter, .filter-div .enabled-filter');
                selectedFilters.each(function () {
                    let filterType = _$(this).attr('data-title');

                    let selectedArray = _$(this).find('.confirmed-selected').siblings('.label-text');
                    let selected = new Array();

                    for(let i = 0; i < selectedArray.length; i++){
                        selected[i] = _$(selectedArray[i]).attr('data-value') ? _$(selectedArray[i]).attr('data-value') : _$(selectedArray[i]).text();
                        selected[i] = selected[i].replace('&', '%26');
                    }

                    if (_$(this).hasClass('bassi-fissi')) {
                        queryParams[filterType] = 'true';
                    }
                    if (selected.length) {
                        queryParams[filterType] = selected.join('||');
                    }
                });

                if (Object.keys(queryParams).length) {
                    queryString =
                        queryString +
                        '&' +
                        Object.keys(queryParams)
                            .map((key) => key + '=' + queryParams[key])
                            .join('&');
                }
            }
            
            let href = window.location.href;
            let category = '';
            let filters;

            if (href.indexOf(window['urlSite'].search) < 0 && href.split('?').length > 1) {
                filters = href.split('?')[1].split('&');
                filters.forEach(filter => {
                    category = filter.split('=')[0] == 'cat_lev1' ? filter : category;
                    category = filter.split('=')[0] == 'cat_lev2' ? filter : category;
                });
            }

            if (category.length) {
                queryString += '&' + category;
            }

            href = href.split('?')[0];

            let link = level2link ? level2link : href;
            
            if(queryString){
                queryString = queryString.replace(/\s/g, '+');  
                if(level2link){
                    window.location.href = level2link;
                }else{
                    this.updateColMain('?' + queryString);
                }
                
            }else{
                if(level2link){
                    window.location.href = level2link;
                }else{
                    this.updateColMain('');
                }
            }
        }
        let event = 'applicaFiltro';
        if (window.location.pathname == window['urlSite'].search) {
            event = 'applicaFiltri';
        }
        
        this.lastFocusedFilter = _$(e).closest('.filter-div').length && _$(e).closest('.filter-div').attr('data-title') ? _$(e).closest('.filter-div').attr('data-title') : '';
        this.lastFocusedFilterContainer = _$(e).closest('.filter-div').closest('.component-ModalSearchFilter').length ? '.component-ModalSearchFilter' : '.component-SearchFilter';

        this.sendAnalytics(event, e);
    }

    showFilters(e: object) {
        var labels = _$(e).parents('.input-filter').find('.custom-list label');
        labels.each(function (index) {
            if (
                _$(this)
                    .find('.label-text')
                    .text()
                    .toLowerCase()
                    .indexOf((<string>_$(e).val()).toLowerCase()) == -1
            ) {
                _$(this).prop('hidden', true);
            } else {
                _$(this).prop('hidden', false);
            }
        });

        _$(e).parent().siblings('.custom-list').removeClass('ps');
        this._utils.customScrollbar();
        _$(e).parent().siblings('.custom-list').scrollTop(0);
    }

    disableFilter(e: object) {
        var className = _$(e).attr('class');
        var checkmarks = _$(e).find(':checkbox').filter(':checked').length;
        if (className.indexOf('bassi-fissi') != -1) {
            if (checkmarks) {
                _$(e).closest('.filters-container').find('.offerte-promozioni').addClass('uk-disabled');
                _$(e).closest('.filters-container').find('.offerte-promozioni.uk-open').removeClass('.uk-open');
            } else {
                _$(e).closest('.filters-container').find('.offerte-promozioni').removeClass('uk-disabled');
            }
        } else if (className.indexOf('offerte-promozioni') != -1) {
            if (checkmarks) {
                _$(e).closest('.filters-container').find('.bassi-fissi').addClass('uk-disabled');
            } else {
                _$(e).closest('.filters-container').find('.bassi-fissi').removeClass('uk-disabled');
            }
        }
    }

    showResults(e: object) {
        /* Bisogna calcolare il filtro di default ordina? */
        let parents = _$('.component-SearchFilter').find('.enabled-filter').not('.filter-order');
        let enabledFilters = _$(parents).length - 1;
        _$('.filter-total').html(`(${enabledFilters})`);
    }

    getQueryValue() {
        var results = new RegExp('[?&]query=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        } else {
            return decodeURI(results[1]) || 0;
        }
    }

    updateColMain(queryParams: string, paginationChange?: boolean, paginationPage?, historyBack?: boolean, deleteAll?: boolean, applyCatFromAccordion?: boolean){
        let link;
        
        if(applyCatFromAccordion){
            this.currentQueryParams = queryParams;
            if(this.currentQueryParams == ''){
                this.updateQueryValue(window.location.pathname);
            }else{
                this.updateQueryValue(this.currentQueryParams);
            }
            
            link = _$('.col-main').attr('data-search-endpoint').toString() + queryParams;
        }else{
            if(paginationChange){
                if(this.currentQueryParams){
                    
                    link = _$('.col-main').attr('data-search-endpoint').toString() + this.currentQueryParams + ((this.currentQueryParams == '' ?  '?page=' : '&page=') + paginationPage);
                    if(!historyBack){
                        this.updateQueryValue(this.currentQueryParams + ((this.currentQueryParams == '' ?  '?page=' : '&page=') + paginationPage));
                    }
                    
                }else{
                    let urlParams = new URLSearchParams(window.location.search);
                    urlParams.set('page', paginationPage);
                    link = _$('.col-main').attr('data-search-endpoint').toString() + '?' + urlParams.toString();
                    if(!historyBack){
                        this.updateQueryValue('?'+ urlParams.toString());
                    }
                }
            }else{
                this.currentQueryParams = queryParams;
                if(!historyBack){
                    if(deleteAll){
                        if(this.getQueryValue()){
                            this.updateQueryValue(this.currentQueryParams);
                            link = _$('.col-main').attr('data-search-endpoint').toString() + this.currentQueryParams;

                        }else{
                            let deleteQueryParams = this.removeAllButCatLev1CatLev2(this.currentQueryParams) == '' ? window.location.pathname : window.location.pathname + '?' + this.removeAllButCatLev1CatLev2(this.currentQueryParams);
                            this.updateQueryValue(deleteQueryParams);
                            link = _$('.col-main').attr('data-search-endpoint').toString() + deleteQueryParams;
                            this.currentQueryParams = this.removeAllButCatLev1CatLev2(this.currentQueryParams) != '' ? '?' + this.removeAllButCatLev1CatLev2(this.currentQueryParams) : this.removeAllButCatLev1CatLev2(this.currentQueryParams);
                        }
                    }else{
                        this.updateQueryValue(this.currentQueryParams);
                        link = _$('.col-main').attr('data-search-endpoint').toString() + queryParams;
                    }
                }else{
                    link = _$('.col-main').attr('data-search-endpoint').toString() + queryParams;
                }
            }
        }

        if(window.location && window.location.hostname == 'localhost'){
            if(link.indexOf('?') > 0){
                link = link + '&wcmmode=disabled'
            }else{
                link = link + '?wcmmode=disabled'
            }
        }

        let success = async (res) => {
            this._utils.showLoader('', false);
            res = res.replace(/>\s+</g,'><');
            await _$('.col-main').html(res);
            let tpl = document.getElementById("search-modal-filters-template");
            let dest = document.getElementById("offcanvasSearchFilter");
            if(tpl && dest){
                dest.innerHTML = tpl.innerHTML;
            }
            setTimeout(() => {
                _$(() => {
                    this.checkBoxOnPageLoad();
                    this.onFilterClose(this);
                    this.onAccordionToggle(this);
                    this._utils.checkCartStatus();
                    this.setPaginationClickEvent();
                    this._carouselBenessere.initializeCarousel();
                    this._carouselBannerSponsor.initializeCarousel();
                    if(_$('.main-content').length && _$('.header-row').length){
                        _$('html, body').animate({
                            scrollTop: _$(".main-content").offset().top - _$('.header-row').height()
                        }, 'fastest');
                    }else{
                        _$('body, html').scrollTop(0);
                    }

                    _Uikit.offcanvas('#offcanvasSearchFilter').hide();

                    if(this.lastFocusedFilter != null && this.lastFocusedFilter != '' && _$(this.lastFocusedFilterContainer).find(`.filter-div[data-title=${this.lastFocusedFilter}`).length){
                        if(this.lastFocusedFilter == '.component-SearchFilter'){
                            _$(this.lastFocusedFilterContainer).find(`.filter-div[data-title=${this.lastFocusedFilter} .filter`).attr('data-acsb-focused', "true");
                        }else if(this.lastFocusedFilter == '.component-ModalSearchFilter'){
                            _$(this.lastFocusedFilterContainer).find(`.filter-div[data-title=${this.lastFocusedFilter} a.filter-title`).attr('data-acsb-focused', "true");
                        }
                    }

                    _$('body').trigger('pageviewReloadFilters');
                });
            }, this.updateColMainTimer);
            
            if(applyCatFromAccordion){
                _$(this.previousCatAccordion).removeClass('active');
                _$(this.nextCatAccordion).addClass('active');
            }
        }

        let error = (err) => {
            this._utils.showLoader('', false);
            console.log('error getting page update data search endpoint');
        }

        this._utils.showLoader('', true);
        this._pageUpdateService.getPageUpdate(success, error, link);
    }

    setPaginationClickEvent(){
        _$('.component-Pagination').on('click', (event) => {
            let dataPage = _$(event.target).closest('a').attr('data-page');
            this.updateColMain('', true, dataPage);
        });
    }

    updateQueryValue(queryParams){
        history.pushState(null, null, queryParams.toString());
    }

    /* Rimuove tutte le chiavi dalle queryparam a parte cat_lev1 e cat_lev2 */
    removeAllButCatLev1CatLev2(queryParam): string{
        let urlParams = new URLSearchParams(queryParam);

        let newUrlParams = new URLSearchParams('');
        if(urlParams.get('cat_lev1')){
            newUrlParams.set('cat_lev1', urlParams.get('cat_lev1'))
        }

        if(urlParams.get('cat_lev2')){
            newUrlParams.set('cat_lev2', urlParams.get('cat_lev2'));
        }
        
        return newUrlParams.toString();
    }

    manageAccordionClick(accordionItem: object){
        let itemType = _$(accordionItem).attr('data-rc-value') != null && _$(accordionItem).attr('data-rc-value') == 'all' ? 'all-cat' : (
            _$(accordionItem).attr('data-rc-level') != null && _$(accordionItem).attr('data-rc-level') == "1" ? 'cat_lev1' : (
                _$(accordionItem).attr('data-rc-level') != null && _$(accordionItem).attr('data-rc-level') == "2" ? 'cat_lev2' : ''
            )
        );

        switch(itemType){
            case 'all-cat':
                this.updateColMain('', false, null, false, false, true);
                break;
            case 'cat_lev1':
                this.updateColMain('?cat_lev1=' + _$(accordionItem).attr('data-rc-value').toString(), false, null, false, false, true);
                break;
            case 'cat_lev2':
                this.updateColMain('?cat_lev2=' + _$(accordionItem).attr('data-rc-value').toString(), false, null, false, false, true);
                break;
            default:
                console.log('no link found');
                break;
        }
    }

}
