import { Window, _$, _Uikit } from '../../main';

export class HeaderSemplificato {
    constructor() {}

    nkOnInit() {}

    //metodo per rimuovere il back sulla modale di login inserito durante la modale scelta del servizio
    // removeBackLogin(){
    //    if (_$('#offcanvasLogin .uk-offcanvas-header .back').length > 0){
    //       _$('#offcanvasLogin .uk-offcanvas-header .back').remove();
    //    }
    //    _Uikit.offcanvas('#offcanvasLogin').show();
    //
    //    _Uikit.util.on('#offcanvasLogin', 'show', function () {
    //       // do something
    //    });
    // }
}
