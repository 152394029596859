import { _$ } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
import { Utils } from '../../services/utils/utils';
import { GoogleUtils } from '../../services/utils/google-utils';

export class Listinglevel2 {
    constructor(
        private _analytics: Analytics,
        private _utils: Utils,
        private _google: GoogleUtils
    ) {}

    nkOnInit() {
        this.checkBreadcrumb();
        setTimeout(() => {
            this.sendAnalytics('pageview', this); 
        }, 100);

        this.addTrackingSponsorsClickEvent();
        _$(() => {
            this.addBannersClickEvent();
        })

    }

    checkBreadcrumb() {
        let breadcrumbs = _$('.component-Breadcrumb');
        
        if (breadcrumbs.length > 1) {
            breadcrumbs.addClass('uk-hidden');
            breadcrumbs.eq(0).removeClass('uk-hidden');
        } 
    }

    sendAnalytics(eventType: string, e: object) {
        try {
            let analyticsData;
            let step;
            let categoriaPrimoLivello = '';
            let categoriaSecondoLivello = '';
            let categoriaTerzoLivello = '';
            let datapromoArray = [];
            if (_$('.uk-breadcrumb')) {
                categoriaPrimoLivello = _$('.uk-breadcrumb').find('li').eq(1).length ? _$('.uk-breadcrumb').find('li').eq(1).children().html().trim() : '';
                categoriaSecondoLivello = _$('.uk-breadcrumb').find('li').eq(2).length ? _$('.uk-breadcrumb').find('li').eq(2).children().html().trim() : '';
                categoriaTerzoLivello = _$('.uk-breadcrumb').find('li').eq(3).length ? _$('.uk-breadcrumb').find('li').eq(3).children().html().trim() : ''; 
            }

            switch (eventType) {
                case 'pageview':
                    let nodoSponsor = []
                    if(_$('.component-CarouselBenessere').length){
                        let carouselBenessere = _$('.component-CarouselBenessere');
                        for(let i = 0; i < _$(carouselBenessere).length; i++){
                            nodoSponsor.push(
                                {
                                    brand: '',
                                    campagna: _$(carouselBenessere).eq(i).find('.title-container .title').length ? _$(carouselBenessere).eq(i).find('.title-container .title').html() : '',
                                    formato: 'Carosello sponsor',
                                    landing: _$(carouselBenessere).eq(i).find('.content-container a').attr('href'),
                                    posizioneSlide: 0
                                }
                            )
                            if (_$(carouselBenessere).eq(i).attr('data-promo')) {
                                datapromoArray.push(JSON.parse(_$(carouselBenessere).eq(i).attr('data-promo')));
                            } else {
                                datapromoArray.push("");
                            }     
                        }
                    }

                    if(_$('.component-ProductCard .card-partner').length){
                        let cardPartner = _$('.component-ProductCard .card-partner').closest('.component-ProductCard');
                        for(let i = 0; i < _$(cardPartner).length; i++){
                            nodoSponsor.push(
                                {
                                    brand: '',
                                    campagna: _$(cardPartner).eq(i).find('.text-wrapper .text').length ? _$(cardPartner).eq(i).find('.text-wrapper .text').html().trim() : '',
                                    formato: 'Box BTF',
                                    landing: _$(cardPartner).eq(i).find('a').length ? _$(cardPartner).eq(i).find('a').attr('href') : '',
                                    posizioneSlide: 0
                                }
                            )
                            if (_$(cardPartner).eq(i).attr('data-promo')) {
                                datapromoArray.push(JSON.parse(_$(cardPartner).eq(i).attr('data-promo')));
                            } else {
                                datapromoArray.push("");
                            }                                 
                        }
                    }

                    if(_$('.component-BannerSponsor').length){
                        let bannerSponsor = _$('.component-BannerSponsor');
                        for(let i = 0; i < _$(bannerSponsor).length; i++){
                            nodoSponsor.push(
                                {
                                    brand: '',
                                    campagna: _$(bannerSponsor).eq(i).find('.banner-title').length ? _$(bannerSponsor).eq(i).find('.banner-title').html() : '',
                                    formato: 'Banner BTF',
                                    landing: _$(bannerSponsor).eq(i).find('a').length ? _$(bannerSponsor).eq(i).find('a').attr('href') : '',
                                    posizioneSlide: 0
                                }
                            )
                            if (_$(bannerSponsor).eq(i).attr('data-promo')) {
                                datapromoArray.push(JSON.parse(_$(bannerSponsor).eq(i).attr('data-promo')));
                            } else {
                                datapromoArray.push("");
                            }                               
                        }
                    }
 

                    if(_$('.main-content').siblings('.component-BannerListing.offerte-video').find('.banner-video').length){
                        let bannerVideo = _$('.main-content').siblings('.component-BannerListing.offerte-video');
                        nodoSponsor.push({
                            brand: '',
                            campagna: _$(bannerVideo).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata video',
                            landing: _$(bannerVideo).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        })
                        if (_$(bannerVideo).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(bannerVideo).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                            
                    }else if(_$('.main-content').siblings('.component-BannerListing.offerte-video').find('.banner').length){
                        let bannerSimple = _$('.main-content').siblings('.component-BannerListing.offerte-video');
                        nodoSponsor.push({
                            brand: '',
                            campagna: _$(bannerSimple).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata semplice',
                            landing: _$(bannerSimple).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        })
                        if (_$(bannerSimple).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(bannerSimple).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                            
                    }else if(_$('.main-content').siblings('.component-BannerListing.banner-iniziative').length){
                        let bannerIniziative = _$('.main-content').siblings('.component-BannerListing.banner-iniziative');
                        nodoSponsor.push({
                            brand: '',
                            campagna: _$(bannerIniziative).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata con categorie',
                            landing: _$(bannerIniziative).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        })
                        if (_$(bannerIniziative).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(bannerIniziative).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                         
                    }else if(_$('.main-content').siblings('.component-BannerListing.banner-hotspot').length){
                        let bannerHotspot = _$('.main-content').siblings('.component-BannerListing.banner-hotspot');
                        nodoSponsor.push({
                            brand: '',
                            campagna: _$(bannerHotspot).find('.text .text-title').length ? _$(bannerHotspot).find('.component-BannerSponsor .text .text-title').html() : '',
                            formato: 'Banner testata con categorie',
                            landing: _$(bannerHotspot).find('.text-link').length ? _$(bannerHotspot).find('.text-link').attr('href') : '',
                            posizioneSlide: 0
                        })
                        if (_$(bannerHotspot).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(bannerHotspot).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                         
                    }

                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        prodotto: {
                            categoriaPrimoLivello,
                            categoriaSecondoLivello,
                            categoriaTerzoLivello
                        },
                        sponsor: nodoSponsor ? nodoSponsor : null
                    };
                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    break;
    
                case 'ctaSecondoLivello':
                    //2.3.3
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        prodotto: {
                            categoriaPrimoLivello,
                            categoriaSecondoLivello: _$(e).text()
                        },
                    };
                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    break;
                case 'clickAdvCarouselBenessere':
                    analyticsData = {
                        event: 'clickAdv',
                        prodotto: {
                            carosello: _$(e).find('.content-container .title').length ? _$(e).find('.content-container .title').html() : ''
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.content-container .title').length ? _$(e).find('.content-container .title').html() : '',
                            formato: 'Carosello sponsor',
                            landing: _$(e).find('.content-container a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }
                    step = 'FUNNEL_DATA_LISTINGPAGE';

                    if(_$(e).attr('data-promo')){
                        datapromoArray.push(JSON.parse(_$(e).attr('data-promo')));
                    }else{
                        datapromoArray.push("");
                    }

                    if(datapromoArray.length){
                        this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                    }else{
                        this._analytics.sendAnalytics(analyticsData, step);
                    }
                    return;
                case 'clickAdvCardPartner':
                    analyticsData = {
                        event: 'clickAdv',
                        prodotto: {
                            carosello: _$(e).find('.text-wrapper .text').length ? _$(e).find('.text-wrapper .text').html() : ''
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.text-wrapper .text').length ? _$(e).find('.text-wrapper .text').html() : '',
                            formato: 'Box BTF',
                            landing: _$(e).find('a').length ? _$(e).find('a').attr('href') : '',
                            posizioneSlide: 0
                        }]
                    }
                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    if (_$(e).closest('.component-ProductCard').attr('data-promo')) {
                        let dataPromo = JSON.parse(_$(e).closest('.component-ProductCard').attr('data-promo'));
                        this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
                    } else {
                        this._analytics.sendAnalytics(analyticsData, step);
                    }  
                    return;
                
                case 'clickAdvBannerSponsor':
                    analyticsData = {
                        event: 'clickAdv',
                        prodotto: {
                            carosello: _$(e).find('.text-container .banner-title').length ? _$(e).find('.text-container .banner-title').html() : ''
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.text-container .banner-title').length ? _$(e).find('.text-container .banner-title').html() : '',
                            formato: 'Banner BTF',
                            landing: _$(e).find('a').length ? _$(e).find('a').attr('href') : '',
                            posizioneSlide: 0
                        }]
                    }
                    if (_$(e).attr('data-promo')) {
                        datapromoArray.push(JSON.parse(_$(e).attr('data-promo')));
                    } else {
                        datapromoArray.push("");
                    }                       
                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                    return;
                
                case 'clickAdvCarouselBenessereVediTutti':
                    analyticsData = {
                        event: 'advVediTutti',
                        prodotto: {
                            categoriaPrimoLivello,
                            categoriaSecondoLivello,
                            categoriaTerzoLivello
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.content-container .title').length ? _$(e).find('.content-container .title').html() : '',
                            formato: 'Carosello sponsor',
                            landing: _$(e).find('.content-container a').attr('href')
                        }]
                    }

                    step = 'FUNNEL_DATA_LISTINGPAGE';

                    if(_$(e).attr('data-promo')){
                        datapromoArray.push(JSON.parse(_$(e).attr('data-promo')));
                    }else{
                        datapromoArray.push("");
                    }
                    
                    if(datapromoArray.length){
                        this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                    }else{
                        this._analytics.sendAnalytics(analyticsData, step);
                    }
                    
                    return;

                case 'advClick':
                    let nodoSponsorClick;

                    if(_$(e).closest('.component-BannerListing.offerte-video').find('.banner-video').length){
                        let bannerVideo = _$(e).closest('.component-BannerListing.offerte-video');
                        nodoSponsorClick = {
                            brand: '',
                            campagna: _$(bannerVideo).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata video',
                            landing: _$(bannerVideo).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        }
                    }else if(_$(e).closest('.component-BannerListing.offerte-video').find('.banner').length){
                        let bannerSimple = _$(e).closest('.component-BannerListing.offerte-video');
                        nodoSponsorClick = {
                            brand: '',
                            campagna: _$(bannerSimple).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata semplice',
                            landing: _$(bannerSimple).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        }
                    }else if(_$(e).closest('.component-BannerListing.banner-iniziative').length){
                        let bannerIniziative = _$(e).closest('.component-BannerListing.banner-iniziative');
                        nodoSponsorClick = {
                            brand: '',
                            campagna: _$(bannerIniziative).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata con categorie',
                            landing: _$(bannerIniziative).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        }
                    }

                    analyticsData = {
                        event: 'advClick',
                        prodotto: {
                            categoriaPrimoLivello,
                            categoriaSecondoLivello,
                            categoriaTerzoLivello
                        },
                        sponsor: nodoSponsorClick ? [nodoSponsorClick] : null
                    };
                    step = 'FUNNEL_DATA_LISTINGPAGE';

                    if (_$(e).closest('.component-BannerSponsor').attr('data-promo')) {
                        let dataPromo = JSON.parse(_$(e).closest('.component-BannerSponsor').attr('data-promo'));
                        this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
                    } else {
                        this._analytics.sendAnalytics(analyticsData, step);
                    }

                    return;
                
                case 'advClickVideo':
                    let nodoSponsorClickVideo = {
                        brand: '',
                        campagna: _$(e).find('.component-BannerSponsor .banner-title').html(),
                        formato: 'Banner testata video',
                        landing: _$(e).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                        posizioneSlide: 0
                    }
                    analyticsData = {
                        event: 'advClickVideo',
                        prodotto: {
                            categoriaPrimoLivello,
                            categoriaSecondoLivello,
                            categoriaTerzoLivello
                        },
                        sponsor: nodoSponsorClickVideo ? [nodoSponsorClickVideo] : null
                    };
                    step = 'FUNNEL_DATA_LISTINGPAGE';

                    if (_$(e).closest('.component-BannerSponsor').attr('data-promo')) {
                        let dataPromo = JSON.parse(_$(e).closest('.component-BannerSponsor').attr('data-promo'));
                        this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
                    } else {
                        this._analytics.sendAnalytics(analyticsData, step);
                    }

                    return;
            }

            let newFilterElem = _$(e).closest('.filter-div');
            let newFilter = newFilterElem.attr('data-title');
            let newFilterVal = '';

            if (newFilter == 'bassi') {
                newFilterVal = newFilterElem.hasClass('enabled-filter') ? 'SI' : '';
            } else {
                let newFilterMultiVal = newFilterElem.find('.selected');
                for (var i = 0; i < newFilterMultiVal.length; i++) {
                    newFilterVal = newFilterVal + '||' + newFilterMultiVal.eq(i).parent().children('.label-text').text();
                }
                newFilterVal = newFilterVal.substring(2);
            }
            
            let filterValues = window.location.href.split('?')[1] ? window.location.href.split('?')[1].split('&') : [];
            filterValues.push(""+newFilter+"="+newFilterVal);            
    
            if (filterValues.length) {
                var bassi = '';
                var brand = '';
                var esigenze = '';
                var region = '';
                var denom = '';
                var order = '';
    
                for (var i = 0; i < filterValues.length; i++) {
    
                    var chiave = filterValues[i].split('=')[0];
                    var valore = filterValues[i].split('=')[1];

                    var chiave = filterValues[i].split('=')[0];
                    var valoreCheck = filterValues[i].split('=')[1].split('||');
                    var valore = '';

                    for (let i = 0; i < valoreCheck.length; i++) {
                        if (valoreCheck[i] != '') {
                            if (chiave == 'bassi') {
                                valoreCheck[i] = 'SI';
                            }
                            let value = valoreCheck[i].split('|')[0];
                            valore = valore + '|' + value.replace("%26", "&").replace("+"," ");
                        }
                    }
    
                    valore = valore.substring(1);
    
                    switch (chiave) {
                        case 'bassiFissi':
                            bassi = valore;
                            break;
                        case 'brand':
                            brand = valore;
                            break;
                        case 'group-esigenze':
                            esigenze = valore;
                            break;
                        case 'region':
                            region = valore;
                            break;
                        case 'group-denominazione':
                            denom = valore;
                            break;
                        case 'order':
                            switch (valore) {
                                case 'ril':
                                    order = 'Rilevanza';
                                    break;
                                case 'nuovo':
                                    order = 'Novità';
                                    break;
                                case 'nome-asc':
                                    order = 'Nome (A-Z)';
                                    break;
                                case 'nome-desc':
                                    order = 'Nome (Z-A)';
                                    break;
                                case 'prezzo-asc':
                                    order = 'Prezzo crescente';
                                    break;
                                case 'prezzo-desc':
                                    order = 'Prezzo decrescente';
                                    break;
                                case 'prezzokg-asc':
                                    order = 'Prezzo al Kg/L crescente';
                                    break;
                                case 'prezzokg-desc':
                                    order = 'Prezzo al Kg/L decrescente';
                                    break;
                                case 'scontocr':
                                    order = '% di sconto crescente';
                                    break;
                                case 'scontodecr':
                                    order = '% di sconto decrescente';
                                    break;                                
                            }
                    }
                }
    
                let filtro = {
                    bassiFissi: bassi,
                    marchi: brand,
                    esigenzeAlimentari: esigenze, 
                    regione: region,
                    denominazione: denom, 
                    ordinamento: order
                }
    
                analyticsData['filtro'] = filtro;
            }

            if (_$(e).closest('.component-BannerSponsor').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-BannerSponsor').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            } else if (_$(e).closest('.component-CarouselBenessere').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-CarouselBenessere').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            }            
            else {
                if (datapromoArray.length) {
                    this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                } else {
                    this._analytics.sendAnalytics(analyticsData, step);
                }                
            }      
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }


    goBackCategoryMobile(e) {
        _$(e).parents().find('.listing-cat-1').removeClass('uk-hidden');
        _$(e).parents().find('.listing-cat-2').addClass('uk-hidden');
    }

    addTrackingSponsorsClickEvent(){

        if(_$('.component-CarouselBenessere').length){
            let carouselBenessere = _$('.component-CarouselBenessere');
            for(let i = 0; i < _$(carouselBenessere).length; i++){
                _$(carouselBenessere).eq(i).find('.main-content .title-container .content-container a').on('click', (event) => {
                    event.stopImmediatePropagation();
                    this.sendAnalytics('clickAdvCarouselBenessereVediTutti', _$(carouselBenessere).eq(i));
                });
                _$(carouselBenessere).eq(i).find('.swiper-slide .component-ProductCard').on('click', () => {
                    this.sendAnalytics('clickAdvCarouselBenessere', _$(carouselBenessere).eq(i));
                });
            }
        }

        if(_$('.component-ProductCard .card-partner').length){
            let cardPartner = _$('.component-ProductCard .card-partner').closest('.component-ProductCard');
            for(let i = 0; i < _$(cardPartner).length; i++){
                _$(cardPartner).eq(i).on('click', () => {
                    this.sendAnalytics('clickAdvCardPartner', _$(cardPartner).eq(i));
                });
            }
        }

        if(_$('.product-results .component-BannerSponsor').length){
            let bannerSponsor = _$('.product-results .component-BannerSponsor');
            for(let i = 0; i < _$(bannerSponsor).length; i++){
                _$(bannerSponsor).eq(i).on('click', () => {
                    this.sendAnalytics('clickAdvBannerSponsor', _$(bannerSponsor).eq(i));
                });
            }
        }
    }
    addBannersClickEvent(){
        if(_$('.main-content').siblings('.component-BannerListing.offerte-video').find('.banner-video').length){
            let bannerVideo = _$('.main-content').siblings('.component-BannerListing.offerte-video');
            _$(bannerVideo).on('click', () => {
                this.sendAnalytics('advClick', _$(bannerVideo));
            });

        }else if(_$('.main-content').siblings('.component-BannerListing.offerte-video').find('banner').length){
            let bannerSimple = _$('.main-content').siblings('.component-BannerListing.offerte-video');

            _$(bannerSimple).on('click', () => {
                this.sendAnalytics('advClick', _$(bannerSimple));
            });
        }else if(_$('.main-content').siblings('.component-BannerListing.banner-iniziative').length){
            let bannerIniziative = _$('.main-content').siblings('.component-BannerListing.banner-iniziative');

            _$(bannerIniziative).on('click', () => {
                this.sendAnalytics('advClick', _$(bannerIniziative));
            });
        }
    }

    
}
