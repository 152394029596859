import Alpine from 'alpinejs';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, i18n } from '../../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';

Alpine.data('ec11x11xAssistanceBoxes', () => ({
    init() {
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    // main component functions
    // only analytics functions
    trackSupportBoxBtnClick(stepFunnel: string) {
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_SUPPORT_BOX;
            ecommerceData.funnel.stepFunnel = stepFunnel;
            return ecommerceData;
        })());
    }
}));