/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ProductCardType {
    PRODUCT_CARD = 'PRODUCT_CARD',
    PRODUCT_CARD_HORIZONTAL = 'PRODUCT_CARD_HORIZONTAL',
    CARD_LAST_VIEW = 'CARD_LAST_VIEW',
    CARD_SUGGEST_MINI = 'CARD_SUGGEST_MINI',
    CARD_DOT_BUTTON = 'CARD_DOT_BUTTON',
    CARD_DOT = 'CARD_DOT',
    CARD_MINI = 'CARD_MINI',
}
