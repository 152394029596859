import { Window } from '../../main';

export class LocalStorage {
    constructor() {}

    setItem(key: string, value: string) {
        Window.Store.set(key, value);
    }

    getItem(key: string, parent?: string, store?: string) {
        let data;
        try {
            store ? (data = JSON.parse(Window.Store.get(store))) : (data = JSON.parse(Window.Store.get('user_data')));
            return parent ? data[parent][key] : data[key];
        } catch (e) {
            data = undefined;
            return data;
        }
    }

    removeItem(key: string) {
        Window.Store.remove(key);
    }

    getFromStorage(key: string) {
        let data;
        try {
            data = JSON.parse(Window.Store.get(key));
        } catch (e) {
            data = undefined;
        }
        return data;
    }
}
