import { _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';
import { Utils } from '../../services/utils/utils';
import { threadId } from 'worker_threads';

export class ProdottiEvidenza {

    constructor(
        private _utils: Utils,
    ) {
    }

    nkOnInit() { 
        for (let i = 0; i < _$('.next-prod-evid').length; i++) {
            _$('.next-prod-evid')[i].setAttribute('onclick','ProdottiEvidenza.mySlideNext(this)');
            _$('.prev-prod-evid')[i].setAttribute('onclick','ProdottiEvidenza.mySlidePrev(this)');            
        }
    }  

    swiperProdEvidenzaOptions: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-prod-evid',
            draggable: true,
            hide: false,
        },
        /* navigation: {
            nextEl: '.next-prod-evid',
            prevEl: '.prev-prod-evid',
        }, */
        pagination: {
            el: '.pagination-prod-evid',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let slideView;
                if (_$(window).width() < 1024) {
                slideView = swiper.params.breakpoints['768'].slidesPerView;
                } else {
                slideView = swiper.params.breakpoints['1024'].slidesPerView;
                }
                return Math.ceil((current+slideView-1)/slideView) + ' di ' + Math.ceil((total+slideView-1)/slideView);
            },
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 15,
                slidesPerView: 1.2
            },
            768: {
                spaceBetween: 15,
                slidesPerView: 3
            },
            1024: {
                spaceBetween: 16,
                slidesPerView: 4
            },
            1440: {
                spaceBetween: 24,
                slidesPerView: 4
            },
        },
        on: {
            init: function () {
                const numberOfSlides = this.slides.length;
                if (numberOfSlides == 1) {
                    _$(this.slides[0]).addClass('single-center-slide');
                    this.params.centeredSlides = false;
                    this.params.noSwiping = true;
                    this.params.noSwipingClass = 'swiper-slide';
                    this.params.allowTouchMove = false;
                    this.params.watchOverflow = true;
                    _$(this.el).find('.next-prod-evid').remove();
                    _$(this.el).find('.prev-prod-evid').remove();
                    _$(this.el).find('.swiper-pagination').remove();
                }
            }
        }
    };
    swiperProdEvidenza = new Swiper('.swiper-prod-evid', this.swiperProdEvidenzaOptions); 
    
    mySlideNext(item) {
        this._utils.mySlideNext(this.swiperProdEvidenzaOptions, _$(item).closest('.swiper-prod-evid')[0].swiper);
    }

    mySlidePrev(item) {
        this._utils.mySlidePrev(this.swiperProdEvidenzaOptions, _$(item).closest('.swiper-prod-evid')[0].swiper);
    }
}