import { Window, _Axios, _Uikit } from '../main';

export class BaseService {
    public headers: Headers;

    constructor() {}

    callGestoure(res, err, success, error, _body) {
        if (res && res.statusCode === 200) {
            success(_body);
        } else {
            if (_body && _body.errorCode) {
                if (_body.errorCode.includes('503')) {
                    Window.location.href = Window.Utility.changeEnvironment('error', true);
                } else {
                    error(_body.errorCode);
                }
            } else {
                error('no such error defined');
            }
        }
    }

    get(url, success, errore, header): void {
        _Axios.default.get(url, { headers: header }).then(
            (response) => {
                success(response.data);
            },
            (error) => {
                this.showNotification(error.response.status + ' ' + error.response.data.code + `<br>` + error.response.data.message, 'primary');
                errore(error);
            }
        );
    }

    post(url, success, errore, body, header, type?): void {
        _Axios.default.post(url, body, { withCredentials: true, headers: header }).then(
            (response) => {
                success(response.data);
            },
            (error) => {
                this.showNotification(error.response.status + ' ' + error.response.data.code + `<br>` + error.response.data.message, 'primary');
                errore(error);
            }
        );
    }

    postAxios(url, success, errore, body, header): void {
        _Axios.default.post(url, body, { withCredentials: true, headers: header }).then(
            (response) => {
                success(response);
            },
            (error) => {
                this.showNotification(error.response.status + ' ' + error.response.data.code + `<br>` + error.response.data.message, 'primary');
                errore(error);
            }
        );
    }

    // makeCall(call, success, error): void {
    //     call.subscribe(
    //         (data) => {
    //             success(data);
    //         },
    //         (err) => {
    //             error(err);
    //             this.generateError(err.json(), {});
    //         }
    //     )
    // }

    // delete(url, success, error, body, header, type?): void {
    //     let _this = this;
    //     let req = {};
    //     req['url'] = url;
    //     req['json'] = true;
    //     type ? (req[type] = body) : (req['body'] = body);
    //     req['headers'] = header;
    //     _Request.delete(req, function (err, res, resp_body) {
    //         _this.callGestoure(res, err, success, error, resp_body);
    //     });
    // }

    // generateError(error, e): void {
    //     this.popupError(error);
    // }

    // popupError(err?): void {
    //     let error = this.modal.open(ErrorPopoupComponent, { centered: true, size: 'lg' });
    //     let modalInstance = error.componentInstance;
    //     err.code ? modalInstance.error = err.code : modalInstance.error = "ERROR_INTERNAL_SERVER";
    //     if(err.error == "Forbidden") {
    //         modalInstance.error = "ERROR_INVALID_LOGIN";
    //         return;
    //     }
    // }

    // getLanguage() {
    //     if(navigator.language != "it" && navigator.language != "en") {
    //         return 'en'
    //     }
    //     else {
    //         return navigator.language
    //     }
    // }


    showNotification(message, status) {
        if(Window.DEV_MODE) {
            _Uikit.notification({
                message: message,
                status: status,
                pos: 'bottom-right',
                timeout: 5000,
            });
        }
    }
}
