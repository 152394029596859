import Alpine from 'alpinejs';
import { removeBlockScroll, setBlockScroll } from '../../../../../libs/block-scroll';

Alpine.data('et900PopupBase', () => ({
    popupName: '',
    popupOpen: false,
    init() {
        this.popupName = this.$root.getAttribute('x-cflowelement') as string;
    },
    openPopup() {
        if (this.popupOpen) return;
        this.$root.showModal();
        setBlockScroll();
        this.popupOpen = true;
    },
    closePopup() {
        if (!this.popupOpen) return;
        this.$root.close();
        removeBlockScroll();
        this.popupOpen = false;
    },
}));
