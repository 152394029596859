export const HEADER_REQUEST = {
    //'Authorization': 'Basic Z2lneTo='
    /* 'Cookie': 'ecAccess=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJlY0FjY2VzcyIsInVzZXJJZCI6IjIwMTg0NTkiLCJ0eXBlT2ZTZXJ2aWNlIjoiSE9NRV9ERUxJVkVSWSIsInBvaW50T2ZTZXJ2aWNlSWQiOiIwMDkxOTIiLCJiZWNvbW1lcmNlIjoic2FwIiwiZGVsaXZlcnlBZGRyZXNzIjoiVmlhIGRlbGxhIFBlY2V0dGEsIDIwMTU1IE1pbGFubyBNSSwgSXRhbGlhIiwiY29vcGVyYXRpdmVJZCI6IkNOTyIsImxhdGl0dWRpbmUiOjQ1LjQ5NTEsImxvbmdpdHVkaW5lIjo5LjE1ODksIm5TdG9yZXNGb3VuZCI6MSwiaXNzIjoiY29uYWQiLCJpYXQiOjE2MjM5MjI1MjF9.vxec3NvGpwXHbaksLmT6PjW2HA-CIf5vTRlkX8Cv5o4' */
};
export const BASE_URL: string = '';
export const BASE_MYCONAD_URL: string = 'https://myconad.nscdev.it';

export const LOGIN: string = '/api/ecommerce/it-it.login.json';
export const LOGIN_CONFIRM: string = '/api/myconad/it-it.confirmLogin.json';
export const SOCIAL_LOGIN: string = '/api/myconad/it-it.social-login.json';
export const STORES: string = '/api/ecommerce/it-it.stores.json';
export const TIMESLOT_STORE: string = '/api/ecommerce/it-it.timeslots.json';
export const CHOSEN_STORE: string = '/api/ecommerce/it-it.set-ecaccess.json';
export const RESERVE_TIMESLOT: string = '/api/ecommerce/it-it.reserve-timeslot.json';
export const EXTEND_ADDRESSES: string = '/api/ecommerce/it-it.extend-addresses.json';
export const USER_ADDRESSES: string = '/api/ecommerce/it-it.user-addresses.json';
export const SUGGESTIONS_SEARCH: string = '/api/ecommerce/it-it.suggestions.json';
export const ADD_TO_CART: string = '/api/ecommerce/it-it.manage-carts.json';
export const USER_MENU: string = '/api/myconad/it-it.menu.json';
export const USER_LOGOUT: string = '/api/myconad/it-it.logout.json';
export const TRACKING_LSF: string = '/api/ecommerce/it-it.lsf-tracking.json';
export const MINICART: string = '/api/ecommerce/it-it.mini-cart.html';
export const MANAGE_WISHLISTS: string = '/api/ecommerce/it-it.manage-wishlists.json';
export const USER_BILLING_ADDRESSES = '/api/myconad/it-it.getBillingAddress.json';
export const USER_BILLING_ADD_ADDRESS = '/api/myconad/it-it.addBillingAddress.json';
export const USER_BILLING_EDIT_ADDRESS = '/api/myconad/it-it.editBillingAddress.json';
export const UTILS_PROVINCE = '/api/myconad/it-it.province.json';
export const UTILS_STATI = '/api/myconad/it-it.stati.json';
export const PREPARATION_NOTES: string = '/api/ecommerce/it-it.modal-preparation.json';
export const CHECKOUT : string = '/api/ecommerce/it-it.create-order.json';
export const FAQFORM: string = '/api/ecommerce/it-it.faq-form.json';
export const USER_DELIVERY_ADD_ADDRESS: string = '/api/myconad/it-it.addDeliveryAddress.json';
export const USER_DELIVERY_EDIT_ADDRESS: string = '/api/myconad/it-it.editDeliveryAddress.json';
export const FAQLIST: string = '/api/ecommerce/it-it.faq-list.json';
export const OLDPLATFORM_PDV_HP : string = '/api/ecommerce/it-it.old-ecommerce.json';
export const CLEAN_ECACCESS : string = '/api/ecommerce/it-it.clean-ecaccess.json';
export const CHECK_OTP: string = '/api/ecommerce/it-it.check-otp.json';
export const COUPON_CHECKOUT: string = '/api/ecommerce/it-it.coupon.json';
export const GUEST_CANCEL_ORDER: string = '/api/myconad/it-it.cancelOrder.json';
export const DELETE_CART: string = '/api/ecommerce/it-it.delete-cart.json';
export const ADD_CART: string = '/api/myconad/it-it.addCart.json';
export const RESERVE_TIMESLOT_ON_CART: string = '/api/myconad/it-it.reserveTimeslotOnCart.json';
export const SET_ADDRESS_CART: string = '/api/ecommerce/it-it.setAddressCart.json';
export const EXTEND_TIMESLOT: string = '/api/ecommerce/it-it.extend-timeslot.json';
export const CHECKOUT_USER_INFO: string = '/api/ecommerce/it-it.user-info.json';
export const GET_MODIFY_GUEST_ORDER_OTP: string = '/api/ecommerce/it-it.get-guest-order-otp.json';
export const CHECK_MODIFY_GUEST_ORDER_EMAIL: string = '/api/ecommerce/it-it.verify-guest-order-otp.json';
export const CHECK_CART_VALIDITY_FOR_LOCKER: string = '/api/ecommerce/it-it.check-cart-locker.json';
export const GET_VALIDATION_RULES: string = '/api/ecommerce/it-it.validationConfig.json';
export const FIRST_TIMESLOT_BY_STORES = '/api/ecommerce/it-it.first-timeslot-by-stores.json';
export const SET_BILLING_ADDRESS_CART = '/api/ecommerce/it-it.setBillingCart.json';
export const DELETE_BILLING_ADDRESS_CART = '/api/ecommerce/it-it.deleteBillingCart.json';
export const DELETE_PAYMENT_TRANSACTION = '/api/ecommerce/it-it.deletePaymentTransaction.json';
