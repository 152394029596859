import Alpine from 'alpinejs';
import apiProvider from '../../../../../../libs/api-provider';

type LoadableState = {
    url: string;
    loadable: string;
};

Alpine.data('xPage', () => ({
    init() {
        window.addEventListener('popstate', (event: PopStateEvent) => {
            const state = event.state as LoadableState;
            this.loadInnestable(state);
        });
    },

    pageClick(e: Event) {
        // only innestable page can innest other pages
        if (!this.$root.dataset.isInnestable) return;

        // check if link is innestable
        const target = e.target as HTMLElement;
        const finalTarget = target.closest('a[data-innestable-loader]') as HTMLElement;
        if (!finalTarget) return;

        e.preventDefault();
        e.stopPropagation();

        // load innestable
        const state = {
            url: finalTarget.getAttribute('href'),
            loadable: finalTarget.dataset.innestableLoader,
        };
        this.loadInnestable(state, true);
    },

    async loadInnestable(state: LoadableState, addToHistory: boolean = false) {
        const result = await apiProvider.loaderGet(state.loadable);

        if (addToHistory) history.pushState(state, '', state.url);
        const main = this.$root.querySelector('.main-content') as HTMLElement;

        window.dispatchEvent(new CustomEvent('innestable-loader-change-history', { detail: { state } }));

        // if dev remove meta
        if (process.env.NODE_ENV === 'development') {
            const metaStart = main.previousElementSibling;
            const metaEnd = main.nextElementSibling;
            if (metaStart && metaStart.matches('meta')) metaStart.remove();
            if (metaEnd && metaEnd.matches('meta')) metaEnd.remove();
        }

        main.insertAdjacentHTML('beforebegin', result);
        main.remove();
    },
}));
