import { BaseService } from './../base-service';
import { HEADER_REQUEST, BASE_URL, ADD_TO_CART, MINICART, PREPARATION_NOTES, CHECKOUT, COUPON_CHECKOUT, GUEST_CANCEL_ORDER, DELETE_CART, ADD_CART, SET_ADDRESS_CART, CHECK_CART_VALIDITY_FOR_LOCKER, SET_BILLING_ADDRESS_CART, DELETE_BILLING_ADDRESS_CART, DELETE_PAYMENT_TRANSACTION } from '../config';
import { _$, Window } from '../../main';

export class CartService extends BaseService {
    
    constructor() {
        super();
    }

    nkOnInit() {
    }

    /* Singola funzione in quanto passiamo sempre il valore totale a carrello */
    addRemoveItemToCart(success, error, body): void {
        if(Window.cart && Window.cart.userGroup){
            body['customer_type'] = Window.cart.userGroup;
        }

        if(Window.pointOfService && Window.pointOfService.type){
            body['isLocker'] = Window.pointOfService.type == "LOCKER" ? true : false;
        }

        if(body && body['scale'] != null){
            body['scale'] = +Math.round((body['scale']) * 1e12) / 1e12;
        }

        if(body && body['weight'] != null){
            body['weight'] = +Math.round((body['weight']) * 1e12) / 1e12;
        }
        
        this.postAxios(BASE_URL + ADD_TO_CART, success, error, body, HEADER_REQUEST);
    }

    getMiniCart(success, error): void {
        this.get(BASE_URL + MINICART, success, error, HEADER_REQUEST);
    }

    getPageUpdate(success, error, link): void {
        this.get(BASE_URL + link, success, error, HEADER_REQUEST);
    }

    getPreparationNotes(success, error, link): void {
        this.get(BASE_URL + PREPARATION_NOTES + link, success, error, HEADER_REQUEST);
    }

    checkout(success, error, body): void {
        this.postAxios(BASE_URL + CHECKOUT, success, error, body, HEADER_REQUEST);
    }

    manageCoupon(success, error, body): void{
        this.postAxios(BASE_URL + COUPON_CHECKOUT, success, error, body, HEADER_REQUEST);
    }

    cancelOrder(success, error, body): void {
        this.postAxios(BASE_URL + GUEST_CANCEL_ORDER, success, error, body, HEADER_REQUEST);
    }

    deleteCart(success, error, body): void {
        this.postAxios(BASE_URL + DELETE_CART, success, error, body, HEADER_REQUEST);
    }

    reopenGuestOrder(success, error, body): void {
        this.postAxios(BASE_URL + ADD_CART, success, error, body, HEADER_REQUEST);
    }

    setAddressCart(success, error, body): void {
        this.postAxios(BASE_URL + SET_ADDRESS_CART, success, error, body, HEADER_REQUEST);
    }

    setBillingAddressCart(success, error, body): void{
        this.postAxios(BASE_URL + SET_BILLING_ADDRESS_CART, success, error, body, HEADER_REQUEST);
    }

    deleteBillingAddressCart(success, error): void{
        this.postAxios(BASE_URL + DELETE_BILLING_ADDRESS_CART, success, error, {}, HEADER_REQUEST);
    }

    checkProgressDeliveryCost() {
        //controllo la soglia di spesa e nascondo quelle già superate
        let progressBarList = _$('.delivery-costs-progress-bar');
        if(progressBarList && progressBarList.length){
            progressBarList.each((value, index) => {
                let itemProgressConsegna = _$(progressBarList[value]).find('.checkpoints');
                let itemProgressItems = _$(itemProgressConsegna).find('.checkpoint.active');
                if(_$(itemProgressConsegna).length) {
                    _$(itemProgressItems).each(function(index) {
                        if (index === itemProgressItems.length - 1) {
                            _$(this).addClass('current');
                            let sogliaCurrent = _$(this).attr('data-soglia');
                            _$(this).children('.uk-badge').attr('aria-hidden', 'false');
                            _$(this).attr('aria-label', sogliaCurrent);
                        }
                    });
                }
            });
        }
    }

    checkCartValidityForLocker(success, error): void{
        this.get(BASE_URL + CHECK_CART_VALIDITY_FOR_LOCKER, success, error, HEADER_REQUEST);
    }

    deleteTransaction(success, error): void{
        this.get(BASE_URL + DELETE_PAYMENT_TRANSACTION, success, error, HEADER_REQUEST);
    }

}

