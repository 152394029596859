import { _$, _Uikit, Window } from "../../main";
import { LOADER_POSITION, Utils } from "../../services/utils/utils";
import { GoogleUtils } from "../../services/utils/google-utils";
import { OnboardingManager } from "../../services/utils/onboarding-manager";
import { BOOKING_SLOT_STATE, BookUtils } from "../../services/utils/book-utils";
import { LocalStorage } from "../../services/local-storage/local-storage";

export class ModalTimeslot {

    _modalTimeslot = _$('#modal-timeslot');

    constructor(
        private utils: Utils,
        private google: GoogleUtils,
        private bookUtils: BookUtils,
        private _onboardingManager: OnboardingManager,
        private localStorage: LocalStorage
    ) {

    }

    nkOnInit(){
        _$(this._modalTimeslot).on('shown', () => {
            if (Window.typeOfService) {
                this.initAddressOnModal(Window.typeOfService);
            }
        });

        _$(this._modalTimeslot).on('show', () => {
            this.utils.showLoader('', false);
        });

        _$(this._modalTimeslot).on('beforeshow', () => {
            _$(this._modalTimeslot).removeClass('locker-error');
        })

        _$(this._modalTimeslot).on('show', (event) => {
            _$('.component-ModalTimeslot .alert-fascia-non-disponibile').removeClass('unavailable-timeslot-selected');
            /* se lo show è scatenato all'apertura della modale e non da un event bubbling */
            if(event.target && event.target.id && event.target.id == 'modal-timeslot'){
                this.showModalTimeslotSkeleton();
                _$('#modal-timeslot').removeClass('selected-timeslot');
            }
        });

        let _this = this;
        //1.2.1
        _$(this._modalTimeslot).on('shown', _this, (event) => {
            if(event.target && event.target.id && event.target.id == 'modal-timeslot'){
                _this._onboardingManager.sendAnalytics('1.5.5', "pageview");
            }
        })

        _$('#modal-timeslot .btn-timeslot button').on('click', function() {
            _this._onboardingManager.sendAnalytics('1.5.6', 'ctaConfermaSlot');
        })
        _$('#modal-timeslot button.uk-modal-close-default').on('click', function() {
            _this._onboardingManager.sendAnalytics('1.5.7', 'chiusuraModale');

        })

        _$('#modal-timeslot .component-AccordionTimeslotInfo').on('show',() => {
            _this._onboardingManager.sendAnalytics('1.5.8', 'clickAccordionInfoPrenotazioneSlot')
        });

        this.manageTitles();

        if(Window.cart && Window.cart.parentOrder){
            _$('#modal-timeslot').addClass('modify-order');
        }
    }

    initAddressOnModal(service){
        let address;
        if(this.localStorage.getFromStorage('step2Flow') == 'loadStep2'){
            address = this.localStorage.getFromStorage('selectedAddress_SAP_Format') ? this.localStorage.getFromStorage('selectedAddress_SAP_Format') : {};
        }else if(this.localStorage.getFromStorage('step2Flow') == 'modifyStep2'){
            if(service == 'HOME_DELIVERY'){
                address = Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress ?
                Window.cart.deliveryAddress : {};
            }else if(service == 'ORDER_AND_COLLECT'){
                address = Window.pointOfService && Window.pointOfService.address ?
                Window.pointOfService.address : (this.localStorage.getFromStorage('selectedAddressOrderAndCollect') != null ? this.localStorage.getFromStorage('selectedAddressOrderAndCollect') : {});
            }
        }

        _$(this._modalTimeslot).find('.section-address-complete .address .value').text(address.formattedAddress);
    }

    confirmTimeslotDay(e: object) {
        _$(this._modalTimeslot).find('.component-card-timeslot').removeClass('selected');
        _$(e).addClass('selected');
        //_$(this._modalTimeslot).addClass('selected-timeslot');
    }

    confirmTimeslotHour(e: object) {
        _$(this._modalTimeslot).find('.chip-hour').removeClass('selected');
        _$(e).addClass('selected');
        _$(this._modalTimeslot).addClass('selected-timeslot');
    }

    openModalTimeslotFromPreheader(){
        this.bookUtils.modifyStep2(Window.typeOfService == 'HOME_DELIVERY' ? 'ricevi' : 'ritira');
        this.bookUtils.bookingSlotState = BOOKING_SLOT_STATE.DEFAULT;
        _$('#modal-timeslot .js-button-conferma-timeslot').attr('onclick', 'BookUtils.bookingSlot()');
        Window.selectedTimeslot ? this._onboardingManager.sendAnalytics('1.5.2', "modificaSlot") : this._onboardingManager.sendAnalytics('1.5.1', "prenotaSlot");
    }

    fetchTimeslots(){

    }

    createDaysHtml(){

    }

    createTimeslotHtml(){

    }

    manageProgressBar(){

    }

    manageTitles(){
        _$('#modal-timeslot').removeClass('service-type-consegna');
        _$('#modal-timeslot').removeClass('service-type-ritiro');
        if(Window.typeOfService && Window.typeOfService == "HOME_DELIVERY"){
            _$('#modal-timeslot').addClass('service-type-consegna');
        }else if(Window.typeOfService && Window.typeOfService == "ORDER_AND_COLLECT"){
            _$('#modal-timeslot').addClass('service-type-ritiro');
        }
    }

    public showModalTimeslotSkeleton(){
        _$('#modal-timeslot').addClass('showing-skeleton');
        this._modalTimeslot.find('.list-delivery-days').html(`
            <li>
                <div class="card-timeslot-day-skeleton">
                    <div class="card-skeleton-detail">
                        <div class="card-skeleton-availability loading"></div>
                        <div class="card-skeleton-info loading"></div>
                    </div>
                </div>
            </li>
            <li>
                <div class="card-timeslot-day-skeleton">
                    <div class="card-skeleton-detail">
                        <div class="card-skeleton-availability loading"></div>
                        <div class="card-skeleton-info loading"></div>
                    </div>
                </div>
            </li>
            <li>
                <div class="card-timeslot-day-skeleton">
                    <div class="card-skeleton-detail">
                        <div class="card-skeleton-availability loading"></div>
                        <div class="card-skeleton-info loading"></div>
                    </div>
                </div>
            </li>
            <li>
                <div class="card-timeslot-day-skeleton">
                    <div class="card-skeleton-detail">
                        <div class="card-skeleton-availability loading"></div>
                        <div class="card-skeleton-info loading"></div>
                    </div>
                </div>
            </li>
            `);
        
            this._modalTimeslot.find('.list-delivery-hours').html(`
                <li>
                    <div class="card-timeslot-hours-skeleton">
                        <div class="card-skeleton-detail">
                            <div class="card-skeleton-info loading"></div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card-timeslot-hours-skeleton">
                        <div class="card-skeleton-detail">
                            <div class="card-skeleton-info loading"></div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card-timeslot-hours-skeleton">
                        <div class="card-skeleton-detail">
                            <div class="card-skeleton-info loading"></div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card-timeslot-hours-skeleton">
                        <div class="card-skeleton-detail">
                            <div class="card-skeleton-info loading"></div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card-timeslot-hours-skeleton">
                        <div class="card-skeleton-detail">
                            <div class="card-skeleton-info loading"></div>
                        </div>
                    </div>
                </li>`);
    }

    hideModalTimeslotSkeleton(){

    }
    
    backToOnboarding(){
        setTimeout(() => {
            _Uikit.modal('#modal-onboarding').show();
        }, 500);
    }
}