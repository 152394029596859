import { Utils } from './../../services/utils/utils';
import { _$, _Uikit, Window } from '../../main';
import { CartService } from "src/services/cart-service/cart-service";
import { DetailCart } from "./../../pages/detail-cart/detail-cart";
import { LockerUtils } from './../../services/utils/locker-utils';
export class ProductCardDetailCart {
    private lastRemovedItem: Object;
    private lastRemovedItemHtml;
    /* Indica se stiamo riaggiungendo un prodotto */
    private readdTimeout;

    constructor(
        private _cartService: CartService,
        private _utils: Utils,
        private _detailCart: DetailCart,
        private _lockerUtils: LockerUtils
    ) {
        let _this = this;
        // _$("select").change(function() {
        //     _this.updateQuantity(this);
        // });
    }

    nkOnInit() {
        _$(document).click(function(e) {
            if (!(_$(e.target).attr('class') === 'icon-more' 
                || _$(e.target).attr('class') === 'product-choice')) {
                _$('.product-choice').removeClass('show');
            }   
        });
    }

    updateQuantity(e: object, remove = false, quantity = false, outOfStock = false){
        let newQuantity = remove ? '0' : _$(e).val().toString();
        let newQuantityInt = parseFloat(newQuantity);
        let productCard = _$(e).closest('.component-ProductCardDetailCart');
        let dataProduct = JSON.parse(productCard.attr('data-product'));
        let productType;
        let productCode = dataProduct.code;
        let entryNumber = productCard.attr('data-entryNumber');
        let oldQuantity = productCard.attr('data-quantity');
        let scale;

        if(!quantity || newQuantity != oldQuantity){
            if(dataProduct.increment){
                if(newQuantityInt.toFixed(3).toString() == '0.000'){
                    this.lastRemovedItem = {
                        "qty" : 1,
                        "productId": productCode,
                    }
                    if(productCard.attr('data-scale')){
                        this.lastRemovedItem['scale'] = productCard.attr('data-scale');
                        this.lastRemovedItem['weight'] = productCard.attr('data-scale');
                    }
                }
            }else{
                if(newQuantity == '0'){
                    this.lastRemovedItem = {
                        "qty" : oldQuantity,
                        "productId": productCode
                    }
                    
                    if(productCard.attr('data-scale')){
                        
                        this.lastRemovedItem['scale'] = productCard.attr('data-scale').toString();
                        this.lastRemovedItem['weight'] = productCard.attr('data-scale').toString();
                    }else{
                        if(dataProduct.netQuantityUm && dataProduct.netQuantityUm.toUpperCase() != "PZ"){
                            this.lastRemovedItem['weight'] = dataProduct.netQuantity ? dataProduct.netQuantity : 0;
                        }
                    }
                }
            }   
            
            if(dataProduct.increment){
                productType = 'incremental';
                if(newQuantityInt > 0){
                    scale = parseFloat(newQuantity);
                    newQuantityInt = 1;
                }else{
                    scale = dataProduct.increment.minWeight;
                }
            }
            else if (dataProduct.variations){
                productType = 'variable';
                scale = dataProduct.entries[0].scale ? dataProduct.entries[0].scale : undefined;
            }
            else if(dataProduct.volume){
                productType = 'heavy';
            }else{
                productType = 'base'
            }

            if(remove || newQuantityInt == 0){
                _$('.component-ProductCardDetailCart').find('.remove-product').css('pointer-events', 'none');
                _$('.component-ProductCardDetailCart').find('.quantity').css('pointer-events', 'none');
                _$('.component-ProductCardDetailCart').find('.quantity-format').css('pointer-events', 'none');
                _$('.component-ProductCardDetailCart').find('.button-more').css('pointer-events', 'none');
            }
            
            this.updateOnCart(productCard, productCode, entryNumber, newQuantityInt, productType, scale, outOfStock);
            if (newQuantityInt != 0) {
                // this._detailCart.sendAnalytics('applicaPezzatura', _$(e).closest('.component-ProductCardDetailCart'));
            } else {
                this.lastRemovedItemHtml = _$(productCard);
            }
        }
    }

    reAddRemovedItem(){
        clearTimeout(this.readdTimeout);
        this._detailCart.sendAnalytics('annullaEliminazione',this.lastRemovedItemHtml);
        let success = (res) => {
            this._detailCart.reloadCart();
            this.getScontrino();
            this._utils.updateCartBtn(res.data.data.cart.subTotal.value, res.data.data.cart.totalUnitCount);
            this._utils.updateProductsOnPage(res.data.data.cart.entries);
            if(Window.volume && Window.volume.cartVolume != null){
                if(res.data.data.cart.volume != null){
                    Window.volume.cartVolume = res.data.data.cart.volume;
                }
            }
        }

        let error = (err) => {
            console.log(err);
            this._detailCart.reloadCart();
        }

        this._cartService.addRemoveItemToCart(success, error, this.lastRemovedItem);
    }

    updateOnCart(productCard: object, productCode: string, entryNumber: string, quantity: number, productType: string, scale?: string, outOfStock: boolean = false){
        
        let dataProduct = JSON.parse(_$(productCard).attr('data-product'));
        let oldQuantity = JSON.parse(_$(productCard).attr('data-quantity')); 
        let success = (res) => {
            let resource = res.data;
            let statusCode = resource.data.modifications.cartModifications[0].statusCode;

            if(statusCode == "success"){

                if(resource.data.cart.entries.length < 1){
                    if(outOfStock){
                        this._detailCart.sendAnalytics('eliminazioneProdotto', productCard);
                        window.location.reload();
                    }else{
                        let accordion = _$(productCard).closest('.category');
                        _$(productCard).after('<div class="uk-alert-primary uk-alert-button-action uk-visible@xl" uk-alert><button onclick="ProductCardDetailCart.reAddRemovedItem()" type="button" class="uk-alert-close uk-button uk-button-small uk-button-primary-outline">Annulla</button><i class="icon-info uk-border-circle"></i><p>Il prodotto è stato rimosso</p></div><div class="uk-alert-primary uk-alert-primary-mobile uk-alert-button-action uk-hidden@xl uk-flex" uk-alert><div class="top uk-flex uk-flex-middle"><i class="icon-info uk-border-circle"></i><p>Il prodotto è stato rimosso</p></div><button onclick="ProductCardDetailCart.reAddRemovedItem()" type="button" class="uk-alert-close uk-button uk-button-small uk-button-primary-outline">Annulla</button></div>');
                        _$(productCard).remove();
                        let newBadgeValue = 0;
                        _$(accordion).find('.component-ProductCardDetailCart').each(function() {
                            newBadgeValue += parseInt(_$(this).attr('data-quantity'), 10) || 0;
                            console.log(_$(this).attr('data-quantity'))
                        })
                        _$(accordion).find('.uk-badge').html(newBadgeValue.toString());
                        this.readdTimeout = setTimeout(() =>{
                            this._detailCart.sendAnalytics('eliminazioneProdotto', productCard);
                            window.location.reload();
                        }, 6000);
                    }
                    
                }else if(quantity < 1){
                    if(outOfStock){
                        let accordion = _$(productCard).closest('.category');
                        _$(productCard).remove();
                        let newBadgeValue = 0;
                        _$(accordion).find('.component-ProductCardDetailCart').each(function() {
                            newBadgeValue += parseInt(_$(this).attr('data-quantity'), 10) || 0;
                            console.log(_$(this).attr('data-quantity'))
                        })
                        _$(accordion).find('.uk-badge').html(newBadgeValue.toString());
                        this._detailCart.sendAnalytics('eliminazioneProdotto', productCard);
                        this._detailCart.reloadCart();

                        if(resource && resource.data && resource.data.cart && resource.data.cart.subTotal && resource.data.cart.subTotal.value != null){
                            let subTotal = resource.data.cart.subTotal.value.toFixed(2);
                            subTotal = subTotal.toString().replace('.',',');
                            _$('.content-grid .primary-panel .total-product .f-roboto').html(subTotal+'&euro;');
                        }
                    }else{
                        let accordion = _$(productCard).closest('.category');
                        _$(productCard).after('<div class="uk-alert-primary uk-alert-button-action uk-visible@xl" uk-alert><button onclick="ProductCardDetailCart.reAddRemovedItem()" type="button" class="uk-alert-close uk-button uk-button-small uk-button-primary-outline">Annulla</button><i class="icon-info uk-border-circle"></i><p>Il prodotto è stato rimosso</p></div><div class="uk-alert-primary uk-alert-primary-mobile uk-alert-button-action uk-hidden@xl uk-flex" uk-alert><div class="top uk-flex uk-flex-middle"><i class="icon-info uk-border-circle"></i><p>Il prodotto è stato rimosso</p></div><button onclick="ProductCardDetailCart.reAddRemovedItem()" type="button" class="uk-alert-close uk-button uk-button-small uk-button-primary-outline">Annulla</button></div>'); 
                        let alert = _$(productCard).next();
                        _$(productCard).remove();
                        let newBadgeValue = 0;
                        _$(accordion).find('.component-ProductCardDetailCart').each(function() {
                            newBadgeValue += parseInt(_$(this).attr('data-quantity'), 10) || 0;
                            console.log(_$(this).attr('data-quantity'))
                        })
                        _$(accordion).find('.uk-badge').html(newBadgeValue.toString());
                        if(_$(accordion).find('.component-ProductCardDetailCart').length < 2){
                            /* Accordion svuotato */
                            this.readdTimeout = setTimeout(() => {
                                    this._detailCart.sendAnalytics('eliminazioneProdotto', productCard);
                                    this._detailCart.reloadCart();
                                }, 6000);
                        }else{
                            /* Accordion non svuotato */
                            this.readdTimeout = setTimeout(() => {
                                    _$(alert).remove();
                                    this._detailCart.sendAnalytics('eliminazioneProdotto', productCard);
                                    this._detailCart.reloadCart();
                                }, 6000);
                        }

                        if(resource && resource.data && resource.data.cart && resource.data.cart.subTotal && resource.data.cart.subTotal.value != null){
                            let subTotal = resource.data.cart.subTotal.value.toFixed(2);
                            subTotal = subTotal.toString().replace('.',',');
                            _$('.content-grid .primary-panel .total-product .f-roboto').html(subTotal+'&euro;');
                        }
                    }
                    
                }else{
                    _$(productCard).find('.alert-quantita-aggiornata').addClass('uk-hidden');
                    _$(productCard).find('.aggregate-price span').html(resource.data.modifications.cartModifications[0].entry.totalPrice.value.toFixed(2).toString().replace('.', ',') + ' €');
                    _$(productCard).attr('data-quantity', resource.data.modifications.cartModifications[0].quantity);
                    let newBadgeValue = 0;
                    let accordion = _$(productCard).closest('.category');
                    _$(accordion).find('.component-ProductCardDetailCart').each(function() {
                        newBadgeValue += parseInt(_$(this).attr('data-quantity'), 10) || 0;
                        console.log(_$(this).attr('data-quantity'))
                    })
                    _$(accordion).find('.accordion-badge').html(newBadgeValue.toString());
                    if(resource && resource.data && resource.data.cart && resource.data.cart.subTotal && resource.data.cart.subTotal.value != null){
                        let subTotal = resource.data.cart.subTotal.value.toFixed(2);
                        subTotal = subTotal.toString().replace('.',',');
                        _$('.content-grid .primary-panel .total-product .f-roboto').html(subTotal+'&euro;');
                    }
                }
                if(productType == "variable" || this._utils.isNxM(dataProduct)){
                    this._detailCart.reloadCart();
                }

                this.getScontrino();
                if(productType == "heavy"){
                    
                    let heavyCartSurcharge = false;
                    let heavyCartLimit = false;
                    if(quantity > oldQuantity){
                        Window.volume.cartVolume += (quantity - oldQuantity) * dataProduct.volume;
                    }else{
                        Window.volume.cartVolume -= (oldQuantity - quantity) * dataProduct.volume;
                    }
    
                    if(Window.volume.cartVolume >= Window.volume.maxVolume){
                        heavyCartLimit = true;
                    }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                        heavyCartSurcharge = true;
                    }
    
                    if(_$('body').attr('nkPage') == 'DetailCart'){
                        if(heavyCartLimit){
                            _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').removeClass('uk-hidden');
                            _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').addClass('uk-hidden');
                        }else if(heavyCartSurcharge){
                            _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').addClass('uk-hidden');
                            _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').removeClass('uk-hidden');
                        }else{
                            _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').addClass('uk-hidden');
                            _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').addClass('uk-hidden')
                        }
                    }

                    let exceedingVolume = Window.volume.cartVolume - Window.volume.maxVolume;
                    if(exceedingVolume > 0){
                        _$('#alert-limit-exceeding-liters').removeClass('uk-hidden');
                        _$('#exceeding-liters-data').html(exceedingVolume.toString());
                        _$(productCard).find('.info-limit-exceeding-liters').find('.badge-text').text(quantity * dataProduct.volume + ' litri');
                    }else{
                        _$('#alert-limit-exceeding-liters').addClass('uk-hidden');
                        this._detailCart.reloadCart();
                    }
                }
                if(dataProduct.promo){
                    /* TODO: manca il controllo nel caso in cui siano soglie extraordine, sarà lo stesso valore della promo? */
                    dataProduct.promo.forEach((index, element) => {
                        if(dataProduct.promo[element].itOrderSap){
                            if(resource.data.modifications.cartModifications[0].quantity >= dataProduct.promo[element].itOrderSap){
                                _$(productCard).find('.price .price-promo-label').removeClass('uk-hidden');
                                _$(productCard).find('.price .aggregate-price span').addClass('unit-price-red');
                            }else{
                                _$(productCard).find('.price .price-promo-label').addClass('uk-hidden');
                                _$(productCard).find('.price .aggregate-price span').removeClass('unit-price-red');
                            }
                        }else{
                            _$(productCard).find('.price .price-promo-label').addClass('uk-hidden');
                            _$(productCard).find('.price .aggregate-price span').removeClass('unit-price-red');
                        }
                    });
                    
                }
                this._utils.updateCartBtn(resource.data.cart.subTotal.value, resource.data.cart.totalUnitCount);
                this._utils.updateProductsOnPage(resource.data.cart.entries);

                if(scale && _$(productCard).attr('data-scale')){
                    _$(productCard).attr('data-scale', parseFloat(scale) ? parseFloat(scale) : scale );
                }
                
            }else if(statusCode =="lowStock" || statusCode =="noStock"){
                this._detailCart.reloadCart();
                this.getScontrino();
                this._utils.updateCartBtn(resource.data.cart.subTotal.value, resource.data.cart.totalUnitCount);
                this._utils.updateProductsOnPage(resource.data.cart.entries);
                
            }
            
        }

        let error = (err) => {
            this._detailCart.reloadCart();
            if(this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message) != null){
                let jsonError = this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message);
                if(jsonError['maxPiecesLk'] != null){
                    this._lockerUtils.manageLockerContingencyModal(jsonError['maxPiecesLk'], true, false);
                }else if(jsonError['maxKgLk'] != null){
                    this._lockerUtils.manageLockerContingencyModal(jsonError['maxKgLk'], false, true);
                }
            }
        }

        let body;

        switch(productType){

            case 'variable':

                body = {
                    "productId": productCode,
                    "entryNumber": entryNumber,
                    "qty": quantity,
                    "scale":parseFloat(scale)
                }

                body["weight"] = body["scale"]

                break;

            case 'incremental' : 
                body = {
                    "productId": productCode,
                    "entryNumber": entryNumber,
                    "qty": quantity,
                    "scale": parseFloat(scale)
                }

                body["weight"] = body["scale"]
                break;
            case 'base':
            case 'heavy':
                body = {
                    "productId": productCode,
                    "entryNumber": entryNumber,
                    "qty": quantity
                }

                if(dataProduct.netQuantityUm && dataProduct.netQuantityUm.toUpperCase() != "PZ"){
                    body["weight"] = dataProduct.netQuantity ? dataProduct.netQuantity : 0;
                }
                break;
        }
        
        body['preparationNotes'] = _$(productCard).attr('data-extra-option') ? _$(productCard).attr('data-extra-option') : '';

        this._cartService.addRemoveItemToCart(success, error, body);
    }

    getScontrino(){
        let link = _$('.primary-panel').attr('data-refresh-endpoint');
        let success = (res) => {
            let resHTML;
            if(res.length){
                res = res.trim();
                let parser = new DOMParser();
                resHTML = parser.parseFromString(res, 'text/html');
            }
            if(_$(resHTML).find('.card-scontrino').length && _$('.card-scontrino').length){
                _$('.card-scontrino').replaceWith(_$(resHTML).find('.card-scontrino'));   
            }
            if(_$(resHTML).find('.container-prodotti-omaggio').length && _$('.container-prodotti-omaggio').length){
            _$('.container-prodotti-omaggio').replaceWith(_$(resHTML).find('.container-prodotti-omaggio'));
            }

            /* Presa dal ts di card prodotto omaggio */
            if(_$('.component-CardProdottoOmaggio').length){
                _$('.component-CardProdottoOmaggio').each((index, value) => {
                    if(_$(value).attr('data-product') != null && _$(value).attr('data-product').toString() != null && JSON.parse(_$(value).attr('data-product').toString()) != null){
                        let dataProduct = JSON.parse(_$(value).attr('data-product').toString());
                        _$(value).find('.a-wrapper').attr('data-code', dataProduct.code != null ? dataProduct.code : '');
                        _$(value).find('.omaggio-checkbox').attr('data-active', dataProduct.active != null ? dataProduct.active : false);
                    }
                })
            }

            if(Window.cart && !Window.cart.parentOrder){
                if(Window.pointOfService && Window.typeOfService){
                    if(!Window.pointOfService.firstAvailableTimeSlot){
                        _$('.card-scontrino-bottom-footer .uk-button').replaceWith('<button type="button" class="uk-button uk-button-primary" disabled>Vai alla cassa - Non disponibile</button>');
                    }
                }
            }

            /* Se il volume è in eccesso (volume del cart > max volume) */
            if(Window.volume.cartVolume - Window.volume.maxVolume > 0){
                _$('.card-scontrino-bottom-footer .uk-button').replaceWith('<button type="button" class="uk-button uk-button-primary" disabled>Vai alla cassa - Non disponibile</button>');
            }
            this._cartService.checkProgressDeliveryCost();
        }

        let error = (err) => {
            console.error(err);
        }

        this._cartService.getPageUpdate(success, error, link);
    }


    showOptionsProduct(e) {
        _$(e).find('.product-choice').addClass('show')
    }
}
