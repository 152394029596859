import Splide, { Options } from '@splidejs/splide';
import Alpine from 'alpinejs';
import { Coupon, DefaultService, PaginationRequest } from '../../../../../../api';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, i18n, renderHrefWithAdditionalParams } from '../../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';

const SPLIDE_COUPONS_PREVIEW_ID = '#splide-coupons-preview';
const SPLIDE_COUPONS_PREVIEW_OPT: Options = {
    drag: 'free',
    pagination: false,
    breakpoints: {
        1280: {
            perPage: 3,
            perMove: 1,
        },
    },
    mediaQuery: 'min', //this set breakpoints mobile-first
    gap: '6%',
    padding: '0.5rem',
};

Alpine.data('ec11x9xPreviewCoupons', () => {
    let splideCouponsPreview;

    return {
        showCardsSkeleton: true,
        coupons: [] as Coupon[],
        initialized: false,
        init() {
            this.loadCoupons();
        },
        // consts
        // utils
        i18n,
        formatC,
        renderHrefWithAdditionalParams,
        // internal functions
        // main component functions
        // only analytics functions
        trackShowAll() {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_PROFILE_SHOW_ALL;
            ecommerceData.funnel.stepFunnel = 'Offerte e buoni';
            sendAnalytics(ecommerceData);
        },
        async loadCoupons() {
            this.showCardsSkeleton = true;
            const requestBody: PaginationRequest = {
                pageSize: 9,
                currentPage: 0
            };
            const response = (await DefaultService.postApiMyconadItItCouponsJson(requestBody));
            this.coupons.push(...response?.data?.coupons || []);
            this.$nextTick(() => {
                if (splideCouponsPreview) splideCouponsPreview.destroy();
                splideCouponsPreview = new Splide(SPLIDE_COUPONS_PREVIEW_ID, SPLIDE_COUPONS_PREVIEW_OPT).mount();
            });

            this.initialized = true;
            this.showCardsSkeleton = false;
        },
    }
});