import { _Uikit, Window, _$ } from '../../main'; 
import { Analytics } from '../../services/analytics/analytics';
export class BannerStripe {
   
   constructor(
      private _analytics: Analytics
   ) { }
   
   nkOnInit() { 
      
   }   

   sendAnalytics(eventType, obj) {
      try {
         var page = _$("body").attr("nkPage");
         var posizioneSlide = 0;
         var step;
         var analyticsData;
         var card = _$(obj);
         var slides = _$('.swiper-banner-stripe .swiper-slide');

         for (let i = 0; i < slides.length; i++) {
            posizioneSlide = slides.eq(i).hasClass('swiper-slide-active') ? i + 1 : posizioneSlide;
         }

         var myPos = posizioneSlide.toString()

         analyticsData = {
            event: eventType,
            sponsor: [{
               brand: "",
               campagna: card.find('.banner-title').length ? card.find('.banner-title').html() : '',
               landing: card.attr('href'),
               posizioneSlide: myPos,
               formato: "Strip BTF"
            }],
            prodotto: {}
         }
         switch (page){
            case 'Homepage':
               step = 'FUNNEL_DATA_HOME';
            break;
            case 'Pageoffers':
               step = 'FUNNEL_DATA_OFFERS';
            break;
         }

         if (_$(obj).closest('.component-BannerStripePromo').attr('data-promo')) {
            let dataPromo = JSON.parse(_$(obj).closest('.component-BannerStripePromo').attr('data-promo'));
            this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo);
         } else if (_$(obj).closest('.component-BannerStripeVisibilita').attr('data-promo')) {
            let dataPromo = JSON.parse(_$(obj).closest('.component-BannerStripeVisibilita').attr('data-promo'));
            this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo);
         } else {
            this._analytics.sendAnalytics(analyticsData, step);
         }

      } catch (error) {
         console.log(eventType + ' : ' + error);
      }
  }

}