import { _Uikit, Window, _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';

export class CardsImageTop{

    swiperOffersOption: SwiperOptions = {
        spaceBetween: 16,
        freeMode: true,
        scrollbar: {
            el: '.swiper-offers .swiper-scrollbar',
            draggable: true,
            hide: false,
        },
        navigation: {
            nextEl: '.swiper-offers .swiper-button-next',
            prevEl: '.swiper-offers .swiper-button-prev',
        },
        breakpointsInverse: true,
        centerInsufficientSlides : true,
        breakpoints: {
            1024: {
                spaceBetween: 24,
                slidesPerView: _$('.swiper-offers .swiper-slide').length > 3 ? 3 : _$('.swiper-offers .swiper-slide').length,
                slidesPerGroup: 4
            },
            1400: {
                slidesPerView: _$('.swiper-offers .swiper-slide').length > 4 ? 4 : _$('.swiper-offers .swiper-slide').length
            }
        },
    };
    
    swiperOffers;


    constructor(){

    }

    nkOnInit(){
        this.swiperOffers = new Swiper('.swiper-offers', this.swiperOffersOption);
    }
}