
import Swiper, { SwiperOptions } from 'swiper';
import { _Uikit, Window, _$ } from '../../main';
export class Ispirazionale {
   
    constructor() { }
    
    nkOnInit() { 
        let cards = _$(".component-Ispirazionale").find('.component-ProductCardLastView');

        for (var i = 0; i < cards.length; i++) {
            cards.eq(i).attr('onmouseenter', 'Ispirazionale.changeGifDesktop(this)');
            cards.eq(i).attr('ontouchstart', 'Ispirazionale.changeGifMobile(this)')
        }
    }   

    swiperLasciatiIspirareOptions: SwiperOptions = {
        spaceBetween: 16,
        freeMode: true,
        breakpointsInverse: true,
        scrollbar: {
            el: '.scrollbar-lasciati-ispirare',
            draggable: true,
            hide: false,
        },
        breakpoints: {
            375: {
                direction: 'horizontal',
                slidesPerView: 1.2
            },
            1024: {
                direction: 'vertical',
                slidesPerView: 4.1
            }, 
        }, 
    };
    swiperLasciatiIspirare = new Swiper('.swiper-lasciati-ispirare', this.swiperLasciatiIspirareOptions);

    changeGifDesktop(e) {
        if (_$(window).width() > 1023) {
            let newGif = _$(e).closest('.card-container').attr('data-product-gif-image');
            _$('.component-Ispirazionale .img-container').attr('data-src', newGif);
        }
    }

    changeGifMobile(e) {
        if (_$(window).width() < 1024) {
            let newGif = _$(e).closest('.card-container').attr('data-product-gif-image');
            _$('.component-Ispirazionale .img-container').attr('data-src', newGif);
        }
    }
 }