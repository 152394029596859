import {BaseService} from './../base-service';
import {
    HEADER_REQUEST,
    BASE_URL,
    GET_VALIDATION_RULES
} from '../config';

export class ValidationService extends BaseService {

    constructor() {
        super();
    }

    getValidation(success, error): void {
        this.get(BASE_URL + GET_VALIDATION_RULES, success, error, HEADER_REQUEST);
    }
}