import { Analytics } from "../../services/analytics/analytics";
import { Utils } from "../../services/utils/utils";

export class CardSpesaOnline {

    constructor() {
    }

    nkOnInit() {
        console.log('init spesa online');
        
    }
}