import { BaseService } from "../base-service";
import { HEADER_REQUEST, BASE_URL, SUGGESTIONS_SEARCH} from '../config';

export class SearchService extends BaseService{

   private timeoutId: any;
   private suggestDelay = 500;

   constructor() {
      super();
   }

   nkOnInit() { }


   search(success, error, queryParam?): void{
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
         const query = queryParam ? '?query=' + queryParam : '';
         this.get(BASE_URL + SUGGESTIONS_SEARCH + query, success, error, HEADER_REQUEST);
      }, this.suggestDelay)
   }
}
