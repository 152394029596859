///////////////////////////////////
/////////////////////////////////// CSS
///////////////////////////////////
import './base.css'; // BASE CSS TO ALIGN BROWSER
import './app.scss'; // OLD APP SCSS
import './tailwind.css'; // NEW TAILWIND CSS

///////////////////////////////////
/////////////////////////////////// JS
///////////////////////////////////

// OLD FE CODE
import './main';
import { initPage } from './binding';

// API
import { OpenAPI } from './api/index';
OpenAPI.BASE = '';

// ALPINE
import Alpine from 'alpinejs';
window.Alpine = Alpine;

// cfamily
import './cfamily';

// stores
import './stores/user';
import './stores/cart';
import './stores/dictionary';
import './stores/page';
import './stores/urlSite';

// components
// | apline templates
import './repository/apps/conad-ecommerce/alpine-templates/ap200-card-order/script';
import './repository/apps/conad-ecommerce/alpine-templates/ap203-like-button/script';
import './repository/apps/conad-ecommerce/alpine-templates/ap205-coupon/script';
import './repository/apps/conad-ecommerce/alpine-templates/ap206-card-customer-care-case/script';
// | structure
import './repository/apps/conad-ecommerce/components/structure/page/script';
import './repository/apps/conad-ecommerce/components/structure/detail-product/script';
// | content
import './repository/apps/conad-ecommerce/components/content/8-0-profile/script';
import './repository/apps/conad-ecommerce/components/content/8-1-orders/script';
import './repository/apps/conad-ecommerce/components/content/8-2-coupons/script';
import './repository/apps/conad-ecommerce/components/content/8-3-wishlists/script';
import './repository/apps/conad-ecommerce/components/content/8-4-assistance/script';
// | dynamic
import './repository/apps/conad-ecommerce/components/dynamic/product-card/script';
import './repository/apps/conad-ecommerce/components/dynamic/30-0-product-card-mini/script';
// | internal
import './repository/apps/conad-ecommerce/components/internal/11-0-radio-select/script';
import './repository/apps/conad-ecommerce/components/internal/11-1-personal-area-menu/script';
import './repository/apps/conad-ecommerce/components/internal/11-3-textbox/script';
import './repository/apps/conad-ecommerce/components/internal/11-4-toasting-box/script';
import './repository/apps/conad-ecommerce/components/internal/11-5-manage-wishlists/script';
import './repository/apps/conad-ecommerce/components/internal/11-7-preview-orders/script';
import './repository/apps/conad-ecommerce/components/internal/11-8-preview-wishlists/script';
import './repository/apps/conad-ecommerce/components/internal/11-9-preview-coupons/script';
import './repository/apps/conad-ecommerce/components/internal/11-10-user-menu/script';
import './repository/apps/conad-ecommerce/components/internal/11-11-assistance-boxes/script';
// | popups
import './repository/apps/conad-ecommerce/components/popup/12-1-description-list/script';
import './repository/apps/conad-ecommerce/components/popup/12-2-question/script';
import './repository/apps/conad-ecommerce/components/popup/12-3-message-with-image/script';
import './repository/apps/conad-ecommerce/components/popup/12-4-multi-info/script';
import './repository/apps/conad-ecommerce/components/popup/12-5-coupon-detail/script';
import './repository/apps/conad-ecommerce/components/popup/12-7-wishlists-add-product/script';
import './repository/apps/conad-ecommerce/components/popup/12-8-wishlists-name/script';
import './repository/apps/conad-ecommerce/components/popup/12-9-wishlists-add-to-cart-with-errors/script';
// | templates
import './repository/apps/conad-ecommerce/templates/et900-popup-base/script';
import './repository/apps/conad-ecommerce/templates/et901-popup-non-modal-demo/script';

// OTHER
import { getStandardContentRedirectDirective } from './libs/base-utils';

// some implementation
window.cStepLoader = async (name: string) => {
    const isLocalHost = location.hostname === "localhost" || location.hostname === "127.0.0.1";

    let html;
    if (isLocalHost && window.isFrontend) { // localhost:3000
        const reqUrl = `/content/conad-ecommerce/it/it/default/popups/${name}/_jcr_content/root/empty.html?wcmmode=disabled`;
        const resp = await fetch(reqUrl);
        html = await resp.text();
    } else if (isLocalHost) { // localhost:4502
        const coopPath = getStandardContentRedirectDirective() || '/content/conad-ecommerce/it/it/default';
        const reqUrl = coopPath + `/popups/${name}/_jcr_content/root/empty.jloader.mpde.json?wcmmode=disabled`;
        const resp = await fetch(reqUrl);
        const jsonResp = await resp.json();
        html = jsonResp?.data?.html || '';
    } else { // cloud (jloader with mpde cache)
        const reqUrl = `/popups/${name}/_jcr_content/root/empty.jloader.mpde.json`;
        const resp = await fetch(reqUrl);
        const jsonResp = await resp.json();
        html = jsonResp?.data?.html || '';
    }

    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(html, 'text/html');
    const el = parsedDoc.body.querySelector(`[x-cflowelement=${name}]`);
    const container = document.getElementById('pm-container');
    container.appendChild(el);
};

// DEV
if (process.env.NODE_ENV === 'development') {
    (async () => {
        const start = new Date();
        console.log('[ECC] Starting dev-utils...');
        const devUtils = await import('./libs/dev-utils');
        await devUtils.default();
        console.log('[ECC] 🔧 Variables loaded');
        console.log('[ECC] 🚂 Initializing OLD FE Page... ', new Date().getTime() - start.getTime());
        initPage();
        console.log('[ECC] 🚞 Initializing Alpine...', new Date().getTime() - start.getTime());
        Alpine.start();
        console.log('[ECC] 🚀 Init Complete', new Date().getTime() - start.getTime());
    })();
} else {
    const start = new Date();
    console.log('[ECC] 🚂 Initializing OLD FE Page... ', new Date().getTime() - start.getTime());
    initPage();
    console.log('[ECC] 🚞 Initializing Alpine...', new Date().getTime() - start.getTime());
    Alpine.start();
    console.log('[ECC] 🚀 Init Complete', new Date().getTime() - start.getTime());
}
