import { cInput } from './cinput';
import { cEnabledOn } from './cenabledon';
import { cDependOn } from './cdependon';
import { cDialog } from './cdialog';
import { cFlowElement } from './cflowelement';

const initCFamily = () => {
    cInput(window.Alpine);
    cEnabledOn(window.Alpine);
    cDependOn(window.Alpine);
    cDialog(window.Alpine);
    cFlowElement(window.Alpine);
};

// initialize
if (window.Alpine) {
    initCFamily();
} else {
    document.addEventListener('alpine:init', () => {
        initCFamily();
    });
}
