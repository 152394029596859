import Alpine from 'alpinejs';
import { CacheService } from '../api.utils/services/CacheService';

document.addEventListener('alpine:init', () => {
    try {
        CacheService.getApiEcommerceItItDictionaryJson().then((responseData) => {
            Alpine.store('dictionary', responseData?.data);
        });
    } catch (e) {
        console.warn(e);
        Alpine.store('dictionary', {});
    }
});
