import './stores';
import './core';
import './validators';
import './loaders';

////////////////////////////////////////// FNS
import { ModalStore } from './types';
import { flowManager } from './flow/flow-manager';

window.cOpenDialog = (name: string) => {
    const modalStore = <ModalStore>window.Alpine.store('cModals');
    modalStore.modal = name;
};

window.cStepLoader = async (name: string): Promise<void> => {
    throw new Error('Please implement this function to load steps dinamically');
};

window.cFlowManager = flowManager;
