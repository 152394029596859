// @ts-nocheck

/**
 * Two Way Data Binding
 * @param {data to bind} b
 */
function Binding(b) {
    var _this = this;
    this.elementBindings = [];
    this.value = b.object[b.property];
    this.valueGetter = function () {
        return _this.value;
    };
    this.valueSetter = function (val) {
        _this.value = val;
        for (var i = 0; i < _this.elementBindings.length; i++) {
            var binding = _this.elementBindings[i];
            binding.element[binding.attribute] = val;
        }
    };
    this.addBinding = function (element, attribute, event) {
        var binding = {
            element: element,
            attribute: attribute
        };
        if (event) {
            element.addEventListener(event, function (event) {
                _this.valueSetter(element[attribute]);
            });
            binding.event = event;
        }
        _this.elementBindings.push(binding);
        element[attribute] = _this.value;
        return _this;
    };
    Object.defineProperty(b.object, b.property, {
        get: this.valueGetter,
        set: this.valueSetter,
        enumerable: true
    });
    b.object[b.property] = this.value;
}

export const initPage = () => {
    /* avvia l'init della pagina */
    var elementsPage = document.querySelectorAll('[nkPage]'), scope = {};
    elementsPage.forEach(function (element) {
        //execute scope setter
        var propToBind = element.getAttribute('nkPage');
        if (window[propToBind] && window[propToBind].nkOnInit) {
            window[propToBind].nkOnInit();
        }
    });

    /* avvia nkModels della pagina */
    var elementsModel = document.querySelectorAll('[nkModel]');
    elementsModel.forEach(function (el) {
        var propToBind = el.getAttribute('nkModel');
        var obj = window[propToBind.split('.')[0]];
        var elem = propToBind.split('.')[1];
        if (el.type === 'text' || el.type === 'textarea' || el.type === 'input') {
            new Binding({object: obj, property: elem + ""}).addBinding(el, "value", "keyup");
        } else {
            new Binding({object: obj, property: elem + ""}).addBinding(el, "innerHTML");
        }
    });

}
