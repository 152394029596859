

export class FaqSectionContattaci{

    constructor(){

    }

    nkOnInit(){

    }
}