import { BaseService } from './../base-service';
import { HEADER_REQUEST, BASE_URL, TRACKING_LSF} from '../config'; 

export class LsfService extends BaseService{
   
   constructor() { 
      super();
   }
   
   nkOnInit() { }   

   lsfTrack(success, error, body): void{
      this.postAxios(BASE_URL + TRACKING_LSF, success, error, body, HEADER_REQUEST);
   }

}