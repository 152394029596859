import Alpine from 'alpinejs';

const CODE_TEMPLATE_IDS = ['code-info', 'code-warning', 'code-error'];

Alpine.data('ec11x4xToastingBox', () => ({
    openToastIds: [] as string[],
    init() {
    },
    // consts
    // utils
    // internal functions
    _createLiveToastFromTemplate(toastTemplateID: string): HTMLElement {
        if (!toastTemplateID) return null;
        let toastTemplateEl: HTMLElement | null = this.$refs[toastTemplateID];
        if (!toastTemplateEl) return null;
        let liveToast;
        try {
            liveToast = toastTemplateEl.cloneNode(true) as HTMLElement;
        } catch (e) {
            console.error('cloning error: ', e);
            return null;
        }
        const liveToastID = toastTemplateID + '&' + Date.now();
        liveToast.dataset.id = liveToastID;
        liveToast.setAttribute('x-ref', liveToastID);
        liveToast.setAttribute('aria-hidden', false);
        return liveToast;
    },
    // main component functions,
    openToast(eventDetail: Record<string, any>) {
        if (eventDetail?.id) {
            // create a live toast element from template
            const liveToast: HTMLElement = this._createLiveToastFromTemplate(eventDetail.id);
            const liveToastID: string = liveToast?.dataset?.id;
            if (!liveToastID || !liveToastID) {
                console.error(`Impossible to create toast from specified template id: '${eventDetail?.id}'`)
                return;
            }
            // add live toast on page and register its id
            const liveToastsContainer = this.$refs['liveToastsContainer'];
            if (CODE_TEMPLATE_IDS.includes(eventDetail?.id) && eventDetail?.customText) { // only code generated toasts
                liveToast.querySelector('span.replace-text').innerHTML = eventDetail.customText;
            }
            liveToastsContainer.insertBefore(liveToast, liveToastsContainer.firstChild);
            this.openToastIds.push(liveToastID);
            // set close temporization (optional)
            const closeMillis = liveToast.dataset?.closeTemporization ? parseInt(liveToast.dataset?.closeTemporization) : 0;
            if (closeMillis && closeMillis != 0) {
                setTimeout(() => {
                    this.removeToast(liveToast, liveToastID);
                }, closeMillis);
            }
        }
    },
    removeToast(liveToast: HTMLElement, liveToastID: string) {
        let index = this.openToastIds.indexOf(liveToastID);
        if (index != -1)
            this.openToastIds.splice(index, 1);
        liveToast.remove();
    },
    closeToast(toastItem: HTMLElement) {
        if (!toastItem || !toastItem?.dataset?.id) return;
        this.removeToast(toastItem, toastItem.dataset.id);
    }
}));
