import { LOADER_POSITION, Utils } from '../../services/utils/utils';
import { GoogleUtils } from '../../services/utils/google-utils';
import { Analytics } from '../../services/analytics/analytics';
import { SearchService } from '../../services/search-service/search-service';
import { CartService } from '../../services/cart-service/cart-service';
import { DetailCart } from '../../pages/detail-cart/detail-cart';
import { LocalStorage } from '../../services/local-storage/local-storage';
import { _$, _Uikit, Window } from '../../main';
import { data } from 'jquery';

export class Header {
    public searchSuggest: JSON;
    public _categoryFirstLevel: string = '';
    public categoryParent: string;
    public _searchString: string = '';
    public suggestType: string = '';
    public suggestAuto: boolean = false;
    public miniBannersLoaded: boolean = false;
    // public numeroRisultati: number;
    // public suggestedBrands: number;
    // public suggestedCategories: number;

    lastRemovedItem: Object;
    isRemovedproduct: boolean = false;
    lastRemovedItemProductType: String;
    lastRemovedItemVolume;

    public autoSuggestTitles = {
        mobile: ['I consigliati', 'Le marche più cliccate', 'Prodotti più acquistati', 'Ricerche popolari'],
        desktop: ['Le ricerche più frequenti', 'Le marche più cliccate', 'Prodotti più acquistati', 'Ricerche popolari'],
    };

    public digitSuggestTitles = {
        mobile: ['Categorie consigliate', 'Marchi', 'Prodotti più acquistati', 'Ricerche popolari'],
        desktop: ['Categorie consigliate', 'Marchi', 'I prodotti consigliati', 'Ricerche popolari'],
    };

    constructor(
        private _analytics: Analytics,
        private _utils: Utils,
        public _googleUtils: GoogleUtils,
        public searchService: SearchService,
        public localStorage: LocalStorage,
        public cartService: CartService,
        private _detailCart: DetailCart
    ) {
        _Uikit.util.on('#menuMobile', 'show', () => {
            setTimeout(() => {
                _$('#menuMobile').find('.icon-alert').addClass('anim-pulseBoxShadow');
            }, 1000);
        });

        _Uikit.util.on('.mega-submenu', 'hide', () => {
            this.resetMegamenu();
        });

        _$('#search_d').on('focusin', () => {
            if(_$('#dropdown_desktop').find('.component-BannerSponsorSearch').length){
                this.sendAnalytics('pageviewBannerSponsorSearch', _$('#dropdown_desktop').find('.component-BannerSponsorSearch'));
            }
        });

        _$('#search_m').on('focusin', () => {
            if(_$('#dropdown_mobile').find('.component-BannerSponsorSearch').length){
                this.sendAnalytics('pageviewBannerSponsorSearch', _$('#dropdown_mobile').find('.component-BannerSponsorSearch'));
            }
        })

        if(_$('.component-BannerSponsorSearch').length){
            _$('.component-BannerSponsorSearch').on('click', (event) => {
                let banner = event.target;
                this.sendAnalytics('clickBannerSponsorSearch', _$(banner).closest('.component-BannerSponsorSearch'));
            })
        }

        if(_$('.component-header').length){
            let vociMenu = _$('.voce-main-menu');

            if(vociMenu.length)
                _$(vociMenu).on('click', (event) => {
                if(_$(event.target).html() != null && _$(event.target).prop('href') != null &&
                    (_$(event.target).closest('.category-menu').hasClass('voce-main-menu') || _$(event.target).closest('li').hasClass('voce-main-menu')))
                    this.sendAnalytics('clickVoceMainMenu', event.target);
            });
        }
        
    }

    nkOnInit() {
        this.manageInput();
        this.blockBodyScroll();

        this.cartService.checkProgressDeliveryCost();
        if(_$('#search-bar-desktop').length && _$('#search-bar-mobile')){
            _$('#search-bar-desktop').on('focusout', (event) =>{
                if(!_$(event.relatedTarget).closest('#search-bar-desktop').length && !_$(event.relatedTarget).closest('#search-bar-mobile').length && !_$(event.relatedTarget).closest('#dropdown_mobile').length){
                    this.hideAutosuggest(_$('#search-bar-desktop').find('.uk-navbar-dropdown-close'));
                    this.hideAutosuggest(_$('#search-bar-mobile').find('.uk-navbar-dropdown-close'));
                }
            })

            _$('#search-bar-mobile').on('focusout', (event) =>{
                if(!_$(event.relatedTarget).closest('#search-bar-desktop').length && !_$(event.relatedTarget).closest('#search-bar-mobile').length && !_$(event.relatedTarget).closest('#dropdown_mobile').length){
                    this.hideAutosuggest(_$('#search-bar-desktop').find('.uk-navbar-dropdown-close'));
                    this.hideAutosuggest(_$('#search-bar-mobile').find('.uk-navbar-dropdown-close'));
                }
            })

            _$('#dropdown_mobile').on('focusout', (event) =>{
                if(!_$(event.relatedTarget).closest('#search-bar-desktop').length && !_$(event.relatedTarget).closest('#search-bar-mobile').length && !_$(event.relatedTarget).closest('#dropdown_mobile').length){
                    this.hideAutosuggest(_$('#search-bar-desktop').find('.uk-navbar-dropdown-close'));
                    this.hideAutosuggest(_$('#search-bar-mobile').find('.uk-navbar-dropdown-close'));
                }
            })
        }
        
    }

    loadMiniBanners(){
        if(!this.miniBannersLoaded){
            let link = _$('.component-header').attr('data-header-minibanner-endpoint');
            let success = (res) => {
                this.miniBannersLoaded = true;
                res = res.replace(/>\s+</g,'><');
                let miniBanners = _$.trim(res);
                _$('.block-minibanner-promozioni').append(miniBanners);
                this.managePageViewSponsorOnMenu();
            }
            let error = (err) => {
                this.miniBannersLoaded = false;
                console.error('Problems fetching minibanners', err);
            }
            this.cartService.getPageUpdate(success, error, link);
        }else{
            setTimeout(() => {
                this.managePageViewSponsorOnMenu();
            }, 100)
        }
    }

    blockBodyScroll() {
        _$('.mega-submenu').on('beforeshow', function () {
            _$('html').css('overflow-y', 'hidden');
        });

        _$('.mega-submenu').on('beforehide', function () {
            _$('html').css('overflow-y', '');
        });
    }

    addOverlay(el: object) {
        if (!_$('.overlay-backdrop').length) {
            _$(_$(el).closest('.search-bar').parent()).append(
                '<div class="overlay-backdrop" onclick="Header.hideAutosuggest()"></div>'
            );
        }
    }

    manageInput() {

        let lastVal;

        _$('#search_d').on('keyup', (event) => {
            let key = event.key;
            let newVal = _$('#search_d').val() as string;
            newVal = newVal.trim();
            if (_$('#search_d').val() && ((lastVal != null && lastVal != newVal) || lastVal == null)) {
                this.showAutosuggest(_$('#search_d'), key);
                lastVal = newVal;
            }
        });

        _$('#search_m').on('keyup', (event) => {
            let key = event.key;
            let newVal = _$('#search_m').val() as string;
            newVal = newVal.trim();
            if (_$('#search_m').val() && ((lastVal != null && lastVal != newVal) || lastVal == null)) {
                this.showAutosuggest(_$('#search_m'), key)
                lastVal = newVal;
            }
        });
    }

    /**
     * Effettuo la chiamata a search per restituirmi i risultati e popolare le sezioni
     * @param el
     */
    populateSuggest(el: object) {
        let success = (res) => {
            if (JSON.stringify(this.searchSuggest) === JSON.stringify(res.data)) {
                console.log('no updates');
            } else {
                if (_$(el).val() && _$(el).val().toString() && params == encodeURIComponent(_$(el).val().toString())) {
                    this.searchSuggest = res.data;
                    this.populateSection('suggested-categories', res.data.suggestedCategories);
                    this.populateSection('suggested-brands', res.data.suggestedBrands);
                    this.populateSection('most-wanted-products', res.data.suggestedProducts);
                    if(res.data && res.data.suggestedQuery){
                        this.populateSection('popular-queries', res.data.suggestedQuery);
                    }
                    
                    // this.numeroRisultati = res.data.suggestedProducts ? res.data.suggestedProducts.length : 0;
                    // this.suggestedBrands = res.data.suggestedBrands ? res.data.suggestedBrands.length : 0;
                    // this.suggestedCategories = res.data.suggestedCategories ? res.data.suggestedCategories.length : 0;

                    _$('.lds-ring-2').addClass('uk-hidden');
                    _$('.dropdown-content').removeClass('uk-hidden');
                } else if(_$(el).val() == ''){
                    this.searchSuggest = res.data;
                    this.populateSection('suggested-categories', res.data.suggestedCategories);
                    this.populateSection('suggested-brands', res.data.suggestedBrands);
                    this.populateSection('most-wanted-products', res.data.suggestedProducts);
                    if(res.data && res.data.suggestedQuery){
                        this.populateSection('popular-queries', res.data.suggestedQuery);
                    }
                    
                    // this.numeroRisultati = res.data.suggestedProducts ? res.data.suggestedProducts.length : 0;
                    // this.suggestedBrands = res.data.suggestedBrands ? res.data.suggestedBrands.length : 0;
                    // this.suggestedCategories = res.data.suggestedCategories ? res.data.suggestedCategories.length : 0;

                    _$('.lds-ring-2').addClass('uk-hidden');
                    _$('.dropdown-content').removeClass('uk-hidden');
                }else {
                    console.log('Esiste una chiamata al servizio più recente');
                }
            }
        };
        let error = (err) => {
            console.log(err);
        };

        let params = _$(el).val() && _$(el).val().toString() ? encodeURIComponent(_$(el).val().toString()) : _$(el).val();
        
        this._searchString = _$(el).val().toString();
        this.localStorage.setItem('searchQuery', JSON.stringify(this._searchString));
        
        //@ts-ignore
        if (params.length > 2) {
            this.searchService.search(success, error, params);
            this.suggestType = 'digitSuggest';
        } else if (!this.suggestAuto) {
            this.searchService.search(success, error);
            this.suggestType = 'autoSuggest';
            this.suggestAuto = true;
        }
    }

    /**
     * Popolo le singole colonne del dropdown della search
     * @param sectionName
     * @param suggestedList
     */
    populateSection(sectionName: string, suggestedList: any) {
        var sectionContainer;
        var suggestTitles;
        var titles;

        if (this.suggestType == 'digitSuggest') {
            suggestTitles = this.digitSuggestTitles;
            sectionContainer = '#dynamic_';
        } else {
            suggestTitles = this.autoSuggestTitles;
            sectionContainer = '#static_';
        }

        if (this._utils.isMobile() || this._utils.isTablet()) {
            sectionContainer = sectionContainer.concat('search_full_m');
            titles = suggestTitles['mobile'];
        } else {
            sectionContainer = sectionContainer.concat('search_full_d');
            titles = suggestTitles['desktop'];
        }

        if (sectionName == 'most-wanted-products') {
            _$(sectionContainer + ' .' + sectionName).html(`<h5 class="title">${titles[2]}</h5>`);
            for (let suggested in suggestedList) {
                let quantity = suggestedList[suggested] && suggestedList[suggested].quantity ? `<div class="quantity">${suggestedList[suggested].quantity}</div>` : '';
                let brand = suggestedList[suggested] && suggestedList[suggested].brand ? `<div class="store ${suggestedList[suggested].brand == "UNBRANDED" ? 'uk-invisible': ''}">${suggestedList[suggested].brand}</div>` : '';
                let code = suggestedList[suggested] && suggestedList[suggested].code ? suggestedList[suggested].code : '';

                let eventType;
                if (this.suggestType == 'digitSuggest') {
                    eventType = 'prodottoConsigliato';
                } else {
                    eventType = 'prodottiPiuAcquistati';
                }

                _$(sectionContainer + ' .' + sectionName).append(`
                    <a href='${suggestedList[suggested].url}' data-product='${JSON.stringify(suggestedList[suggested]).replace(/'/g, "%27")}' class="list uk-flex uk-width-expand no-t-decoration" onclick="Header.sendAnalytics('${eventType}',this); Header.lsfTrack(this, 'view', '${suggestedList[suggested].query}');">
                        <div class="product-image">
                            <img data-src="${suggestedList[suggested].rendition}" alt="alt" uk-img>
                        </div>
                        <div class="content uk-flex uk-flex-column uk-width-auto">
                            <div class="extra-info uk-flex">
                                ${brand}
                                <div class="badge uk-flex uk-margin-auto-left"></div>
                            </div>
                            <div class="product">${suggestedList[suggested].title}
                            </div>
                            ${quantity}
                        </div>
                    </a>
                `);

                if(suggestedList[suggested]) {
                    if (suggestedList[suggested].promo) {
                        let promoArray = suggestedList[suggested].promo;
                        promoArray.forEach((index, element) => {
                            if(promoArray[element].active){
                                _$(sectionContainer + ' .' + sectionName).find('.list:last .badge').append(`<div nkPage="PromoBadgeCard" class="component-PromoBadgeCard uk-flex ${promoArray[element].badgeColor}">
                                                                                                <span class="badge-text">
                                                                                                    <i class="icon-delivery"></i>
                                                                                                    ${promoArray[element].badgeText}
                                                                                                </span>
                                                                                            </div>`);
                                if(promoArray[element].customerType == 'CONAD_CARD'){
                                    _$(sectionContainer + ' .' + sectionName).find('.list:last .badge').append(`<i class="icon-fidelity-grey"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>`);
                                }else if(promoArray[element].customerType == 'FIDELITY_CARD'){
                                    _$(sectionContainer + ' .' + sectionName).find('.list:last .badge').append(`<span class="icon-fidelity-double"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>`);
                                }
                            }
                        });
                    } else {
                        _$(sectionContainer + ' .' + sectionName).find('.list:last .badge:last-child').html('');
                    }
                }
            }
            
        } else {
            let eventType;

            if (sectionName == 'suggested-categories') {
                _$(sectionContainer + ' .' + sectionName).html(`<h5 class="title">${titles[0]}</h5>`);
                if (this.suggestType == 'digitSuggest') {
                    eventType = 'categoriaConsigliata';
                } else {
                    eventType = 'ricercaFrequente';
                }
            } else if(sectionName == 'popular-queries'){
                _$(sectionContainer + ' .' + sectionName).html(`<h5 class="title">${titles[3]}</h5>`);
                eventType = 'ricerchePopolari';
            } else {
                _$(sectionContainer + ' .' + sectionName).html(`<h5 class="title">${titles[1]}</h5>`);
                if (this.suggestType == 'digitSuggest') {
                    eventType = 'marchioCompare';
                } else {
                    eventType = 'marchePiuCliccate';
                }
            }
            for (var suggested in suggestedList) {
                var quantity = suggestedList[suggested] && suggestedList[suggested].quantity ? `<div class="quantity">${suggestedList[suggested].quantity}</div>` : '';
                console.log(sectionContainer + ' .' + sectionName);
                
                _$(sectionContainer + ' .' + sectionName).append(
                    `<div class="list uk-flex uk-flex-middle" onclick="Header.sendAnalytics('${eventType}',this)">
                        <a href='${suggestedList[suggested].url}' class="product">${suggestedList[suggested].name}</a>
                        <span class="tot uk-badge uk-margin-auto-left">${quantity}</span>
                    </div>`
                );
                if (!quantity) {
                    _$(sectionContainer + ' .' + sectionName + ' .list:last-child .uk-badge').addClass('uk-hidden');
                }
            }
        }
    }


    showMenuMobile() {
        this.backMenu();
        _Uikit.offcanvas('#menuMobile').show();
        this.loadMiniBanners();
    }

    /**
     * Resetta le classi del megamenu
     */
    resetMegamenu() {
        _$('.catList').find('.cat').removeClass('uk-active');
        _$('.subcatList').find('li').removeClass('uk-active');
        _$('.megamenu-second-level').children().addClass('uk-hidden');
        _$('.megamenu-third-level').children().addClass('uk-hidden');
    }

    /**
     * Apre il dropdown della Search bar
     * @param el
     * @param ev
     */
    showAutosuggest(el: object, ev: String) {
        if (ev && ev === 'Enter' && _$(el).val().toString().length > 0) {
            window.location.href = Window.urlSite.search.concat(`?query=${encodeURIComponent(_$(el).val().toString())}`);
            return;
        }

        if(el && _$(el).val() && _$(el).val().toString() && _$(el).val().toString().length > 0){
            _$(el).closest('.search-bar').find('.clear-search').removeClass('uk-hidden');
            _$(el).closest('.search-bar').find('.clear-search').show();
        }

        if (_$('.search-dropdown').is(':hidden')) {
            _$('.search-dropdown').fadeIn();
            _$('.search-bar').addClass('autosuggest-visible');
        } else {
            _$('.uk-search-input').focus();
        }

        if (_$(el).parents().find('#search-bar-mobile')) {
            _$('.close-search').removeClass('hidden-btn-close');
        }

        //@ts-ignore
        if (_$(el).val().length > 2) {
            if(this._searchString != _$(el).val()){
                _$('.lds-ring-2').removeClass('uk-hidden');
                _$('.dropdown-content').addClass('uk-hidden');
                _$('#dynamic_search_full_d, #dynamic_search_full_m').removeClass('uk-hidden');
                _$('#static_search_full_d, #static_search_full_m').addClass('uk-hidden');
                this.searchSuggest = JSON;
                this.populateSuggest(el);
            }
        } else {
            _$('.lds-ring-2').addClass('uk-hidden');
            _$('.dropdown-content').removeClass('uk-hidden');
            _$('#dynamic_search_full_d, #dynamic_search_full_m').addClass('uk-hidden');
            _$('#static_search_full_d, #static_search_full_m').removeClass('uk-hidden');
            this.populateSuggest(el);
            this._searchString = '';
        }
    }

    getSuggestClick(el) {
        if (_$('#' + el).val().toString().length > 0) {
            window.location.href = Window.urlSite.search.concat(`?query=${encodeURIComponent(_$('#' + el).val().toString())}`);
        }
    }

    /**
     * Nasconde il dropdown della searchbar
     * @param el
     */
    hideAutosuggest(el: object) {
        _$('.search-dropdown').slideUp();
        _$('.search-bar').removeClass('autosuggest-visible');
        _$('.overlay-backdrop').remove();

        if (_$(el).parents().find('#search-bar-mobile')) {
            _$('.close-search').addClass('hidden-btn-close');
        }
    }

    trackFirstLevel(e) {
        if(_$(e).closest('.megamenu-third-level').length){
            let subcat = _$(e).closest('ul').attr('data-target');
            this._categoryFirstLevel = _$(e).closest('.megamenu-third-level').siblings('.megamenu-second-level').find(`li.uk-active a[data-target=${subcat}]`).length ? _$(e).closest('.megamenu-third-level').siblings('.megamenu-second-level').find(`li.uk-active a[data-target=${subcat}]`).text() : '';
        }else if(_$(e).closest('.category-third-submenu').length){
            this._categoryFirstLevel = _$(e).closest('.category-third-submenu').find('.category-title').length ? _$(e).closest('.category-third-submenu').find('.category-title').text() : '';
        }else{
            this._categoryFirstLevel = e.innerText;
        }
        
        let analyticsData = {
            event: 'voceMenu',
            funnel: {},
            prodotto: {
                categoriaPrimoLivello: this._categoryFirstLevel,
            },
        };
        this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_MEGAMENU');
    }

    showSubcatDesktop(e: any) {
        //reset
        _$('.megamenu-third-level > * ').addClass('uk-hidden');
        _$('.megamenu-third-level > * > * ').addClass('uk-hidden');
        if (_$(e).parent('li').parent('ul').hasClass('catList')) {
            let category = _$(e).attr('data-target');

            //reset
            _$('.megamenu-second-level > * ').removeClass('uk-hidden');

            _$(e).parents('.catList').find('.cat').removeClass('uk-active');
            _$(e).parent().addClass('uk-active');
            _$('.subcatList').addClass('uk-hidden');
            _$('.subcatList[data-target=' + category + ']').removeClass('uk-hidden');
        } else if (_$(e).parent('li').parent('ul').hasClass('subcatList')) {
            let subcategory = _$(e).attr('data-target');

            //reset
            _$(e).parent('li').parent('ul').children('li').removeClass('uk-active');
            _$('.megamenu-third-level > * ').removeClass('uk-hidden');

            _$(e).parent().addClass('uk-active');
            _$('.subcatListThird').addClass('uk-hidden');
            _$('.subcatListThird[data-target=' + subcategory + ']').removeClass('uk-hidden');
        }
    }

    showFirstSubmenuMobile(e) {
        let category = _$(e).attr('data-target');

        _$('#menu-mobile').addClass('uk-hidden');
        _$('#header-menu-mobile').addClass('uk-hidden');

        _$('#header-category-first-submenu').removeClass('uk-hidden');
        _$('.category-first-submenu').addClass('uk-hidden');
        _$('.category-first-submenu[data-target=' + category + ']').removeClass('uk-hidden');
        this.managePageViewSponsorOnMenu()
    }

    showSecondSubmenu(e) {
        this.categoryParent = e.parentElement.parentElement.dataset.target;
        _$('.category-first-submenu').addClass('uk-hidden');
        _$('#header-category-first-submenu').addClass('uk-hidden');

        _$('#header-category-second-submenu').removeClass('uk-hidden');

        let category = _$(e).attr('data-target');
        _$('.category-second-submenu').addClass('uk-hidden');
        _$('.category-second-submenu[data-target=' + category + ']').removeClass('uk-hidden');
        this.managePageViewSponsorOnMenu();
    }

    showThirdSubmenu(e) {
        this.categoryParent = e.parentElement.parentElement.dataset.target;
        _$('.category-first-submenu').addClass('uk-hidden');
        _$('.category-second-submenu').addClass('uk-hidden');
        _$('#header-category-first-submenu').addClass('uk-hidden');
        _$('#header-category-second-submenu').addClass('uk-hidden');

        _$('#header-category-third-submenu').removeClass('uk-hidden');

        let category = _$(e).attr('data-target');
        
        _$('.category-third-submenu').addClass('uk-hidden');
        _$('.category-third-submenu[data-target=' + category + ']').removeClass('uk-hidden');
        this.managePageViewSponsorOnMenu();
    }

    backFirstSubmenu() {
        _$('.category-first-submenu[data-target=' + this.categoryParent + ']').removeClass('uk-hidden');
        _$('#header-category-first-submenu').removeClass('uk-hidden');

        _$('.category-second-submenu').addClass('uk-hidden');
        _$('#header-category-second-submenu').addClass('uk-hidden');
        this.managePageViewSponsorOnMenu()
    }

    backSecondSubmenu() {
        _$('.category-second-submenu[data-target=' + this.categoryParent + ']').removeClass('uk-hidden');
        _$('#header-category-second-submenu').removeClass('uk-hidden');

        _$('.category-third-submenu').addClass('uk-hidden');
        _$('#header-category-third-submenu').addClass('uk-hidden');
        this.managePageViewSponsorOnMenu()
    }

    backMenu() {
        _$('#menu-mobile').removeClass('uk-hidden');
        _$('#header-menu-mobile').removeClass('uk-hidden');

        _$('.category-first-submenu').addClass('uk-hidden');
        _$('#header-category-first-submenu').addClass('uk-hidden');
        this.managePageViewSponsorOnMenu();
    }

    scroll() {
        var scroll = _$('.uk-offcanvas-body').offset().top;

        if (scroll < 10) {
            _$('.uk-navbar-container').addClass('scroll');
        } else {
            _$('.uk-navbar-container').removeClass('scroll');
        }
    }

    async sendAnalytics(eventType, e?) {
        try {
            let analyticsData;
            let step;
            let dataPromo;

            switch (eventType) {
                case 'ricercaFrequente':
                    //2.4.1
                    analyticsData = {
                        event: eventType,
                        ricerca: {
                            query: _$("#search_d").val(),
                            numeroRisultati: ''
                        },
                        prodotto: {
                            categoriaPrimoLivello: '',
                            categoriaSecondoLivello: '',
                            categoriaTerzoLivello: _$(e).find('.product') && _$(e).find('.product').length ? _$(e).find('.product').html() : ''
                        }
                    }
                    step = 'FUNNEL_DATA_HEADER_PRESEARCH';
                    break;
                case 'categoriaConsigliata':
                    //2.5.1
                    analyticsData = {
                        event: eventType,
                        ricerca: {
                            query:  _$("#search_d").val(),
                            numeroRisultati: '',
                        },
                        prodotto: {
                            categoriaPrimoLivello: '',
                            categoriaSecondoLivello: '',
                            categoriaTerzoLivello: _$(e).find('.product') && _$(e).find('.product').length ? _$(e).find('.product').html() : ''
                        }
                    };
                    step = 'FUNNEL_DATA_HEADER_POSTSEARCH';
                    break;
                case 'marchePiuCliccate': {
                    //2.4.2
                    analyticsData = {
                        event: eventType,
                        ricerca: {
                            brand:  _$(e).find('.product').html(),
                            numeroRisultati: '',
                        },
                        prodotto: {
                            marchio: _$(e).find('.product') && _$(e).find('.product').length ? _$(e).find('.product').html() : '',
                            sottomarchio: ''
                        }
                    };
                    step = 'FUNNEL_DATA_HEADER_PRESEARCH';
                    break;
                }
                case 'marchioCompare':
                    //2.5.2
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        ricerca: {
                            query:  _$("#search_d").val(),
                            numeroRisultati: '',
                        },
                        prodotto: {
                            marchio: _$(e).find('.product') && _$(e).find('.product').length ? _$(e).find('.product').html() : '',
                            sottomarchio: ''
                        }
                    };
                    step = 'FUNNEL_DATA_HEADER_POSTSEARCH';
                    break;
                case 'prodottiPiuAcquistati':
                    //2.4.3
                    analyticsData = {
                        event: 'prodottiPiuAcquistati',
                        prodotto: (await this._analytics.getProductData(_$(e))),
                        ricerca: {
                            query: '',
                            numeroRisultati: '1',
                        }
                    }
                    step = 'FUNNEL_DATA_HEADER_PRESEARCH';
                    break;
                case 'prodottoConsigliato':
                    //2.5.3
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        prodotto: (await this._analytics.getProductData(_$(e))),
                        ricerca: {
                            query: this._searchString,
                            numeroRisultati: '',
                        }
                    }
                    step = 'FUNNEL_DATA_HEADER_POSTSEARCH';
                    break;
                case 'ricerchePopolari':
                    return;

                case 'ctaAccedi':
                    //5.6.0
                    analyticsData = {
                        event: eventType
                    }
                    step = "FUNNEL_DATA_CTA_ACCEDI";
                    break;

                case 'ctaRegistrati':
                    //5.6.00
                    analyticsData = {
                        event: eventType
                    }
                    step = "FUNNEL_DATA_CTA_REGISTRATI";
                    break;
                case 'clickProfilo':
                    //5.1.1
                    analyticsData = {
                        event: eventType
                    }
                    step = "FUNNEL_DATA_CLICK_PROFILO";
                    break;
                case 'clickVoceMenu':
                    //5.1.2
                    let menuEntry = _$(e.target).closest("a");

                    if (menuEntry.length > 0) {
                        let voceMenu = menuEntry.find(".label").text();

                        analyticsData = {
                            event: eventType,
                            funnel : {
                                nomeFunnel: 'Menu Personale',
                                stepFunnel: voceMenu
                            }
                        }
                        break;
                    }
                    else {
                        break;
                    }
                case 'viewCardSponsor':
                    let cards = _$(e).find('.block-minibanner-promozioni .block-card:visible');
                    let sponsorData = [];
                    let dataPromoArray = [];
                    for(let i = 0; i < cards.length; i++){
                        sponsorData[i] = {
                            brand: '',
                            campagna: _$(cards).eq(i).find('span').html(),
                            formato: 'Card Sponsor',
                            landing: _$(cards).eq(i).attr('href'),
                            posizioneSlide: cards.length > 1 ? i + 1 : i
                        }

                        if(_$(cards).eq(i).attr('data-promo')){
                            dataPromoArray.push(JSON.parse(_$(cards).eq(i).attr('data-promo')));
                        }else{
                            dataPromoArray.push("");
                        }
                    }
                    analyticsData = {
                        event: 'pageview',
                        sponsor: sponsorData
                    }
                    step = 'FUNNEL_DATA_MEGAMENU';
                    this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromoArray);
                    return;
                case 'clickBannerSponsorSearch':
                    //6.11.2
                    analyticsData = {
                        event: 'advClick',
                        ricerca: {
                            query: _$(window).width() > 991 ? _$('#search_d').val() : _$('#search_m').val(),
                            numeroRisultati: ''
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.text-container .title').html(),
                            formato: 'Banner suggest ricerca',
                            landing: _$(e).attr('href'),
                            posizioneSlide: 0
                        }]
                    }

                    step = 'FUNNEL_DATA_HEADER_POSTSEARCH';
                    dataPromo = _$(e).closest('.component-BannerSponsorSearch').attr('data-promo') ? JSON.parse(_$(e).closest('.component-BannerSponsorSearch').attr('data-promo')) : null;
                    this._analytics.sendAnalytics(analyticsData, step, true, false, dataPromo);
                    return;
                case 'pageviewBannerSponsorSearch':
                    //6.11.1
                    analyticsData = {
                        event: 'pageview',
                        ricerca: {
                            query: _$(window).width() > 991 ? _$('#search_d').val() : _$('#search_m').val(),
                            numeroRisultati: ''
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.text-container .title').html(),
                            formato: 'Banner suggest ricerca',
                            landing: _$(e).attr('href'),
                            posizioneSlide: 0
                        }]
                    }

                    step = 'FUNNEL_DATA_HEADER_POSTSEARCH';
                    dataPromo = _$(e).closest('.component-BannerSponsorSearch').attr('data-promo') ? JSON.parse(_$(e).closest('.component-BannerSponsorSearch').attr('data-promo')) : null;
                    this._analytics.sendAnalytics(analyticsData, step, true, false, dataPromo);
                    return;
                case 'clickVoceMainMenu':

                    analyticsData = {
                        event: 'clickVoceMainMenu',
                        funnel: {
                            nomeFunnel: 'Menu',
                            stepFunnel: _$(e).html(),
                            urlDestinazione: _$(e).prop('href')
                        }
                    }
                    break;
            }

            if (_$(e).closest('.block-minibanner-promozioni .block-card').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.block-minibanner-promozioni .block-card').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)                
            } else if (_$(e).closest('.block-minibanner-promozioni').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.block-minibanner-promozioni').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            } 
            // else if (_$(e).closest('.component-BannerSponsorSearch').attr('data-promo')) {
            //     let dataPromo = JSON.parse(_$(e).closest('.component-BannerSponsorSearch').attr('data-promo'));
            //     this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            // } 
            else{
                this._analytics.sendAnalytics(analyticsData, step);
            }
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    async sendAnalyticsMinicart(eventType, element?) {
        try {
            let analyticsData;
            let step;
            let spesaMin = _$('#minicartFull .products-costs .uk-alert') ? 'NO' : 'SI';
            let importoCarr = _$('#miniCartFull').find('.products-costs').find('.value').children('b').html().replace(',','.').replace('€','');
            let importoCons;
            let consegnaGrat;

            if (window['typeOfService'] == 'HOME_DELIVERY') {
                importoCons = _$('#miniCartFull').find('.delivery-costs').find('.value').html().replace(',','.').replace('€','');
                consegnaGrat = parseInt(_$('#miniCartFull').find('.delivery-costs-progress-bar').find('.progress-bar-custom').attr('value')) == 100 ? 'SI' : 'NO';
            }

            let prodotti = []
            let productCard = _$('.container-cart-full .miniCartFull .header-mini-cart .uk-card-type-product-minicart');

            switch (eventType) {
                case 'pageview':
                    for (var i = 0; i < productCard.length; i++) {
                        prodotti.push((await this._analytics.getProductData(productCard.eq(i))))
                    }

                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo: importoCarr,
                            consegnaGratuita: consegnaGrat,
                            importoConsegna: importoCons,
                            prodotti: prodotti
                        }
                    }
                    step = 'FUNNEL_DATA_MINICART_VIEW';
                    break;
                case 'chiusuraModale':
                    for (var i = 0; i < productCard.length; i++) {
                        prodotti.push((await this._analytics.getProductData(productCard.eq(i))))
                    }

                    analyticsData = {
                        event: eventType,
                        carrello: {
                            spesaMinimaRaggiunta: spesaMin,
                            importo: importoCarr,
                            consegnaGratuita: consegnaGrat,
                            importoConsegna: importoCons,
                            prodotti: prodotti
                        }
                    }
                    step = 'FUNNEL_DATA_MINICART_CLOSE';
                    break;
                case 'dettaglioCarrello':
                    for (var i = 0; i < productCard.length; i++) {
                        prodotti.push((await this._analytics.getProductData(productCard.eq(i))))
                    }

                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo: importoCarr,
                            consegnaGratuita: consegnaGrat,
                            importoConsegna: importoCons,
                            prodotti: prodotti
                        }
                    }
                    step = 'FUNNEL_DATA_MINICART_TO_CART';
                    break;
                case 'infoConsegna':
                    //4.3.36
                    for (var i = 0; i < productCard.length; i++) {
                        prodotti.push((await this._analytics.getProductData(productCard.eq(i))))
                    }

                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Minicart',
                            stepFunnel: 'Tooltip info consegna',
                        },
                        carrello: {
                            importo: importoCarr,
                            consegnaGratuita: consegnaGrat,
                            importoConsegna: importoCons,
                            prodotti: prodotti
                        }
                    };
                    this._analytics.sendAnalytics(analyticsData, '', false);
                    return;
                default:
                    let prodotto = _$(element).closest('.uk-card-type-product-minicart');

                    analyticsData = {
                        event: eventType,
                        prodotto: (await this._analytics.getProductData(prodotto))
                    };
                    step = eventType == 'eliminazioneProdotto' ? 'FUNNEL_DATA_MINICART_REMOVE_ITEM' : 'FUNNEL_DATA_MINICART_CLICK_ITEM';
                    break;
            }

            this._analytics.sendAnalytics(analyticsData, step);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }

    }
        
    trackSecondLevel(e) {

        /* desktop */
        if(_$(e).closest('.megamenu-second-level').length){
            this._categoryFirstLevel = _$(e).parent().siblings('.all').length ? _$(e).parent().siblings('.all').eq(0).find('a').text() : '';
        }else if(_$(e).closest('.megamenu-third-level').length){
            let subcat = _$(e).closest('ul').attr('data-target');
            this._categoryFirstLevel = _$(e).closest('.megamenu-third-level').siblings('.megamenu-second-level').find(`li.uk-active a[data-target=${subcat}]`).length ? _$(e).closest('.megamenu-third-level').siblings('.megamenu-second-level').find(`li.uk-active a[data-target=${subcat}]`).text() : '';
        }else if(_$(e).closest('.category-second-submenu').length){
            this._categoryFirstLevel = _$(e).closest('.category-second-submenu').find('.category-title').length ? _$(e).closest('.category-second-submenu').find('.category-title').text() : '';
        }else if(_$(e).closest('.category-third-submenu').length){
            this._categoryFirstLevel = _$(e).closest('.category-third-submenu').find('.category-title').length ? _$(e).closest('.category-third-submenu').find('.category-title').text() : '';
        }

        let analyticsData = {
            event: 'voceMenu',
            funnel: {},
            prodotto: {
                categoriaPrimoLivello: this._categoryFirstLevel,
                categoriaSecondoLivello: e.innerText,
            },
        };
        this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_MEGAMENU');
    }

    trackThirdLevel(e) {
        let analyticsData = {};
        //this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_MEGAMENU');
    }

    trackCategory() {
        let analyticsData = {
            event: 'categoriaMese',
            funnel: {},
            prodotto: {
                categoriaPrimoLivello: this._categoryFirstLevel,
            },
        };
        this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_MEGAMENU');
    }

    trackPromo(e) {
        let dataPromoArray = [];
        let analyticsData = {
            event: 'clickAdv',
            funnel: {},
            sponsor: [{
                brand: '',
                campagna: _$(e).find('span').html(),
                formato: 'Card sponsor',
                landing: _$(e).attr('href'),
                posizioneSlide: _$(e).parent().find('.block-card').length > 1 ? _$(e).index() : 0
            }]
        };

        if(_$(e).attr('data-promo')){
            dataPromoArray.push(JSON.parse(_$(e).attr('data-promo')));
        }else{
            dataPromoArray.push("");
        }

        if(dataPromoArray.length){
            this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_MEGAMENU', true, true, dataPromoArray);
        }else{
            this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_MEGAMENU');
        }
        
    }

    removeItem(e: object){
        let productCard = _$(e).closest('.uk-card-type-product-minicart');
        let dataProduct = JSON.parse(_$(productCard).attr('data-product'));
        let productCode = dataProduct.code;
        let oldQuantity = dataProduct.entries[0].qty;
        let entryNumber = dataProduct.entries[0].entryNumber;
        let netQuantity = dataProduct.netQuantity;
        let netQuantityUm = dataProduct.netQuantityUm;
        
        let productType;
        if(dataProduct.increment){
            productType = 'incremental';
        }
        else if (dataProduct.variations){
            productType = 'variable';  
        }
        else if(dataProduct.volume){
            productType = 'heavy';
        }else{
            productType = 'base';
        }

        // Save the removed product
         this.lastRemovedItem = {
             "qty" : oldQuantity,
             "productId": productCode
         }
         if(dataProduct.entries[0].scale){
             this.lastRemovedItem['scale'] = dataProduct.entries[0].scale;
             this.lastRemovedItem['weight'] = dataProduct.entries[0].scale;
         }else{
             if(netQuantityUm && netQuantityUm.toUpperCase() != "PZ"){
                this.lastRemovedItem['weight'] = netQuantity;
             }
         }
         
         _$(productCard).addClass('minicart-removed');

        let success = (res) => {
            let response = res.data;
            this.isRemovedproduct = true;
            this.lastRemovedItemProductType = productType;
            this.fetchMinicart(this.isRemovedproduct, response.data && response.data.cart && response.data.cart.entries ? response.data.cart.entries : null, response.data && response.data.cart ? response.data.cart : null);
            
            if(response.data.cart.entries.length > 0){
                this._utils.updateEntryNumbers(_$('body'), JSON.stringify(response.data.cart.entries));
                if(_$('body').hasClass('page-DetailCart')){
                    this._detailCart.reloadCart();
                }
            }else{
                if(_$('body').hasClass('page-DetailCart')){
                    window.location.reload();
                }
            }

            if(productType == "heavy" && Window.volume && Window.volume.cartVolume){
                Window.volume.cartVolume -= dataProduct.volume * oldQuantity;
                this.lastRemovedItemVolume = dataProduct.volume;
            }
            this._utils.updateProductsOnPage(response.data.cart.entries);
            this._utils.updateRemovedProductOnPage(productType, productCode, dataProduct.entries[0].scale? dataProduct.entries[0].scale : undefined, response.data.cart.entries);
        }

        let error = (err) => {
            console.error(err);
        }

        let body = {}

        switch(productType) {
            case 'base':
            case 'heavy':
                body = {
                    "qty" : 0,
                    "productId" : dataProduct.code,
                    "entryNumber": dataProduct.entries[0].entryNumber
                }

                if(netQuantityUm && netQuantityUm.toUpperCase() != "PZ"){
                    body['weight'] = netQuantity;
                }
                break;
            case 'variable':
                body = {
                    "qty": 0,
                    "scale": dataProduct.entries[0].scale,
                    "entryNumber": dataProduct.entries[0].entryNumber,
                    "productId": dataProduct.code,
                    "weight": dataProduct.entries[0].scale
                }
                break;
            case 'incremental':
                body = {
                    "qty" : 0,
                    "scale" : dataProduct.increment.minWeight,
                    "entryNumber": dataProduct.entries[0].entryNumber,
                    "productId" : dataProduct.code,
                    "weight": dataProduct.increment.minWeight
                }
                break;
        }
        
        this.cartService.addRemoveItemToCart(success, error, body);
        this.sendAnalyticsMinicart('eliminazioneProdotto', e);
    }

    fetchMinicart(removedItem?, entries?, cart?) {
        let htmlBody = _$('#miniCartFull').find('.cart-body');
        let success = (res) => {
            this._utils.showLoader(_$('.container-cart-full #miniCartFull'),false, LOADER_POSITION.MINICART_LOAD);
            // Da rimuovere

            if (removedItem) {
                _$('.container-cart-full #miniCartFull').html(res);
                _$('.container-cart-full #miniCartFull .cart-body').remove();
                _$('.container-cart-full #miniCartFull .cart-title').after(htmlBody);
                _$('.container-cart-full #miniCartFull .minicart-removed').addClass('uk-hidden');
                _$('.container-cart-full #miniCartFull .minicart-removed').each((index, element) => {
                if(_$(element).next().hasClass('alert-remove-product')){
                    _$(element).next().addClass('uk-hidden');
                }else{
                    _$(element).after(`<div class="uk-card uk-card-type-product-minicart alert-remove-product">
                                            <div class="uk-alert-primary uk-flex uk-flex-column remove-alert" uk-alert>
                                                <div class="uk-flex">
                                                    <i class="icon-info uk-border-circle"></i>
                                                    <span>Il prodotto è stato rimosso</span>
                                                </div>
                                                <button type="button" onclick="Header.reAddRemovedItem()" class="uk-alert-close undo-remove uk-button uk-button-small uk-button-primary-outline">Annulla</button>
                                            </div>
                                        </div>`);
                }
                });
                
                if(entries != null){
                    this.updateEntryNumbersOnMinicart(entries);
                }
                setTimeout(() => { 
                    _$('.container-cart-full #miniCartFull').html(res);
                    if(cart != null){
                        this._utils.updateCartBtn(cart.subTotal.value, cart.totalUnitCount); 
                    }
                }, 2000);
            } else {
                 _$('.container-cart-full #miniCartFull').html(res);
            }
            
            this._utils.customScrollbar();

            this.cartService.checkProgressDeliveryCost();

            if(Window.volume != null && Window.volume.cartVolume != null && Window.volume.maxVolumeNoSurcharge != null){
                if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                    if(_$('.container-cart-full #miniCartFull').find('.minicart-surcharge-warning').length){
                        _$('.container-cart-full #miniCartFull').find('.minicart-surcharge-warning').removeClass('uk-hidden');
                    }
                }
            }

            this.sendAnalyticsMinicart('pageview');
        };

        let error = (err) => {
            console.log(err);
            this.sendAnalyticsMinicart('pageview');
        };

        this._utils.showLoader(_$('.container-cart-full #miniCartFull'), true, LOADER_POSITION.MINICART_LOAD);
        this.cartService.getMiniCart(success, error);
        
    }

    reAddRemovedItem(){
        let success = (res) => {
            if(res.data && res.data.data && res.data.data.cart){
                setTimeout(() => {
                    this._utils.updateCartBtn(res.data.data.cart.subTotal.value, res.data.data.cart.totalUnitCount);
                    this.fetchMinicart();
                }, 1000)
            }

            if(this.lastRemovedItemProductType == "heavy" && this.lastRemovedItemVolume && Window.volume && Window.volume.cartVolume > -1){
                Window.volume.cartVolume += this.lastRemovedItemVolume * this.lastRemovedItem['qty'];
            }

            if(_$('body').hasClass('page-DetailCart')){
                this._detailCart.reloadCart();
            }

            this._utils.updateProductsOnPage(res.data.data.cart.entries);
            this._utils.updateCartBtn(res.data.data.cart.subTotal.value, res.data.data.cart.totalUnitCount);
        }

        let error = (err) => {
            console.log(err);
        }

        this.cartService.addRemoveItemToCart(success, error, this.lastRemovedItem);
    }

    lsfTrack(e: object, action: string, searchQuery: string) {
        let productCode = JSON.parse(_$(e).attr('data-product'))['code'] ? JSON.parse(_$(e).attr('data-product'))['code'] : 
            (_$(e).attr('data-product') ? _$(e).attr('data-product') : '');
        this._utils.callLsfTrack(action, productCode, searchQuery);
    }

    closeAllSubmenu() {
        let megamenu = _$('#menuMobile');
        let submenus = megamenu.find('.category-first-submenu, .category-second-submenu');
        submenus.addClass('uk-hidden');
    }

    managePageViewSponsorOnMenu(){
        if(_$('.block-minibanner-promozioni .block-card').length && _$('.block-minibanner-promozioni .block-card').is(':visible')){
            if(_$(window).width() > 991){
                this.sendAnalytics('viewCardSponsor', _$('.component-header'));
            }else{
                this.sendAnalytics('viewCardSponsor', _$('#menuMobile'));
            }
        }
    }

    updateEntryNumbersOnMinicart(entries){
        if(_$('.uk-card-type-product-minicart:not(.alert-remove-product)').length){
            _$('.uk-card-type-product-minicart:not(.alert-remove-product)').each(function(index){
                let dataProduct = JSON.parse(_$(this).attr('data-product'));
                let dataCode = dataProduct.code;
                let dataScale = dataProduct.entries[0] && dataProduct.entries[0].scale != null ? dataProduct.entries[0].scale : null;
                if(dataScale != null){
                    _$(this).attr('data-scale', dataScale);
                }
                _$(this).attr('data-code', dataCode);
                let updatedDataProduct = dataProduct;
                entries.forEach((index, element) => {
                    if(entries[element].product.code == dataCode){
                        if(dataScale != null){
                            if(entries[element].scale == dataScale)
                                updatedDataProduct.entries[0].entryNumber = entries[element].entryNumber;
                        }else{
                            updatedDataProduct.entries[0].entryNumber = entries[element].entryNumber;
                        }
                    }
                });
                _$(this).attr('data-product', JSON.stringify(updatedDataProduct));
            })
        }
    }
}
