import { _$, Window } from "../../main";
import { LOADER_POSITION, Utils } from "../../services/utils/utils";
import { GoogleUtils } from "../../services/utils/google-utils";
import { ValidationUtils } from "../../services/utils/validation-utils";
import { BookUtils } from "../../services/utils/book-utils";
import { Analytics } from "../../services/analytics/analytics";
import { CheckoutNew } from "../../pages/checkout-new/checkout-new";
import { LocalStorage } from "../../services/local-storage/local-storage";
import { MODAL_ONBOARDING_STATUS } from "../../services/utils/onboarding-manager";

export class ModalOnboarding {

    _addressOrderPickup: any;
    _stores: any;

    _modalOnboarding = _$('#modal-onboarding');

    constructor(
        private utils: Utils,
        private google: GoogleUtils,
        private bookUtils: BookUtils,
        private validationUtils: ValidationUtils,
        private _analytics: Analytics,
        private checkoutNew: CheckoutNew,
        private localStorage: LocalStorage

    ) {

        /* ^^^^^^ APPUNTO PER ALVIN:
        ogni qualvolta viene modificata la card scegli spesa (che sia onfocus dell'input, che al geocode) bisogna richiamare questa funzione per allineare le card in altezza:
        _Uikit.heightMatch('.card-section', {target: '> * > .uk-card'});
        */
    }

    nkOnInit() {
        let _this = this;

        //1.1.3
        _$('.component-banner-address button:not(.submitButton)').on('click', _this, function(){
            _this.sendAnalytics('1.1.3','cancellaIndirizzo');
        })
        //1.1.4
        _$('.component-banner-address span.banner-address-accedi a').on('click', _this, function(){
            _this.sendAnalytics('1.1.4','ctaAccediCentrale');
        })
        //1.1.5
        _$('.entrypage-provinces .section-body .uk-tag').on('click', function(el){
            _this.sendAnalytics('1.1.5','ctaProvincia-'+el.target.innerHTML);

        })
        //1.1.6
        _$('.entrypage-provinces .uk-container').children().last().on('click', function(){
            _this.sendAnalytics('1.1.6','ctaTutteLeProvince');
        })

        _$('#modal-onboarding').attr('data-first-show', 'false');

        //1.2.1
        _$(this._modalOnboarding).on('shown', _this, function (e) {
            
            if(_$('#modal-onboarding').attr('data-first-show') == 'false'){
                _$('#modal-onboarding').attr('data-first-show', 'true');
                _this.sendAnalytics(!_$('#modal-onboarding').hasClass('step-2') ? '1.2.1' : '1.3.1','pageview');

                //1.2.2 click su button default card spesa-a-casa o card ordina-e-ritira
                
                let analytic_1_2_2 = function(){
                    _this.sendAnalytics('1.2.2','selezioneServizio');
                    _this.sendAnalytics('1.3.1','pageview');
                };

                _$("#spesa-a-casa button.default, #ordina-e-ritira button.default").off('click', analytic_1_2_2);
                _$("#spesa-a-casa button.default, #ordina-e-ritira button.default").on('click', analytic_1_2_2);

                //1.2.3
                let analytic_1_2_3 = function(){
                    _this.sendAnalytics(!_$('#modal-onboarding').hasClass('step-2') ? '1.2.3' : '1.3.3','modificaIndirizzo');
                };

                _$('.section-address-complete button.address').off('click', analytic_1_2_3);
                _$('.section-address-complete button.address').on('click', analytic_1_2_3);
                
                //1.2.5
                let analytic_1_2_5 = function(){
                    _this.sendAnalytics(parseInt(_$('#modal-onboarding').attr('data-status')) != MODAL_ONBOARDING_STATUS.INCOMPLETE_ADDRESS ? '1.2.5' : '1.4.3','cancellaIndirizzo');

                };
                _$('.section-address-edit button#verificaButton').prev().off('click', analytic_1_2_5);
                _$('.section-address-edit button#verificaButton').prev().on('click', analytic_1_2_5);

                //1.2.6

                let analytic_1_2_6 = function(){
                    _this.sendAnalytics('1.2.6','ctaCompletaIndirizzo');
                    _this.sendAnalytics('1.4.1','pageview');
                }
                _$('button.incomplete').off('click', analytic_1_2_6);
                _$('button.incomplete').on('click', analytic_1_2_6);

                //1.2.7
                let analytic_1_2_7 = function(){
                    _this.sendAnalytics(parseInt(_$('#modal-onboarding').attr('data-status')) != MODAL_ONBOARDING_STATUS.INCOMPLETE_ADDRESS ? '1.2.7' : '1.4.4','ctaAnnulla');
                };
                _$('.bottom-section.btn-annulla, button.btn-annulla-mobile ').off('click', analytic_1_2_7);
                _$('.bottom-section.btn-annulla, button.btn-annulla-mobile ').on('click', analytic_1_2_7);

                //1.2.8
                let analytic_1_2_8 = function(){
                    if(_$('#section-estensione-servizio').is(':visible')){
                        _this.sendAnalytics('1.2.14', 'chiusuraModale');
                    }else{
                        !_$('#modal-onboarding').hasClass('step-2') ? _this.sendAnalytics(parseInt(_$('#modal-onboarding').attr('data-status')) != MODAL_ONBOARDING_STATUS.INCOMPLETE_ADDRESS ? '1.2.8' : '1.4.5','chiusuraModale') : _this.sendAnalytics('1.3.5', 'chiusuraModale');
                    }
                };

                _$('#modal-onboarding button.uk-modal-close-default').off('click', analytic_1_2_8);
                _$('#modal-onboarding button.uk-modal-close-default').on('click', analytic_1_2_8);

                //STEP 2- SCELTA NEGOZIO
                _$('#modal-onboarding-content .section-address .section-address-complete button.to-back').on('click', function(){
                    _this.sendAnalytics('1.3.4', 'tornaIndietro');
                })

                _$('#verificaButton-checkout').prev().on('click', _this, function(){
                    _this.checkoutNew.sendAnalytics('cancellaIndirizzo', null);
                })

                _$('#googleInputLPOnboardingLine1').prev().on('click', _this, function(){
                    _this.sendAnalytics('1.7.4','cancellaIndirizzo' );
                })

                _$('.lp-onboarding-accedi a').on('click', _this, function(){
                    _this.sendAnalytics( '1.7.5','ctaAccediCentrale');

                })
            }

        });
        
        _$('#googleInputLandingpageLine2 + label + button').on('click', function(){
            _this.sendAnalytics('1.6.2', 'cancellaIndirizzo');
        })



        /* Gestione box shadow - vedere se integrare su alti punti della modal onboarding */

        function manageFirstScroll() {
            _$('#modal-onboarding').find('.component-ordina-ritira-scelta-pdv .lista-negozi-section').addClass('add-box-shadow');
            _$('#modal-onboarding').find('.component-ordina-ritira-scelta-pdv .lista-negozi-section .js-scroll').off('ps-scroll-y', manageFirstScroll)
        }

        _$('#modal-onboarding').find('.component-ordina-ritira-scelta-pdv .lista-negozi-section .js-scroll').on('ps-scroll-y', manageFirstScroll);

        _$('#modal-onboarding').find('.component-ordina-ritira-scelta-pdv .lista-negozi-section .js-scroll').on('ps-y-reach-start', () => {
            _$('#modal-onboarding').find('.component-ordina-ritira-scelta-pdv .lista-negozi-section').removeClass('add-box-shadow');
            _$('#modal-onboarding').find('.component-ordina-ritira-scelta-pdv .lista-negozi-section .js-scroll').on('ps-scroll-y', manageFirstScroll);
        });

        let scrollTimeout = null;
        let throttle = 200;

        _$('.section-spesa-a-casa .lista-negozi-section').on('scroll', function () {
            if (!scrollTimeout) {
                scrollTimeout = setTimeout(function () {
                    if(_$('#modal-onboarding').find('.section-spesa-a-casa .lista-negozi-section').scrollTop() == 0){
                        _$('#modal-onboarding').find('.section-spesa-a-casa .title-section').removeClass('add-box-shadow');
                        _$('#modal-onboarding').find('.section-spesa-a-casa .lista-negozi-section').on('scroll', manageFirstScroll);
                    }else{
                        _$('#modal-onboarding').find('.section-spesa-a-casa .title-section').addClass('add-box-shadow');
                    }
                    scrollTimeout = null;
                }, throttle);
            }
        });

        
        
    }

    /*TODO: loader fullscreen*/
    loading(status: boolean) {
        this.utils.showLoader(_$('#modal-onboarding > div'), status, LOADER_POSITION.FULLPAGE_ORANGE);
    }

    loadStep0(type?: string) {
        _$(this._modalOnboarding).removeClass('step-2');
        _$(this._modalOnboarding).find('.section-spesa-a-casa').fadeOut();
        _$(this._modalOnboarding).find('.section-ordina-e-ritira').fadeOut();
        _$(this._modalOnboarding).find('.section-scelta-spesa').fadeIn();
    }

    loadStep1(type?: string) {
        _$(this._modalOnboarding).find('.section-scelta-spesa').fadeOut();
        if(type == 'spesa-a-casa') {
            _$(this._modalOnboarding).find('.section-spesa-a-casa').fadeIn();

        } else if(type == 'ordina-e-ritira') {
            _$(this._modalOnboarding).find('.section-ordina-e-ritira').fadeIn();
        }
        _$(this._modalOnboarding).addClass('step-2');
    }


    /* spostata */
    confirmStore(e: object) {
        _$(this._modalOnboarding).find('.component-card-negozio').removeClass('selected');
        _$(e).addClass('selected');
        _$(this._modalOnboarding).addClass('selected-pdv');

    }


    /**
     * 3 tipologie di analytics:
     * - case step2-0: pageview modale
     * - case step2-1: con sceltaNegozio
     * - case step2-2: senza sceltaNegozio
     * - case step2-3: errore di form
     *  */
    sendAnalytics(analyticsType: string, eventType, search?) {
        try {
            let analyticsData;
            let step = '';
            let link = document.location.pathname.toString().split('/');
            let linkProvenienza = _$('body').attr('nkpage') == 'DetailProduct' ? link[link.length - 2].split('?')[0] : link[link.length - 1].split('?')[0];
            let provenienza = this._analytics.getProvenienza(linkProvenienza);

            let consegna = '';
            let ritiro = '';
            let tipo_servizio = Window.typeOfService == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna';
            let card_spesa_a_casa = _$('#spesa-a-casa');
            let card_ordina_ritira = _$('#ordina-e-ritira');

            if(card_spesa_a_casa.hasClass("incomplete")){
                consegna = "verifica indirizzo";
            }else if(card_spesa_a_casa.hasClass("disabled")){
                consegna = "non disponibile";
            }else{
                consegna = "disponibile";
            }

            if(card_ordina_ritira.hasClass("incomplete")){
                ritiro = "verifica indirizzo";
            }else if(card_ordina_ritira.hasClass("disabled")){
                ritiro = "non disponibile";
            }else{
                ritiro = "disponibile";
            }

            let disponibilita_negozio = _$('.component-card-negozio .selected').hasClass('available') ? "Disponibile" : "Esaurita";

            //CALCOLO NUMERO RISULTATI
            let num = _$('.section-view-pdv-content .lista-negozi-section ul')[0].children.length;

            //let storeSelected = this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected') : '';
            //if (!search) {
            //    search = this._search;
            //}
            switch (analyticsType) {
                case '1.1.3':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP1';
                    break;
                case '1.1.4':
                    analyticsData = {
                        event: eventType,
                    };

                    step = 'FUNNEL_DATA_CTA_ACCEDI_CENTRALE';
                    break;
                case '1.1.5':
                    analyticsData = {
                        event: eventType.slice(0, eventType.indexOf('-')),
                        funnel: {
                            provincia: eventType.slice(eventType.indexOf('-')+1, eventType.length)
                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP1';
                    break;
                case '1.1.6':
                    analyticsData = {
                        event: eventType,
                    };

                    step = 'FUNNEL_DATA_EP_STEP1';
                    break;

                //SCELTA NEGOZIO
                case '1.3.1':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                            sceltaNegozio : {
                               numeroRisultati : num
                            }

                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP2';
                    break;
                case '1.3.3':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                            sceltaNegozio : {
                                idNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').name : '',
                                cooperativaNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').cooperativeId : '',
                                disponibilitaNegozio: window['typeOfService'] && this.localStorage.getFromStorage('storeSelected') ?
                                    (window['typeOfService'] == 'HOME_DELIVERY' ?
                                        (window['pointOfService'].hdTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita')
                                        :
                                        (window['pointOfService'].ocTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita'))
                                    : '',
                                numeroRisultati: num
                            }

                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP2';
                    break;
                case '1.3.4':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                            sceltaNegozio : {
                                idNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').name : '',
                                cooperativaNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').cooperativeId : '',
                                disponibilitaNegozio: window['typeOfService'] && this.localStorage.getFromStorage('storeSelected') ?
                                    (window['typeOfService'] == 'HOME_DELIVERY' ?
                                        (window['pointOfService'].hdTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita')
                                        :
                                        (window['pointOfService'].ocTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita'))
                                    : '',
                                numeroRisultati: num
                            }

                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP2';
                    break;
                case '1.3.5':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                            sceltaNegozio : {
                                numeroRisultati: num,
                                idNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').name : '',
                                cooperativaNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').cooperativeId : '',
                                disponibilitaNegozio: window['typeOfService'] && this.localStorage.getFromStorage('storeSelected') ?
                                    (window['typeOfService'] == 'HOME_DELIVERY' ?
                                        (window['pointOfService'].hdTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita')
                                        :
                                        (window['pointOfService'].ocTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita'))
                                    : ''
                            }

                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP2';
                    break;

                case '1.4.1':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            tipoServizio: tipo_servizio,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };
                    step = 'FUNNEL_DATA_STEP_1C_COMPLETA_INDIRIZZO';
                    break;
                case '1.4.3':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1C_COMPLETA_INDIRIZZO';
                    break;

                case '1.4.5':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1C_COMPLETA_INDIRIZZO';
                    break;
                //SCELTA SERVIZIO
                case '1.2.1':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO';
                    break;
                case '1.2.2':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO';
                    break;
                case '1.2.3':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO';
                    break;
                case '1.2.5':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO';
                    break;
                case '1.2.6':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO';
                    break;
                case '1.2.8':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO';
                    break;

                case '1.2.14':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_FORM_ESTENSIONE_SERVIZIO';
                    break;
                case '1.6.2':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_LISTA_PROVINCE';
                    break;

                //MODALE SU LANDINGPAGE
                case '1.7.1':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: provenienza,
                            stepFunnel: 'CTA verifica ora'
                        },
                    };
                    break;
                case '1.7.2':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            provenienza: provenienza,
                        },
                    };
                    step = 'FUNNEL_DATA_EP_STEP1';
                    break;
                case '1.7.4':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            provenienza: provenienza,
                            ricerca: "",
                            //ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP1';
                    break;
                case '1.7.5':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            provenienza: provenienza,
                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP1';
                    break;


            }
            if (analyticsType != 'chiusuraModale' && analyticsData) {
                this._analytics.sendAnalytics(analyticsData, step, true, false);
            }

        }catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    isValidForm() {
        let $form = _$('#modal-onboarding').find('.service-request');
        let $allInputs = $form.find('input');
        let validInputs = [];

        _$($allInputs).each((index, singleInput) => {
            let $input = _$(singleInput);
            this.validationUtils.newValidInput($input);
            if ($input.attr('data-valid') == 'false') {
                validInputs.push(false);
            } else {
                validInputs.push(true);
            }
        });

        return validInputs.indexOf(false) < 0;
    }
}
