import Alpine from 'alpinejs';
import PerfectScrollbar from 'perfect-scrollbar';
import { FlowStore } from '../../../../../../cfamily/types';

Alpine.data('popup12x1xDescriptionList', () => ({
    ps: null,
    init() {
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
        };
        this.ps = new PerfectScrollbar(this.$refs.scrollable, scrollbarOptions);
    },
    // main component functions
    async cancel() {
        const flow = (await <FlowStore>window.Alpine.store('cFlow'));
        flow.data.result = {
            state: 'confirmed'
        };
        window.cFlowManager.complete();
    }
}));
