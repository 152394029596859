import { _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';
import { Utils } from '../../services/utils/utils';
export class StorytellingA {
   
    constructor(
        private _utils: Utils,
    ) { }
    
    nkOnInit() { 
        for (let i = 0; i < _$('.next-storytelling-a').length; i++) {
            _$('.next-storytelling-a')[i].setAttribute('onclick','StorytellingA.mySlideNext(this)');
            _$('.prev-storytelling-a')[i].setAttribute('onclick','StorytellingA.mySlidePrev(this)');            
        }
    }
    
    swiperStoryAOptions: SwiperOptions = {
        spaceBetween: 15,
        freeMode: true,
        breakpointsInverse: true,
        scrollbar: {
            el: '.scrollbar-storytelling-a',
            draggable: true,
            hide: false,
        },
        /* navigation: {
            nextEl: '.next-storytelling-a',
            prevEl: '.prev-storytelling-a',
        }, */
        breakpoints: {
            375: {
                spaceBetween: 15,
               /*  slidesPerGroup: 1, */
                slidesPerView: 1.2
            },
            768: {
                spaceBetween: 15,
                /* slidesPerGroup: 2, */
                slidesPerView: 2.2
            },
            1024: {
                spaceBetween: 16,
                /* slidesPerGroup: 3, */
                slidesPerView: 3.2
            },
            1440: {
                spaceBetween: 24,
                /* slidesPerGroup: 4, */
                slidesPerView: 4.2
            },
        },
    };
    swiperStoryA = new Swiper('.swiper-storytelling-a', this.swiperStoryAOptions);

    mySlideNext(item) {
        this._utils.mySlideNext(this.swiperStoryAOptions, _$(item).closest('.swiper-storytelling-a')[0].swiper);
    }

    mySlidePrev(item) {
        this._utils.mySlidePrev(this.swiperStoryAOptions, _$(item).closest('.swiper-storytelling-a')[0].swiper);
    }
 }