import Swiper, { SwiperOptions } from 'swiper';
import { _Uikit, Window, _$ } from '../../main';

export class IspirazionaleHotspot {
   
    constructor() { }
    
    nkOnInit() {
        this.initHotspot();
    }   

    initHotspot(){
        try{
            //load s7 div element
            var dms = _$('.component-IspirazionaleHotspot').find('.dm-interactive-image');
            /* var dms = document.querySelectorAll(".dm-interactive-image"); */
            for (let i = 0; i < dms.length; i++) {
                var dmDiv = dms[i];
                //load params
                //@ts-ignore
                var params = JSON.parse(dmDiv.dataset.configuration);

                //create s7 viewer
                //@ts-ignore
                var s7interactiveimageviewer = new s7viewers.InteractiveImage({
                    "containerId" : dmDiv.id,
                    "params" : params});

                //add event listeners to hotspot click
                
                let functions = this;
                s7interactiveimageviewer.setHandlers({
                    "quickViewActivate": function(data/* objID,compClass,instName,timeStamp,eventInfo */) {
                        //console.log(objID,compClass,instName,timeStamp,eventInfo);
                        
                        /* var elem = _$('#switcher-hotspot').find('div[prod-id='+data.sku+']')
                        _Uikit.drop(_$(elem)).show(); */
                        
                    },
                    "initComplete": function(){
                        functions.addCardsToHotspots(i);
                        /* functions.initDrops(i); */
                    }
                });
                //init s7 viewer
                s7interactiveimageviewer.init();

            }
        }catch(error){
            console.log(error);
        }
    }

    initDrops(nSlide) {
        var slide = _$('#switcher-hotspot').children('div').eq(nSlide).children('.card-to-switch');
        var cards = slide.find('.component-DotCard').parent();

        for (var i = 0; i < cards.length; i++) {
            if (_$(cards[i]).attr('uk-drop')) {
                _Uikit.drop(_$(cards[i]));
            }
        }
    }

    changeCard(e: object) {
        var cardId = _$(e).attr('card-id');
        var cardNum = parseInt(cardId.substr(-1));
        var cards = _$(e).closest('.card-to-switch').find('.component-ProductCard');
        for (var i = 0; i < cards.length; i++) {
            _$(cards[i]).css('display','none');
            if (i == cardNum) {
                _$(cards[i]).css('display','block');
            }
        }
    }

    addCardsToHotspots(nSlide) {
        var slide = _$('#switcher-hotspot').children('div').eq(nSlide).children('.card-to-switch'); //change index
        var hotspots;
        var dotCards;
        var prodCards;
        var card;

        hotspots = slide.find('.s7icon');
        dotCards = slide.find('.dot-card-container').find('.component-DotCard');
        prodCards = slide.find('.component-ProductCard');
        
        var controlProduct = slide.find('.dm-interactive-image').attr('data-product-ids'); //check no null hotspot

        if (controlProduct && controlProduct.length) {
            var checkProduct = (slide.find('.dm-interactive-image').attr('data-product-ids')).split(',');
            if (hotspots.length == checkProduct.length) {
                var validProd = checkProduct.length;
                if (checkProduct.indexOf('null') > -1) {
                    for(var i = 0; i < checkProduct.length; i++) {
                        if (checkProduct[i] == 'null') {
                            validProd--;
                            _$(hotspots[i]).css('display','none');
                            delete hotspots[i];
                        }
                    }
                }

                for (var j = 0; j < validProd; j++) {
                    card = dotCards[j].outerHTML.toString();
                    _$(hotspots[Object.keys(hotspots)[j]]).attr('card-id','slide-'+nSlide+'-'+j);
                    _$(hotspots[Object.keys(hotspots)[j]]).attr('onclick','IspirazionaleHotspot.changeCard(this)');
                    _$(hotspots[Object.keys(hotspots)[j]]).after('<div uk-drop="mode:click,hover">' + card + '</div>');

                    if (j == 0) {
                        _$(prodCards[j]).css('display','block');
                    }
                }        
            } else {
                for(var i = 0; i < checkProduct.length; i++) {
                    _$(hotspots[i]).css('display','none');
                    delete hotspots[i];
                }
            }
        }
    }  
 }
