import { enGB, it } from 'date-fns/locale'
import { format } from 'date-fns';

export const getLocale = () => {
    const documentLang = document.documentElement.lang || 'en_GB';
    if (documentLang === 'it') return it;
    return enGB;
};

export const formatDate = (date, formatStr = "PP") => {
    return format(date, formatStr, { locale: getLocale() })
};
