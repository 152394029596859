import Swiper, { SwiperOptions } from 'swiper';

export class StorytellingB {
   
    constructor() { }
    
    nkOnInit() { }   

    swiperStoryBOptions: SwiperOptions = {
        spaceBetween: 16,
        freeMode: true,
        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 16,
                slidesPerGroup: 1,
                slidesPerView: 1.2,
                noSwiping: false,
                allowSlideNext: true,
                allowSlidePrev: true
            },
            1024: {
                slidesPerView: 2.2,
                slidesPerGroup: 2,
                spaceBetween: 8,
                noSwiping: true,
                allowSlideNext: false,
                allowSlidePrev: false
            },
        },
    };
    swiperStoryB = new Swiper('.swiper-storytelling-b', this.swiperStoryBOptions);
 }