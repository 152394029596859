let blockCounter = 0;

export const toggleBlockPageScroll = () => {
    const hasBlock = blockCounter > 0;
    if (hasBlock) removeBlockScroll();
    else setBlockScroll();
};

export const setBlockScroll = () => {
    if (blockCounter > 0) {
        blockCounter++;
        return;
    }
    const bodyWidth = document.body.clientWidth;
    document.body.classList.add('overflow-y-hidden');
    const margin = document.body.clientWidth - bodyWidth;
    //set body margin
    document.body.style.marginRight = `${margin}px`;
    //set fixed margin
    const fixedEls = document.body.querySelectorAll<HTMLElement>('.fixed');
    for (const fix of fixedEls) {
        fix.style.marginRight = `${margin}px`;
    }
    blockCounter++;
};

export const removeBlockScroll = () => {
    if (blockCounter <= 0) return;
    blockCounter--;
    //remove block scroll only if block counter = 0 so there is only a 1 modal/popup open on page
    if (blockCounter > 0) return;

    document.body.classList.remove('overflow-y-hidden');
    //set body margin
    document.body.style.marginRight = '0';
    //set fixed margin
    const fixedEls = document.body.querySelectorAll<HTMLElement>('.fixed');
    for (const fix of fixedEls) {
        fix.style.marginRight = '0';
    }
};
