import Alpine from 'alpinejs';
import { AddCartRequest, Address, CancelOrderRequest, DefaultService, ErrorBody, Order, PointOfService, TypeOfService } from '../../../../../api';
import { FlowStore } from '../../../../../cfamily/types';
import { BASE_ECOMMERCE_DATA, ORDER_STATUS, TRANSACTION_STATUS } from '../../../../../constants';
import { formatC, formatCurrency, i18n, renderHrefWithAdditionalParams } from '../../../../../libs/base-utils';
import { formatDate, getLocale } from '../../../../../libs/locale-utils';
import { sendAnalytics } from '../../../../../libs/tracking-utils';

Alpine.data('ap200xCardOrder', () => ({
    // order as Order, // given by parent
    openAddressBox: false,
    init() {
    },
    // consts
    TRANSACTION_STATUS,
    // utils
    formatC,
    i18n,
    formatDate,
    formatCurrency,
    getLocale,
    renderHrefWithAdditionalParams,
    // internal functions
    async _deleteCart() {
        if (!this.$store.cart?.code || this.$store.cart?.code === '') {
            console.log('cart id not valid, skipping delete cart');
            return;
        }
        try {
            await DefaultService.postApiEcommerceItItDeleteCartJson();
            console.log(`Current cart deleted`);
        } catch (e) {
            this.$dispatch('toast', { id: 'code-error', customText: i18n('Error deleting current cart') });
            throw e;
        }
    },
    async _addCartFromOrder(reorder: false) {
        try {
            let requestBody: AddCartRequest = {
                pointOfServiceId: this.order.timeSlot?.pointOfService?.name || undefined,
                deliveryArea: this.order.deliveryAreaName,
                typeOfService: this.order.serviceType,
            };
            if (reorder) requestBody.reorderId = this.order.code;
            else requestBody.parentOrderId = this.order.code;

            const cartFromOrder = (await DefaultService.postApiMyconadItItAddCartJson(requestBody))?.data;
            console.log(`Created new cart '${cartFromOrder.code}' with parent order '${this.order.code}'`);
        } catch (e) {
            this.$dispatch('toast', { id: 'code-error', customText: i18n('Error generating cart from an existing order') });
            throw e;
        }
    },
    async _cancelOrder() {
        try {
            let requestBody: CancelOrderRequest = {
                code: this.order.code,
                typeOfService: this.order.serviceType,
            };
            await DefaultService.postApiMyconadItItCancelOrderJson(requestBody);
        } catch (e) {
            this.$dispatch('toast', { id: 'code-error', customText: i18n('Error in canceling existing order') });
            throw e;
        }
    },
    // main component functions
    getFormattedOrderDate(order: Order, multiline: boolean = false) {
        if (!order) return '';
        if (order.moreDayTimeslot) {
            return `${formatDate(order.timeSlot.startDate, 'dd/MM')} - ${formatDate(order.timeSlot.startTime.date, 'HH:mm')} 
                ${i18n('to the')}${multiline ? '<br/>' : ''}
                ${formatDate(order.timeSlot.endDate, 'dd/MM')} - ${formatDate(order.timeSlot.endTime.date, 'HH:mm')}`;
        } else {
            return `${formatDate(order.timeSlot.date, 'dd MMMM yyyy')}${multiline ? '<br/>' : ' - '}
                ${formatDate(order.timeSlot.startTime.date, 'HH:mm')}/${formatDate(order.timeSlot.endTime.date, 'HH:mm')}`;
        }
    },
    summarizeDeliveryAddress(deliveryAddress: Address) {
        if (!deliveryAddress) return '';
        let lines = [];
        if (deliveryAddress?.firstName || deliveryAddress?.lastName)
            lines.push((deliveryAddress?.firstName || '') + ' ' + (deliveryAddress?.lastName || ''));
        if (deliveryAddress?.cellphone)
            lines.push(deliveryAddress?.cellphone);
        if (deliveryAddress?.floor >= 0)
            lines.push(deliveryAddress.floor != 0 ? deliveryAddress.floor + '° piano' : i18n('Ground floor'));
        { // delivery at floor with lift/reception
            if (deliveryAddress?.lift && deliveryAddress?.reception)
                lines.push(i18n('With lift and reception'));
            else if (deliveryAddress?.lift)
                lines.push(i18n('With lift'));
            else if (deliveryAddress?.reception)
                lines.push(i18n('With reception'));
        }
        if (deliveryAddress?.building)
            lines.push(deliveryAddress?.building);
        if (deliveryAddress?.remarks)
            lines.push('“' + deliveryAddress?.remarks + '”');
        return lines.join('<br>');
    },
    summarizePdvAddress(pointOfService: PointOfService) {
        if (!pointOfService) return '';
        const storeType: string = pointOfService?.storeType;
        const formattedAddress: string = pointOfService?.address?.formattedAddress;
        let lines = [];
        if (storeType)
            lines.push(storeType);
        if (formattedAddress)
            lines.push(formattedAddress);
        return lines.join('<br>');
    },
    modifiableOrder(order: Order) {
        if (!order) return false;
        return !order?.amendExpired && order?.status == 'CONFIRMED';
    },
    changeDayAndTimeslotEnabled(order: Order) {
        if (!order) return false;
        return this.modifiableOrder(order) && order?.timeSlot?.pointOfService?.type != 'LOCKER';
    },
    addRemoveProductsEnabled(order: Order) {
        if (!order) return false;
        return this.modifiableOrder(order) && order?.timeSlot?.pointOfService?.type != 'LOCKER';
    },
    reorderable(order: Order) {
        if (!order) return false;
        return order?.status == 'DELIVERED_PICKED' || order?.status == 'DELIVERY_FAILED' || order?.status == 'NOT_DELIVERED' ||
            order?.status == 'CANCELLED_BY_CP' || order?.status == 'CANCELLED_BY_OMS' || order?.status == 'CANCELLED_BY_USER' ||
            order?.status == 'CANCELLED_BY_CUSTOMER_CARE';
    },
    getBadge(order: Order) {
        if (!order || !order?.status) return { label: '', description: '' };
        if (order.status == 'DELIVERED_PICKED')
            return order?.serviceType == 'HOME_DELIVERY' ? ORDER_STATUS['DELIVERED_PICKED_HD'] : ORDER_STATUS['DELIVERED_PICKED_OC'];
        return ORDER_STATUS[order.status];
    },
    actionAddRemoveProducts(order: Order) {
        if (!order) return;
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_ORDERS_MANAGE;
            ecommerceData.funnel.stepFunnel = 'Aggiungi';
            return ecommerceData;
        })());
        window.cFlowManager.startAsyncFlow({
            name: 'orders-modify-flow',
            steps: [{
                name: 'orders-modify',
                toContinue: async () => {
                    const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                    this.$store.showGlobalLoader = true;
                    try {
                        await this._deleteCart();
                        await this._addCartFromOrder();
                        flowStore.data.result = { state: 'confirmed' };
                        window.cFlowManager.complete();

                        // redirect
                        if (this.$store.urlSite?.detailCart) {
                            window.location.href = `${this.$store.urlSite?.detailCart}`;
                        } else {
                            this.$dispatch('toast', { id: 'code-error', customText: i18n('urlSite.detailCart not found: redirect failed') });
                            console.error('urlSite.detailCart not fount: redirect failed');
                        }
                    } catch (e) {
                        console.error(e);
                        flowStore.data.result = { state: 'errors' };
                        window.cFlowManager.complete();
                    } finally {
                        this.$store.showGlobalLoader = false;
                    }
                },
                toGoBack: async () => {
                    const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                    flowStore.data.result = { state: 'cancelled' };
                    window.cFlowManager.complete();
                }
            }],
            initialData: {
                formatLabels: {
                    textArgs: []
                }
            }
        });
    },
    actionModifyDaytime(order: Order) {
        if (!order) return;
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_ORDERS_MANAGE;
            ecommerceData.funnel.stepFunnel = 'Modifica';
            return ecommerceData;
        })());
        window.cFlowManager.startAsyncFlow({
            name: 'orders-modify-flow',
            steps: [{
                name: 'orders-modify',
                toContinue: async () => {
                    const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                    this.$store.showGlobalLoader = true;
                    try {
                        await this._deleteCart();
                        await this._addCartFromOrder();
                        flowStore.data.result = { state: 'confirmed' };
                        window.cFlowManager.complete();

                        // redirect
                        if (this.$store.urlSite?.checkoutRecognized) {
                            window.location.href = renderHrefWithAdditionalParams(
                                `${this.$store.urlSite?.checkoutRecognized}`, { 'oldTimeSlotId': this.order.timeSlot?.slotId });
                        } else {
                            this.$dispatch('toast', { id: 'code-error', customText: i18n('urlSite.detailCart not fount: redirect failed') });
                            console.error('urlSite.detailCart not fount: redirect failed');
                        }
                    } catch (e) {
                        console.error(e);
                        flowStore.data.result = { state: 'errors' };
                        window.cFlowManager.complete();
                    } finally {
                        this.$store.showGlobalLoader = false;
                    }
                },
                toGoBack: async () => {
                    const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                    flowStore.data.result = { state: 'cancelled' };
                    window.cFlowManager.complete();
                }
            }],
            initialData: {
                formatLabels: {
                    textArgs: []
                }
            }
        });
    },
    actionCancelOrder(order: Order) {
        if (!order) return;
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_ORDERS_MANAGE;
            ecommerceData.funnel.stepFunnel = 'Annulla';
            return ecommerceData;
        })());
        window.cFlowManager.startAsyncFlow({
            name: 'orders-delete-flow',
            steps: [{
                name: 'orders-delete',
                toContinue: async () => {
                    const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                    this.$store.showGlobalLoader = true;
                    try {
                        await this._cancelOrder();
                        flowStore.data.result = { state: 'confirmed' };
                        window.cFlowManager.complete();

                        // reload page
                        setTimeout(() => {
                            location.reload();
                        }, 200);
                    } catch (e) {
                        console.error(e);
                        flowStore.data.result = { state: 'errors' };
                        window.cFlowManager.complete();
                    } finally {
                        this.$store.showGlobalLoader = false;
                    }
                },
                toGoBack: async () => {
                    const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                    flowStore.data.result = { state: 'cancelled' };
                    window.cFlowManager.complete();
                }
            }],
            initialData: {
                formatLabels: {
                    textArgs: []
                }
            }
        });
    },
    repeatOrder(order: Order) {
        if (!order) return;
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_ORDERS_MANAGE;
            ecommerceData.funnel.stepFunnel = 'Ordina di nuovo';
            return ecommerceData;
        })());
        window.cFlowManager.startAsyncFlow({
            name: 'orders-repeat-flow',
            steps: [{
                name: 'orders-repeat',
                toContinue: async () => {
                    const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                    this.$store.showGlobalLoader = true;
                    try {
                        await this._deleteCart();
                        await this._addCartFromOrder(true);
                        flowStore.data.result = { state: 'confirmed' };
                        window.cFlowManager.complete();

                        // redirect
                        if (this.$store.urlSite?.detailCart) {
                            window.location.href = `${this.$store.urlSite?.detailCart}`;
                        } else {
                            this.$dispatch('toast', { id: 'code-error', customText: i18n('urlSite.detailCart not fount: redirect failed') });
                            console.error('urlSite.detailCart not fount: redirect failed');
                        }
                    } catch (e) {
                        console.error(e);
                        const errorBody: ErrorBody = e.body;
                        if ((errorBody?.message?.indexOf("No PointOfServce and Delivery Area available") > -1) ||
                            (errorBody?.message?.indexOf("Address not covered by deliveryArea") > -1)) {
                            flowStore.data.alternativeId = TypeOfService.HOME_DELIVERY;
                            window.cFlowManager.next('orders-repeat-errors');
                        } else if (errorBody?.message?.indexOf("PointOfService not available") > -1) {
                            flowStore.data.alternativeId = TypeOfService.ORDER_AND_COLLECT;
                            window.cFlowManager.next('orders-repeat-errors');
                        } else {
                            flowStore.data.result = { state: 'errors' };
                            window.cFlowManager.complete();
                        }
                    } finally {
                        this.$store.showGlobalLoader = false;
                    }
                },
                toGoBack: async () => {
                    const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                    flowStore.data.result = { state: 'cancelled' };
                    window.cFlowManager.complete();
                }
            },
            {
                name: 'orders-repeat-errors'
            }],
            initialData: {
                formatLabels: {
                    textArgs: []
                }
            }
        });
    },
}));