import { EcommerceData, UserInfo } from './analytics-interface';
import { trackingDictionary } from './analytics-dictionary';
import { Window, _$ } from '../../main';
import { LocalStorage } from '../local-storage/local-storage';
import * as WishlistUtils from '../../libs/wishlists-utils';
import { getNomeFunnelFromPage } from '../../libs/tracking-utils';

export class Analytics {
    constructor(private localStorage: LocalStorage) {}

    /**
     * Creates analytics data
     * @param ecommerceData
     * @param analyticsType
     * @param useUserData
     * @returns analytics data
     */
    private _createAnalyticsData(ecommerceData: EcommerceData, analyticsType: string, needFunnelDict: boolean, needSlot: boolean, dataPromo?: any): EcommerceData {
        let analytics: EcommerceData = ecommerceData;
        if (needFunnelDict) {
            let analyticsFunnelDict = { ...trackingDictionary[analyticsType] };
            if (analyticsFunnelDict?.funnel?.nomeFunnel === '<current>') {
                analyticsFunnelDict.funnel.nomeFunnel = getNomeFunnelFromPage();
            }
            analytics.funnel = { ...analyticsFunnelDict.funnel, ...analytics.funnel };
        }
        if(window['user']) {
            analytics.userInfo = this._createUserInfo();
        }
        if (needSlot) {
            analytics.funnel = { ...this._createSlotInfo(), ...analytics.funnel};
            analytics.funnel = { ...this._createServiceInfo(), ...analytics.funnel};
            analytics.funnel = { ...this._createStoreInfo(), ...analytics.funnel};
        }
        if (dataPromo) {
            if(Array.isArray(dataPromo)) {
                dataPromo.forEach((dataPromoItem, index) => {
                    dataPromoItem['idPromo'] ? analytics["sponsor"][index]['ID_promo'] = dataPromoItem['idPromo'] : null;
                    dataPromoItem['impattoPromo'] ? analytics["sponsor"][index]['Impatto_promo'] = dataPromoItem['impattoPromo'] : null;
                    dataPromoItem['qualifyingBrand'] ? analytics["sponsor"][index]['Qualifying_Brand'] = dataPromoItem['qualifyingBrand'] : null;
                    dataPromoItem['qualifyingLines'] ? analytics["sponsor"][index]['Qualifying_Lines'] = dataPromoItem['qualifyingLines'] : null;
                    dataPromoItem['includedCategory'] ? analytics["sponsor"][index]['Included_Category'] = dataPromoItem['includedCategory'] : null;
                    dataPromoItem['productList'] ? analytics["sponsor"][index]['Included_Product'] = dataPromoItem['productList'] : null;
                });
            } else {
                dataPromo['idPromo'] ? analytics["sponsor"][0]['ID_promo'] = dataPromo['idPromo'] : null;
                dataPromo['impattoPromo'] ? analytics["sponsor"][0]['Impatto_promo'] = dataPromo['impattoPromo'] : null;
                dataPromo['qualifyingBrand'] ? analytics["sponsor"][0]['Qualifying_Brand'] = dataPromo['qualifyingBrand'] : null;
                dataPromo['qualifyingLines'] ? analytics["sponsor"][0]['Qualifying_Lines'] = dataPromo['qualifyingLines'] : null;
                dataPromo['includedCategory'] ? analytics["sponsor"][0]['Included_Category'] = dataPromo['includedCategory'] : null;
                dataPromo['productList'] ? analytics["sponsor"][0]['Included_Product'] = dataPromo['productList'] : null;
            }
        }  

        if(this.localStorage.getFromStorage('flagFirstAddToCart') && analytics.funnel['creazioneCarrello'] == ''){
            analytics.funnel['creazioneCarrello'] = this.localStorage.getFromStorage('flagFirstAddToCart');
            
        }

        /* Lettura IDPromoLanding */
        if(Window.IDPromoLanding != null && Window.IDPromoLanding != ''){
            analytics.funnel['IDPromoLanding'] = Window.IDPromoLanding;
        }

        return analytics;
    }

    private _createSlotInfo() {
        let giorno = '';
        let giornoProposto = '';
        let fasciaOraria = '';
        let tsd = false;

        if (window['selectedTimeslotExpiration']) {
            giorno = window['selectedTimeslot'] ? this.getDayFromTimestamp(window['selectedTimeslot'].date).toString() : '';
            giornoProposto = window['pointOfService'] && window['pointOfService'].firstAvailableTimeSlot && window['selectedTimeslot'] ?
            (window['pointOfService'].firstAvailableTimeSlot.date == window['selectedTimeslot'].date ? 'SI' : 'NO') : '';
            fasciaOraria = window['selectedTimeslot'] ? window['selectedTimeslot'].startTime.formattedHour + ' - ' + window['selectedTimeslot'].endTime.formattedHour : '';
        }

        let sceltaSlot = {
            sceltaSlot : {
                giorno,
                giornoProposto,
                fasciaOraria
            }
        };
        return sceltaSlot;
    }

    private _createServiceInfo() {
        let tipoServizio = { 
            tipoServizio: window['typeOfService'] ? (window['typeOfService'] == 'HOME_DELIVERY' ? 'Ordina e ricevi' : 'Ordina e ritira') : ''
        };

        return tipoServizio;
    }

    private _createStoreInfo() {
        let sceltaNegozio = {
            sceltaNegozio : {
                idNegozio: window['pointOfService'] ? window['pointOfService'].name : '',
                cooperativaNegozio: window['pointOfService'] ? window['pointOfService'].cooperativeId : '',
                disponibilitaNegozio: window['typeOfService'] && window['pointOfService'] ?
                    (window['typeOfService'] == 'HOME_DELIVERY' ?
                        (window['pointOfService'].hdTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita')
                        :
                        (window['pointOfService'].ocTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita'))
                : ''
            }
        };

        return sceltaNegozio;
    }

    /**
     * Creates user info
     * @returns user info
     */
     private _createUserInfo(): UserInfo {
        const user = window['user'];
        let contatto = window['user'].canaliDiContatto ? window['user'].canaliDiContatto : null;
        let canaliDiContatto = {};
        if (contatto) {
            for (let i = 0; i < contatto.length; i++) {
                canaliDiContatto[contatto[i].codice] = contatto[i].consenso;
            }
        }
        const userInfo: UserInfo = {
            abitudiniAlimentariFamiglia: user.abitudiniAlimentariFamiglia ? user.abitudiniAlimentariFamiglia : '',
            accessoMinisito: user.accessoMinisito ? user.accessoMinisito : '',
            acquistiOnline: user.acquistiOnline ? user.acquistiOnline : '',
            animaliDomestici: user.animaliDomestici ? user.animaliDomestici : '',
            canaliDiContatto,
            cap: user.cap ? user.cap : '',
            cardistaAnteCPlus: user.cardistaAnteCPlus ? user.cardistaAnteCPlus : '',
            cartaDematerializzata: user.cartaDematerializzata ? user.cartaDematerializzata : '',
            cartaFedelta: user.cartaFedelta ? user.cartaFedelta : '',
            citta: user.citta ? user.citta : '',
            codiceCitta: user.codiceCitta ? user.codiceCitta : '',
            codiceComuneDiNascita: user.codiceComuneDiNascita ? user.codiceComuneDiNascita : '',
            codiceCooperativa: user.codiceCooperativa ? user.codiceCooperativa : '',
            codiceNazione: user.codiceNazione ? user.codiceNazione : '',
            codiceProvincia: user.codiceProvincia ? user.codiceProvincia : '',
            comuneNascita: user.comuneNascita ? user.comuneNascita : '',
            conadCard: user.conadCard ? user.conadCard : '',
            dataNascita: user.dataNascita ? user.dataNascita : '',
            dataNascitaConiuge: user.dataNascitaConiuge ? user.dataNascitaConiuge : '',
            dataNascitaFiglio1: user.dataNascitaFiglio1 ? user.dataNascitaFiglio1 : '',
            dataNascitaFiglio2: user.dataNascitaFiglio2 ? user.dataNascitaFiglio2 : '',
            dataNascitaFiglio3: user.dataNascitaFiglio3 ? user.dataNascitaFiglio3 : '',
            email: user.email ? user.email : '',
            hobbyFamiglia: user.hobbyFamiglia ? user.hobbyFamiglia : '',
            negozioPreferito: user.negozioPreferito ? user.negozioPreferito : '',
            negozioPreferitoCooperativa: user.negozioPreferitoCooperativa ? user.negozioPreferitoCooperativa : '',
            numeroComponentiNucleoFamiliare: user.numeroComponentiNucleoFamiliare ? user.numeroComponentiNucleoFamiliare : '',
            privacy1: user.privacy1 ? user.privacy1 : '',
            privacy2: user.privacy2 ? user.privacy2 : '',
            privacy3: user.privacy3 ? user.privacy3 : '',
            provenienza: user.provenienza ? user.provenienza : '',
            saldoPunti: user.saldoPunti ? user.saldoPunti : '',
            saldoPuntiCollectionPrecedente: user.saldoPuntiCollectionPrecedente ? user.saldoPuntiCollectionPrecedente : '',
            sesso: user.sesso ? user.sesso : '',
            socialRegistration: user.socialRegistration ? user.socialRegistration : '',
            stato: user.stato ? user.stato : '',
            totCoupons: user.totCoupons ? user.totCoupons : '',
            unreadMessages: user.unreadMessages ? user.unreadMessages : '',
            utenzaId: user.utenzaId ? user.utenzaId : ''
        };
        return userInfo;
    }

    /**
     * Sends analytics
     * @param ecommerceData
     * @param analyticsType
     * @param useUserData
     */
    sendAnalytics(ecommerceData: EcommerceData, analyticsType: string, needFunnelDict = true, needSlot = true, dataPromo?: any) {
        const analytics = this._createAnalyticsData(ecommerceData, analyticsType, needFunnelDict, needSlot, dataPromo);
        if (Window.adobeDataLayer) {
            console.log('tracciamento: ', analytics);
            Window.adobeDataLayer.push(analytics);
        } else if (location?.hostname === 'localhost') {
            console.log('local env tracking preview: ', analytics);
        }
        
    }

    //recupera la prenotazione dell'utente dall'oggetto Window.selectedTimeslot
    manageSceltaSlot() {
        let slotOrario = {};
        if (Window.selectedTimeslot && Window.selectedTimeslot.startTime && Window.selectedTimeslot.endTime) {
            let giorno = Window.selectedTimeslot.date;
            let fascia =
                Window.selectedTimeslot.startTime.formattedHour + '-' + Window.selectedTimeslot.endTime.formattedHour;
            slotOrario = {
                giorno: giorno,
                fasciaOraria: fascia,
            };
        }
        return slotOrario;
    }

    getDayFromTimestamp(timestamp){
        var date = new Date(timestamp);
        return date;
    }

    getProvenienza(page : String) {
        let provenienza = '';
        /* page = '/' + page; */
        switch (page) {
            case window['urlSite'].entry.split('/')[1]:
                provenienza = 'EntryPage';
            break;
            case window['urlSite'].home.split('/')[1]:
                provenienza = 'HomePage';
            break;
            case window['urlSite'].search.split('/')[1]:
                provenienza = 'Search';
            break;
            // case window['urlSite'].cart:
            //     provenienza = 'Cart';
            // break;
            case window['urlSite'].detailCart.split('/')[1]:
                provenienza = 'Detail Cart';
            break;
            case window['urlSite'].checkout.split('/')[1]:
                provenienza = 'Checkout';
            break;
            case window['urlSite'].detail.split('/')[1]:
                provenienza = 'Scheda prodotto';
            break;
            case window['urlSite'].detailCart.split('/')[1]:
                provenienza = 'Detail Cart';
            break;
            default:
                provenienza = 'EntryPage';
            break;
        }
        return provenienza;
    }

    /* Recupera la ricerca effettuata solo se presente nella querystring della url */
    manageProductSearch(page,eventType,queryString) {
        let ricerca;
        let numResults = '';

        if (page == 'search' || page == 'listing') {
            let results = _$('.results-info').children('.results').html();
            
            if (results) {
                numResults = results.split(' ')[0];
            }
            if (['pageview','categoriaConsigliata','applicaFiltri'].indexOf(eventType) > -1) {
                ricerca = {
                    query: queryString ? queryString : '',
                    numeroRisultati: numResults,
                };
            } else if (eventType == 'marchioCompare') {
                ricerca = {
                    query: _$("#search_d").val(),
                    numeroRisultati: numResults,
                };
            }
        } else {
            ricerca = {
                query: queryString ? queryString : '',
                numeroRisultati: numResults,
            };
        }
        
        return ricerca;
    }

    async getProductData(dataProduct, detailProduct = false, eventType = '', variante?, singlePrice?){
        let product;

        if (dataProduct.attr('data-product')) {
            let data = JSON.parse(dataProduct.attr('data-product'));
            let promo = data.promo;
            let cardista = '';
            let bolliniExtra = 0;
            let puntiExtra = 0;
            let tipo = [];
            let dettaglioPromozioni = [];

            if (promo && promo.length) {
                for (var i = 0; i < promo.length; i++) {
                    tipo.push(promo[i]['promoType']);
                }
                cardista = promo[0]['customerType'] ? promo[0]['customerType'] : '';
                bolliniExtra = promo[0]['bolliniExtra'] ? promo[0]['bolliniExtra'] : 0;
                puntiExtra = promo[0]['puntiExtra'] ? promo[0]['puntiExtra'] : 0;

                let promoBadgeCard;
                if (detailProduct) {
                    promoBadgeCard = dataProduct.find('.product-data-container .component-PromoBadgeCard');
                } else if (dataProduct.hasClass('component-ProductCardDetailCart')) {
                    promoBadgeCard = dataProduct.find('.category-desktop-product .component-PromoBadgeCard');
                } else {
                    promoBadgeCard = dataProduct.find('.component-PromoBadgeCard');
                }
                if (promoBadgeCard.length) {
                    for (var i = 0; i < promoBadgeCard.length; i++) {
                        dettaglioPromozioni.push(promoBadgeCard.eq(i).find('.badge-text').text() ? promoBadgeCard.eq(i).find('.badge-text').text().trim() : '');
                    }
                }
            }

            product = {
                codiceVariante: '',
                codiceConart: data.code ? data.code : '',
                ID_promo: data.customPromoSponsor ? data.customPromoSponsor : [],                
                marchio: data.marchio? data.marchio : (data.brand ? data.brand : ''),
                sottomarchio: data.sottomarchio ? data.sottomarchio : '',
                preferito: data?.code && (await WishlistUtils.isWishListProduct(data?.code)) ? 'SI' : 'NO',
                ean: data.ean ? data.ean : '',
                categoriaPrimoLivello: data.categoriaPrimoLivello ? data.categoriaPrimoLivello : '',
                categoriaSecondoLivello: data.categoriaSecondoLivello ? data.categoriaSecondoLivello : '',
                categoriaTerzoLivello: data.categoriaTerzoLivello ? data.categoriaTerzoLivello : '',
                nome: data.nome ? data.nome : (data.title ? data.title : ''),
                prezzo: singlePrice ? singlePrice : this.getPriceData(dataProduct, detailProduct, eventType),
                quantita: this.getQuantityData(dataProduct, detailProduct, eventType),
                variante: this.getVarianteData(dataProduct, eventType, variante),
                promozione: {
                    bassiFissi: data.bassiFissi ? (data.bassiFissi == true ? 'SI' : 'NO') : 'NO',
                    tipo,
                    dettaglio: dettaglioPromozioni
                },
                cardista, 
                bolliniExtra, 
                puntiExtra, 
                pesante: data.pesante ? (data.pesante == true ? 'SI' : 'NO') : (data.volume ? 'SI' : 'NO'),
                categoriaMarketing: detailProduct   ?
                        /* detail product page */       (_$(dataProduct).find('.product-data-container .component-BadgeCard .text').length ? _$(dataProduct).find('.product-data-container .component-BadgeCard .text').html().trim() : '')
                                                    :
                        /* product card */              (_$(dataProduct).find('.component-BadgeCard').length ? _$(dataProduct).find('.component-BadgeCard .text').html().trim() : ''),
                notePreparazione: {
                    nota: ''
                }
            };
        } else {
            product = '';
        }
        return product;
    }

    getQuantityData(obj, isDetailProduct, event) {
        let quantita;
        let dataProduct = JSON.parse(obj.attr('data-product'));
        if (event == 'aggiungiProdotto' || event == 'rimuoviProdotto' || event == 'prodottoPreferito' || event == 'dettaglioProdotto' ||
            event == 'aggiungiDaCarosello' || event == 'rimuoviDaCarosello' || event == 'preferitoDaCarosello' || event == 'dettaglioDaCarosello') {
            quantita = 1;
        } else {
            if (obj.attr('data-quantity')) {
                quantita = parseInt(obj.attr('data-quantity'));
            } else if (obj.closest('.header-mini-cart').length) {
                quantita = dataProduct['entries'][0]['qty'];
            } else {
                quantita = 1;
            }
        }
        return quantita;
    }

    getPriceData(obj, isDetailProduct, event) {
        let prezzo;

        if (isDetailProduct) {
            prezzo = obj.find('.product-price .old').length ?
            /* prezzo scontato */ obj.find('.product-price .old').siblings('span').eq(0) :
            /* prezzo intero */ obj.find('.product-price div:first-child span');
        } else if (obj.closest('.component-ProductCard').length) {

            if (event != '') {

                prezzo = obj.find('.product-info .product-price-red').length ?
                    /* prezzo scontato */ obj.find('.product-info .product-price-red') :
                    /* prezzo intero */ obj.find('.product-info .product-price');

            } else {

                prezzo = obj.find('.add-quantity').hasClass('uk-hidden') ?
                    (
                    /* prezzo singolo */ obj.find('.product-info .product-price-red').length ?
                        /* prezzo scontato */ obj.find('.product-info .product-price-red') :
                        /* prezzo intero */ obj.find('.product-info .product-price')
                    ) :
                    /* prezzo cumulativo */ obj.find('.add-quantity .price') ;

            }
        } else if (obj.closest('.component-ProductCardLastView').length) {

            prezzo = obj.find('.price .red').length ?
                /* prezzo scontato */ obj.find('.price .red') :
                /* prezzo intero */ obj.find('.price');

        } else if (obj.closest('.component-ProductCardDetailCart').length) {

            prezzo = obj.find('.aggregate-price span').eq(0) ;

        } else if (obj.closest('.component-CardSuggest').length) {

            prezzo = obj.find('.price-container .price-off').length ?
                /* prezzo scontato */ obj.find('.price-container .price-off') :
                /* prezzo intero */ obj.find('.price-container .price-full');

        } else if (obj.closest('.uk-card-type-product-minicart').length){

            prezzo = obj.find('.price span') ;

        } else if (obj.closest('.dot-card').length) {
            prezzo = obj.find('.price');
        }

        return (typeof prezzo == "number") ? prezzo : (prezzo ? prezzo.text().trim().replace('€','').replace(' ','').replace(',','.') : '');
    }

    getVarianteData(obj, event, variante) {
        let dataProduct = JSON.parse(obj.attr('data-product'));
        let v;
        let unit = "";
        if (dataProduct.increment) {
            unit = dataProduct.increment.unitOfMeasure.toString().toLowerCase();
        } else if (dataProduct.variations) {
            unit = dataProduct.variations.unitOfMeasure.toString().toLowerCase();
        }

        if (event == 'aggiungiProdotto' || event == 'rimuoviProdotto' ||
            event == 'aggiungiDaCarosello' || event == 'rimuoviDaCarosello') {
            if (variante >= 0) {//increment o variations
                v = variante.toString() + " " + unit;
            } else {//base o heavy
                v = dataProduct.entries && dataProduct.entries.length ?
            /* minicart */    (dataProduct.entries[0]['scale'] ? dataProduct.entries[0]['scale'].toString() + " " + unit : '')
                            : (dataProduct.variante ? dataProduct.variante.toString() + " " + unit : '');
            }
        } else if (event == 'applicaPezzatura') {
            v = variante.toString().split(".")[0] + " " + unit;
        }
        else {
            if (obj.hasClass('uk-card-type-product-minicart')) {//minicart
                v = dataProduct.entries && dataProduct.entries.length ?
                (dataProduct.entries[0]['scale'] ? dataProduct.entries[0]['scale'].toString().split("-")[0] + " " + unit : '') : '';
            } else {
                v = dataProduct.variante ? dataProduct.variante.toString().split("-")[0] + " " + unit : '';
            }
        }

        return v;
    }

    getIDpromozioni() {
        let IDpromozioni = [];
        if (Window.cart) {
            if (Window.cart.appliedOrderPromotions) {
                let appliedOrderPromotions = this.getPromotions(Window.cart.appliedOrderPromotions);
                if (Window.cart.appliedProductPromotions) {
                    let appliedProductPromotions = this.getPromotions(Window.cart.appliedProductPromotions);

                    IDpromozioni = appliedOrderPromotions.concat(appliedProductPromotions);
                } else {
                    IDpromozioni = this.getPromotions(Window.cart.appliedOrderPromotions);
                }
            } else if (Window.cart.appliedProductPromotions) {
                IDpromozioni = this.getPromotions(Window.cart.appliedProductPromotions);
            }
            this.localStorage.setItem('IDpromozioni', JSON.stringify(IDpromozioni));
        } else {
            if (this.localStorage.getFromStorage('IDpromozioni')) {
                IDpromozioni = this.localStorage.getFromStorage('IDpromozioni');
            }
        }
        return IDpromozioni; 
    }

    getPromotions(promotions) {
        let promo = [];
        promotions.forEach(item => {
            if (item.promotion && item.promotion.code) {
                promo.push(item.promotion.code);
            }
        });
        return promo;
    }

    addCampiTsdDinamicoWindow(analyticsData): object{
        let data = analyticsData;

        if(Window.selectedTimeslot && Window.selectedTimeslot.tsd){
            data.sceltaSlot['slotDinamico'] = Window.selectedTimeslot.tipologiaFascia;
            data.sceltaSlot['slotDinamicoScontato'] = Window.selectedTimeslot.deliveryCost != null && Window.selectedTimeslot.discountedDeliveryCost != null 
                                                                && Window.selectedTimeslot.deliveryCost > Window.selectedTimeslot.discountedDeliveryCost ? 'SI' : 'NO';
        }
        return data;
    }
}
