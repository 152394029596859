import Swiper, { SwiperOptions } from 'swiper';
import { _Uikit, Window, _$ } from '../../main';
import { LOADER_POSITION, Utils } from '../../services/utils/utils';
import { PageUpdateService  } from '../../services/page-update-service/page-update-service';

export class AreaUtente {  
    public swiperUtente;
    constructor(
        private _utils: Utils,
        private _pageUpdateService : PageUpdateService
    ) { 
    }
    
    nkOnInit() { 
        this.fetchCarousels();
    }   

    swiperAreaUtenteOptions: SwiperOptions = {
        observer: true,
        observeParents: true,
        spaceBetween: 0,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-utente',
            draggable: true,
            hide: false,
        },
        /* navigation: {
            nextEl: '.next-utente',
            prevEl: '.prev-utente',
        }, */
        pagination: {
            el: '.pagination-utente',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let slideView;
                if (_$(window).width() < 768) {
                    slideView = swiper.params.breakpoints['375'].slidesPerView;
                } else if (_$(window).width() < 1024){
                    slideView = swiper.params.breakpoints['768'].slidesPerView;
                } else if (_$(window).width() < 1440){
                    slideView = swiper.params.breakpoints['1024'].slidesPerView;
                } else {
                    slideView = swiper.params.breakpoints['1440'].slidesPerView;
                }
                return Math.ceil((current+slideView-1)/slideView) + ' di ' + Math.ceil((total+slideView-1)/slideView);
            },
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 0,
                /* slidesPerGroup: 2, */
                slidesPerView: 2
            },
            768: {
                spaceBetween: 0,
                /* slidesPerGroup: 4, */
                slidesPerView: 4
            },
            1024: {
                spaceBetween: 0,
                /* slidesPerGroup: 6, */
                slidesPerView: 6
            },
            1440: {
                spaceBetween: 0,
                /* slidesPerGroup: 8, */
                slidesPerView: 8
            },
        },
    };

    mySlideNext(item) {
        this._utils.mySlideNext(this.swiperAreaUtenteOptions, _$(item).closest('.swiper-utente')[0].swiper);
    }

    mySlidePrev(item) {
        this._utils.mySlidePrev(this.swiperAreaUtenteOptions, _$(item).closest('.swiper-utente')[0].swiper);
    }

    getActiveSlider(): number{
        let activeIndex = _$('.component-AreaUtente .swiper-button-utente').find('li').index(_$('.component-AreaUtente .swiper-button-utente').find('li.uk-active'));
        return activeIndex;
    }

    initializeCarousel(){
        this.swiperUtente = new Swiper('.swiper-utente', this.swiperAreaUtenteOptions);
        if(_$('.swiper-utente').length){
            _$('.swiper-utente').each((index, element) => {
                let swiperClass = 'swiper-utente-' + index;
                _$('.swiper-utente').eq(index).addClass(swiperClass);
                _$('.swiper-utente').eq(index).find('.next-utente').attr('onclick','AreaUtente.mySlideNext(this)');
                _$('.swiper-utente').eq(index).find('.prev-utente').attr('onclick','AreaUtente.mySlidePrev(this)');   
                if(_$(element).find('.swiper-slide').length){
                    var swiperAreaUtente = new Swiper('.swiper-utente-'+index , this.swiperAreaUtenteOptions);
                }
            })
        }
    }

    fetchCarousels(){
        if(Window.user && _$('.component-AreaUtente').attr('data-cards-endpoint') != null && _$('.component-AreaUtente').attr('data-cards-endpoint') != ''){
            let link = _$('.component-AreaUtente').attr('data-cards-endpoint');
            this._utils.showLoader(_$('#switcher-area-utente'), true, LOADER_POSITION.USER_DASHBOARD);

            let success = (res) => {
                res = res.replace(/>\s+</g,'><');

                if(_$(res).length){
                    for(let i = _$(res).children('div').length - 1; i >= 0; i--){
                        _$('#switcher-area-utente').prepend(_$(res).eq(i));
                    }
                    _$('#switcher-area-utente').children().first().addClass('uk-active');

                    if(_$('#switcher-area-utente .swiper-utente').length){
                        this.initializeCarousel();
                        this._utils.checkCartStatus();
                    }
                }

                this._utils.showLoader(_$('#switcher-area-utente'), false, LOADER_POSITION.USER_DASHBOARD);
            }

            let error = (err) => {
                console.log('error loading user dashboard');
                this._utils.showLoader(_$('#switcher-area-utente'), false, LOADER_POSITION.USER_DASHBOARD);
            }

            this._pageUpdateService.getPageUpdate(success, error, link);
        }
    }
 }