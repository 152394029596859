import { _Uikit, Window, _$ } from '../../main';
export class BannerSponsor {
   
   constructor() { }
   
   nkOnInit() { 
   }

   // redirectForMobile(e: object){
   //    if(_$(window).width() <= 1023){
   //       window.location.href = _$(e).find('.link-banner-sponsor a').attr('href');
   //    }
   // }
}