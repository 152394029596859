import { _$ } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
import { Utils } from '../../services/utils/utils';
import Swiper, { SwiperOptions } from 'swiper';
import { GoogleUtils } from '../../services/utils/google-utils';
import { BANNER_TYPES, AnalyticsUtility } from '../../services/analytics/analytics-utility';

export class Listinglevel1 {

    public _categoryFirstLevel;
    private pageOffers = false;

    listingPageSwiperOffersOption: SwiperOptions = {
        slidesPerView: 1.15,
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            hide: false,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpointsInverse: true,
        breakpoints: {
            600: {
                slidesPerView: 2.3,
                spaceBetween: 15
            },
            1024:{
                slidesPerView: 2.49,
                spaceBetween: 16
            },
            1440: {
                slidesPerView: 2.49,
                spaceBetween: 24
            }
        },
        on : {
            transitionStart: () => { 
                this._utils.draggedByScrollbar = true;
            },
            transitionEnd: () => {
                this._utils.draggedByScrollbar = false;
            }
        }
    }
    listingPageSwiperOffers = new Swiper('.listing-swiper-offers', this.listingPageSwiperOffersOption)


    listingPageSwiperCategoryOption: SwiperOptions = {
        slidesPerView: 1.15,
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            hide: false,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpointsInverse: true,
        breakpoints: {
            500:{
                slidesPerView: 2.30,
                spaceBetween: 15
            },
            768:{
                slidesPerView: 2.50,
                spaceBetween: 15
            }
        },
        on : {
            transitionStart: () => { 
                this._utils.draggedByScrollbar = true;
            },
            transitionEnd: () => {
                this._utils.draggedByScrollbar = false;
            }
        }
    }
    listingPageSwiperCategory = new Swiper('.listing-swiper-category', this.listingPageSwiperCategoryOption)
    

    constructor(
        private _analytics: Analytics,
        private _utils: Utils,
        private _google: GoogleUtils,
        private _analyticsUtility: AnalyticsUtility
    ) { }
   
    nkOnInit() {
        this.checkBreadcrumb();
        if (_$('.listing-header .header-title').length) {
            this._categoryFirstLevel = _$('.listing-header .header-title').html().toString();
        }
        this.listingPageSwiperOffers;
        this.listingPageSwiperCategory;

        if (_$("body").hasClass('Listinglevel1Offers')) {
            this.sendAnalytics('pageview-Listinglevel1Offers', this);
            this.pageOffers = true;
        } else {
            this.sendAnalytics('pageview', this);
        }

        this.setBannersClickEvents();
    }   

    checkBreadcrumb() {
        let breadcrumbs = _$('.component-Breadcrumb');
        
        if (breadcrumbs.length > 1) {
            breadcrumbs.addClass('uk-hidden');
            breadcrumbs.eq(0).removeClass('uk-hidden');
        } 
    }

    async sendAnalytics(eventType: string, e: object){
        try {
            let analyticsData;
            let datapromoArray = [];
            let clickEvent = false;
            

            if (eventType == 'pageview') {
                console.log('pageview');
                
                let sponsor = [];
                let prodotto = [];
                let productCard = _$('.component-ProductCard');

                for (var i = 0; i < productCard.length; i++) {
                    prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                }

                let productCardLastView = _$('.component-ProductCardLastView');
                for (var i = 0; i < productCardLastView.length; i++) {
                    prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))));
                }

                let cardSuggest = _$('.component-CardSuggest');
                for (var i = 0; i < cardSuggest.length; i++) {
                    prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                }

                if(_$('.component-BannerSponsor').length){
                    let bannerSponsor = _$('.component-BannerSponsor');
                    for(let i = 0; i < _$(bannerSponsor).length; i++){
                        sponsor.push(
                            {
                                brand: '',
                                campagna: _$(bannerSponsor).eq(i).find('.banner-title').length ? _$(bannerSponsor).eq(i).find('.banner-title').html() : '',
                                formato: 'Banner BTF',
                                landing: _$(bannerSponsor).eq(i).find('a').length ? _$(bannerSponsor).eq(i).find('a').attr('href') : '',
                                posizioneSlide: 0
                            }
                        )
                        if (_$(bannerSponsor).eq(i).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(bannerSponsor).eq(i).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                               
                    }
                }

                analyticsData = {
                    event: eventType,
                    IDpromozioni: this._analytics.getIDpromozioni(),
                    prodotto,
                    sponsor: sponsor ? sponsor : null
                }

            } else if (eventType == 'pageview-Listinglevel1Offers') {
                //6.6.1
                let sponsor = [];
                let banner;
                let slides;
                let slide;

                let bannerList = [BANNER_TYPES.PROMO_COMPONENT, 
                    BANNER_TYPES.STRIP_BTF, 
                    BANNER_TYPES.CARD_OFFERTA_BTF,
                    BANNER_TYPES.CARD_SHOP_IN_SHOP_BTF,
                    BANNER_TYPES.BANNER_SPONSOR,
                    BANNER_TYPES.PROMOTIONS_AND_VISIBILITIES,
                    BANNER_TYPES.CONAD_BRANDS,
                    BANNER_TYPES.HIGHLIGHTED_PRODUCTS,
                    BANNER_TYPES.ISPIRAZIONALE,
                    BANNER_TYPES.STORYTELLING_A,
                    BANNER_TYPES.STORYTELLING_B];

                bannerList.forEach((value) => {
                    [sponsor, datapromoArray] = this._analyticsUtility.getBannerPageview(value, sponsor, datapromoArray);
                });

                let prodotto = []
                let productCard = _$('.component-ProductCard');

                for (var i = 0; i < productCard.length; i++) {
                    prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                }

                let productCardLastView = _$('.component-ProductCardLastView');
                for (var i = 0; i < productCardLastView.length; i++) {
                    prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))));
                }

                let cardSuggest = _$('.component-CardSuggest');
                for (var i = 0; i < cardSuggest.length; i++) {
                    prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                }
                    
                analyticsData = {
                    event: 'pageview',
                    funnel: {
                        nomeFunnel: 'Offerte e promozioni',
                        stepFunnel: _$('.page-title').text()
                    },
                    sponsor,
                    prodotto
                };
            } else if(eventType == 'ctaScopriCategoria' || eventType == 'ctaEsploraCategoria'){
                let prodotto = []
                let productCard = _$('.component-ProductCard');

                for (var i = 0; i < productCard.length; i++) {
                    prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                }

                let productCardLastView = _$('.component-ProductCardLastView');
                for (var i = 0; i < productCardLastView.length; i++) {
                    prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))));
                }

                let cardSuggest = _$('.component-CardSuggest');
                for (var i = 0; i < cardSuggest.length; i++) {
                    prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                }

                analyticsData = {
                    event: eventType,
                    IDpromozioni: this._analytics.getIDpromozioni(),
                    prodotto
                }
            }else if(eventType == 'advClickBannerSponsor'){
                var slides = _$(e).closest('.swiper-banner-sponsor').find('.swiper-slide');
                var slidesDuplicate =  _$(e).closest('.swiper-banner-sponsor').find('.swiper-slide-duplicate').length;
                let posizioneSlide;
                if((slides.length - slidesDuplicate) > 1){
                    for (let i = 0; i < slides.length; i++) {
                        posizioneSlide = slides.eq(i).hasClass('swiper-slide-active') ? i : posizioneSlide;
                    }
                }
                analyticsData = {
                    event: 'clickAdv',
                    prodotto: {
                        carosello: _$(e).find('.text-container .banner-title').length ? _$(e).find('.text-container .banner-title').html() : ''
                    },
                    sponsor: [{
                        brand: '',
                        campagna: _$(e).find('.text-container .banner-title').length ? _$(e).find('.text-container .banner-title').html() : '',
                        formato: 'Banner BTF',
                        landing: _$(e).find('a').length ? _$(e).find('a').attr('href') : '',
                        posizioneSlide: posizioneSlide
                    }]
                }

                if (_$(e).attr('data-promo')) {
                    datapromoArray.push(JSON.parse(_$(e).attr('data-promo')));
                } else {
                    datapromoArray.push("");
                }
                let step = 'FUNNEL_DATA_LISTINGPAGE';
                this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                return;
            }else if(eventType == 'advClickPromotionsVisibilities'){
                clickEvent = true;
                analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.PROMOTIONS_AND_VISIBILITIES, analyticsData, e);
            }else if(eventType == 'advClickConadPartners'){
                clickEvent = true;
                analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.CARD_SHOP_IN_SHOP_BTF, analyticsData, e);
            }else {
                analyticsData = {
                    event: eventType,
                    prodotto: {
                        categoriaPrimoLivello: this._categoryFirstLevel ? this._categoryFirstLevel : '',
                        categoriaSecondoLivello: _$(e).closest('.section-what-offer').find('.section-title').html().toString()
                    }
                }
            }
            
            if(clickEvent){
                let dataPromo = _$(e).attr('data-promo') != null && _$(e).attr('data-promo') != '' ? JSON.parse(_$(e).attr('data-promo')) : '';
                this._analytics.sendAnalytics(analyticsData, this.pageOffers ? 'FUNNEL_DATA_OFFERS' : 'FUNNEL_DATA_LISTINGPAGE', true, true, dataPromo);
            }else if (eventType == 'pageview-Listinglevel1Offers') {
                if(datapromoArray.length){
                    this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_OFFERS', true, true, datapromoArray)
                }else{
                    this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_OFFERS');
                }
                
            } else if (_$(e).closest('.component-PromoIdm').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-PromoIdm').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_LISTINGPAGE', true, true, dataPromo)
            } else {
                if (datapromoArray.length) {
                    this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_LISTINGPAGE', true, true, datapromoArray);
                } else {
                    this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_LISTINGPAGE')
                }
            }
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    setBannersClickEvents(){
        _$('.component-BannerSponsor').on('click', (event) => {
            this.sendAnalytics('advClickBannerSponsor', _$(event.target).closest('.component-BannerSponsor'));
        });

        _$('.component-PromoMdd .component-CardSimple').on('click', (event) => {
            this.sendAnalytics('advClickPromotionsVisibilities', _$(event.target).closest('.component-CardSimple'));
        });

        _$('.component-ShopIdm .component-TilePartner').on('click', (event) => {
            this.sendAnalytics('advClickConadPartners', _$(event.target).closest('.component-TilePartner'));
        });
    }
}