import Alpine from 'alpinejs';
import { FlowStore } from '../../../../../../cfamily/types';
import { formatC, i18n } from '../../../../../../libs/base-utils';

Alpine.data('popup12x4xMultiInfo', (args: Record<string, any>) => ({
    inizialized: false as boolean,
    alternativeId: '',
    init() {
        this._initialize();
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    async _initialize() {
        const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
        this.alternativeId = flowStore?.data?.alternativeId;
        if (!this.alternativeId)
            console.warn('warning: alternativeId not provided');
        this.inizialized = true;
    },
    // main component functions
    async ok() {
        const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
        if (flowStore?.currentStep?.toContinue) {
            flowStore.currentStep.toContinue();
        } else {
            flowStore.data.result = {
                state: 'confirmed'
            };
            window.cFlowManager.complete();
        }
    }
}));
