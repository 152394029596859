import { _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';
import { Utils } from '../../services/utils/utils';
export class OfferteScadenzaA {
   
    constructor(
        private _utils: Utils,
    ) { }
    
    nkOnInit() { 
        for (let i = 0; i < _$('.next-off-scad-a').length; i++) {
            _$('.next-off-scad-a')[i].setAttribute('onclick','OfferteScadenzaA.mySlideNext(this)');
            _$('.prev-off-scad-a')[i].setAttribute('onclick','OfferteScadenzaA.mySlidePrev(this)');            
        }
    }   

    swiperOfferteScadenzaOptions: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-off-scad-a',
            draggable: true,
            hide: false,
        },
        /* navigation: {
            nextEl: '.next-off-scad-a',
            prevEl: '.prev-off-scad-a',
        }, */
        pagination: {
            el: '.pagination-off-scad-a',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let slideView;
                if (_$(window).width() < 768) {
                    slideView = swiper.params.breakpoints['375'].slidesPerView;
                } else if (_$(window).width() < 1024){
                    slideView = swiper.params.breakpoints['768'].slidesPerView;
                } else if (_$(window).width() < 1440){
                    slideView = swiper.params.breakpoints['1024'].slidesPerView;
                } else {
                    slideView = swiper.params.breakpoints['1440'].slidesPerView;
                }
                return Math.ceil((current+slideView-1)/slideView) + ' di ' + Math.ceil((total+slideView-1)/slideView);
            },
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 15,
                /* slidesPerGroup: 1, */
                slidesPerView: 1.2
            },
            768: {
                spaceBetween: 15,
               /*  slidesPerGroup: 3, */
                slidesPerView: 3
            },
            1024: {
                spaceBetween: 16,
                /* slidesPerGroup: 3, */
                slidesPerView: 3
            },
            1440: {
                spaceBetween: 24,
                /* slidesPerGroup: 3, */
                slidesPerView: 3
            },
        },
    };
    swiperOfferteScadenza = new Swiper('.swiper-off-scad-a', this.swiperOfferteScadenzaOptions);

    mySlideNext(item) {
        this._utils.mySlideNext(this.swiperOfferteScadenzaOptions, _$(item).closest('.swiper-off-scad-a')[0].swiper);
    }

    mySlidePrev(item) {
        this._utils.mySlidePrev(this.swiperOfferteScadenzaOptions, _$(item).closest('.swiper-off-scad-a')[0].swiper);
    }
 
 }