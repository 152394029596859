import { Options, Splide } from '@splidejs/splide';
import Alpine from 'alpinejs';
import { DefaultService, GetOrderHistoryRequest, Order, TypeOfService } from '../../../../../../api';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, i18n, renderHrefWithAdditionalParams } from '../../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';

const ORDERS_PAGE_SIZE = 5;
const SPLIDE_ORDERS_PREVIEW_ID = '#splide-orders-preview';
const SPLIDE_ORDERS_PREVIEW_OPT: Options = {
    drag: 'free',
    pagination: false,
    autoWidth: true,
    breakpoints: {
        1280: {
            perPage: 2,
            perMove: 1,
        },
    },
    mediaQuery: 'min', //this set breakpoints mobile-first
};

Alpine.data('ec11x7xPreviewOrders', () => {
    let splideOrdersPreview;

    return {
        initialized: false,
        openTab: 'OR', // 'OR' || 'OC'
        ordersTotalResults: 0,
        ordersORTotalResults: 0,
        ordersOCTotalResults: 0,
        orders: [] as Order[],
        ordersOR: [] as Order[],
        ordersOC: [] as Order[],
        init() {
            this._loadOrders();
        },
        // consts
        // utils
        i18n,
        formatC,
        renderHrefWithAdditionalParams,
        // internal functions
        async _loadOrders() {
            try {
                let requestBodyOR: GetOrderHistoryRequest = {
                    serviceType: TypeOfService.HOME_DELIVERY, // 'OR'
                    pageSize: ORDERS_PAGE_SIZE,
                    currentPage: 0,
                    timeFilter: 'ALL'
                };
                const responseOR = (await DefaultService.postApiEcommerceItItGetOrderHistoryJson(requestBodyOR));
                this.ordersOR = responseOR?.data?.orders || [];
                this.ordersORTotalResults = responseOR?.data?.pagination?.totalResults || 0;

                let requestBodyOC: GetOrderHistoryRequest = {
                    serviceType: TypeOfService.ORDER_AND_COLLECT, // 'OC'
                    pageSize: ORDERS_PAGE_SIZE,
                    currentPage: 0,
                    timeFilter: 'ALL'
                };
                const responseOC = (await DefaultService.postApiEcommerceItItGetOrderHistoryJson(requestBodyOC));
                this.ordersOC = responseOC?.data?.orders || [];
                this.ordersOCTotalResults = responseOC?.data?.pagination?.totalResults || 0;
            } catch (e) {
                this.$dispatch('toast', { id: 'code-error', customText: i18n('Error retrieving orders') });
                console.warn(e);
            }
            this._swithToMostRecentOrderTab();
        },
        async _refreshTab() {
            this.initialized = false;
            this.orders = this.openTab === 'OR' ? this.ordersOR : this.ordersOC;
            this.ordersTotalResults = this.openTab === 'OR' ? this.ordersORTotalResults : this.ordersOCTotalResults;
            this.$nextTick(() => {
                if (splideOrdersPreview) splideOrdersPreview.destroy();
                splideOrdersPreview = new Splide(SPLIDE_ORDERS_PREVIEW_ID, SPLIDE_ORDERS_PREVIEW_OPT).mount();
            });
            this.initialized = true;
        },
        _swithToMostRecentOrderTab() {
            if (this.ordersOC.length == 0 || this.ordersOR.length == 0) {
                if (this.ordersOR.length == 0) {
                    this.openTab = 'OC';
                    this._refreshTab();
                }
                return;
            }
            const firstOC: Order = this.ordersOC[0];
            const firstOR: Order = this.ordersOR[0];
            if (new Date(firstOC?.created) > new Date(firstOR?.created)) {
                this.openTab = 'OC';
            }
            this._refreshTab();
        },
        // main component functions
        changeOrdersHistoryTab(tab: string) {
            this.openTab = tab;
            sendAnalytics((() => {
                let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_ORDERS_CHANGE_SERVICE;
                ecommerceData.funnel.stepFunnel = (this.openTab == 'OR') ? 'Spesa a casa' : 'Ordina e ritira';
                return ecommerceData;
            })());
            this._refreshTab();
        },
        // only analytics functions
        trackShowAll() {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_PROFILE_SHOW_ALL;
            ecommerceData.funnel.stepFunnel = 'I miei ordini';
            sendAnalytics(ecommerceData);
        }
    }
});