import Alpine from 'alpinejs';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, getQueryParamFromUrl, i18n } from '../../../../../../libs/base-utils';
import { populateAnalyticsProduct, sendAnalytics } from '../../../../../../libs/tracking-utils';
import { DEFAULT_WISHLIST_NAME } from '../../../../../../libs/wishlists-utils';

Alpine.data('ec30x0xProductCardMini', () => ({
    productData: {} as {},
    init() {
        this.productData = JSON.parse(this.$el.dataset?.product);
        if (!this.productData) {
            console.warn('no data-product attribute found')
            return;
        }
    },
    // consts
    // utils
    formatC,
    i18n,
    // internal functions
    // main component functions
    // only analytics functions
    async trackProductCardClick() {
        sendAnalytics(await (async () => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PRODUCT_CARD;
            await populateAnalyticsProduct(ecommerceData, this.productData);
            ecommerceData.funnel.listaVisualizzata = (window.pageTemplate === 'personal-area-page')
                ? getQueryParamFromUrl('pre-selected-wishlist', DEFAULT_WISHLIST_NAME) : '';
            return ecommerceData;
        })());
    }
}));
