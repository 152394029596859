import Alpine from 'alpinejs';
import { Coupon, DefaultService } from '../../../../../../api';
import { PaginationRequest } from '../../../../../../api/models/PaginationRequest';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, i18n } from '../../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';

const COUPONS_PAGE_SIZE = 12;

Alpine.data('ec8x2xCoupons', () => ({
    isPreview: false,
    coupons: [] as Coupon[],
    currentLoadedPage: 0,
    couponsTotalResults: 0,
    hasMoreCoupons: false,
    showCardsSkeleton: false,
    init() {
        sendAnalytics(BASE_ECOMMERCE_DATA.PAGE_VIEW_PA_COUPONS);
        this.loadCoupons();
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    // main component functions
    async loadCoupons() {
        this.showCardsSkeleton = true;
        const requestBody: PaginationRequest = {
            pageSize: COUPONS_PAGE_SIZE,
            currentPage: this.currentLoadedPage++
        };
        const response = (await DefaultService.postApiMyconadItItCouponsJson(requestBody));
        this.coupons.push(...response?.data?.coupons || []);
        this.couponsTotalResults = response?.data?.couponsNumber || 0;
        this.hasMoreCoupons = this.couponsTotalResults > COUPONS_PAGE_SIZE * this.currentLoadedPage;
        this.showCardsSkeleton = false;
    },
}));