import Alpine from 'alpinejs';
import { BASE_ECOMMERCE_DATA } from '../../../../../constants';
import { formatC, i18n } from '../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../libs/tracking-utils';

Alpine.data('ap205xCoupon', () => ({
    showTooltip: false,
    openAddressBox: false,
    init() {
    },
    // consts
    // utils
    formatC,
    i18n,
    // internal functions
    // main component function
    copyToClipboard() {
        const code = this.$refs.couponCode.textContent;
        navigator.clipboard.writeText(code).then(() => {
            this.showTooltip = true;
            setTimeout(() => this.showTooltip = false, 6300);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    },
    async showPopup() {
        let flowOutputData: any = (await window.cFlowManager.startAsyncFlow({
            name: 'coupon-detail-flow',
            steps: [{ name: 'coupon-detail' }],
            initialData: { coupon: this.coupon }
        }));
    },
    // only analytics functions
    trackCuponClick() {
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_COUPON;
            ecommerceData.buono.IDBuono = this.coupon.codiceCouponEcommerce;
            return ecommerceData;
        })());
    },
    trackCuponCodeClick() {
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_COUPON_CODE;
            ecommerceData.buono.IDBuono = this.coupon.codiceCouponEcommerce;
            return ecommerceData;
        })());
    }
}));