import { Analytics } from 'src/services/analytics/analytics';
import { _$ } from '../../main'; 
export class FooterSemplificato {
   
   constructor(private _analytics: Analytics) { }
   
   nkOnInit() {
      var self = this;
      _$( ".box-carte img" ).on( "click", function () {
         self.sendAnalytics("metodi_pagamento", this);
       });

       _$( ".item-left a img" ).on( "click", function () {
         self.sendAnalytics("seguici", this);
       });

       _$( ".box-footer-item" ).on( "click", function () {
         self.sendAnalytics("box-footer-item", this);
       });

       _$( ".link-utili-box" ).on( "click", function () {
         self.sendAnalytics("link-utili-box", this);
       });
   }   

   sendAnalytics(eventType: string, e?: object){
      try {
          let analyticsData;
          let funnelDict;
            
          analyticsData = {
            event: "clickOpzioniFooter",
          }

          switch (eventType) {
            case "metodi_pagamento":
               analyticsData["footer"] = {
                  categoriaFooter: _$( ".box-carte").siblings(".text").text(),
                  opzioneFooter: _$(e).attr('alt')
               }
               break;
            case "seguici":
               analyticsData["footer"] = {
                  categoriaFooter: _$( ".item-left").find(".text").text(),
                  opzioneFooter: _$(e).attr('alt')
               }               
               break;
            case "box-footer-item":
               analyticsData["footer"] = {
                  categoriaFooter: 'Supporto Cliente',
                  opzioneFooter: _$(e).find(".title").length ? _$(e).find(".title").text().trim() : ''
               }               
               break;
            case "link-utili-box":
               analyticsData["footer"] = {
                  categoriaFooter: _$(e).siblings(".title").text(),
                  opzioneFooter: _$(e).text() ? _$(e).text().trim() : ''
               }               
               break;
          }

          //2.1.1
         funnelDict = 'FUNNEL_DATA_CLICK_OPZIONI_FOOTER';
  
          this._analytics.sendAnalytics(analyticsData, funnelDict, true, false);
      } catch (error) {
          console.log(eventType + ' : ' + error);
      }
  }
}