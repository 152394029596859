import { _$ } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
import { Utils } from '../../services/utils/utils';
import { GoogleUtils } from '../../services/utils/google-utils';

export class Search {
    constructor(
        private _analytics: Analytics,
        private _utils: Utils,
        private _google: GoogleUtils
    ) {}

    private filterTitles = {
        'bassi': 'bassiFissi',
        'brand': 'marchi',
        'group-esigenze': 'esigenzeAlimentari',
        'group-denominazione': 'denominazione',
        'order': 'ordinamento',
        'promoType': 'promozioni',
        'promoUser': 'offertePer',
        'region': 'regione'
    }

    nkOnInit() {
        this.checkBreadcrumb();
        this.sendAnalytics('pageview',this);

        _$('body').on('pageviewReloadFilters', () => {
            this.sendAnalytics('pageview', this);
        });
        var self = this;
        _$( ".section-offer .component-FlatCategory" ).on( "click", function () {
            self.sendAnalyticsNavigazione("selezioneCategoriaPostRicerca", this);
          });
        this.addTrackingSponsorsClickEvent();
        this.addBannersClickEvent();
    }

    checkBreadcrumb() {
        let breadcrumbs = _$('.component-Breadcrumb');
        
        if (breadcrumbs.length > 1) {
            breadcrumbs.addClass('uk-hidden');
            breadcrumbs.eq(0).removeClass('uk-hidden');
        } 
    }

    sendAnalytics(eventType, e) {
        if (window.location.pathname == window['urlSite'].search) {
            this.sendAnalyticsSearch(eventType,e);
        } else {
            this.sendAnalyticsListing(eventType,e);
        }
    }


    async sendAnalyticsSearch(eventType: string, e: object) {
        try {
            let analyticsData;
            let step;
            let prodotto = [];
            let datapromoArray = [];
            let productCard;
            let productCardLastView;
            let cardSuggest;
        
            switch (eventType) {
                case 'pageview':

                    let nodoSponsor = [];
                    //2.6.1
                    productCard = _$('.component-ProductCard');

                    for (var i = 0; i < productCard.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                    }

                    productCardLastView = _$('.component-ProductCardLastView');
                    for (var i = 0; i < productCardLastView.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))));
                    }

                    cardSuggest = _$('.component-CardSuggest');
                    for (var i = 0; i < cardSuggest.length; i++) {
                        prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                    }

                    if(_$('.product-results .component-BannerSponsor').length){
                        let bannerSponsor = _$('.product-results .component-BannerSponsor');
                        for(let i = 0; i < _$(bannerSponsor).length; i++){
                            nodoSponsor.push(
                                {
                                    brand: '',
                                    campagna: _$(bannerSponsor).eq(i).find('.banner-title').length ? _$(bannerSponsor).eq(i).find('.banner-title').html() : '',
                                    formato: 'Banner BTF',
                                    landing: _$(bannerSponsor).eq(i).find('a').length ? _$(bannerSponsor).eq(i).find('a').attr('href') : '',
                                    posizioneSlide: 0
                                }
                            )
                            if (_$(bannerSponsor).eq(i).attr('data-promo')) {
                                datapromoArray.push(JSON.parse(_$(bannerSponsor).eq(i).attr('data-promo')));
                            } else {
                                datapromoArray.push("");
                            }                               
                        }
                    }

                    analyticsData = {
                        event: eventType,
                        ricerca: { },
                        prodotto,
                        sponsor: nodoSponsor ? nodoSponsor : null
                    };
                    step = 'FUNNEL_DATA_SEARCH';
                    break;
                case 'categoriaConsigliata': {
                    //2.6.3
                    productCard = _$('.component-ProductCard');

                    for (var i = 0; i < productCard.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                    }

                    productCardLastView = _$('.component-ProductCardLastView');
                    for (var i = 0; i < productCardLastView.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))));
                    }

                    cardSuggest = _$('.component-CardSuggest');
                    for (var i = 0; i < cardSuggest.length; i++) {
                        prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                    }

                    //2.5.1
                    analyticsData = {
                        event: eventType,
                        ricerca: { },
                        prodotto
                    };
                    step = 'FUNNEL_DATA_SEARCH';
                    break;
                }
                case 'marchioCompare':
                    //2.6.4
                    productCard = _$('.component-ProductCard');

                    for (var i = 0; i < productCard.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                    }

                    productCardLastView = _$('.component-ProductCardLastView');
                    for (var i = 0; i < productCardLastView.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))));
                    }

                    cardSuggest = _$('.component-CardSuggest');
                    for (var i = 0; i < cardSuggest.length; i++) {
                        prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                    }

                    //2.5.2
                    analyticsData = {
                        event: eventType,
                        ricerca: { },
                        prodotto
                    };
                    step = 'FUNNEL_DATA_SEARCH';
                    break;
                case 'clickAdvBannerSponsor':
                    analyticsData = {
                        event: 'clickAdv',
                        prodotto: {
                            carosello: _$(e).find('.text-container .banner-title').length ? _$(e).find('.text-container .banner-title').html() : ''
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.text-container .banner-title').length ? _$(e).find('.text-container .banner-title').html() : '',
                            formato: 'Banner BTF',
                            landing: _$(e).find('a').length ? _$(e).find('a').attr('href') : '',
                            posizioneSlide: 0
                        }]
                    }
                    if (_$(e).attr('data-promo')) {
                        datapromoArray.push(JSON.parse(_$(e).attr('data-promo')));
                    } else {
                        datapromoArray.push("");
                    }                      
                    step = 'FUNNEL_DATA_SEARCH';
                    this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                    return;
            }

            analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
            
            analyticsData.ricerca = this._analytics.manageProductSearch('search',eventType,this._utils.getQueryValue());
            
            if (_$(e).closest('.component-BannerSponsor').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-BannerSponsor').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            } else if (_$(e).closest('.component-CarouselBenessere').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-CarouselBenessere').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            } else {
                if (datapromoArray.length) {
                    this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                } else {
                    this._analytics.sendAnalytics(analyticsData, step);
                }      
            }                
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    async sendAnalyticsListing(eventType: string, e: object) {
        try {
            let analyticsData;
            let step;
            let categoriaPrimoLivello = '';
            let categoriaSecondoLivello = '';
            let categoriaTerzoLivello = '';
            let datapromoArray = [];
            let prodotto = [];
            let productCard;
            let productCardLastView;
            let cardSuggest;
            if (_$('.uk-breadcrumb')) {
                categoriaPrimoLivello = _$('.uk-breadcrumb').find('li').eq(1).length ? _$('.uk-breadcrumb').find('li').eq(1).children().html().trim() : '';
                categoriaSecondoLivello = _$('.uk-breadcrumb').find('li').eq(2).length ? _$('.uk-breadcrumb').find('li').eq(2).children().html().trim() : '';
                categoriaTerzoLivello = _$('.uk-breadcrumb').find('li').eq(3).length ? _$('.uk-breadcrumb').find('li').eq(3).children().html().trim() : ''; 
            }
            switch (eventType) {
                case 'pageview':
                    let nodoSponsor = []
                    
                    if(_$('.component-CarouselBenessere').length){
                        let carouselBenessere = _$('.component-CarouselBenessere');
                        for(let i = 0; i < _$(carouselBenessere).length; i++){
                            nodoSponsor.push(
                                {
                                    brand: '',
                                    campagna: _$(carouselBenessere).eq(i).find('.title-container .title').length ? _$(carouselBenessere).eq(i).find('.title-container .title').html() : '',
                                    formato: 'Carosello sponsor',
                                    landing: _$(carouselBenessere).eq(i).find('.content-container a').attr('href'),
                                    posizioneSlide: 0
                                }
                            )
                            if (_$(carouselBenessere).eq(i).attr('data-promo')) {
                                datapromoArray.push(JSON.parse(_$(carouselBenessere).eq(i).attr('data-promo')));
                            } else {
                                datapromoArray.push("");
                            }                            
                        }
                    }

                    if(_$('.component-ProductCard .card-partner').length){
                        let cardPartner = _$('.component-ProductCard .card-partner').closest('.component-ProductCard');
                        for(let i = 0; i < _$(cardPartner).length; i++){
                            nodoSponsor.push(
                                {
                                    brand: '',
                                    campagna: _$(cardPartner).eq(i).find('.text-wrapper .text').length ? _$(cardPartner).eq(i).find('.text-wrapper .text').html().trim() : '',
                                    formato: 'Box BTF',
                                    landing: _$(cardPartner).eq(i).find('a').length ? _$(cardPartner).eq(i).find('a').attr('href') : '',
                                    posizioneSlide: 0
                                }
                            )
                            
                            if (_$(cardPartner).eq(i).attr('data-promo')) {
                                datapromoArray.push(JSON.parse(_$(cardPartner).eq(i).attr('data-promo')));
                            } else {
                                datapromoArray.push("");
                            }                               
                        }
                    }

                    if(_$('.product-results .component-BannerSponsor').length){
                        let bannerSponsor = _$('.product-results .component-BannerSponsor');
                        for(let i = 0; i < _$(bannerSponsor).length; i++){
                            nodoSponsor.push(
                                {
                                    brand: '',
                                    campagna: _$(bannerSponsor).eq(i).find('.banner-title').length ? _$(bannerSponsor).eq(i).find('.banner-title').html() : '',
                                    formato: 'Banner BTF',
                                    landing: _$(bannerSponsor).eq(i).find('a').length ? _$(bannerSponsor).eq(i).find('a').attr('href') : '',
                                    posizioneSlide: 0
                                }
                            )
                            if (_$(bannerSponsor).eq(i).attr('data-promo')) {
                                datapromoArray.push(JSON.parse(_$(bannerSponsor).eq(i).attr('data-promo')));
                            } else {
                                datapromoArray.push("");
                            }                               
                        }
                    }

                    if(_$('.main-content').siblings('.component-BannerListing.offerte-video').find('.banner-video').length){
                        let bannerVideo = _$('.main-content').siblings('.component-BannerListing.offerte-video');
                        nodoSponsor.push({
                            brand: '',
                            campagna: _$(bannerVideo).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata video',
                            landing: _$(bannerVideo).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        })
                        if (_$(bannerVideo).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(bannerVideo).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                              
                    }else if(_$('.main-content').siblings('.component-BannerListing.offerte-video').find('.banner').length){
                        let bannerSimple = _$('.main-content').siblings('.component-BannerListing.offerte-video');
                        nodoSponsor.push({
                            brand: '',
                            campagna: _$(bannerSimple).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata semplice',
                            landing: _$(bannerSimple).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        })
                        if (_$(bannerSimple).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(bannerSimple).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                          
                    }else if(_$('.main-content').siblings('.component-BannerListing.banner-iniziative').length){
                        let bannerIniziative = _$('.main-content').siblings('.component-BannerListing.banner-iniziative');
                        nodoSponsor.push({
                            brand: '',
                            campagna: _$(bannerIniziative).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata con categorie',
                            landing: _$(bannerIniziative).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        })

                        if (_$(bannerIniziative).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(bannerIniziative).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                                
                    }else if(_$('.main-content').siblings('.component-BannerListing.banner-hotspot').length){
                        let bannerHotspot = _$('.main-content').siblings('.component-BannerListing.banner-hotspot');
                        nodoSponsor.push({
                            brand: '',
                            campagna: _$(bannerHotspot).find('.text .text-title').length ? _$(bannerHotspot).find('.text .text-title').html() : '',
                            formato: 'Header interattivo',
                            landing: _$(bannerHotspot).find('.text-link').length ? _$(bannerHotspot).find('.text-link').attr('href') : '',
                            posizioneSlide: 0
                        })

                        if (_$(bannerHotspot).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(bannerHotspot).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                             
                    }else if(_$('.main-content').siblings('.component-BannerListing.banner-simple').length){
                        let bannerSimple = _$('.main-content').siblings('.component-BannerListing.banner-simple');
                        nodoSponsor.push({
                            brand: '',
                            campagna: _$(bannerSimple).find('.text .text-title').html(),
                            formato: 'Banner BTF',
                            landing: _$(bannerSimple).find('.text-link').attr('href'),
                            posizioneSlide: 0
                        })

                        if (_$(bannerSimple).attr('data-promo')) {
                            datapromoArray.push(JSON.parse(_$(bannerSimple).attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                                 
                    }

                    productCard = _$('.component-ProductCard');

                    for (var i = 0; i < productCard.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                    }

                    productCardLastView = _$('.component-ProductCardLastView');
                    for (var i = 0; i < productCardLastView.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))));
                    }

                    cardSuggest = _$('.component-CardSuggest');
                    for (var i = 0; i < cardSuggest.length; i++) {
                        prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                    }
                    
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        prodotto,
                        sponsor: nodoSponsor ? nodoSponsor : null
                    };
                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    break;
    
                case 'ctaSecondoLivello':

                    productCard = _$('.component-ProductCard');

                    for (var i = 0; i < productCard.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                    }

                    productCardLastView = _$('.component-ProductCardLastView');
                    for (var i = 0; i < productCardLastView.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))));
                    }

                    cardSuggest = _$('.component-CardSuggest');
                    for (var i = 0; i < cardSuggest.length; i++) {
                        prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                    }
                    
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        prodotto
                    };
                    
                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    break;
                case 'clickAdvCarouselBenessere':
                    analyticsData = {
                        event: 'clickAdv',
                        prodotto: {
                            carosello: _$(e).find('.content-container .title').length ? _$(e).find('.content-container .title').html() : ''
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.content-container .title').length ? _$(e).find('.content-container .title').html() : '',
                            formato: 'Carosello sponsor',
                            landing: _$(e).find('.content-container a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }
                    
                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    if(_$(e).attr('data-promo')){
                        datapromoArray.push(JSON.parse(_$(e).attr('data-promo')));
                    }else{
                        datapromoArray.push("");
                    }

                    if(datapromoArray.length){
                        this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                    }else{
                        this._analytics.sendAnalytics(analyticsData, step);
                    }
                    
                    return;
                case 'clickAdvCardPartner':
                    analyticsData = {
                        event: 'clickAdv',
                        prodotto: {
                            carosello: _$(e).find('.text-wrapper .text').length && _$(e).find('.text-wrapper .text').html() != null ? _$(e).find('.text-wrapper .text').html().trim() : ''
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.text-wrapper .text').length && _$(e).find('.text-wrapper .text').html() != null ? _$(e).find('.text-wrapper .text').html().trim() : '',
                            formato: 'Box BTF',
                            landing: _$(e).find('a').length ? _$(e).find('a').attr('href') : '',
                            posizioneSlide: 0
                        }]
                    }
                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    if (_$(e).closest('.component-ProductCard').attr('data-promo')) {
                        let dataPromo = JSON.parse(_$(e).closest('.component-ProductCard').attr('data-promo'));
                        this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
                    } else {
                        this._analytics.sendAnalytics(analyticsData, step);
                    }                      
                    return;
                
                case 'clickAdvBannerSponsor':
                    analyticsData = {
                        event: 'clickAdv',
                        prodotto: {
                            carosello: _$(e).find('.text-container .banner-title').length ? _$(e).find('.text-container .banner-title').html() : ''
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.text-container .banner-title').length ? _$(e).find('.text-container .banner-title').html() : '',
                            formato: 'Banner BTF',
                            landing: _$(e).find('a').length ? _$(e).find('a').attr('href') : '',
                            posizioneSlide: 0
                        }]
                    }
                    if (_$(e).attr('data-promo')) {
                        datapromoArray.push(JSON.parse(_$(e).attr('data-promo')));
                    } else {
                        datapromoArray.push("");
                    }                      
                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                    return;
                
                case 'clickAdvCarouselBenessereVediTutti':
                    analyticsData = {
                        event: 'advVediTutti',
                        prodotto: {
                            categoriaPrimoLivello,
                            categoriaSecondoLivello,
                            categoriaTerzoLivello
                        },
                        sponsor: [{
                            brand: '',
                            campagna: _$(e).find('.content-container .title').length ? _$(e).find('.content-container .title').html() : '',
                            formato: 'Carosello sponsor',
                            landing: _$(e).find('.content-container a').attr('href')
                        }]
                    }

                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    
                    if(_$(e).attr('data-promo')){
                        datapromoArray.push(JSON.parse(_$(e).attr('data-promo')));
                    }else{
                        datapromoArray.push("");
                    }
                    
                    if(datapromoArray.length){
                        this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                    }else{
                        this._analytics.sendAnalytics(analyticsData, step);
                    }
                    return;
                case 'advClick':
                    let nodoSponsorClick;

                    if(_$(e).closest('.component-BannerListing.offerte-video').find('.banner-video').length){
                        let bannerVideo = _$(e).closest('.component-BannerListing.offerte-video');
                        nodoSponsorClick = [{
                            brand: '',
                            campagna: _$(bannerVideo).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata video',
                            landing: _$(bannerVideo).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }else if(_$(e).closest('.component-BannerListing.offerte-video').find('.banner').length){
                        let bannerSimple = _$(e).closest('.component-BannerListing.offerte-video');
                        nodoSponsorClick = [{
                            brand: '',
                            campagna: _$(bannerSimple).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata semplice',
                            landing: _$(bannerSimple).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }else if(_$(e).closest('.component-BannerListing.banner-iniziative').length){
                        let bannerIniziative = _$(e).closest('.component-BannerListing.banner-iniziative');
                        nodoSponsorClick = [{
                            brand: '',
                            campagna: _$(bannerIniziative).find('.component-BannerSponsor .banner-title').html(),
                            formato: 'Banner testata con categorie',
                            landing: _$(bannerIniziative).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                            posizioneSlide: 0
                        }]
                    }else if(_$(e).closest('.component-BannerListing.banner-hotspot').length){
                        let bannerHotspot = _$(e).closest('.component-BannerListing.banner-hotspot');
                        nodoSponsorClick = [{
                            brand: '',
                            campagna: _$(bannerHotspot).find('.text .text-title').html(),
                            formato: 'Banner BTF',
                            landing: _$(bannerHotspot).find('.text-link').attr('href'),
                            posizioneSlide: 0
                        }]
                    }else if(_$(e).closest('.component-BannerListing.banner-simple').length){
                        let bannerSimple = _$(e).closest('.component-BannerListing.banner-simple');
                        nodoSponsorClick = [{
                            brand: '',
                            campagna: _$(bannerSimple).find('.text .text-title').html(),
                            formato: 'Banner BTF',
                            landing: _$(bannerSimple).find('.text-link').attr('href'),
                            posizioneSlide: 0
                        }]
                    }

                    analyticsData = {
                        event: 'advClick',
                        prodotto: {
                            categoriaPrimoLivello,
                            categoriaSecondoLivello,
                            categoriaTerzoLivello
                        },
                        sponsor: nodoSponsorClick ? nodoSponsorClick : null
                    };
                    step = 'FUNNEL_DATA_LISTINGPAGE';


                    if (_$(e).closest('.component-BannerListing').attr('data-promo')) {
                        let dataPromo = JSON.parse(_$(e).closest('.component-BannerListing').attr('data-promo'));
                        this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
                    } else {
                        this._analytics.sendAnalytics(analyticsData, step);
                    }

                    return;
                case 'advClickVideo':
                    let nodoSponsorClickVideo = [{
                        brand: '',
                        campagna: _$(e).find('.component-BannerSponsor .banner-title').html(),
                        formato: 'Banner testata video',
                        landing: _$(e).find('.component-BannerSponsor .link-banner-sponsor a').attr('href'),
                        posizioneSlide: 0
                    }]
                    analyticsData = {
                        event: 'advClickVideo',
                        prodotto: {
                            categoriaPrimoLivello,
                            categoriaSecondoLivello,
                            categoriaTerzoLivello
                        },
                        sponsor: nodoSponsorClickVideo ? [nodoSponsorClickVideo] : null
                    };
                    step = 'FUNNEL_DATA_LISTINGPAGE';
                    this._analytics.sendAnalytics(analyticsData, step);

                    return;
            }

            let filtro = {
                bassiFissi: '',
                denominazione: '',
                esigenzeAlimentari: '',
                marchi: '',
                offertePer: '',
                ordinamento: '',
                promozioni: '',
                regione: ''
            };

            let filterContainer = _$(window).width() > 1023 ? _$('.component-SearchFilter') : _$('.component-ModalSearchFilter');

            let filters = filterContainer.find('.filter-div .confirmed-selected, .filter-div .selected');

            filtro['ordinamento'] = '|' + filterContainer.find('.filter-order input:checked').siblings('span').text();

            for (let i = 0; i < filters.length; i++) {
                let filter = filters.eq(i);
                let keyFiltro = filter.closest('.filter-div').attr('data-title');
                let valueFiltro = '';
                
                switch (keyFiltro) {
                    case 'bassi':
                        valueFiltro = '|SI';
                        break;
                    case 'order':
                        break;
                    default:
                        valueFiltro = '|' + filter.siblings('.label-text').text();
                }
                
                if(this.filterTitles[keyFiltro]) {
                    filtro[this.filterTitles[keyFiltro]] = filtro[this.filterTitles[keyFiltro]] + valueFiltro;
                }
                
            }

            for (let filter in filtro) {
                filtro[filter] = filtro[filter].length ? filtro[filter].substring(1) : filtro[filter];
            }
           
            filtro['bassiFissi'] = filtro['bassiFissi'] == '' ? 'NO' : filtro['bassiFissi'];

            analyticsData['filtro'] = filtro;

            if (_$(e).closest('.component-BannerSponsor').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-BannerSponsor').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            } else if (_$(e).closest('.component-CarouselBenessere').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-CarouselBenessere').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            } else if (_$(e).closest('.component-BannerListing').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-BannerListing').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            } else {
                if (datapromoArray.length) {
                    this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                } else {
                    this._analytics.sendAnalytics(analyticsData, step);
                }                
            }
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    sendAnalyticsNavigazione(eventType: string, e?: object){
        try {
            let analyticsData;
            let funnelDict;
              
            analyticsData = {
              event: eventType,
              ricerca: {}
            }
            
            analyticsData.ricerca.categoriaSelezionataPostRicerca = _$(e).find(".text").text();
            analyticsData.ricerca.query = this._utils.getQueryValue();
            analyticsData.ricerca.numeroRisultati = 0;

            //6.2.1
           funnelDict = 'FUNNEL_DATA_SELEZIONE_CATEGORIA';
    
            this._analytics.sendAnalytics(analyticsData, funnelDict, true, false);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }
    addTrackingSponsorsClickEvent(){

        if(_$('.component-CarouselBenessere').length){
            let carouselBenessere = _$('.component-CarouselBenessere');
            for(let i = 0; i < _$(carouselBenessere).length; i++){
                _$(carouselBenessere).eq(i).find('.main-content .title-container .content-container a').on('click', (event) => {
                    event.stopImmediatePropagation();
                    this.sendAnalytics('clickAdvCarouselBenessereVediTutti', _$(carouselBenessere).eq(i));
                });
                
                _$(carouselBenessere).eq(i).find('.swiper-slide .component-ProductCard').on('click', () => {
                    this.sendAnalytics('clickAdvCarouselBenessere', _$(carouselBenessere).eq(i));
                });
            }
        }

        if(_$('.component-ProductCard .card-partner').length){
            let cardPartner = _$('.component-ProductCard .card-partner').closest('.component-ProductCard');
            for(let i = 0; i < _$(cardPartner).length; i++){
                _$(cardPartner).eq(i).on('click', () => {
                    this.sendAnalytics('clickAdvCardPartner', _$(cardPartner).eq(i));
                });
            }
        }

        if(_$('.product-results .component-BannerSponsor').length){
            let bannerSponsor = _$('.product-results .component-BannerSponsor');
            for(let i = 0; i < _$(bannerSponsor).length; i++){
                _$(bannerSponsor).eq(i).on('click', () => {
                    this.sendAnalytics('clickAdvBannerSponsor', _$(bannerSponsor).eq(i));
                });
            }
        }
    }

    addBannersClickEvent(){
        if(_$('.main-content').siblings('.component-BannerListing.offerte-video').find('.banner-video').length){
            let bannerVideo = _$('.main-content').siblings('.component-BannerListing.offerte-video');
            _$(bannerVideo).on('click', () => {
                this.sendAnalytics('advClick', _$(bannerVideo));
            });

        }else if(_$('.main-content').siblings('.component-BannerListing.offerte-video').find('banner').length){
            let bannerSimple = _$('.main-content').siblings('.component-BannerListing.offerte-video');

            _$(bannerSimple).on('click', () => {
                this.sendAnalytics('advClick', _$(bannerSimple));
            });
        }else if(_$('.main-content').siblings('.component-BannerListing.banner-iniziative').length){
            let bannerIniziative = _$('.main-content').siblings('.component-BannerListing.banner-iniziative');

            _$(bannerIniziative).on('click', () => {
                this.sendAnalytics('advClick', _$(bannerIniziative));
            });
        }else if(_$('.main-content').siblings('.component-BannerListing.banner-hotspot').length){
            let bannerHotspot = _$('.main-content').siblings('.component-BannerListing.banner-hotspot');
            _$(bannerHotspot).on('click', () => {
                this.sendAnalytics('advClick', _$(bannerHotspot));
            })
        }else if(_$('.main-content').siblings('.component-BannerListing.banner-simple').length){
            let bannerSimple = _$('.main-content').siblings('.component-BannerListing.banner-simple');
            _$(bannerSimple).on('click', () => {
                this.sendAnalytics('advClick', _$(bannerSimple));
            })
        }
    }
}
