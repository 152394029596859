/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ProductSubstitutionMode {
    SEMI_AUTOMATIC_SUBSTITUTION = 'SEMI_AUTOMATIC_SUBSTITUTION',
    PUNCTUAL_SUBSTITUTION = 'PUNCTUAL_SUBSTITUTION',
    AUTOMATIC_SUBSTITUTION = 'AUTOMATIC_SUBSTITUTION',
    NO_SUBSTITUTION = 'NO_SUBSTITUTION',
}
