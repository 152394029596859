import Swiper, { SwiperOptions } from 'swiper';
import { _Uikit, Window, _$ } from '../../main';
export class StorytellingD {
   
    constructor() { }
    
    nkOnInit() { 
        this.initHotspot();
    }   

    initHotspot(){
        try{
            //load s7 div element
            let dms = _$('.component-StorytellingD').find('.dm-interactive-image');
            /* let dms = document.querySelectorAll(".dm-interactive-image"); */
            for (let i = 0; i < dms.length; i++) {
                let dmDiv = dms[i];
                //load params
                //@ts-ignore
                let params = JSON.parse(dmDiv.dataset.configuration);

                //create s7 viewer
                //@ts-ignore
                let s7interactiveimageviewer = new s7viewers.InteractiveImage({
                    "containerId" : dmDiv.id,
                    "params" : params});

                //add event listeners to hotspot click
                
                let functions = this;
                s7interactiveimageviewer.setHandlers({
                    "quickViewActivate": function(data/* objID,compClass,instName,timeStamp,eventInfo */) {
                        /* console.log(data); */
                        
                        /* var elem = _$('#switcher-hotspot').find('div[prod-id='+data.sku+']')
                        _Uikit.drop(_$(elem)).show(); */
                        
                    },
                    "initComplete": function(){
                        functions.addCardsToHotspots();
                        /* functions.initDrops(i); */
                    }
                });
                //init s7 viewer
                s7interactiveimageviewer.init();

            }
        }catch(error){
            console.log(error);
        }
    }

    addCardsToHotspots() {
        let slide = _$('.component-StorytellingD').find('.hotspot-desktop');
        let hotspots;
        let dotCards;
        let card;
        hotspots = slide.find('.s7icon');
        dotCards = slide.find('.dot-card-container').find('.component-DotCard');

        var controlProduct = slide.find('.dm-interactive-image').attr('data-product-ids'); //check no null hotspot

        if (controlProduct && controlProduct.length) { 
            let checkProduct = (slide.find('.dm-interactive-image').attr('data-product-ids')).split(','); //check no null hotspot
            let validProd = checkProduct.length;
            
    
            if (hotspots.length == checkProduct.length) {
                if (checkProduct.indexOf('null') > -1) {
                    for(let i = 0; i < checkProduct.length; i++) {
                        if (checkProduct[i] == 'null') {
                            validProd--;
                            _$(hotspots[i]).css('display','none');
                            delete hotspots[i];
                        }
                    }
                }
                
                for (let j = 0; j < validProd; j++) {
                    card = dotCards[j].outerHTML.toString();
                    _$(hotspots[Object.keys(hotspots)[j]]).after('<div uk-drop="mode:click,hover" style="width:201px">' + card + '</div>');
                }       
            } else {
                for(var i = 0; i < checkProduct.length; i++) {
                    _$(hotspots[i]).css('display','none');
                    delete hotspots[i];
                }
            } 
        }
    } 

    swiperStoryDOptions: SwiperOptions = {
        spaceBetween: 16,
        freeMode: true,
        breakpointsInverse: true,
        navigation: {
            nextEl: '.next-storytelling-d',
            prevEl: '.prev-storytelling-d',
        },
        breakpoints: {
            375: {
                slidesPerGroup: 1,
                slidesPerView: 1,
                noSwiping: false,
                allowSlideNext: true,
                allowSlidePrev: true
            },
            1024: {
                spaceBetween: 22,
                slidesPerGroup: 3,
                slidesPerView: 3,
                noSwiping: true,
                allowSlideNext: false,
                allowSlidePrev: false
            },

        },
    };
    swiperStoryD = new Swiper('.swiper-storytelling-d', this.swiperStoryDOptions);
 }
