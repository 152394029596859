import collapse from '@alpinejs/collapse';
import Alpine from 'alpinejs';
import { CloseCustomerCaseRequest, CustomerCareAttachment } from 'src/api';
import { DefaultService } from '../../../../../api/services/DefaultService';
import { FlowStore } from '../../../../../cfamily/types';
import { formatC, i18n } from '../../../../../libs/base-utils';
import { formatDate } from '../../../../../libs/locale-utils';

const statusMap = {
    NEW: 'Aperto',
    IN_GESTIONE: 'In gestione',
    ON_HOLD: 'In attesa',
    IN_SOSPESO: 'In attesa',
    CLOSED: 'Chiuso',
};

const statusBadgeColorMap = {
    NEW: 'text-info bg-info/20',
    IN_GESTIONE: 'text-on-hold-blue bg-on-hold-yellow',
    ON_HOLD: 'text-negative bg-negative/20',
    IN_SOSPESO: 'text-negative bg-negative/20',
    CLOSED: 'text-positive bg-positive/20',
};

Alpine.plugin(collapse);

Alpine.data('ap206xCardxCustomerxCarexCase', () => ({
    isExpanded: false,
    showTooltip: false,
    customerCareAttachments: [] as CustomerCareAttachment[],
    init() { },
    // consts
    statusMap,
    statusBadgeColorMap,
    // utils
    formatC,
    formatDate,
    i18n,
    // internal functions
    //main functions
    async getAttachments() {
        if (!this.customerCase.hasAttachments || this.customerCareAttachments.length > 0) return;
        this.customerCareAttachments = (
            await DefaultService.getApiMyconadItItGetCaseAttachmentsListJson(this.customerCase.id)
        ).data;
    },
    async actionCloseRequest() {
        let flowOutputData: any = await window.cFlowManager.startAsyncFlow({
            name: 'assistance-close-request-flow',
            steps: [
                {
                    name: 'assistance-close-request',
                    toContinue: async () => {
                        const flowStore = await (<FlowStore>window.Alpine.store('cFlow'));
                        this.$store.showGlobalLoader = true;
                        try {
                            const requestBody: CloseCustomerCaseRequest = {
                                code: this.customerCase.id,
                            };
                            const closed =
                                await DefaultService.postApiMyconadItItCloseCustomerCareRequestJson(requestBody);
                            if (closed) {
                                this._loadCustomerCareCases(true);
                                flowStore.data.result = { state: 'confirmed' };
                                window.cFlowManager.next('assistance-close-request-confirmation');
                            } else {
                                this.$dispatch('toast', {
                                    id: 'code-warning',
                                    customText:
                                        'La richiesta n° ' + this.customerCase.caseNumber + ' non può essere chiusa.',
                                });
                                flowStore.data.result = { state: 'erros' };
                                window.cFlowManager.complete();
                            }
                        } catch (e) {
                            console.error(e);
                            flowStore.data.result = { state: 'errors' };
                            window.cFlowManager.complete();
                        } finally {
                            this.$store.showGlobalLoader = false;
                        }
                    },
                    toGoBack: async () => {
                        const flowStore = await (<FlowStore>window.Alpine.store('cFlow'));
                        flowStore.data.result = { state: 'cancelled' };
                        window.cFlowManager.complete();
                    },
                },
                {
                    name: 'assistance-close-request-confirmation',
                },
            ],
            initialData: {
                formatLabels: {
                    textArgs: [this.customerCase.caseNumber],
                },
            },
        });
    },
}));
