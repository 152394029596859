import { _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';
import { Utils } from '../../services/utils/utils';
export class MarchiConad {
   
    constructor(
        private _utils: Utils,
    ) { }
    
    nkOnInit() {
        for (let i = 0; i < _$('.next-marchi-des').length; i++) {
            _$('.next-marchi-des')[i].setAttribute('onclick','MarchiConad.mySlideNext(this)');
            _$('.prev-marchi-des')[i].setAttribute('onclick','MarchiConad.mySlidePrev(this)');            
        }
    }   

    swiperScopriMarchiMobileOptions: SwiperOptions = {
        freeMode: true,

        /* Pagination con le frecce */
        pagination: {
            el: '.pagination-marchi-mob',
            clickable: true,
        },
        breakpointsInverse: true,
        breakpoints: {
            320: {
                spaceBetween: 16,
                slidesPerGroup: 1,
                slidesPerView: 1
            },
            375: {
                spaceBetween: 16,
                slidesPerGroup: 2,
                slidesPerView: 2
            },
            768: {
                spaceBetween: 16,
                slidesPerGroup: 3,
                slidesPerView: 3
            },
        },
    };
    swiperScopriMarchiMobile = new Swiper('.swiper-scopri-marchi-mobile', this.swiperScopriMarchiMobileOptions);

    swiperScopriMarchiDesktopOptions: SwiperOptions = {
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-marchi-des',
            draggable: true,
            hide: false,
        },
        /* navigation: {
            nextEl: '.next-marchi-des',
            prevEl: '.prev-marchi-des',
        },
 */
        /* Pagination con le frecce */
        pagination: {
            el: '.pagination-marchi-des',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let slideView;
                if (_$(window).width() < 1440){
                    slideView = swiper.params.breakpoints['1024'].slidesPerView;
                } else {
                    slideView = swiper.params.breakpoints['1440'].slidesPerView;
                }
                return Math.ceil((current+slideView-1)/slideView) + ' di ' + Math.ceil((total+slideView-1)/slideView);
            },
        },
        breakpointsInverse: true,
        breakpoints: {
            
            1024: {
                spaceBetween: 16,
               /*  slidesPerGroup: 4, */
                slidesPerView: 4
            },
            1440: {
                spaceBetween: 24,
                /* slidesPerGroup: 5, */
                slidesPerView: 5
            },
        },
    };
    swiperScopriMarchiDesktop = new Swiper('.swiper-scopri-marchi-desktop', this.swiperScopriMarchiDesktopOptions);

    mySlideNext(item) {
        this._utils.mySlideNext(this.swiperScopriMarchiDesktopOptions, _$(item).closest('.swiper-scopri-marchi-desktop')[0].swiper);
    }

    mySlidePrev(item) {
        this._utils.mySlidePrev(this.swiperScopriMarchiDesktopOptions, _$(item).closest('.swiper-scopri-marchi-desktop')[0].swiper);
    }
 }
