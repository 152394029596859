import { Analytics } from 'src/services/analytics/analytics';
import { Utils } from 'src/services/utils/utils';
import { _$, _Uikit, Window } from '../../main';
 
export class CardSuggest {
   
   constructor(private _analytics: Analytics, private _utils: Utils) { }
   
   nkOnInit() { }

   async sendAnalytics(eventType: string, e: object){
      try {
          let analyticsData;
          let funnelDict;
          let myEvent = eventType;
          let page = _$('body').attr('nkpage').toString();
          
          if (page == 'DetailCart') {
              switch (myEvent){
                  case 'aggiungiProdotto':
                      myEvent = 'aggiungiDaCarosello';
                      break;
                  case 'rimuoviProdotto':
                      myEvent = 'rimuoviDaCarosello';
                      break;
                  case 'prodottoPreferito':
                      myEvent = 'preferitoDaCarosello';
                      break;
                  case 'dettaglioProdotto':
                      myEvent = 'dettaglioDaCarosello';
                      break;
              }
          }
  
          analyticsData = {
              event: myEvent,
              prodotto: (await this._analytics.getProductData(_$(e).closest('.component-CardSuggest'))),
          }
  
          if (page == 'DetailProduct' || page == 'DetailCart') {
              analyticsData.prodotto['carosello'] = _$(e).closest('.section-carousel').find('.section-title').html();
          } else if (page == 'Homepage') {
              analyticsData.prodotto['carosello'] = _$('#switcher-area-utente').closest('.section-body').find('.swiper-button-utente').find('li.uk-active').find('a').html();
          }
          
          switch (myEvent) {
              case 'aggiungiDaCarosello':
                //4.2.15
                  funnelDict = 'FUNNEL_DATA_CART_PRODUCT_CARD_ADD_TO_CART';
                  analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                  break;
              case 'rimuoviDaCarosello':
                //4.2.16
                  funnelDict = 'FUNNEL_DATA_CART_PRODUCT_CARD_REMOVE_FROM_CART';
                  analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                  break;
              case 'preferitoDaCarosello':
                //4.2.17
                  funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_CART_PRODUCT_CARD_ADD_FAVOURITE' : 'FUNNEL_DATA_CART_PRODUCT_CARD_REMOVE_FAVOURITE';
                  analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                  break;
              case 'dettaglioDaCarosello':
                //4.2.18
                  funnelDict = 'FUNNEL_DATA_CART_PRODUCT_CARD_CLICK_PRODUCT';
                  analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                  break;
              case 'aggiungiProdotto':
                  if (page == 'DetailProduct') {
                      funnelDict = 'FUNNEL_DATA_DET_PROD_ADD_TO_CART';
                  } else if (page == 'Homepage') {
                      funnelDict = 'FUNNEL_DATA_HOME_ADD_TO_CART';
                  } else {
                      funnelDict = window.location.pathname == window['urlSite'].search ? 'FUNNEL_DATA_PRODUCT_CARD_ADD_TO_CART_SEARCH' : 'FUNNEL_DATA_PRODUCT_CARD_ADD_TO_CART_CHOOSE';
                  }
                  break;
              case 'rimuoviProdotto':
                  if (page == 'DetailProduct') {
                      funnelDict = 'FUNNEL_DATA_DET_PROD_REMOVE_FROM_CART';
                  } else if (page == 'Homepage') {
                      funnelDict = 'FUNNEL_DATA_HOME_REMOVE_FROM_CART';
                  } else {
                      funnelDict = 'FUNNEL_DATA_PRODUCT_CARD_REMOVE_FROM_CART';
                  }
                  break;
              case 'prodottoPreferito':
                  if (page == 'DetailProduct') {
                      funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_DET_PROD_ADD_FAVOURITE' : 'FUNNEL_DATA_DET_PROD_REMOVE_FAVOURITE';
                  } else if (page == 'Homepage') {
                      funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_HOME_ADD_FAVOUR' : 'FUNNEL_DATA_HOME_REMOVE_FAVOUR';
                  } else {
                      if (window.location.pathname == window['urlSite'].search) {
                          funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_PRODUCT_CARD_ADD_FAVOURITE_SEARCH' : 'FUNNEL_DATA_PRODUCT_CARD_REMOVE_FAVOURITE_SEARCH';
                      } else {
                          funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_PRODUCT_CARD_ADD_FAVOURITE_CHOOSE' : 'FUNNEL_DATA_PRODUCT_CARD_REMOVE_FAVOURITE_CHOOSE';
                      }
                  }
                  break;
              case 'dettaglioProdotto':
                  if (page == 'DetailProduct') {
                      funnelDict = 'FUNNEL_DATA_DET_PROD_CLICK_PRODUCT';
                  } else if (page == 'Homepage') {
                      funnelDict = 'FUNNEL_DATA_HOME_CLICK_PRODUCT';
                  } else {
                      funnelDict = window.location.pathname == window['urlSite'].search ? 'FUNNEL_DATA_PRODUCT_CARD_CLICK_PRODUCT_SEARCH' : 'FUNNEL_DATA_PRODUCT_CARD_CLICK_PRODUCT_CHOOSE';
                  }
                  break;
          }
  
          this._analytics.sendAnalytics(analyticsData, funnelDict);
      } catch (error) {
          console.log(eventType + ' : ' + error);
      }
  }
} 
