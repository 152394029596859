import { _$, _Uikit, Window, _zoom } from '../../main';
import { LocalStorage } from '../local-storage/local-storage';
import { ValidationService } from '../validation-service/validation-service';

export class ValidationUtils {
    constructor(
        private _localStorage: LocalStorage,
        private _validationService: ValidationService
    ) {
        this.newValidInput('');
    }

     /**
     * It takes a key, a value, and a time to live (in minutes) and stores the value in localStorage
     * with an expiry time
     * @param key - The key to store the data under.
     * @param value - The value to be stored.
     * @param ttl - Time to live in minutes.
     */
    setStorageWithExpiry(key, value, ttl) {
        let now = new Date();
        let item = {
            value: value,
            expiry: now.getTime() + ttl * 60 * 1000,
        }
        this._localStorage.setItem(key, JSON.stringify(item));
    }

    /**
     * If the item exists and is not expired, return the value of the item
     * @param key - The key of the item you want to get from storage.
     * @returns The value of the item in local storage.
     */
    getStorageWithExpiry(key) {
        let item = this._localStorage.getFromStorage(key);
        if (!item) {
            return null;
        }
        let now = new Date();
        if (now.getTime() > item.expiry) {
            this._localStorage.removeItem(key);
            return null;
        }
        return item.value;
    }

    newValidInput(input) {
        let validation = this.getStorageWithExpiry('validation');
        if (!validation) {
            let success = (res) => {
                this.setStorageWithExpiry('validation',res, 5);
                validation = this.getStorageWithExpiry('validation');
                this.validateInput(input, validation.data);
            }
    
            let error = (err) => {
                console.log('Error validation');
            }
            
            this._validationService.getValidation(success, error);
            
        } else {
            this.validateInput(input, validation.data?.response);
        }        
    }

    validateInput(input, validation) {
        if (!validation) return true;

        if (!!input) {
            input = _$(input)
            for (let i = 0; i < validation.length; i++) {
                if (validation[i].id == input.attr('data-id')) {
                    let controls = validation[i].validations;
                    if (input.val() == '') {
                        if(input.prop('required')){
                            _$(input).attr('data-valid','false');
                            _$(input).siblings('.message-error').text('Campo obbligatorio.');
                            _$(input).siblings('.message-error').removeClass('uk-hidden');
                            _$(input).addClass('uk-form-danger');
                            _$(input).parent().css('margin-bottom','27px');
                            return false;
                        }else{
                            _$(input).attr('data-valid', 'true');
                            _$(input).siblings('.message-error').addClass('uk-hidden');
                            _$(input).removeClass('uk-form-danger');
                            _$(input).parent().css('margin-bottom','16px');
                            return true;
                        }
                    } else if (!controls.length) {
                        _$(input).attr('data-valid', 'true');
                        _$(input).siblings('.message-error').addClass('uk-hidden');
                        _$(input).removeClass('uk-form-danger');
                        _$(input).parent().css('margin-bottom','16px');
                        return true;
                    }
    
                    _$(controls).each((index, control) => {
                        if (!!control.pattern) {
                            let isValid = new RegExp(control.pattern).exec(input.val());
                            if (!isValid) {
                                _$(input).attr('data-valid','false');
                                _$(input).siblings('.message-error').text(control.label.it);
                                _$(input).siblings('.message-error').removeClass('uk-hidden');
                                _$(input).addClass('uk-form-danger');
                                _$(input).parent().css('margin-bottom','27px');
                                return false;
                            } else {
                                _$(input).attr('data-valid','true');
                                _$(input).siblings('.message-error').text('');
                                _$(input).siblings('.message-error').addClass('uk-hidden');
                                _$(input).removeClass('uk-form-danger');
                                _$(input).parent().css('margin-bottom','16px');
                            }
                        }
                        if (!!control.fn) {
                            switch (control.fn) {
                                case 'dataNascitaFiglio':
                                    //chiamata a funzione
                                    break;
                            }
                        }
                    });
                }
            }
        }

        if(!_$(input).attr('data-id') && !_$(input).prop('required')){
            _$(input).attr('data-valid', 'true'); 
        }
        
    }
}