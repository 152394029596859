import { _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';

export class SpinteMarketing {
   
    constructor() { }
    
    nkOnInit() { 
        if(_$('.swiper-popolari').find('.swiper-slide').length > 1){
            const swiperPopolari = new Swiper('.swiper-popolari', this.swiperPopolariOptions);
        }else{
            const swiperPopolari = new Swiper('.swiper-popolari', this.swiperPopolariOptionsNoPagination);
        }
        
    }   

    swiperPopolariOptions: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        freeMode: false,
        pagination: {
            el: '.pagination-popolari',
            clickable: true,
        },

        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 15,
                slidesPerGroup: 1,
                noSwiping: false,
                allowSlideNext: true,
                allowSlidePrev: true
            },
            768: {
                spaceBetween: 15,
                slidesPerGroup: 1,
                noSwiping: false,
                allowSlideNext: true,
                allowSlidePrev: true
            },
            1024: {
                spaceBetween: 16,
                slidesPerGroup: 2,
                noSwiping: true,
                allowSlideNext: false,
                allowSlidePrev: false
            },
            1440: {
                spaceBetween: 24,
                slidesPerGroup: 2,
                noSwiping: true,
                allowSlideNext: false,
                allowSlidePrev: false
            },
        },
    };

    swiperPopolariOptionsNoPagination: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        freeMode: false,
        allowSlidePrev: false,
        allowSlideNext: false,
        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 15,
                slidesPerGroup: 1
            },
            768: {
                spaceBetween: 15,
                slidesPerGroup: 1
            },
            1024: {
                spaceBetween: 16,
                slidesPerGroup: 2
            },
            1440: {
                spaceBetween: 24,
                slidesPerGroup: 2
            },
        },
    };
 }
