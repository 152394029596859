import { Alpine } from 'alpinejs';
import { FlowStore, ModalStore, SingleFlow } from './types';

const initModalStore = (Alpine: Alpine) => {
    const modals: ModalStore = {
        modal: '',
    };
    Alpine.store('cModals', modals);
};

const initFlowStore = (Alpine: Alpine) => {
    const flowStore: FlowStore = { flow: undefined, data: {}, currentStep: undefined, backStack: [] };
    Alpine.store('cFlow', flowStore);
};

// initialize
if (window.Alpine) {
    initModalStore(window.Alpine);
    initFlowStore(window.Alpine);
} else {
    document.addEventListener('alpine:init', () => {
        initModalStore(window.Alpine);
        initFlowStore(window.Alpine);
    });
}
