import { _$, _Uikit } from '../../main';
export class SearchRecommended {
    constructor() {}

    nkOnInit() {
        var urlParams = new URLSearchParams(window.location.search);
        var catLev1 = urlParams.get("cat_lev1");
        var catLev2 = urlParams.get("cat_lev2");

        document.querySelectorAll("[data-rc-value]").forEach((val) => {
            let el = val as HTMLElement;
            if (catLev1 && el.dataset.rcValue == catLev1)
                el.classList.add("active");
            if (catLev2 && el.dataset.rcValue == catLev2) {
                el.classList.add("active");
                var par = el.parentElement.parentElement;
                if (par.matches("li")) {
                    par.classList.add("uk-open");
                }
            }
            if (!catLev1 && !catLev2 && el.dataset.rcValue == "all")
                el.classList.add("active");
        });

        document.querySelectorAll("[data-sh-value]").forEach((val) => {
            let el = val as HTMLElement;
            if (catLev1 && el.dataset.shValue == catLev1)
                el.classList.remove("uk-hidden");
            if (!catLev1 && el.dataset.shValue == "all")
                el.classList.remove("uk-hidden");
        });
    }
}
