import { Utils } from '../../services/utils/utils';
import { _Uikit, _$ } from '../../main';
import { GoogleUtils } from '../../services/utils/google-utils';
import { Analytics } from '../../services/analytics/analytics';
import { BANNER_TYPES, AnalyticsUtility } from '../../services/analytics/analytics-utility';
export class Brands {

   constructor(
       private _utils: Utils,
       private _google: GoogleUtils,
       private _analytics: Analytics,
       private _analyticsUtility: AnalyticsUtility
   ) { }

   nkOnInit() {
      this.sendAnalytics('pageview');
      this.setBannersClickEvents();
   }

   setBannersClickEvents(){
      _$('.general-tile-container .component-TileBrand').on('click', (event) => {
         this.sendAnalytics('advClickTileBrand', _$(event.target).closest('.component-TileBrand'));
     });

     _$('.general-tile-container .component-TilePartner').on('click', (event) => {
         this.sendAnalytics('advClickTilePartner', _$(event.target).closest('.component-TilePartner'));
     });
   }

   sendAnalytics(eventType, element?) {
      try {
         let analyticsData;
         let step;
         let tipoServizio = window['typeOfService'] == 'ORDER_AND_COLLECT' ? 'Ordina e ritira' : 'Ordina e ricevi' ;
         let idNegozio = window['pointOfService']['name'];
         let cooperativaNegozio = window['pointOfService']['cooperativeId'];
         let giorno = this._analytics.getDayFromTimestamp(window['selectedTimeslot']['date']);
         let fasciaOraria = window['selectedTimeslot']['startTime']['formattedHour'] + " - " + window['selectedTimeslot']['endTime']['formattedHour'];
         let sponsor = [];
         let datapromoArray = [];
         let banner;
         let slides;
         let slide;
         let clickEvent = false;
         switch (eventType) {
            case 'pageview':
               let bannerList = [BANNER_TYPES.TILES_BRANDS,
                                 BANNER_TYPES.TILES_PARTNERS];

               bannerList.forEach((value) => {
               [sponsor, datapromoArray] = this._analyticsUtility.getBannerPageview(value, sponsor, datapromoArray);
               });
               analyticsData = {
                  event: eventType,
                  sponsor
              };
               break;
         }

         analyticsData = this._analytics.addCampiTsdDinamicoWindow(analyticsData);
         step = 'FUNNEL_PAGINA_MARCHI';

         if(clickEvent){
            let dataPromo = _$(element).attr('data-promo') != null && _$(element).attr('data-promo') != '' ? JSON.parse(_$(element).attr('data-promo')) : '';
            this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo);
        }else{
            if (datapromoArray.length) {
                this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
            } else {
                this._analytics.sendAnalytics(analyticsData, step);
            }  
        }
      } catch (error) {
         console.log(eventType + ' : ' + error);
      }
   }
}