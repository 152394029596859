import Alpine from 'alpinejs';
import { DEFAULT_WISHLIST_NAME, getNumberOfWishlists, getWishlist, getWishlistNames, getWishlistsContainingProduct, updateWishlistsContainingProduct } from '../../../../../../libs/wishlists-utils';
import { formatC, i18n } from '../../../../../../libs/base-utils';
import PerfectScrollbar from 'perfect-scrollbar';
import { FlowStore } from '../../../../../../cfamily/types';

Alpine.data('popup12x7xWishlistsAddProduct', () => ({
    initialized: false as boolean,
    activeWishlists: new Set() as Set<string>,
    scrollbarOptions: {
        swipeEasing: true,
        suppressScrollX: true,
    },
    pScroll: undefined,
    init() {
        this._checkWishlistsContainingProduct();
        this.pScroll = new PerfectScrollbar(this.$refs.scrollable, this.scrollbarOptions);
    },
    // consts
    DEFAULT_WISHLIST_NAME,
    // utils
    i18n,
    formatC,
    getWishlistNames,
    getNumberOfWishlists,
    getWishlist,
    // internal functions
    async _checkWishlistsContainingProduct() {
        const flow = (await <FlowStore>window.Alpine.store('cFlow'));
        this.activeWishlists = new Set(await getWishlistsContainingProduct(flow?.data?.productData?.code));
        this.initialized = true;
    },
    // main component functions
    checkboxClickAction(wishlistName: string) {
        if (!wishlistName) return;
        if (!this.activeWishlists.has(wishlistName)) {
            // wishlist selected
            this.activeWishlists.add(wishlistName);
            if (wishlistName !== DEFAULT_WISHLIST_NAME)
                this.activeWishlists.add(DEFAULT_WISHLIST_NAME);
        } else {
            // wishlist deselected
            if (wishlistName !== DEFAULT_WISHLIST_NAME)
                this.activeWishlists.delete(wishlistName);
            else
                this.activeWishlists.clear();
        }
    },
    async closeWithConfirm() {
        const flow = (await <FlowStore>window.Alpine.store('cFlow'));
        flow.data.result = {
            state: 'confirmed'
        };
        const actions = (await updateWishlistsContainingProduct(
            flow?.data?.productData?.code, new Set(this.activeWishlists), flow?.data?.productData?.nome));
        if (actions && actions > 0)
            this.$dispatch('toast', { id: 'wishlists-successfully-add-product-feedback' });
        window.cFlowManager.complete();
    }
}));
