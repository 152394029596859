/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Price = {
    currencyIso?: string;
    formattedValue?: string;
    priceByWeight: number;
    priceByWeightUnit: Price.priceByWeightUnit;
    value?: number;
};
export namespace Price {
    export enum priceByWeightUnit {
        KG = 'KG',
        LT = 'LT',
        PIECES = 'PIECES',
    }
}

