import { GoogleAddress } from 'src/services/utils/google-address-interface';
import { _$, _Uikit, Window } from '../../main';
import { getGMapsManager } from './google-service/google-maps-manager';

export class GoogleAddressUtils{
    
    private gMapsManager;
    constructor(){
        this.gMapsManager = getGMapsManager();
    }

    private addressTypesNoStreetNumber = ['locality', 'sublocality', 'postal_code', 'country', 'administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3']
    public googleAddressSearched;
    /* 
        Crea un oggetto google partendo dalla place object
    */
    createGoogleAddressObject(place: object): GoogleAddress{
        let addressComponents = place['address_components'];
        let googleAddress: GoogleAddress = {};
        googleAddress['formatted_address'] = place['formatted_address'];
        googleAddress['route'] = this._getAddressComp(addressComponents, 'route', 'long_name');
        googleAddress['street_number'] = this._getAddressComp(addressComponents, 'street_number', 'long_name');
        googleAddress['locality'] = this._getAddressComp(addressComponents, 'locality', 'long_name')
        googleAddress['postal_code'] = this._getAddressComp(addressComponents, 'postal_code', 'long_name')
        googleAddress['country'] = this._getAddressComp(addressComponents, 'country', 'long_name');
        googleAddress['administrative_area_level_3'] = this._getAddressComp(addressComponents, 'administrative_area_level_3', 'long_name');
        googleAddress['administrative_area_level_2'] = this._getAddressComp(addressComponents, 'administrative_area_level_2', 'long_name');
        googleAddress['administrative_area_level_1'] = this._getAddressComp(addressComponents, 'administrative_area_level_1', 'long_name');
        googleAddress['types'] = place['types'];
        googleAddress['isStreetAddress'] = this.addressTypesNoStreetNumber.some(type => googleAddress['types'].indexOf(type) != -1) ? false : true;
        googleAddress['formatted_address_no_street_number'] = googleAddress['isStreetAddress'] ? 
            ( 
                (googleAddress.route != null && googleAddress.route.length ? googleAddress.route: '') + 
                (googleAddress.postal_code != null && googleAddress.postal_code.length ? ', '+googleAddress.postal_code : '') + 
                (googleAddress.locality != null && googleAddress.locality.length ? ', '+googleAddress.locality : (
                    googleAddress.administrative_area_level_3 != null && googleAddress.administrative_area_level_3.length ? ', '+googleAddress.administrative_area_level_3 : (
                        googleAddress.administrative_area_level_2 != null && googleAddress.administrative_area_level_2.length ? ', '+googleAddress.administrative_area_level_2 : (
                            googleAddress.administrative_area_level_1 != null && googleAddress.administrative_area_level_1.length ? ', '+googleAddress.administrative_area_level_1 : ''
                        )
                    )
                )) + 
                (googleAddress.country!=null && googleAddress.country.length ? ', ' + googleAddress.country : '')
            ) : googleAddress['formatted_address'];
        googleAddress['concatenated_params'] =   googleAddress.route + 
            (googleAddress.street_number !== '' ? `, ${googleAddress.street_number}` : '') +
            (googleAddress.postal_code !== '' ? `, ${googleAddress.postal_code}` : '') +
            (googleAddress.locality !== '' ? `, ${googleAddress.locality}` : (
                googleAddress.administrative_area_level_3 !== '' ? `, ${googleAddress.administrative_area_level_3}` : (
                    googleAddress.administrative_area_level_2 !== '' ? `, ${googleAddress.administrative_area_level_2}` : (
                        googleAddress.administrative_area_level_1 !== '' ? `, ${googleAddress.administrative_area_level_1}` : ''
                    )
                )
            )) +
            (googleAddress.country !== '' ? `, ${googleAddress.country}` : '');
        return googleAddress;
    }

    async createGoogleAddressFromString(inputText: string): Promise<GoogleAddress>{
        const gmaps = await this.gMapsManager.getGMaps();

        return new Promise((resolve) => {
            let geocoder = new gmaps.Geocoder();
            let googleAddress: GoogleAddress = {};
            //@ts-ignore
            geocoder.geocode({ 'address': inputText }, async (results, status) => {
                if (status === gmaps.GeocoderStatus.OK && results.length > 0) {
                    let _this = this;
                    googleAddress = _this.createGoogleAddressObject(results[0]);
                }
                resolve(googleAddress);
        });
        })
    }

    _getAddressComp(comps, type, field) {
        const comp = comps.filter((cmp) => cmp.types.includes(type))[0];
        return comp ? comp[field] : '';
    }

    getConcatenatedParams(googleAddress: GoogleAddress): string{
        if(googleAddress['route'] != null && googleAddress['route'].length){
            return googleAddress.route + 
                (googleAddress.street_number !== '' ? `, ${googleAddress.street_number}` : '') +
                (googleAddress.postal_code !== '' ? `, ${googleAddress.postal_code}` : '') +
                (googleAddress.locality !== '' ? `, ${googleAddress.locality}` : (
                    googleAddress.administrative_area_level_3 != null && googleAddress.administrative_area_level_3.length ? ', '+googleAddress.administrative_area_level_3 : (
                        googleAddress.administrative_area_level_2 != null && googleAddress.administrative_area_level_2.length ? ', '+googleAddress.administrative_area_level_2 : (
                            googleAddress.administrative_area_level_1 != null && googleAddress.administrative_area_level_1.length ? ', '+googleAddress.administrative_area_level_1 : ''
                        )
                    )
                )) +
                (googleAddress.country !== '' ? `, ${googleAddress.country}` : '');
        }else{
            return googleAddress['formatted_address'];
        }
    }

    fillGoogleInputAddress(googleAddress: GoogleAddress, inputCivico: object){
        _$(inputCivico).removeClass('uk-hidden');
        _$(inputCivico).val(googleAddress.street_number);
        _$(inputCivico).siblings('.location-finder-number-label').removeClass('uk-hidden');
        _$(inputCivico).closest('.uk-label-float').find('.location-finder').addClass('street-number-visible');
        
    }

    resetGoogleInputAddress(el: object){
        let inputCivico = _$(el).closest('.uk-label-float').find('.location-finder-number');
        _$(inputCivico).val('');
        _$(inputCivico).addClass('uk-hidden');
        _$(inputCivico).siblings('label.location-finder-number-label').addClass('uk-hidden');
        _$(el).closest('.uk-label-float').find('.location-finder').removeClass('street-number-visible');
    }

    setGoogleInputStreetNumber(el: object){
        let inputCivico = _$(el).closest('.uk-label-float').find('.location-finder-number');
        _$(inputCivico).val('');
        _$(inputCivico).removeClass('uk-hidden');
        _$(inputCivico).siblings('label.location-finder-number-label').removeClass('uk-hidden');
        _$(el).closest('.uk-label-float').find('.location-finder').addClass('street-number-visible');
    }
}