import Alpine from 'alpinejs';
import { FlowStore } from '../../../../../../cfamily/types';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, getQueryParamFromUrl, i18n } from '../../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';
import { DEFAULT_WISHLIST_NAME, createWishlist, getWishlistNames, renameWishlist, updateWishlistsContainingProduct } from '../../../../../../libs/wishlists-utils';

Alpine.data('popup12x8xWishlistsName', (args: Record<string, any>) => ({
    existentWishlistsNames: [] as string[],
    wishlistNameRegex: new RegExp("^[a-zA-Z0-9àèìòù\#\.\_\+\%\!\@ \-]{1,50}$") as RegExp,
    textboxValue: '',
    showErrorLabel: false,
    currentWishlistName: undefined, // used only on 'wishlists-rename'
    init() {
        this._loadExistentWishlistsNames();
        this._loadCurrentWishlistName();
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    async _loadExistentWishlistsNames() {
        this.existentWishlistsNames = (await getWishlistNames());
    },
    async _loadCurrentWishlistName() {
        const flow = (await <FlowStore>window.Alpine.store('cFlow'));
        if (flow?.currentStep?.name === 'wishlists-rename') {
            if (flow.data?.wishlistName) {
                this.textboxValue = flow.data.wishlistName;
                this.currentWishlistName = flow.data.wishlistName;
            }
        }
    },
    // main component functions
    checkValidWishlistName(): [boolean, string] {
        if (!this.textboxValue)
            return [false, 'The field cannot be empty'];
        if (this.textboxValue === DEFAULT_WISHLIST_NAME || this.textboxValue?.toUpperCase() === i18n('Favorites')?.toUpperCase())
            return [false, 'A wishlist with this name already exists'];
        if (!this.wishlistNameRegex.test(this.textboxValue))
            return [false, 'The provided name contains invalid characters'];
        if (this.existentWishlistsNames && this.existentWishlistsNames.includes(this.textboxValue))
            return [false, 'A wishlist with this name already exists'];
        return [true, ''];
    },
    async confirm() {
        if (!(this.checkValidWishlistName()[0])) return;
        const flow = (await <FlowStore>window.Alpine.store('cFlow'));
        switch (flow?.currentStep?.name) {
            case 'wishlists-create':
                // create wishlist and add product
                const successC1 = (await createWishlist(this.textboxValue));
                if (!successC1) return;
                // confirm popup
                flow.data.formatLabels = {
                    titleArgs: [this.textboxValue]
                }
                window.cFlowManager.next('wishlists-confirm-create');
                break; // end code block
            case 'wishlists-rename':
                sendAnalytics((() => {
                    let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_POPUP_CONFIRM_RENAME_WISHLIST;
                    ecommerceData.funnel.listaVisualizzata = getQueryParamFromUrl('pre-selected-wishlist', DEFAULT_WISHLIST_NAME);
                    ecommerceData.funnel.listaSelezionata = this.currentWishlistName;
                    return ecommerceData;
                })());
                const successR2 = (await renameWishlist(this.currentWishlistName, this.textboxValue));
                if (!successR2) return;
                // confirm popup
                flow.data.formatLabels = {
                    titleArgs: [this.textboxValue]
                }
                window.cFlowManager.next('wishlists-confirm-rename');
                break; // end code block
            case 'wishlists-create-and-add-product':
                // some checks
                const productCode = flow?.data?.productData?.code;
                const productName = flow?.data?.productData?.nome || '';
                if (!productCode) return;
                // create wishlist and add product
                const successC3 = (await createWishlist(this.textboxValue));
                if (!successC3) return;
                const successA3 = (await updateWishlistsContainingProduct(productCode, new Set([DEFAULT_WISHLIST_NAME, this.textboxValue]), productName));
                if (!successA3) return;
                // confirm popup
                flow.data.formatLabels = {
                    titleArgs: [this.textboxValue]
                }
                window.cFlowManager.next('wishlists-confirm-create-and-add');
                break; // end code block
            default:
                console.warn('the current step does not correspond to one of the possible ones')
                return;
        }
    },
    async cancel() {
        const flow = (await <FlowStore>window.Alpine.store('cFlow'));
        switch (flow?.currentStep?.name) {
            case 'wishlists-create':
                flow.data.result = {
                    state: 'cancelled'
                };
                window.cFlowManager.complete();
                break; // end code block
            case 'wishlists-rename':
                flow.data.result = {
                    state: 'cancelled'
                };
                window.cFlowManager.complete();
                break; // end code block
            case 'wishlists-create-and-add-product':
                window.cFlowManager.back();
                break; // end code block
            default:
                console.warn('the current step does not correspond to one of the possible ones')
                return;
        }
    }
}));
