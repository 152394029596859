import { _$, _Uikit, Window } from '../../main';
import { Utils } from '../../services/utils/utils';
export class ModalCheckoutAddressDelivery {

   constructor(
      private utils: Utils
   ) {
   }

   nkOnInit() {
      
   }
}
