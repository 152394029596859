import { GoogleUtils } from "../../services/utils/google-utils";
import { BookUtils } from "../../services/utils/book-utils";
import { Utils } from "../../services/utils/utils";
import { LocalStorage } from "../../services/local-storage/local-storage";
import { _$, _Uikit, Window } from "../../main";
import { CartService } from "../../services/cart-service/cart-service";
import { UserService } from "../../services/user-service/user-service";
import { Analytics } from "../../services/analytics/analytics";

export class DetailOrder {

    constructor(
        private _google: GoogleUtils,
        private _localStorage: LocalStorage,
        private _bookUtils: BookUtils,
        private _utils: Utils,
        private _cartService: CartService,
        private _userService: UserService,
        private _analytics: Analytics
    ) {
    }

    private dataOrder;
    private orderId = "";

    nkOnInit() {

        _$(".row-order-header").attr("data-order") ? this.dataOrder = JSON.parse(_$(".row-order-header").attr("data-order")) : {};

        _$('#modalVerifyEmail').find('button[nkButton="sendEmailOrder"]').attr('onclick', 'DetailOrder.verifyEmail();');
    }

    setActionOnOrder(action: string) {
        this._localStorage.setItem("actionOnGuestOrder", JSON.stringify(action));
        if (_$("#modalVerifyEmail").length) {
            this.checkGuestEmailOrder();
        } else {
            console.error("modalVerifyEmail not found");
        }
    }


    checkGuestEmailOrder() {
        if (this.dataOrder.amendExpiration && new Date(this.dataOrder.amendExpiration) > new Date()) {
            _Uikit.modal("#modalVerifyEmail").show();
        } else {
            _Uikit.modal("#modalGestisciOrdineLeadTimeOver").show();
        }
    }

    verifyEmail() {

        let success = (res) => {
            this._utils.showLoader("", false);
            let checkoutAction = this._localStorage.getFromStorage("actionOnGuestOrder") ? this._localStorage.getFromStorage("actionOnGuestOrder") : "";
            _$("#email-error-1").addClass("uk-hidden");

            switch (checkoutAction) {
                case "modifyStep2":
                case "modifyCart":
                case "deleteOrder":
                    this.manageOrderChange();
                    break;
                default:
                    console.log("no action found");
                    break;
            }
        };

        let error = (err) => {
            this._utils.showLoader("", false);
            _$("#email-error-1").removeClass("uk-hidden");
        };

        let body = {
            "email": _$("#modalVerifyEmail #emailOrder") && _$("#modalVerifyEmail #emailOrder").val() ? _$("#modalVerifyEmail #emailOrder").val() : "",
            "orderCode": this.dataOrder && this.dataOrder.code ? this.dataOrder.code : "",
            "becommerce": this.dataOrder && this.dataOrder.becommerce ? this.dataOrder.becommerce : ""
        };

        this._utils.showLoader("", true);
        this._userService.verifyModifyGuestOrderEmail(success, error, body);
    }

    /* Gestione a valle di verifica email per la modifica ordine guest */
    manageOrderChange() {
        let checkoutAction = this._localStorage.getFromStorage("actionOnGuestOrder") ? this._localStorage.getFromStorage("actionOnGuestOrder") : "";
        switch (checkoutAction) {
            case "modifyStep2":
                this.fetchTimeslots();
                _$("#modal-timeslot").find(".js-button-conferma-timeslot").attr("onclick", "DetailOrder.showModifyOrderModal()");
                break;
            case "modifyCart":
                _Uikit.modal("#modalModificaOrdineGuest").show();
                break;
            case "deleteOrder":
                _Uikit.modal("#modalAnnullaOrdineGuest").show();
                break;
            default:
                console.log("no action found");
                break;
        }
    }

    fetchTimeslots() {
        if (this.dataOrder) {
            this._bookUtils.modifyStep2GuestOrder(this.dataOrder.typeOfService, this.dataOrder.pointOfServiceId, this.dataOrder.becommerce, this.dataOrder.deliveryArea, this.dataOrder.tsdEnabled);
        } else {
            console.log("no data order found for fetching timeslots");
        }
    }

    async modifyOrderUpdate() {
        let checkoutAction = this._localStorage.getFromStorage("actionOnGuestOrder") ? this._localStorage.getFromStorage("actionOnGuestOrder") : "";

        switch (checkoutAction) {
            case "modifyStep2":
                /* delete cart */
                await this.deleteCart();
                break;
            case "modifyCart":
                /* delete cart */
                await this.deleteCart();
                break;
            default:
                console.log("no action found");
                break;
        }
    }

    deleteCart() {
        let success = (res) => {
            console.log("cart deleted successfully");
            this._utils.showLoader("", false);
            let checkoutAction = this._localStorage.getFromStorage("actionOnGuestOrder") ? this._localStorage.getFromStorage("actionOnGuestOrder") : "";
            switch (checkoutAction) {
                case "modifyStep2":
                    /* add cart */
                    this.openGuestOrder();
                    break;
                case "modifyCart":
                    /* add cart */
                    this.openGuestOrder();
                    break;
                default:
                    console.log("no action found");
                    break;
            }

        };

        let error = (err) => {
            this._utils.showLoader("", false);
            console.log("error deleting cart");
        };

        let body = {};

        this._utils.showLoader("", true);
        this._cartService.deleteCart(success, error, body);
    }

    openGuestOrder() {
        let success = (res) => {
            this._utils.showLoader("", false);
            let checkoutAction = this._localStorage.getFromStorage("actionOnGuestOrder") ? this._localStorage.getFromStorage("actionOnGuestOrder") : "";
            switch (checkoutAction) {
                case "modifyStep2":
                    this._bookUtils.bookingSlotOnCart();
                    break;
                case "modifyCart":
                    /* redirect al cart */
                    Window.location.href = Window.urlSite.detailCart + "?edit";
                    break;
                default:
                    console.log("no action found");
                    break;
            }
        };

        let error = (err) => {
            this._utils.showLoader("", false);
            console.log("error opening order");
        };

        let body = {
            "pointOfServiceId": this.dataOrder ? this.dataOrder.pointOfServiceId : "",
            "becommerce": this.dataOrder ? this.dataOrder.becommerce : "",
            "deliveryArea": this.dataOrder ? this.dataOrder.deliveryArea : "",
            "parentOrderId": this.dataOrder ? this.dataOrder.code : ""
        };

        this._utils.showLoader("", true);

        this._cartService.reopenGuestOrder(success, error, body);
    }

    deleteGuestOrder() {

        let success = (res) => {
            this._utils.showLoader('', false);
            this.orderId = this.dataOrder.code ? this.dataOrder.code : "";
            _Uikit.modal("#modalOrdineGuestAnnullato").show();
        };

        let error = (err) => {
            this._utils.showLoader('', false);
            console.log("Error in cancel order guest");
        };

        let body = {
            "code": this.dataOrder ? this.dataOrder.code : "",
            "becommerce": this.dataOrder ? this.dataOrder.becommerce : "",
            "typeOfService": this.dataOrder ? this.dataOrder.typeOfService : ""
        };

        this._utils.showLoader('', true);
        this._cartService.cancelOrder(success, error, body);
    }

    showModifyOrderModal() {
        _Uikit.modal("#modalModificaOrdineGuest").show();
    }

    sendAnalytics(eventType) {
        try {
            let analyticsData;

            switch (eventType) {
                case 'stampaRicevutaPagamento':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Checkout',
                            stepFunnel: 'Stampa ricevuta pagamento',
                        }
                    }
                    break;
            }

            this._analytics.sendAnalytics(analyticsData, '', false, true);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

}