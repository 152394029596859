import { Window, _$, _Uikit } from '../../main';
import { Utils } from '../../services/utils/utils';
import { GoogleUtils } from '../../services/utils/google-utils';
import { Analytics } from '../../services/analytics/analytics';
import { LocalStorage } from 'src/services/local-storage/local-storage';
import { Search } from 'src/services/analytics/analytics-interface';
import { GoogleInputManager } from '../../services/utils/google-service/google-input-manager';

export class Landingpage {
    private region: string = '';
    private _search: Search = {};

    constructor(
        private _utils: Utils,
        private _google: GoogleUtils,
        private _analytics: Analytics,
        private _localStorage: LocalStorage,
        private _googleInputManager: GoogleInputManager
        
    ) {}

    nkOnInit() {
        this.onCustomChange();
        setTimeout(() => {
            this.sendAnalytics('pageview');
        }, 100);
        if (this._utils.getQueryValue()) {
            this.region = this._utils.getQueryValue().toString();
            this.loadRegion();
        }
        this.placeFeedbackFull();
        this.checkAlertAccediScegliServizio();
        this.checkAgevolazione();
    }

    checkAgevolazione() {
        let url = Window.location.href;
        if (url.indexOf('?') > 0) {
            let params = url.toString().split('?')[1];
            let agType = params.split('=')[1];

            switch(agType) {
                case 'over':
                    _Uikit.offcanvas('#offcanvasAgevolazioneOver').show();
                    break;
                case 'inv':
                    _Uikit.offcanvas('#offcanvasAgevolazioneInvalidita').show();
                    break;
            }
        }
    }

    checkAlertAccediScegliServizio(){
        if(Window.typeOfService || Window.user){
            _$('.alert-accedi-scegli-servizio').addClass('uk-hidden');
        }
    }

    loadRegion() {
        _$('.img-with-region').removeClass('uk-hidden');
        _$('.img-no-region').addClass('uk-hidden');
        _$('.landing-header').addClass('loaded-region');
        _$('.landing-header .header-title').html(this.region);
        _$('.list-region').addClass('uk-hidden');
        _$('.list-card-store').removeClass('uk-hidden');
    }

    placeFeedbackFull() {
        let height = _$('.component-header').innerHeight() + 8;
        let attributeContent = _$('.feedback-full').attr('uk-sticky');
        _$('.feedback-full').attr('uk-sticky', attributeContent + "; offset:" + height);
    }

    sendAnalytics(eventType, obj?, tipoRicerca?, indirizzoRicerca?) {
        try {
            let analyticsData;
            let step;
            let city = this.getCity();
            let idNegozio = window['pointOfService'] ? window['pointOfService']['name'] : '';
            let cooperativaNegozio = window['pointOfService'] ? window['pointOfService']['cooperativeId'] : '';

            let needSlot = false;
            switch (eventType) {
                case 'pageview':
                    //6.3.1 e 6.3.2
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel:'Copertura servizio', 
                            tipoServizio: window['typeOfService'] == 'ORDER_AND_COLLECT' ? 'Ordina e ritira' : 'Ordina e ricevi', 
                            sceltaNegozio: {
                                idNegozio,
                                cooperativaNegozio
                            }
                        }
                    }
                    needSlot = true;
                    break;
                case 'tipoServizio':
                    //6.3.3
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel:'Copertura servizio', 
                            tipoServizio: _$(obj).find('span').eq(1).html()
                        }
                    }
                    break;
                case 'ricercaIndirizzo':
                    //6.3.4
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel:'Copertura servizio', 
                            tipoServizio: _$('.box-ordina-ricevi').children('.uk-subnav').children('.uk-active').find('span').eq(1).html(),
                            ricerca:{
                                tipoRicerca,
                                indirizzoRicerca
                            }
                        }
                    }
                    break;
            }
    
            if (city) {
                analyticsData.funnel['stepFunnel'] = 'Lista ' + city;
            } else {
                analyticsData.funnel['stepFunnel'] = 'Lista regioni';
            }
      
            this._analytics.sendAnalytics(analyticsData, '', false, needSlot);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    onCustomChange() {
        let _this = this;
        _$('.page-Landingpage')
            .find('input.order-ritira')
            .on('manualAddress locateAddress', _this, function (e) {
                var tipoRicerca = e.type.indexOf('manualAddress') > -1 ? 'Manuale' : 'Geolocalizzazione';
                var indirizzoRicerca = _this._localStorage.getFromStorage('selectedAddress') != null ? _this._localStorage.getFromStorage('selectedAddress').formatted_address : '';
                if (indirizzoRicerca == '') {
                    indirizzoRicerca = _$("#pdv").val().toString();
                }
                _this.sendAnalytics('ricercaIndirizzo', null, tipoRicerca, indirizzoRicerca); 
                _this._localStorage.setItem('ricercaAnalytics', JSON.stringify(_this._search));
            }); 
    }


    openAgevolazione(type: string) {
        switch (type) {
            case 'over':
                if (!Window.user) {
                    let url = Window.location.href + '?agType=over';
                    this._utils.goToMyConad('login', url);
                    break;
                }
                _Uikit.offcanvas('#offcanvasAgevolazioneOver').show();
                break;
            case 'inv':
                if (!Window.user) {
                    let url = Window.location.href + '?agType=over';
                    this._utils.goToMyConad('login', url);
                    break;
                }
                _Uikit.offcanvas('#offcanvasAgevolazioneInvalidita').show();
                break;
        }
    }


    /**
     * Switch della sezione tra regioni / stores
     * @param type
     */
    switchSection(type: string) {
        if(type === 'ricevi') {
            _$('.js-sezione-stores-ricevi').removeClass('uk-hidden');
            _$('.js-sezione-stores-ritira').addClass('uk-hidden');
        } else if (type === 'ritira') {
            _$('.js-sezione-stores-ritira').removeClass('uk-hidden');
            _$('.js-sezione-stores-ricevi').addClass('uk-hidden');
        }
    }

    getCity(){
        if(_$('.js-sezione-stores-ricevi').length || _$('.js-sezione-stores-ritira').length){
            return _$('.landing-header .header-title').length ? _$('.landing-header .header-title').text() : '';
        }
    }
}
