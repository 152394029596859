import { Utils } from '../../services/utils/utils';
import { GoogleUtils } from '../../services/utils/google-utils';
import Swiper, { SwiperOptions } from 'swiper';
import { _Uikit, Window, _$ } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
import { BANNER_TYPES, AnalyticsUtility } from '../../services/analytics/analytics-utility';

export class Homepage {

    _urlEdit: boolean;

    constructor(
        private _utils: Utils,
        private _google: GoogleUtils,
        private _analytics: Analytics,
        private _analyticsUtility: AnalyticsUtility
    ) { }

    nkOnInit() {
        setTimeout(() => {
            this.checkLogIn();
            this._utils.customScrollbar();
        }, 100);
        
        this.sendAnalytics('pageview');
        this.setBannerSponsorAnalytics();
        this.setBannersClickEvents();
    }


    checkLogIn() {
        if (!Window.user) {
            _$(".swiper-button-utente").find("li.uk-active").removeClass("uk-active");
        }
    }

    

    swiperButtonMenuIspOptions: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 16,
        freeMode: true,
        breakpointsInverse: true,
        breakpoints: {
            375: {
                noSwiping: false,
                allowSlideNext: true,
                allowSlidePrev: true
            },
            600: {
                noSwiping: true,
                allowSlideNext: false,
                allowSlidePrev: false
            }
        },
    };
    swiperButtonIsp = new Swiper('.swiper-button-isp', this.swiperButtonMenuIspOptions);

    setBannersClickEvents(){
        _$('.component-PromoMdd .component-CardSimple').on('click', (event) => {
            this.sendAnalytics('advClickPromotionsVisibilities', _$(event.target).closest('.component-CardSimple'));
        });

        _$('.component-1-4-conad-brands').find('.component-TileBrand').on('click', (event) => {
            this.sendAnalytics('advClickConadBrands', _$(event.target).closest('.component-TileBrand'));
        });

        _$('.component-ShopIdm .component-TilePartner').on('click', (event) => {
            this.sendAnalytics('advClickConadPartners', _$(event.target).closest('.component-TilePartner'));
        });

        _$('.component-ProdottiEvidenza').on('click', (event) => {
            this.sendAnalytics('advClickHighlightedProducts', event.target);
        })

        _$('.component-Ispirazionale').on('click', (event) => {
            this.sendAnalytics('advClickIspirazionale', _$(event.target).closest('.component-Ispirazionale'));
        });

        _$('.component-StorytellingA').on('click', (event) => {
            this.sendAnalytics('advClickStorytellingA', _$(event.target).closest('.component-StorytellingA'));
        });

        _$('.component-StorytellingB').on('click', (event) => {
            this.sendAnalytics('advClickStorytellingB', _$(event.target).closest('.component-StorytellingB'));
        });
    }

    async sendAnalytics(eventType, obj?){
        try {
            let analyticsData;
            let step;
            let tipoServizio = Window.pointOfService === 'ORDER_AND_COLLECT' ? 'Ordina e ritira' : 'Ordina e ricevi' ;
            let idNegozio = Window.pointOfService ? Window.pointOfService.name : '';
            let coopNegozio = Window.pointOfService ? Window.pointOfService.cooperativeId : '';
            let giorno = Window.selectedTimeslot ? this._analytics.getDayFromTimestamp(Window.selectedTimeslot.date) : '';
            let fasciaOraria = Window.selectedTimeslot ? Window.selectedTimeslot.startTime.formattedHour + " - " + Window.selectedTimeslot.endTime.formattedHour : '';
            let sponsor = [];
            let datapromoArray = [];
            let banner;
            let slides;
            let slide;
            let clickEvent = false;
    
            switch (eventType) {
                case 'pageview':

                    let bannerList = [BANNER_TYPES.PROMO_COMPONENT, 
                                        BANNER_TYPES.STRIP_BTF, 
                                        BANNER_TYPES.CARD_OFFERTA_BTF,
                                        BANNER_TYPES.CARD_SHOP_IN_SHOP_BTF,
                                        BANNER_TYPES.BANNER_SPONSOR,
                                        BANNER_TYPES.PROMOTIONS_AND_VISIBILITIES,
                                        BANNER_TYPES.CONAD_BRANDS,
                                        BANNER_TYPES.HIGHLIGHTED_PRODUCTS,
                                        BANNER_TYPES.ISPIRAZIONALE,
                                        BANNER_TYPES.STORYTELLING_A,
                                        BANNER_TYPES.STORYTELLING_B];

                    bannerList.forEach((value) => {
                        [sponsor, datapromoArray] = this._analyticsUtility.getBannerPageview(value, sponsor, datapromoArray);
                    });
    
                    let prodotto = []
                    let productCard = _$('.component-ProductCard');
    
                    for (var i = 0; i < productCard.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                        if(_$(productCard.eq(i)).closest('.component-ProdottiEvidenza').length){
                            prodotto[prodotto.length - 1].carosello = 
                                _$(productCard.eq(i)).closest('.component-ProdottiEvidenza').find('.title-prod-evid').length ?
                                    _$(productCard.eq(i)).closest('.component-ProdottiEvidenza').find('.title-prod-evid').html() : 'Prodotti in evidenza';
                        }else if(_$(productCard.eq(i)).closest('.component-OfferteScadenzaA').length){
                            prodotto[prodotto.length - 1].carosello = 
                                _$(productCard.eq(i)).closest('.component-OfferteScadenzaA').find('.title-off-scad-a').length ?
                                    _$(productCard.eq(i)).closest('.component-OfferteScadenzaA').find('.title-off-scad-a').html() : 'Offerte in scadenza A';
                        }else if(_$(productCard.eq(i)).closest('.component-IspirazionaleHotspot').length){
                            prodotto[prodotto.length - 1].carosello = 
                                _$(productCard.eq(i)).closest('.component-IspirazionaleHotspot').find('.section-lasciati-ispirare-hotspot > .uk-container > .section-title').length ?
                                    _$(productCard.eq(i)).closest('.component-IspirazionaleHotspot').find('.section-lasciati-ispirare-hotspot > .uk-container > .section-title').html() : 'Offerte in scadenza A';
                            
                        }
                    }
    
                    let productCardLastView = _$('.component-ProductCardLastView');
                    for (var i = 0; i < productCardLastView.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))))
                        
                        if(_$(productCardLastView).eq(i).closest('.component-StorytellingA').length){
                            prodotto[prodotto.length - 1].carosello =
                                _$(productCardLastView).eq(i).closest('.component-StorytellingA').find('.txt-container .title').length ?
                                _$(productCardLastView).eq(i).closest('.component-StorytellingA').find('.txt-container .title').html() : 'Storytelling A';
                        }else if(_$(productCardLastView).eq(i).closest('.component-StorytellingB').length){
                            prodotto[prodotto.length - 1].carosello =
                                _$(productCardLastView).eq(i).closest('.component-StorytellingB').find('.banner > .title').length ?
                                _$(productCardLastView).eq(i).closest('.component-StorytellingB').find('.banner > .title').html() : 'Storytelling B';
                        }else if(_$(productCardLastView).eq(i).closest('.component-OfferteScadenzaB').length){
                            prodotto[prodotto.length - 1].carosello =
                                _$(productCardLastView).eq(i).closest('.component-OfferteScadenzaB').find('.section-off-scad-container > .section-title').length ?
                                _$(productCardLastView).eq(i).closest('.component-OfferteScadenzaB').find('.section-off-scad-container > .section-title').html() : 'Offerte in scadenza B';
                        }else if(_$(productCardLastView).eq(i).closest('.component-Ispirazionale').length){
                            prodotto[prodotto.length - 1].carosello =
                                _$(productCardLastView).eq(i).closest('.component-Ispirazionale').find('.section-lasciati-ispirare > .uk-container > .section-title').length ?
                                _$(productCardLastView).eq(i).closest('.component-Ispirazionale').find('.section-lasciati-ispirare > .uk-container > .section-title').html() : 'Ispirazionale';
                            
                        }else if(_$(productCardLastView).eq(i).closest('.component-SpinteMarketing').length){
                            prodotto[prodotto.length - 1].carosello =
                                _$(productCardLastView).eq(i).closest('.component-SpinteMarketing').find('.section-popolari-container .title').length ?
                                _$(productCardLastView).eq(i).closest('.component-SpinteMarketing').find('.section-popolari-container .title').html() : 'Spinte Marketing';
                        }else if(_$(productCardLastView).eq(i).closest('.component-ScopriNovita').length){
                            prodotto[prodotto.length - 1].carosello =
                                _$(productCardLastView).eq(i).closest('.component-ScopriNovita').find('.section-scopri-novita > .uk-container > .section-title').length ?
                                _$(productCardLastView).eq(i).closest('.component-ScopriNovita').find('.section-scopri-novita > .uk-container > .section-title').html() : 'Scopri Novità';
                        }
                    }
    
                    let cardSuggest = _$('.component-CardSuggest');
                    for (var i = 0; i < cardSuggest.length; i++) {
                        prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                        if(_$(cardSuggest.eq(i)).closest('.component-AreaUtente').length){
                            prodotto[prodotto.length - 1].carosello = 
                            _$(cardSuggest.eq(i)).closest('.component-AreaUtente').find('li').eq(0).find('.uk-tag').length ?
                                _$(cardSuggest.eq(i)).closest('.component-AreaUtente').find('li').eq(0).find('.uk-tag').html() : 'Area utente';
                        }
                    }
    
                    analyticsData = {
                        event: eventType,
                        sponsor,
                        prodotto: prodotto
                    }
                    step = 'FUNNEL_DATA_HOME';
                break;
    
                case 'advVediTuttiOfferte':
                    //6.4.7
                    banner = _$('.component-AreaOfferte').length ? _$('.component-AreaOfferte') : '';
                    slides = banner ? banner.find(".section-carousel").find(".swiper-wrapper").find(".swiper-slide") : '';
    
                    for (let i = 0; i < slides.length; i++) {
                        slide = slides ? slides.eq(i) : '';
                        sponsor.push({
                            brand: "",
                            campagna: slide ? slide.find('.card-offerta-title').html() : '',
                            formato: 'Card offerta BTF',
                            landing: slide ? slide.find('.link-card-offerta a').attr('href') : '',
                            posizioneSlide: i+1
                        });

                        if (slide.find('div').first().attr('data-promo')) {
                            datapromoArray.push(JSON.parse(slide.find('div').first().attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }                          
                    } 
    
                    analyticsData = {
                        event: 'advVediTutti',
                        sponsor,
                    }
                    step = 'FUNNEL_DATA_HOME';
                break;
    
                case 'advVediTuttiPartner':
                    //6.4.7
                    banner = _$('.component-ShopIdm').length ? _$('.component-ShopIdm') : '';
                    slides = banner ? banner.find(".swiper-slide") : '';
    
                    for (let i = 0; i < slides.length; i++) {
                        slide = slides ? slides.eq(i) : '';
                        sponsor.push({
                            brand: slide ? slide.find('span').html() : '',
                            campagna: '',
                            formato: 'Card Shop in Shop BTF',
                            landing: slide ? slide.find('a').attr('href') : '',
                            posizioneSlide: i+1
                        });
                        if (slide.find('div').first().attr('data-promo')) {
                            datapromoArray.push(JSON.parse(slide.find('div').first().attr('data-promo')));
                        } else {
                            datapromoArray.push("");
                        }   
                    }
    
                    analyticsData = {
                        event: 'advVediTutti',
                        sponsor,
                    }
                    step = 'FUNNEL_DATA_HOME';
                break;
    
                case 'advClickPartner':
                    //6.4.6
                    var card = _$(obj);
                    sponsor.push({
                        brand: card.find('span').html(),
                        campagna: '',
                        formato: 'Card Shop in Shop BTF',
                        landing: card.attr('href'),
                        posizioneSlide: 0,
                    });
    
                    analyticsData = {
                        event: 'advClick',
                        sponsor,
                        prodotto: {}
                    }
                    step = 'FUNNEL_DATA_HOME';
                break;
    
                case 'advClickOffer':
                    //6.4.6
                    var card = _$(obj).closest('.component-OfferCard');
    
                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            brand: "",
                            campagna: card.find('.card-offerta-title').html(),
                            formato: 'Card offerta BTF',
                            landing: card.find('.link-card-offerta').children('a').attr('href'),
                            posizioneSlide: _$(obj).closest('.swiper-slide').index() + 1,
                        }],
                        prodotto: {}
                    }
                    step = 'FUNNEL_DATA_HOME';
                break;

                case 'advClickBannerSponsor':
                    //6.4.4
                    var card = _$(obj).closest('.component-BannerSponsor');

                    analyticsData = {
                        event: 'advClick',
                        sponsor: [{
                            brand: '',
                            campagna: card.find('.banner-title').html(),
                            formato: 'Banner BTF',
                            landing: card.find('a').attr('href'),
                            posizioneSlide: _$(obj).closest('.swiper-slide').index() + 1,
                        }],
                        prodotto: {}
                    }
                    step = 'FUNNEL_DATA_HOME';
                    break;
                case 'advClickConadBrands':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.CONAD_BRANDS, analyticsData, obj);
                    break;
                case 'advClickPromotionsVisibilities':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.PROMOTIONS_AND_VISIBILITIES, analyticsData, obj);
                    break;
                case 'advClickConadPartners':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.CARD_SHOP_IN_SHOP_BTF, analyticsData, obj);
                    break;
                case 'advClickHighlightedProducts':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.HIGHLIGHTED_PRODUCTS, analyticsData, obj);
                    break;
                case 'advClickIspirazionale':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.ISPIRAZIONALE, analyticsData, obj);
                    break;
                case 'advClickStorytellingA':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.STORYTELLING_A, analyticsData, obj);
                    break;
                case 'advClickStorytellingB':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.STORYTELLING_B, analyticsData, obj);
                    break;
                default: 
                    break;
            }

            step = 'FUNNEL_DATA_HOME';

            if(clickEvent){
                if(eventType == 'advClickHighlightedProducts')
                    obj = _$(obj).closest('.component-ProdottiEvidenza');
                let dataPromo = _$(obj).attr('data-promo') != null && _$(obj).attr('data-promo') != '' ? JSON.parse(_$(obj).attr('data-promo')) : '';
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo);
            }else{
                if (_$(obj).closest('.component-OfferCard').attr('data-promo')) {
                    let dataPromo = JSON.parse(_$(obj).closest('.component-OfferCard').attr('data-promo'));
                    this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
                } else if (_$(obj).closest('.component-TilePartner').attr('data-promo')) {
                    let dataPromo = JSON.parse(_$(obj).closest('.component-TilePartner').attr('data-promo'));
                    this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
                } else if (_$(obj).closest('.component-BannerSponsor').attr('data-promo')) {
                    let dataPromo = JSON.parse(_$(obj).closest('.component-BannerSponsor').attr('data-promo'));
                    this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
                } else if (_$(obj).closest('.component-PromoIdm').attr('data-promo')) {
                    let dataPromo = JSON.parse(_$(obj).closest('.component-PromoIdm').attr('data-promo'));
                    this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
                } else if (_$(obj).closest('.component-ShopIdm').attr('data-promo')) {
                    let dataPromo = JSON.parse(_$(obj).closest('.component-ShopIdm').attr('data-promo'));
                    this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
                } else {
                    if (datapromoArray.length) {
                        this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                    } else {
                        this._analytics.sendAnalytics(analyticsData, step);
                    }
                }  
            }        
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    setBannerSponsorAnalytics(){
        _$('.component-BannerSponsor').on('click', (event) => {
            this.sendAnalytics('advClickBannerSponsor', _$(event.target).closest('.component-BannerSponsor'));
        });
    }
}