import { Utils } from './../../services/utils/utils';
import { _$, _Uikit, Window } from '../../main';
import { UserService } from '../../services/user-service/user-service';
import { Analytics } from 'src/services/analytics/analytics';

export class ModalLogin {

    constructor(private utils: Utils, private userService: UserService, private _analytics: Analytics) {
    }

    nkOnInit() {
        _Uikit.util.on('#offcanvasLogin', 'show', () => {
            setTimeout(() => {
                this.sendAnalytics("pageview");
            }, 500);
        });

        _$( ".js-button-login" ).on( "click", () => {
            this.sendAnalytics("clickConfermaIdentita")
        });
    }

    logoutUser() {
        let success = (res) => {
            _Uikit.offcanvas('#offcanvasLogin').hide();
            let url = encodeURIComponent(Window.location.href);
            Window.location.href = Window.urlSite.myConadLogin + '?cb=' + url;
        }
        let error = (err) => {
            _Uikit.offcanvas('#offcanvasLogin').hide();
        }
        this.userService.doLogout(success, error);
    }

    sendAnalytics(eventType: string, e?: object){
        try {
            let analyticsData;
            let funnelDict;
            let myEvent = eventType;

            let page = "";
            if (_$('body').attr('nkpage')) {
                page = _$('body').attr('nkpage').toString();
            }
    
            analyticsData = {
                event: myEvent,
                pagina: {
                    locazioneBannerIdentita: page
                }
            }

            switch (myEvent) {
                case 'pageview':
                    //1.1.1
                    funnelDict = 'FUNNEL_DATA_CONFERMA_IDENTITA';
                    break;
                case 'clickConfermaIdentita':
                    //1.1.2
                    funnelDict = 'FUNNEL_DATA_CLICK_CONFERMA_IDENTITA';
                    break;                
            }
    
            this._analytics.sendAnalytics(analyticsData, funnelDict, true, false);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    // Login - spostata al checkout
    // callLogin() {
    //     let errorMessage = document.getElementsByClassName('js-alert-login')[0];
    //     let inputFields = _$('.component-modal-login input');
    //     let success = (res) => {
    //         if (res) {
    //             errorMessage.classList.add('uk-hidden');
    //             inputFields.removeClass('uk-form-danger');
    //             location.reload(); // da verificare se funziona correttamente
    //         } else {
    //             errorMessage.classList.remove('uk-hidden');
    //             inputFields.addClass('uk-form-danger');
    //         }
    //     };
    //     let error = (err) => {
    //         // da capire la gestione degli errori
    //         errorMessage.classList.remove('uk-hidden');
    //         inputFields.addClass('uk-form-danger');
    //     };
    //     let params = {
    //         email: this.valueEmail,
    //         password: this.valuePassword,
    //     };
    //     this.userService.doLogin(success, error, params);
    // }

    // check field - spostata al checkout
    // setValueInput(e, type) {
    //     switch (type) {
    //         case 'email':
    //             this.valueEmail = e.value;
    //             break;
    //         case 'password':
    //             this.valuePassword = e.value;
    //             break;
    //     }
    //
    //     if (this.valuePassword && this.utils.isValidEmail(this.valueEmail)) {
    //         _$('.js-button-login').removeAttr('disabled');
    //     } else {
    //         _$('.js-button-login').attr('disabled', 'true');
    //     }
    // }

    // Login Social - spostata al checkout
    // callSocialLogin(e: object) {
    //     let social = _$(e).attr('data-social');
    //
    //     let success = (res) => {
    //         if (res.data) {
    //             Window.location.href = res.data['socialLoginUrl'];
    //         }
    //     };
    //
    //     let error = (err) => {
    //         console.log(err);
    //     };
    //
    //     let params = {
    //         socialType: social,
    //         startingPage: window.location.pathname + window.location.search,
    //     };
    //
    //     this.userService.doSocialLogin(success, error, params);
    // }
}
