import { Alpine } from 'alpinejs';
import { CChangeDetail } from '../types';

export const cDependOn = (Alpine: Alpine) => {
    Alpine.directive('cdependon', (el: HTMLElement, { expression }, { cleanup, evaluateLater }) => {
        const realEl = el as HTMLInputElement;

        if (!expression) {
            return;
        }

        const form = realEl.closest('form');
        if (!form) {
            return;
        }

        const dependencyEl = form.querySelector(`[name='${expression}']`) as HTMLInputElement;
        if (!dependencyEl) {
            return;
        }

        const changeHandler = (e: Event) => {
            const detail = (e as CustomEvent).detail as CChangeDetail;

            const ev = new CustomEvent('cdependchange', {
                detail,
                bubbles: true,
            });
            realEl.dispatchEvent(ev);
        };

        dependencyEl.addEventListener('cchange', changeHandler);
        cleanup(() => {
            dependencyEl.removeEventListener('cchange', changeHandler);
        });
    });
};
