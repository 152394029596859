import { Analytics } from "../../services/analytics/analytics";
import { Utils } from "../../services/utils/utils";

export class CardNegozio {

    constructor() {
    }

    nkOnInit() {
    }
}