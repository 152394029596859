import Alpine from "alpinejs";

Alpine.data('ecDetailProduct', () => ({
    productData: {} as {},
    init() {
        this.productData = JSON.parse(this.$el.dataset?.product);
        if (!this.productData) {
            console.warn('no data-product attribute found')
            return;
        }
    },
    // internal functions
    // main component functions
}));