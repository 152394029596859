import { Window, _$, _Uikit } from '../../main';
import { Analytics } from '../../services/analytics/analytics';

export class PromoIdm {
   
   constructor(
      private _analytics: Analytics
   ) { 
      
   }
   
   nkOnInit() { }  
   
   sendAnalytics(eventType, obj) {
      try {
         var page = _$("body").attr("nkPage");
         var step;
         var card = _$(obj).closest('.component-PromoIdm');
         let analyticsData;
         var posizioneSlide = 0;

         var slides = _$(obj).closest('.swiper-promo-idm').find('.swiper-slide');
         var slidesDuplicate =  _$(obj).closest('.swiper-promo-idm').find('.swiper-slide-duplicate').length;

         if((slides.length - slidesDuplicate) > 1){
            for (let i = 0; i < slides.length; i++) {
               posizioneSlide = slides.eq(i).hasClass('swiper-slide-active') ? i : posizioneSlide;
            }
         }

         var myPos = posizioneSlide.toString();
         
         analyticsData = {
            event: eventType,
            sponsor: [{
               brand: card.find('.section-title').children('b').length ? card.find('.section-title').children('b').html() : '',
               campagna: card.find('.promo-title').length ? card.find('.promo-title').html() : '',
               landing: card.find('.button-scopri').attr('href'),
               posizioneSlide: myPos,
            }],
            prodotto: {}
         }
         if (card.hasClass('type-b1')){
            analyticsData.sponsor[0]['formato'] = 'Banner testata SX';
         } else if (card.hasClass('type-b2')){
            analyticsData.sponsor[0]['formato'] = 'Banner testata DX';
            analyticsData.sponsor[0]['posizioneSlide'] = "0";
         } else if (card.hasClass('type-c')) {
            analyticsData.sponsor[0]['formato'] = 'tbd';
            analyticsData.sponsor[0]['posizioneSlide'] = "0";
         } else {
            analyticsData.sponsor[0]['formato'] = 'Banner testata';
         }
   
         switch (page){
            case 'Homepage':
               step = 'FUNNEL_DATA_HOME';
            break;
            case 'Listinglevel1':
               step = 'FUNNEL_DATA_OFFERS';
            break;
         }

         if (_$(obj).closest('.component-PromoIdm').attr('data-promo')) {
            let dataPromo = JSON.parse(_$(obj).closest('.component-PromoIdm').attr('data-promo'));
            this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
         } else {
            this._analytics.sendAnalytics(analyticsData, step);
         }   
      } catch (error) {
         console.log(eventType + ' : ' + error);
      }
   }
}