import { Analytics } from 'src/services/analytics/analytics';
import Swiper, { SwiperOptions } from 'swiper';
import { _Uikit, Window, _$ } from '../../main';

export class BannerListing {
   
    constructor(private _analytics: Analytics) { }
    
    nkOnInit() { 
        this.initHotspot();
    }

    /* Inizializza gli hotspot Image dynamic per Componente - 2.2 Banner Listing Text Image (type hotspot) */
    initHotspot(){
        try{
            //load s7 div element
            var dms = document.querySelectorAll(".dm-interactive-image");
            for (let i = 0; i < dms.length; i++) {
                var dmDiv = dms[i];
                //load params
                //@ts-ignore
                var params = JSON.parse(dmDiv.dataset.configuration);

                //create s7 viewer
                //@ts-ignore
                var s7interactiveimageviewer = new s7viewers.InteractiveImage({
                    "containerId" : dmDiv.id,
                    "params" : params});

                //add event listeners to hotspot click
                
                let functions = this;
                s7interactiveimageviewer.setHandlers({
                    "quickViewActivate": function(data/* objID,compClass,instName,timeStamp,eventInfo */) {
                        //console.log(objID,compClass,instName,timeStamp,eventInfo);
                        
                        /* var elem = _$('#switcher-hotspot').find('div[prod-id='+data.sku+']')
                        _Uikit.drop(_$(elem)).show(); */
                        
                    },
                    "initComplete": function(){
                        functions.addCardsToHotspots();
                    }
                });
                //init s7 viewer
                s7interactiveimageviewer.init();

            }
        }catch(error){
            console.log(error);
        }
    }

    /* Aggiunge le dot card agli hotspot Image dynamic per Componente - 2.2 Banner Listing Text Image (type hotspot) */
    addCardsToHotspots() {
        var slide = _$('.banner-hotspot .img-container');
        var hotspots;
        var dotCards;
        var card;

        hotspots = slide.find('.s7icon');
        dotCards = slide.find('.dot-card-container').find('.component-DotCard');
        
        var controlProduct = slide.find('.dm-interactive-image').attr('data-product-ids'); //check no null hotspot

        if (controlProduct && controlProduct.length) {
            var checkProduct = (slide.find('.dm-interactive-image').attr('data-product-ids')).split(','); //check no null hotspot
            if (hotspots.length == checkProduct.length) {
                var validProd = checkProduct.length;
                if (checkProduct.indexOf('null') > -1) {
                    for(var i = 0; i < checkProduct.length; i++) {
                        if (checkProduct[i] == 'null') {
                            validProd--;
                            _$(hotspots[i]).css('display','none');
                            delete hotspots[i];
                        }
                    }
                }

                for (var j = 0; j < validProd; j++) {
                    card = dotCards[j].outerHTML.toString();
                    _$(hotspots[Object.keys(hotspots)[j]]).attr('card-id','slide-'+j);
                    _$(hotspots[Object.keys(hotspots)[j]]).attr('onclick',"BannerListing.sendAnalytics('clickHotspot',this)");
                    _$(hotspots[Object.keys(hotspots)[j]]).after('<div uk-drop="mode: click,hover; pos: bottom-center">' + card + '</div>');
                }
            } else {
                for(var i = 0; i < hotspots.length; i++) {
                    _$(hotspots[i]).css('display','none');
                }
            }
        }
    } 


    /* Swiper for Component 2.3 - Banner Listing Iniziative */
    swiperFlatCatOptions: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        freeMode: true,
        pagination: {
            el: '.pagination-flat-cat',
            clickable: true,
        },

        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerGroup: 2,
                slidesPerView: 2
            },
            768: {
                slidesPerGroup: 4,
                slidesPerView: 4
            },
            1024: {
                slidesPerGroup: 6,
                slidesPerView: 6
            },
            1440: {
                slidesPerGroup: 8,
                slidesPerView: 8
            },
        },
    };
    swiperFlatCat = new Swiper('.swiper-flat-cat', this.swiperFlatCatOptions);


    /**
     * Analytics per Componente - 2.2 Banner Listing Text Image (type hotspot)
     * @param eventType
     * @param e
     */
    async sendAnalytics(eventType, e?){
        console.log(_$(e).closest('.dot-card'))
        try {
            let page = document.location.pathname.split('/');
            let myPage = page[page.length - 1].split('?')[0];

            //6.5.2
           
            let analyticsData;

            analyticsData = {
                event: eventType,
                funnel: {
                    nomeFunnel: 'Scelta prodotto',
                    stepFunnel: 'Listing page'
                },
                prodotto: (await this._analytics.getProductData(_$(e).next().find('.dot-card'))),
                sponsor: [{
                    brand: '',
                    campagna: _$(e).closest('.banner-hotspot').find('.text .text-title').text(),
                    formato: 'Header interattivo',
                    landing:  _$(e).closest('.banner-hotspot').find('.text .text-link').attr('href'),
                    posizioneSlide: 0
                }]
            }
            
            if (_$(e).closest('.component-BannerListing').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-BannerListing').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, '', false, true, dataPromo)
            } else {
                this._analytics.sendAnalytics(analyticsData, '', false)
            }
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

 }