import { _Uikit, Window, _$ } from '../../main'; 
import { Analytics } from '../../services/analytics/analytics';

export class BannerShopInShop {
   
    constructor(
        private _analytics: Analytics
    ) { }
    
    nkOnInit() { 
        
    }   

    async sendAnalytics(eventType,e,variante?,prezzo?) {
        try {
            let analyticsData;
            let banner = {
                brand: JSON.parse(_$('.main-content').attr('data-product'))['marchio'],
                campagna: _$(e).find('.banner-title').text().trim(),
                formato: 'Banner partner',
                landing: _$(e).find('.link').attr('href'),
                posizioneSlide: 0
            }
            

            switch (eventType) {
                case 'advClick':
                //6.8.2
                analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Scheda prodotto',
                            stepFunnel: 'Visualizzazione scheda'
                        },
                        prodotto: (await this._analytics.getProductData(_$('body .main-content'), true, '', variante, prezzo)),
                        sponsor: [banner]
                }
                break;
            }

            if (_$(e).closest('.component-BannerShopInShop').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-BannerShopInShop').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, '', false, true, dataPromo)
            } else {
                this._analytics.sendAnalytics(analyticsData, '', false)
            }
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }
}