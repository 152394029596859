import { _$, Window } from "../../main";

export class PreHeader {
   
   constructor() {
      if(Window.cart && Window.cart.parentOrder){
         _$('.component-PreHeader').css('top', '0px');
         _$('.component-PreHeader').css('position', 'relative');
         _$('.page-with-preheader').removeClass('page-with-preheader');
      }else{
         let lastScrollTop = 0;
         _$(window).scroll(function() {
            let st = _$(this).scrollTop();
            if (st > lastScrollTop){
               _$('.component-PreHeader').addClass('scrolling_down');
               _$('.component-PreHeader').removeClass('scrolling_up');
            } else {
               _$('.component-PreHeader').addClass('scrolling_up');
               _$('.component-PreHeader').removeClass('scrolling_down');
            }
            lastScrollTop = st;

            if (st <= 0) {
               _$('.component-PreHeader').removeClass('scrolling_down');
            }
         });

         if(_$('html').is('.device_mobile, .device_tablet')){
            if(!Window.selectedTimeslot && Window.pointOfService){
               _$('.component-PreHeader').addClass('timeslot-non-disponibili');
               _$('.component-header').css('box-shadow', 'none');
            }else{
               if(_$('.page-with-preheader').length){
                  _$('.page-with-preheader').removeClass('page-with-preheader');
               }
            }
         }
      }
   }
   
   nkOnInit() { }   
}