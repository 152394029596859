import axios from 'axios';

class ApiProvider {
    private jsonLoader: boolean;

    constructor() {
        this.jsonLoader = false;
    }

    /**
     * Do HTTP GET for loader.
     * Internally uses _doTextGet & _doGet
     * @param {*} url
     * @param {*} queryParams
     * @returns
     */
    async loaderGet(endpoint: string, queryParams = undefined) {
        if (this.jsonLoader) {
            // replace selector and extension
            let split = endpoint.split('.');
            split.splice(1, 0, 'jloader');
            const newEndpoint = split.join('.').replace('.html', '.json');
            const result = await this._doGet(newEndpoint, queryParams);
            return result.html;
        } else {
            return await this._doTextGet(endpoint, queryParams);
        }
    }

    /**
     * Do HTTP GET for text content.
     * Internally uses _doTextGet
     * @param {*} endpoint
     * @param {*} queryParams
     * @returns
     */
    async textGet(endpoint: string, queryParams = undefined) {
        return await this._doTextGet(endpoint, queryParams);
    }

    /**
     * Do HTTP GET with response type "text"
     * Used for download html content
     * @param {*} endpoint
     * @param {*} queryParams
     * @returns
     */
    async _doTextGet(endpoint: string, queryParams = undefined) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios({
                method: 'get',
                url: endpoint,
                responseType: 'text',
                params: params,
            });
            return result?.data;
        } catch (error) {
            console.warn(error);
            throw 'Cannot download page';
        }
    }

    async _doMultipartPost(endpoint: string, body = {}, queryParams = undefined, { unwrap } = { unwrap: true }) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                params,
            });
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    async _doBlobPost(endpoint: string, body = {}, queryParams = undefined) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                params,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return result.data;
        } catch (error) {
            console.warn(error);
            throw 'Cannot download blob';
        }
    }

    async _doPost(endpoint: string, body = {}, queryParams = undefined, { unwrap } = { unwrap: true }) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                withCredentials: true,
                params,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    async _doGet(endpoint: string, queryParams = undefined, { unwrap } = { unwrap: true }) {
        try {
            const params = this._paramsToURLParams(queryParams);
            const result = await axios.get(endpoint, {
                withCredentials: true,
                params: params,
            });
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    _paramsToURLParams(queryParams = undefined) {
        if (!queryParams) return new URLSearchParams();

        let urlParams = new URLSearchParams();
        //Ensure the url encoding is performed correctly for arrays
        Object.entries(queryParams).forEach(([param, value]) => {
            if (value)
                if (Array.isArray(value)) value.forEach((item) => urlParams.append(param, item));
                else urlParams.append(param, value as string);
        });
        return urlParams;
    }
}

const apiProvider = new ApiProvider();
export default apiProvider;
