import { Utils } from '../../services/utils/utils';
import { GoogleUtils } from '../../services/utils/google-utils';
import Swiper, { SwiperOptions } from 'swiper';
import { _Uikit, Window, _$ } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
import { AnalyticsUtility, BANNER_TYPES } from '../../services/analytics/analytics-utility';
export class Landingidm {

    constructor(
        private _utils: Utils,
        private _google: GoogleUtils,
        private _analytics: Analytics,
        private _analyticsUtility: AnalyticsUtility
    ) { }

    nkOnInit() {
        this.checkBreadcrumb();
        setTimeout(() => {
            this._utils.customScrollbar();
        }, 100);
        this.sendAnalytics('pageview');
        this.setBannersClickEvents();
    }

    checkBreadcrumb() {
        let breadcrumbs = _$('.component-Breadcrumb');
        let breadcrumbsContainer = _$('.breadcrumb-custom-listing');

        if (breadcrumbs.length > 1) {
            breadcrumbs.addClass('uk-hidden');
            breadcrumbs.eq(0).removeClass('uk-hidden');
        }
        if (breadcrumbsContainer.length > 1) {
            breadcrumbsContainer.addClass('uk-hidden');
            //breadcrumbsContainer.eq(0).removeClass('uk-hidden');
        }
    }

    async sendAnalytics(eventType, element?){
        try {
            let analyticsData;
            let step;
            let sponsor = [];
            let datapromoArray = [];
            let banner;
            let slides;
            let slide;
            let clickEvent = false;
    
            switch (eventType) {
                
                case 'pageview':
                    let bannerList = [BANNER_TYPES.PROMO_COMPONENT, 
                                        BANNER_TYPES.BANNER_SPONSOR, 
                                        BANNER_TYPES.STRIP_BTF, 
                                        BANNER_TYPES.CARD_OFFERTA_BTF,
                                        BANNER_TYPES.CARD_SHOP_IN_SHOP_BTF,
                                        BANNER_TYPES.BANNERS_TESTATA,
                                        BANNER_TYPES.CAROSELLO_SPONSOR,
                                        BANNER_TYPES.BANNER_LANDING_IDM,
                                        BANNER_TYPES.CARDS_IMAGE_TOP,
                                        BANNER_TYPES.PROMOTIONS_AND_VISIBILITIES,
                                        BANNER_TYPES.CONAD_BRANDS,
                                        BANNER_TYPES.TILES_BRANDS,
                                        BANNER_TYPES.TILES_PARTNERS,
                                        BANNER_TYPES.HIGHLIGHTED_PRODUCTS,
                                        BANNER_TYPES.ISPIRAZIONALE,
                                        BANNER_TYPES.STORYTELLING_A,
                                        BANNER_TYPES.STORYTELLING_B];

                    bannerList.forEach((value) => {
                        [sponsor, datapromoArray] = this._analyticsUtility.getBannerPageview(value, sponsor, datapromoArray);
                    });

                    let prodotto = []
                    let productCard = _$('.component-ProductCard');

                    for (var i = 0; i < productCard.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                    }

                    let productCardLastView = _$('.component-ProductCardLastView');
                    for (var i = 0; i < productCardLastView.length; i++) {
                        prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))));
                    }

                    let cardSuggest = _$('.component-CardSuggest');
                    for (var i = 0; i < cardSuggest.length; i++) {
                        prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                    }
                        
                    analyticsData = {
                        event: eventType,
                        sponsor,
                        prodotto
                    };
                    break;  
                case 'advClickBannerIdm':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.BANNER_LANDING_IDM, analyticsData, element);
                    break;
                case 'advClickPromoComponent':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.PROMO_COMPONENT, analyticsData, element);
                    break;
                case 'advClickBannerSponsor':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.BANNER_SPONSOR, analyticsData, element);
                    break;
                case 'advClickBannerStripe':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.STRIP_BTF, analyticsData, element);
                case 'advClickOfferCard':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.CARD_OFFERTA_BTF, analyticsData, element);
                    break;
                case 'advClickShopIdm':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.CARD_SHOP_IN_SHOP_BTF, analyticsData, element);
                    break;
                case 'advClickBannerListing':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.BANNERS_TESTATA, analyticsData, element);
                    break;
                case 'advClickCarouselBenessere':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.CAROSELLO_SPONSOR, analyticsData, element);
                    break;
                case 'advClickCardImageTop':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.CARDS_IMAGE_TOP, analyticsData, element);
                    break;
                case 'advClickPromotionsVisibilities':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.PROMOTIONS_AND_VISIBILITIES, analyticsData, element);
                    break;
                case 'advClickConadBrands':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.CONAD_BRANDS, analyticsData, element);
                case 'advClickTileBrand':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.TILES_BRANDS, analyticsData, element);
                    break;
                case 'advClickTilePartner':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.TILES_PARTNERS, analyticsData, element);
                    break;
                case 'advClickHighlightedProducts':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.HIGHLIGHTED_PRODUCTS, analyticsData, element);
                    break;
                case 'advClickIspirazionale':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.ISPIRAZIONALE, analyticsData, element);
                    break;
                case 'advClickStorytellingA':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.STORYTELLING_A, analyticsData, element);
                    break;
                case 'advClickStorytellingB':
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.STORYTELLING_B, analyticsData, element);
                    break;
                default:
                    break;
            }

            
            if(_$('body').hasClass('page-LandingPromoPerTe')){
                step = 'FUNNEL_LANDING_PROMO_PER_TE';
            }else{
                step = 'FUNNEL_LANDING_IDM';
            }
            
            if(clickEvent){

                if(eventType == 'advClickHighlightedProducts')
                    element = _$(element).closest('.component-ProdottiEvidenza');

                let dataPromo = _$(element).attr('data-promo') != null && _$(element).attr('data-promo') != '' ? JSON.parse(_$(element).attr('data-promo')) : '';
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo);
            }else{
                if (datapromoArray.length) {
                    this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                } else {
                    this._analytics.sendAnalytics(analyticsData, step);
                }  
            }

        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    setBannersClickEvents(){
        _$('.component-BannerLandingIdm').on('click', (event) => {
            this.sendAnalytics('advClickBannerIdm', _$(event.target).closest('.component-BannerLandingIdm'));
        });

        _$('.component-PromoIdm').on('click', (event) => {
            this.sendAnalytics('advClickBannerPromoComponent', _$(event.target).closest('.component-PromoIdm'));
        })

        _$('.component-BannerSponsor').on('click', (event) => {
            this.sendAnalytics('advClickBannerSponsor', _$(event.target).closest('.component-BannerSponsor'));
        })

        _$('div[nkPage="BannerStripe"').on('click', (event) => {
            this.sendAnalytics('advClickBannerStripe', _$(event.target).closest('div[nkPage="BannerStripe"]'));
        });

        _$('.component-OfferCard').on('click', (event) => {
            this.sendAnalytics('advClickOfferCard', _$(event.target).closest('.component-OfferCard'));
        });

        _$('.main-content .component-ShopIdm').on('click', (event) => {
            this.sendAnalytics('advClickShopIdm', _$(event.target).closest('.component-OfferCard'));
        });

        _$('.component-BannerListing').on('click', (event) => {
            this.sendAnalytics('advClickBannerListing', _$(event.target).closest('.component-BannerListing'));
        });

        _$('.component-CarouselBenessere').on('click', (event) => {
            this.sendAnalytics('advClickCarouselBenessere', _$(event.target).closest('.component-CarouselBenessere'));
        })

        _$('.cards-image-top .component-card-image-top').on('click', (event) => {
            this.sendAnalytics('advClickCardImageTop', _$(event.target).closest('.component-card-image-top'));
        });

        _$('.component-PromoMdd .component-CardSimple').on('click', (event) => {
            this.sendAnalytics('advClickPromotionsVisibilities', _$(event.target).closest('.component-CardSimple'));
        });

        _$('.component-1-4-conad-brands .component-TileBrand').on('click', (event) => {
            this.sendAnalytics('advClickConadBrands', _$(event.target).closest('.component-TileBrand'));
        });

        _$('.general-tile-container .component-TileBrand').on('click', (event) => {
            this.sendAnalytics('advClickTileBrand', _$(event.target).closest('.component-TileBrand'));
        });

        _$('.general-tile-container .component-TilePartner').on('click', (event) => {
            this.sendAnalytics('advClickTilePartner', _$(event.target).closest('.component-TilePartner'));
        });

        _$('.component-ProdottiEvidenza').on('click', (event) => {
            this.sendAnalytics('advClickHighlightedProducts', event.target);
        });

        _$('.component-Ispirazionale').on('click', (event) => {
            this.sendAnalytics('advClickIspirazionale', _$(event.target).closest('.component-Ispirazionale'));
        });

        _$('.component-StorytellingA').on('click', (event) => {
            this.sendAnalytics('advClickStorytellingA', _$(event.target).closest('.component-StorytellingA'));
        });

        _$('.component-StorytellingB').on('click', (event) => {
            this.sendAnalytics('advClickStorytellingB', _$(event.target).closest('.component-StorytellingB'));
        });
    }
}