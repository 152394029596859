import Alpine from 'alpinejs';
import { FlowStore } from '../cfamily/types';
import { User } from '../types/user';
import { i18n } from './base-utils';
import { addWishlistProductsToCart } from './wishlists-utils';

/**
 * Flow: Logout 
 * @returns flow output data
 */
export async function logoutFlow(this_: any): Promise<any> {
    const user = <User>Alpine.store('user');
    if (!user || !user.utenzaId) return;
    let flowOutputData: any = await window.cFlowManager.startAsyncFlow({
        name: 'user-logout-flow',
        steps: [
            { name: 'user-logout' }
        ],
        initialData: {
            formatLabels: {}
        }
    });
    if (flowOutputData?.result?.state === 'confirmed') {
        const anyway = (resOrErr) => { window.location.reload(); }
        window.UserService.doLogout(anyway, anyway); // old-fe call
    }

    return flowOutputData;
}

/**
 * Flow: Add All Wishlist Products To Cart
 * @returns flow output data
 */
export async function addAllWishlistProductsToCartFlow(this_: any): Promise<any> {
    let flowOutputData: any = await window.cFlowManager.startAsyncFlow({
        name: 'wishlists-add-to-cart-flow',
        steps: [
            {
                name: 'wishlists-add-to-cart',
                toContinue: async () => {
                    this_.$store.showGlobalLoader = true;
                    const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                    try {
                        const response = (await addWishlistProductsToCart(this_.selectedWishlist));
                        if (response.errorEntries && response.errorEntries.length == 0) {
                            flowStore.data.result = { state: 'confirmed' };
                            window.cFlowManager.next('wishlists-confirm-add-to-cart');
                        } else if (response.errorEntries && response.errorEntries.length > 0) {
                            flowStore.data.result = { state: 'confirmed' };
                            flowStore.data.unavailableProducts = response.errorEntries;
                            window.cFlowManager.next('wishlists-confirm-add-to-cart-with-errors');
                        } else { // error
                            flowStore.data.result = { state: 'error' };
                            window.cFlowManager.complete();
                            this_.$dispatch('toast', { id: 'code-error', customText: i18n('Error in add wishlist products to cart') });
                        }

                        if (response.cart) {
                            window.Utils.updateCartBtn(response.cart.subTotal?.value, response.cart.totalUnitCount); // old-fe call
                            if (response.cart.entries) {
                                window.Utils.updateProductsOnPage(response.cart.entries); // old-fe call
                                response.cart.entries.forEach((entry) => {
                                    const frmPrice = `${entry?.totalPrice?.value?.toString()?.replace('.',',')}€`;
                                    window.Utils.updateAllCardsPicker(entry?.productCode, entry?.quantity?.toString(), true, frmPrice); // old-fe call
                                });
                            }
                        }
                    } catch (e) {
                        flowStore.data.result = { state: 'error' };
                        window.cFlowManager.complete();
                        this_.$dispatch('toast', { id: 'code-error', customText: i18n('Error in add wishlist products to cart') });
                    } finally {
                        this_.$store.showGlobalLoader = false;
                    }
                },
                toGoBack: async () => {
                    const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                    flowStore.data.result = { state: 'cancelled' };
                    window.cFlowManager.complete();
                }
            },
            { name: 'wishlists-confirm-add-to-cart' },
            { name: 'wishlists-confirm-add-to-cart-with-errors' },
        ],
        initialData: {
            formatLabels: {
                textArgs: []
            }
        }
    });

    return flowOutputData;
}