import { _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';
import { Utils } from '../../services/utils/utils';
import { SwiperConfigInitializer } from "../../services/utils/swiper-config-initializer";
export class CarouselBenessere {
    public swiperBenessere;
    constructor( 
        private _utils: Utils,
        private swiperConfigInitializer: SwiperConfigInitializer
    ) { }
   
    nkOnInit() {
        this.initializeCarousel();
    }

    initializeCarousel(){
        if(_$("body").hasClass("page-Listinglevel2")){
            _$(() => {
                this.swiperBenessere = new Swiper('.swiper-benessere', this.swiperConfigInitializer.initConfig(['watchOverflow'], null, this.swiperBenessereOptions));
            });
        }else{
            this.swiperBenessere = new Swiper('.swiper-benessere', this.swiperConfigInitializer.initConfig(['watchOverflow'], null, this.swiperBenessereOptions));
        }
        
        for (let i = 0; i < _$('.next-benessere').length; i++) {
            _$('.next-benessere')[i].setAttribute('onclick','CarouselBenessere.mySlideNext(this)');
            _$('.prev-benessere')[i].setAttribute('onclick','CarouselBenessere.mySlidePrev(this)');            
        }
    }

    swiperBenessereOptions: SwiperOptions = {
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-benessere',
            draggable: true,
            hide: false,
        },
        navigation: {
            nextEl: '.next-benessere',
            prevEl: '.prev-benessere',
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 16,
                /* slidesPerGroup: 1, */
                slidesPerView: 1.2
            },
            768: {
                spaceBetween: 20,
                /* slidesPerGroup: 1, */
                slidesPerView: 1.7
            },
            1024: {
                spaceBetween: 24,
                /* slidesPerGroup: 2, */
                slidesPerView: 2.2
            },
            1440: {
                spaceBetween: 24,
                /* slidesPerGroup: 3, */
                slidesPerView: 3
            },
        },
        on : {
            transitionStart: () => { 
                this._utils.draggedByScrollbar = true;
            },
            transitionEnd: () => {
                this._utils.draggedByScrollbar = false;
            }
        }
    };

    mySlideNext(item) {
        this._utils.mySlideNext(this.swiperBenessereOptions, _$(item).closest('.swiper-benessere')[0].swiper);
    }

    mySlidePrev(item) {
        this._utils.mySlidePrev(this.swiperBenessereOptions, _$(item).closest('.swiper-benessere')[0].swiper);
    }
}