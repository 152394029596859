import { EcommerceData } from "./services/analytics/analytics-interface";

export const ORDER_STATUS = {
    'CONFIRMED': {
        label: 'Confirmed',
        description: 'The order is confirmed. If you wish to make changes or cancel the order you will be able to do it within the time indicated on the order detail.'
    },
    'IN_PREPARATION': {
        label: 'In preparation',
        description: 'We are preparing your order. We will inform you if there are any changes on the availability of the products purchased.'
    },
    'READY_FOR_DELIVERY': {
        label: 'In preparation',
        description: 'We are preparing your order. We will inform you if there are any changes on the availability of the products purchased.'
    },
    'READY_FOR_PREPARATION': {
        label: 'In preparation',
        description: 'We are preparing your order. We will inform you if there are any changes on the availability of the products purchased.'
    },
    'PICKER_DELAYED': {
        label: 'Delayed',
        description: 'The order has yet to be entrusted to the delivery guy, who appears to be late with the collection of the order.'
    },
    'DELIVERY_DELAYED': {
        label: 'Delayed',
        description: 'The order has yet to be entrusted to the delivery guy, who appears to be late with the collection of the order.'
    },
    'OUT_FOR_DELIVERY': {
        label: 'Out for delivery',
        description: 'The order has been taken into the delivery guy\'s care and is about to be delivered to the delivery address and within the time window you provided.'
    },
    'OUT_FOR_LOCKER': {
        label: 'Out for delivery',
        description: 'The order has been taken into the delivery guy\'s care and is about to be delivered to the delivery address and within the time window you provided.'
    },
    'READY_FOR_COLLECTION': {
        label: 'Ready for collection',
        description: 'The order can be collected at the selected point of service. On the order detail you can find the address of the collection point.'
    },
    'CUSTOMER_DELAYED': {
        label: 'Ready for collection',
        description: 'The order can be collected at the selected point of service. On the order detail you can find the address of the collection point.'
    },
    'DELIVERED_PICKED_HD': {
        label: 'Delivered',
        description: 'The order has been delivered to the provided delivery address and the payment has been carried out successfully.'
    },
    'DELIVERED_PICKED_OC': {
        label: 'Collected',
        description: 'The order has been collected at the point of service and the payment has been carried out successfully.'
    },
    'DELIVERY_FAILED': {
        label: 'Delivery failed',
        description: 'The order has not been delivered to the provided delivery address.'
    },
    'NOT_DELIVERED': {
        label: 'Delivery failed',
        description: 'The order has not been delivered to the provided delivery address.'
    },
    'CANCELLED_BY_CP': {
        label: 'Cancelled',
        description: 'The order has been cancelled successfully.'
    },
    'CANCELLED_BY_OMS': {
        label: 'Cancelled',
        description: 'The order has been cancelled successfully.'
    },
    'CANCELLED_BY_USER': {
        label: 'Cancelled',
        description: 'The order has been cancelled successfully.'
    },
    'CANCELLED_BY_CUSTOMER_CARE': {
        label: 'Cancelled',
        description: 'The order has been cancelled successfully.'
    },
};

export const TRANSACTION_STATUS = {
    'ACCEPTED': 'Accepted',
    'REJECTED': 'Rejected'
};

export const PAYMENT_METHOD = {
    '1': 'Bancomat',
    '13': 'Conad Card',
    '20': 'Credit Card',
    '25': 'Credit Card',
    '26': 'Credit Card',
    '27': 'Credit Card',
    '28': 'Credit Card',
    '33': 'Maestro',
    '34': 'Visa',
    '36': 'MasterCard',
    '32': 'Amex',
    '35': 'Diners',
    '37': 'JCB',
    '38': 'Unionpay',
    '14': 'Conad Card (traditional)',
    '15': 'Conad Card (prepaid)',
    '16': 'Conad Card (rechargeable)',
    '44': 'Conad Pay (app)',
    '11': 'Satispay',
    '12': 'Paypal',
    'default': 'Other'
};

export const BASE_ECOMMERCE_DATA: { [id: string]: EcommerceData; } = {
    PAGE_VIEW_PA_PROFILE: {
        event: 'pageview',
        funnel: {
            nomeFunnel: 'Area personale - Profilo',
            stepFunnel: 'Profilo'
        }
    },
    PAGE_VIEW_PA_ORDERS: {
        event: 'pageview',
        funnel: {
            nomeFunnel: 'Area personale - I miei Ordini',
            stepFunnel: 'I miei ordini'
        }
    },
    PAGE_VIEW_PA_WISHLISTS: {
        event: 'pageview',
        funnel: {
            nomeFunnel: 'Area personale - Preferiti',
            stepFunnel: 'Liste prodotti preferiti',
            listaVisualizzata: '<Valorizzato con la lista visualizzata>'
        }
    },
    PAGE_VIEW_PA_COUPONS: {
        event: 'pageview',
        funnel: {
            nomeFunnel: 'Area personale - Offerte e buoni',
            stepFunnel: 'Offerte e buoni'
        }
    },
    PAGE_VIEW_PA_ASSISTANCE: {
        event: 'pageview',
        funnel: {
            nomeFunnel: 'Area personale - Assistenza',
            stepFunnel: 'Assistenza'
        }
    },
    CLICK_USER_MENU: {
        event: 'clickProfilo',
        funnel: {
            nomeFunnel: 'Area personale',
            stepFunnel: 'Apertura'
        }
    },
    CLICK_USER_MENU_ITEM: {
        event: 'clickVoceArea',
        funnel: {
            nomeFunnel: 'Area personale',
            stepFunnel: '<Nome della voce di Area>'
        }
    },
    CLICK_PA_PROFILE_LEFT_SIDE: {
        event: 'pulsantiSpallaSinistra',
        funnel: {
            nomeFunnel: 'Area personale - Profilo',
            stepFunnel: '<Modifica Dati|Contattaci|Logout>'
        }
    },
    CLICK_PA_PROFILE_SHOW_ALL: {
        event: 'vediTutti',
        funnel: {
            nomeFunnel: 'Area personale - Profilo',
            stepFunnel: '<I miei ordini|Liste Prodotti|Offerte e buoni>'
        }
    },
    CLICK_PA_ORDERS_MANAGE: {
        event: 'clickGestioneOrdine',
        funnel: {
            nomeFunnel: '<current>',
            stepFunnel: '<Modifica|Aggiungi|Annulla|Ordina di nuovo>'
        }
    },
    CLICK_PA_ORDERS_CHANGE_SERVICE: {
        event: 'clickServizioOrdine',
        funnel: {
            nomeFunnel: '<current>',
            stepFunnel: '<Spesa a casa|Ordina e ritira>'
        }
    },
    CLICK_PA_WISHLISTS_ADD_ALL_TO_CART: {
        event: 'aggiungiTuttiProdotti',
        funnel: {
            nomeFunnel: '<current>',
            stepFunnel: 'Liste prodotti preferiti',
            listaVisualizzata: '<Valorizzato con la lista visualizzata al momento del caricamento della pagina>'
        }
    },
    CLICK_PA_WISHLISTS_MANAGE: {
        event: 'ctaListePreferiti',
        funnel: {
            nomeFunnel: 'Area personale - Preferiti',
            stepFunnel: '<Elimina Lista|Modifica Lista|Nuova lista>',
            listaVisualizzata: '<Valorizzato con la lista visualizzata al momento del caricamento della pagina>'
        }
    },
    CLICK_PA_WISHLISTS_CHANGE_LIST: {
        event: 'ctaselezioneListaPreferiti',
        funnel: {
            nomeFunnel: 'Area personale - Preferiti',
            stepFunnel: 'Cambia Lista',
            listaVisualizzata: '<Valorizzato con la lista visualizzata al momento del caricamento della pagina>',
            listaSelezionata: '<Valorizzato con la lista selezionata>'
        }
    },
    CLICK_PA_MENU: {
        event: 'menuAreaPersonale',
        funnel: {
            nomeFunnel: 'Area personale',
            stepFunnel: '<valorizzato con il nome della cta cliccata>'
        }
    },
    CLICK_PA_POPUP_CONFIRM_DELETE_WISHLIST: {
        event: 'eliminaLista',
        funnel: {
            nomeFunnel: 'Area personale - Preferiti',
            stepFunnel: 'Conferma Elimina Lista',
            listaVisualizzata: '<Valorizzato con la lista visualizzata al momento del caricamento della pagina>',
            listaSelezionata: '<Valorizzato con la lista selezionata>'
        }
    },
    CLICK_PA_POPUP_CONFIRM_RENAME_WISHLIST: {
        event: 'modificaLista',
        funnel: {
            nomeFunnel: 'Area personale - Preferiti',
            stepFunnel: 'Conferma Modifica Lista',
            listaVisualizzata: '<Valorizzato con la lista visualizzata al momento del caricamento della pagina>',
            listaSelezionata: '<Valorizzato con la lista selezionata>'
        }
    },
    CLICK_LIKE_BTN: {
        event: 'prodottoPreferito',
        funnel: {
            nomeFunnel: '<current>',
            stepFunnel: '<Aggiunta/Rimozione> dai preferiti',
            listaVisualizzata: '<Valorizzato con la lista visualizzata al momento del caricamento della pagina>'
        }
    },
    CLICK_PRODUCT_CARD: {
        event: 'dettaglioProdotto',
        funnel: {
            nomeFunnel: '<current>',
            stepFunnel: 'Click su prodotto',
            listaVisualizzata: '<Valorizzato con la lista visualizzata al momento del caricamento della pagina>'
        }
    },
    CLICK_PA_COUPON: {
        event: 'clickCoupon',
        funnel: {
            nomeFunnel: '<current>',
            stepFunnel: 'Click su coupon'
        },
        buono: {
            IDBuono: '<ID del buono mostrato all utente che sarà quello che arriva da AEM/Campaign>'
        }
    },
    CLICK_PA_COUPON_CODE: {
        event: 'copiaCodiceCoupon',
        funnel: {
            nomeFunnel: '<current>',
            stepFunnel: 'Copia codice coupon'
        },
        buono: {
            IDBuono: '<ID del buono mostrato all utente che sarà quello che arriva da AEM/Campaign>'
        }
    },
    CLICK_PA_CONTACT_CUSTOMER_CARE_LINK: {
        event: 'contattaServizioClienti',
        funnel: {
            nomeFunnel: 'Area personale - Assistenza',
            stepFunnel: 'Contatta Servizio Clienti'
        }
    },
    CLICK_PA_CLOSE_CUSTOMER_CASE_REQUEST: {
        event: 'chiudiSegnalazione',
        funnel: {
            nomeFunnel: 'Area personale - Assistenza',
            stepFunnel: 'Chiudi Segnalazione',
            idSegnalazione: '<valorizzato con id della segnalazione chiusa>',
            tipologiaSegnalazione: '<valorizzato con la tipologia di richiesta>',
            statoSegnalazione: '<valorizzato con lo stato della segnalazione>'
        }
    },
    CLICK_SUPPORT_BOX: {
        event: 'richiestaSupporto',
        funnel: {
            nomeFunnel: 'Area personale - Assistenza',
            stepFunnel: '<Chatta|Chiama|Apri Form>'
        }
    },
}

export const TEMPLATE_NOMEFUNNEL_MAPPING: { [template: string]: string; } = {
    'entry-page': 'EntryPage',
    'root-page': '',
    'site-page': '',
    'home-page': 'Homepage',
    'offers-page': 'Offerte e promozioni',
    'search-page': 'Ricerca',
    'modal-page': '',
    'popup-page': '',
    'redirect-page': '',
    'content-page': '',
    'detail-product-page': 'Scheda prodotto',
    'detail-cart-page': 'Carrello',
    'listing-level1-page': 'Scelta prodotto',
    'listing-level2-page': 'Scelta prodotto',
    'listing-level3-page': 'Scelta prodotto',
    'custom-listing-page': 'Scelta prodotto',
    'listing1-brand-page': 'Scelta prodotto',
    'checkout-guest-page': 'Checkout',
    'checkout-recognized-page': 'Checkout',
    'checkout-dispatcher-page': 'Checkout',
    'categories-dispatcher-page': '',
    'order-completed-page': '',
    'order-completed-page-guest-expired': '',
    'order-dispatcher-page': 'Checkout',
    'faq-page': 'Supporto',
    'landing-info-page': '',
    'landing-region-page': 'Copertura Servizio',
    'brands-page': 'Marchi',
    'partners-page': 'Partner',
    'promo-empty-page': '',
    'wishlist-page': 'Prodotti preferiti',
    'personal-area-page': 'Area personale',
    'brands-partners-page': 'Marchi e Partner',
    'error-page': 'Pagina di errore',
    'carousel-listing-page': '',
    'cgv-page': '',
    'error-pagefaq-page': '',
    'guest-order-detail-page': '',
    'order-error-page': '',
    'privacy-page': 'Policy',
    'recipe-page': 'Ricette',
    'idm-landing-page': 'Landing IDM',
    'offers-for-you-landing-page': 'Promo per te'
}
