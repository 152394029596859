import Swiper, { SwiperOptions } from 'swiper';

export class StorytellingC {
   
    constructor() { }
    
    nkOnInit() { }   

    swiperStoryCOptions: SwiperOptions = {
        spaceBetween: 16,
        freeMode: true,
        breakpointsInverse: true,
        scrollbar: {
            el: '.scrollbar-storytelling-c',
            draggable: true,
            hide: false,
        },
        breakpoints: {
            375: {
                spaceBetween: 16,
                /* slidesPerGroup: 1, */
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 1.75,
                /* slidesPerGroup: 2, */
                spaceBetween: 19,
            },
            1024: {
                spaceBetween: 22,
                /* slidesPerGroup: 3, */
                slidesPerView: 2.75
            },
            1440: {
                spaceBetween: 24,
                /* slidesPerGroup: 4, */
                slidesPerView: 3.75
            },
        },
    };
    swiperStoryC = new Swiper('.swiper-storytelling-c', this.swiperStoryCOptions);
 }