import Alpine from 'alpinejs';
import { FlowStore } from '../../../../../../cfamily/types';
import { formatC, i18n } from '../../../../../../libs/base-utils';

Alpine.data('popup12x2xQuestion', (args: Record<string, any>) => ({
    inizialized: false as boolean,
    popupTitle: '',
    text: '',
    init() {
        this._initializeFields();
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    async _initializeFields() {
        const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
        const formatLabels: Record<string, string[]> = flowStore?.data?.formatLabels;
        if (!formatLabels)
            console.warn('warning: formatLabels not provided');
        this.popupTitle = formatC(this.$refs.popupTitleFormat?.innerHTML || '', ...(formatLabels?.popupTitleArgs || []));
        this.text = formatC(this.$refs.popupTextFormat?.innerHTML || '', ...(formatLabels?.textArgs || []));
        this.inizialized = true;
    },
    // main component functions
    async confirm() {
        const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
        if (flowStore?.currentStep?.toContinue) {
            flowStore.currentStep.toContinue();
        } else {
            flowStore.data.result = {
                state: 'confirmed'
            };
            window.cFlowManager.complete();
        }
    },
    async cancel() {
        const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
        if (flowStore?.currentStep?.toGoBack) {
            flowStore.currentStep.toGoBack();
        } else {
            flowStore.data.result = {
                state: 'cancelled'
            };
            window.cFlowManager.complete();
        }
    }
}));
