import Alpine from 'alpinejs';

Alpine.data('ec11x0xRadioSelect', () => ({
    open: false,
    value: '',
    selectedValueLabel: '',
    init() {
        //this.value = this.$refs.input.value;
        this.$refs.list.children.forEach((li) => {
            if (li.dataset.preSelected !== undefined) {
                this.value = li.dataset.value;
                this.selectedValueLabel = li.dataset.label;
            }
        });
    },
    // consts
    // utils
    // internal functions
    // main component functions
    setValue(value, label) {
        this.value = value;
        this.selectedValueLabel = label;
        this.$refs.input.dispatchEvent(new CustomEvent('change', { bubbles: true })); // trigger onchange
        this.open = false;
    }
}));