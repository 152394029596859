import { _$ } from '../../main';

export class SwiperConfigInitializer{

    private defaultAutoplay = 11000; /* valore di default dell'autoplay a 11 secondi */
    constructor(){

    }

    nkOnInit(){

    }

    initializeAutoplay(swiper: object, swiperOptions: object): object{
        
        if(_$(swiper).attr('data-autoplay') != null && parseInt(_$(swiper).attr('data-autoplay')) > 0){
            let autoplayValue = _$(swiper).attr('data-autoplay');
            if(autoplayValue != 'off'){
                swiperOptions['autoplay'] = {
                    delay: parseInt(autoplayValue),
                    disableOnInteraction: false
                }
            }
        }else{
            swiperOptions['autoplay'] = {
                delay: this.defaultAutoplay,
                disableOnInteraction: false
            }
        }

        swiperOptions['noSwipingSelector'] = '.swiper-pagination, .button-scopri, .link-banner-sponsor';
        
        return swiperOptions;
    }

    initializeWatchOverflow(swiperOptions){
        swiperOptions['watchOverflow'] = true;
        return swiperOptions;
    }

    initConfig(configParams: [string], swiper: object, swiperOptions: object): object{
        let newSwiperOptions = swiperOptions;
        if(configParams.length){
            configParams.forEach((value) => {
                switch(value){
                    case 'autoplay':
                        newSwiperOptions = this.initializeAutoplay(swiper, newSwiperOptions);
                        break;
                    case 'watchOverflow':
                        newSwiperOptions = this.initializeWatchOverflow(newSwiperOptions);
                        break;
                    default:
                        console.log('parameter not configurable');
                        break;
                }
            })
        }

        return newSwiperOptions;
    }

}