/**
 * Semaphore implementaton for concurrency managament
 */
export default class Semaphore {

    private requestsQueue: any = [];
    private currentInto: number = 0;
    private maxInto: number = 1;

    constructor(maxInto: number = 1) {
        this.requestsQueue = [];
        this.currentInto = 0;
        this.maxInto = maxInto;
    }

    callFunction(fnToCall, ...args): Promise<any> {
        return new Promise((resolve, reject) => {
            this.requestsQueue.push({
                resolve,
                reject,
                fnToCall,
                args,
            });
            this.tryNext();
        });
    }

    tryNext() {
        if (!this.requestsQueue.length) {
            return;
        } else if (this.currentInto < this.maxInto) {
            let { resolve, reject, fnToCall, args } = this.requestsQueue.shift();
            this.currentInto++;
            let req = fnToCall(...args);
            req.then((res) => resolve(res))
                .catch((err) => reject(err))
                .finally(() => {
                    this.currentInto--;
                    this.tryNext();
                });
        }
    }
}

