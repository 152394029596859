import { FlowStep, FlowStore, SingleFlow } from '../types';

export class FlowManager {
    constructor() {}

    async startAsyncFlow({ name, steps, initialData }: { name: string; steps: FlowStep[]; initialData?: any }) {
        return await new Promise((resolve) => {
            this.startFlow({
                name,
                steps,
                initialData,
                onComplete: (data) => {
                    resolve(data);
                },
            });
        });
    }

    async startFlow({
        name,
        steps,
        onComplete,
        initialData,
    }: {
        name: string;
        steps: FlowStep[];
        onComplete?: (data: any) => void;
        initialData?: any;
    }) {
        if (!steps || steps.length <= 0) {
            console.warn('Cannot start flow without steps');
            return;
        }

        try {
            // verify steps exists & load if missing
            for (const stepConf of steps) {
                const stepName = stepConf.name;
                let stepEl = document.querySelector(`[x-cflowelement=${stepName}]`);
                if (!stepEl) await window.cStepLoader(stepName);
            }

            // create single flow
            const flow: SingleFlow = {
                name,
                steps,
                onComplete,
            };

            const flowStore = <FlowStore>window.Alpine.store('cFlow');
            flowStore.flow = flow;
            flowStore.backStack = [];
            flowStore.currentStep = steps[0];
            flowStore.data = initialData;

            console.log(`Started flow with name ${name}`);
        } catch (e) {
            console.warn(`Cannot start flow ${name}`, e);
        }
    }

    /**
     * Go To Next step ( target specifies the next step )
     * @param {*} target
     */
    next(target?: string) {
        const flowStore = <FlowStore>window.Alpine.store('cFlow');
        let nextStep: FlowStep | undefined = undefined;
        if (!target) {
            const currentStepIndex = flowStore.flow.steps.findIndex((item) => item.name == flowStore.currentStep.name);
            nextStep = flowStore.flow.steps[currentStepIndex + 1];
        } else {
            nextStep = flowStore.flow.steps.find((item) => item.name == target);
        }

        if (!nextStep) {
            throw new Error('Missing next step');
        }

        const current = flowStore.currentStep;
        flowStore.backStack.push(current);
        flowStore.currentStep = flowStore.flow.steps.find((item) => item.name == target);
    }

    /**
     * Back to a previous step ( target specifies the back step )
     * @param {*} target
     */
    back(target?: string) {
        const flowStore = <FlowStore>window.Alpine.store('cFlow');

        let backStep: FlowStep = undefined;
        let foundWhere: number = flowStore.backStack.length;
        if (target) {
            const backStack = flowStore.backStack;
            let i = backStack.length - 1;
            for (i = backStack.length - 1; i >= 0; i--) {
                const tmpStep = backStack[i];
                if (target == tmpStep.name) {
                    backStep = tmpStep;
                    break;
                }
            }
            foundWhere = i;
        } else {
            backStep = flowStore.backStack[flowStore.backStack.length - 1];
            foundWhere = flowStore.backStack.length - 1;
        }

        if (backStep) {
            flowStore.backStack = flowStore.backStack.slice(0, foundWhere);
            flowStore.currentStep = backStep;
        }
    }

    complete() {
        const flowStore = <FlowStore>window.Alpine.store('cFlow');
        const name = flowStore.flow.name;

        //trigger onComplete
        if (flowStore.flow.onComplete) flowStore.flow.onComplete(flowStore.data);

        // reset
        flowStore.flow = undefined;
        flowStore.backStack = [];
        flowStore.data = {};
        flowStore.currentStep = undefined;

        //send complete log
        console.log(`Completed flow: ${name}`);
    }
}

const defaultFlowManager = new FlowManager();
export const flowManager = defaultFlowManager;
