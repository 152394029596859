import { Loader } from "@googlemaps/js-api-loader";
import { buildWaitForEvent } from "../utils";
import { Window } from "../../../main";

const gMapsLoaded = buildWaitForEvent('gMapsLoaded');

export class GoogleMapsManager{

    public loader;
    public status;

    constructor(){
        this.loader = new Loader({
            apiKey: Window.googleMapsAPIKey,
            version: 'weekly',
            libraries: ['places'],
        });
        this.status = 'unloaded';
    }

    nkOnInit(){
        let x = this.getGMaps();
    }

    async getGMaps() {
        if (this.status == 'loaded') {
            return window.google.maps;
        } else if (this.status == 'loading') {
            await gMapsLoaded(document.body, null);
            return window.google.maps;
        } else if (this.status == 'unloaded') {
            this.status = 'loading';
            try {
                await this.loadGMaps();
                this.status = 'loaded';
                return window.google.maps;
            } catch (error) {
                console.error(error);
                this.status = 'error';
            }
        } else {
            console.error('Cannot get gmaps js api');
        }
    }

    async loadGMaps() {
        await this.loader.load();
        const event = new CustomEvent('gMapsLoaded');
        document.body.dispatchEvent(event);
    }
}

/**
 * Get the current GMapsHelper
 * @returns {GMapsManager}
 */
export const getGMapsManager = () => {
    if (!Window.rcGMapsManager) {
        Window.rcGMapsManager = new GoogleMapsManager();
    }
    return Window.rcGMapsManager;
};