import {BaseService} from './../base-service';
import {
    HEADER_REQUEST,
    BASE_URL
} from '../config';

export class PageUpdateService extends BaseService {
    constructor() {
        super();
    }

    getPageUpdate(success, error, link): void {
        this.get(BASE_URL + link, success, error, HEADER_REQUEST);
    }

}