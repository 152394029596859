import { Alpine } from 'alpinejs';
import { ModalStore } from '../types';

export const cDialog = (Alpine: Alpine) => {
    Alpine.directive('cdialog', (el: HTMLElement, { expression }, { cleanup, effect }) => {
        const realEl = el as HTMLDialogElement;
        if (!expression || !realEl.matches('dialog')) {
            return;
        }

        const closeHandler = () => {
            const modalStore = <ModalStore>Alpine.store('cModals');
            modalStore.modal = '';
        };

        effect(() => {
            const modalStore = <ModalStore>Alpine.store('cModals');
            if (modalStore.modal == expression) realEl.showModal();
            // todo: if used with non-modal dialog imlpement also .show() mode
        });

        realEl.addEventListener('close', closeHandler);
        cleanup(() => {
            realEl.removeEventListener('close', closeHandler);
        });
    });
};
