import Alpine from 'alpinejs';
import { FlowStore } from '../../../../../../cfamily/types';
import { formatC, i18n, getProductImageUrl } from '../../../../../../libs/base-utils';
import PerfectScrollbar from 'perfect-scrollbar';

Alpine.data('popup12x9xWishlistsAddToCartWithErrors', (args: Record<string, any>) => ({
    baseInizialized: false as boolean,
    unavailableProductInizialized: false as boolean,
    warningAlert: '',
    unavailableProducts: {} as Record<string, string>,
    scrollbarOptions: {
        swipeEasing: true,
        suppressScrollX: true,
    },
    pScroll: undefined,
    init() {
        this._initialize();
        this._initializeUnavailableProducts();
        this.pScroll = new PerfectScrollbar(this.$refs.scrollable, this.scrollbarOptions);
    },
    // consts
    // utils
    i18n,
    formatC,
    getProductImageUrl,
    // internal functions
    async _initialize() {
        const flow = (await <FlowStore>window.Alpine.store('cFlow'));
        const formatLabels: Record<string, string[]> = flow?.data?.formatLabels;
        if (!formatLabels)
            console.warn('warning: formatLabels not provided');
        this.warningAlert = formatC(args.warningAlertFormat, ...(formatLabels?.warningAlertArgs || []));
        this.baseInizialized = true;
    },
    async _initializeUnavailableProducts() {
        const flow = (await <FlowStore>window.Alpine.store('cFlow'));
        this.unavailableProducts = flow?.data?.unavailableProducts;
        if (!this.unavailableProducts)
            console.warn('warning: unavailableProducts not provided into flow');
        this.unavailableProductInizialized = true;
    },
    // main component functions
    async confirm() {
        const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
        flowStore.data.result = {
            state: 'confirmed'
        };
        window.cFlowManager.complete();
    },
    async cancel() {
        this.confirm();
    }
}));
