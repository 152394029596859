
import Swiper, { SwiperOptions } from 'swiper';

export class PromoMdd {
   
    constructor() { }
    
    nkOnInit() { }   

    swiperPromVisOptions: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 16,
        freeMode: true,
        breakpointsInverse: true,
        breakpoints: {
            1024: {
                allowSlideNext: false,
                allowSlidePrev: false
            }
        },
    };
    swiperPromVis = new Swiper('.swiper-prom-vis', this.swiperPromVisOptions);

 }