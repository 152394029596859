import { Utils } from '../../services/utils/utils';
import { GoogleUtils } from '../../services/utils/google-utils';

export class Recipes {

   constructor(
       private _utils: Utils,
       private _google: GoogleUtils
   ) { }

   nkOnInit() {
   }
}