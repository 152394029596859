import Alpine from 'alpinejs';
import { DefaultService, ProductCardType } from '../../../../../../api';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { getCommaDataAttributeAsArray, formatC, fromResponseToCards, getQueryParamFromUrl, getStandardContentRedirectDirective, i18n, throttle } from '../../../../../../libs/base-utils';
import { addAllWishlistProductsToCartFlow } from '../../../../../../libs/common-flows';
import { formatDate, getLocale } from '../../../../../../libs/locale-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';
import { checkAddWishlistProductsToCart, DEFAULT_WISHLIST_NAME, getWishlist } from '../../../../../../libs/wishlists-utils';

const parser = new DOMParser();
const WISHLIST_PAGE_SIZE = 24;

Alpine.data('ec8x3xWishlists', () => ({
    wishlistFilter: 'recent',
    selectedWishlist: DEFAULT_WISHLIST_NAME,
    showCardsSkeleton: false,
    showCardsWrapper: false,
    wishlistNominalSize: 0,
    wishlistCards: [] as Node[],
    wishlistAvailableProductCodes: [] as string[],
    wishlistUnavailableProductCodes: [] as string[],
    currentLoadedPage: 0,
    addAllTempDisabled: false,
    init() {
        this.selectedWishlist = getQueryParamFromUrl('pre-selected-wishlist', DEFAULT_WISHLIST_NAME);
        this._refreshWishlistCards();
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.PAGE_VIEW_PA_WISHLISTS;
            ecommerceData.funnel.listaVisualizzata = this.selectedWishlist;
            return ecommerceData;
        })());
    },
    // consts
    DEFAULT_WISHLIST_NAME,
    WISHLIST_PAGE_SIZE,
    // utils
    formatC,
    i18n,
    formatDate,
    getLocale,
    getWishlist,
    checkAddWishlistProductsToCart,
    // internal functions
    async _getWishlistCards(): Promise<[Node[], string[], string[]]> {
        // console.log(`Load wishlist product cards of '${this.selectedWishlist}', filter: '${this.wishlistFilter}', page: '${this.currentLoadedPage}'`);
        try {
            const htmlResponse: string = (await DefaultService.getWishlistJcrContentRootWishlistListHtml(
                getStandardContentRedirectDirective(),
                (this.selectedWishlist && this.selectedWishlist !== DEFAULT_WISHLIST_NAME) ?
                    (await getWishlist(this.selectedWishlist)).wishlistId : undefined, // wishlistId
                ['incr', 'decr'].includes(this.wishlistFilter) ? this.wishlistFilter : undefined, // price
                WISHLIST_PAGE_SIZE, // pageSize
                this.currentLoadedPage, // page
                ProductCardType.PRODUCT_CARD, // cardType
                true // errorsAsServlet
            ));
            const wishlistDocument: Document = parser.parseFromString(htmlResponse, 'text/html');
            return [
                fromResponseToCards(wishlistDocument),
                getCommaDataAttributeAsArray(wishlistDocument.querySelector('#availableProductCodes'), 'value'),
                getCommaDataAttributeAsArray(wishlistDocument.querySelector('#unavailableProductCodes'), 'value')
            ];
        } catch (e) {
            this.$dispatch('toast', { id: 'code-error', customText: i18n('Error retrieving wishlist cards') });
            console.error(e);
            return [[], [], []];
        }
    },
    _refreshWishlistCards: throttle(async function () {
        this.addAllTempDisabled = false;
        this.currentLoadedPage = 0;
        this.wishlistNominalSize = (await getWishlist(this.selectedWishlist))?.entries?.size || 0;
        if (this.wishlistNominalSize == 0) {
            this.wishlistCards = [];
            return;
        }
        this.showCardsSkeleton = true;
        this.showCardsWrapper = false;
        [this.wishlistCards, this.wishlistAvailableProductCodes, this.wishlistUnavailableProductCodes] = (await this._getWishlistCards());
        this.$nextTick(window.Utils.checkCartStatus); // old-fe call
        this.showCardsWrapper = true;
        this.showCardsSkeleton = false;
    }, 500),
    _loadMoreWishlistCards: throttle(async function () {
        if (((this.currentLoadedPage + 1) * WISHLIST_PAGE_SIZE) > this.wishlistNominalSize)
            return; // nominally the are no other pages to load
        this.currentLoadedPage++;
        this.showCardsSkeleton = true;
        this.wishlistCards = this.wishlistCards.concat((await this._getWishlistCards())[0]);
        this.$nextTick(window.Utils.checkCartStatus); // old-fe call
        this.showCardsSkeleton = false;
    }, 500),
    // main component functions
    async changeSelectedWishlist(event) {
        if (this.selectedWishlist === event?.detail?.value) return;
        this.selectedWishlist = event?.detail?.value || DEFAULT_WISHLIST_NAME;
        this._refreshWishlistCards();
    },
    async changeWishlistFilter(event) {
        if (this.wishlistFilter === event?.detail?.value) return;
        this.wishlistFilter = event?.detail?.value || 'recent';
        this._refreshWishlistCards();
    },
    async loadMore() {
        this._loadMoreWishlistCards();
    },
    async addAllToCart() {
        if (!this.$store.user || !this.$store.user.utenzaId || !this.$store.cart) return;
        if (!this.selectedWishlist || this.wishlistCards?.length == 0) return;
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_WISHLISTS_ADD_ALL_TO_CART;
            ecommerceData.funnel.listaVisualizzata = getQueryParamFromUrl('pre-selected-wishlist', DEFAULT_WISHLIST_NAME);
            return ecommerceData;
        })());
        const flowOutputData = (await addAllWishlistProductsToCartFlow(this));
        if (flowOutputData?.result?.state === 'confirmed') {
            this.addAllTempDisabled = true;
        }
    }
}));
