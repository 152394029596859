import { _$, _Uikit, Window } from "../../main";
export class ModalCheckoutPdvOnly {
    constructor(
    ) {}

    nkOnInit() {
        _$('#modalCheckoutPDVOnly').on('hide', () => {
            _$('#modalCheckoutPDVOnly').removeClass('type-a type-b')
        })
    }
}