import Swiper, { SwiperOptions } from 'swiper';
import { Utils } from "../../services/utils/utils";

export class AreaOfferte {
   
    constructor(
        private utils: Utils
    ) {
        this.utils.randomizeCards('.component-AreaOfferte');
        this.swiperNostreOfferte = new Swiper('.swiper-nostre-off', this.swiperNostreOfferteOptions);
     }
    
    nkOnInit() { }   

    swiperNostreOfferteOptions: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        freeMode: true,
        pagination: {
            el: '.pagination-nostre-off',
            clickable: true,
        },

        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 12,
                slidesPerGroup: 1,
                slidesPerView: 1.35
            },
            768: {
                spaceBetween: 15,
                slidesPerGroup: 2,
                slidesPerView: 2
            },
            1024: {
                spaceBetween: 16,
                slidesPerGroup: 3,
                slidesPerView: 3
            },
            1440: {
                spaceBetween: 24,
                slidesPerGroup: 3,
                slidesPerView: 3
            },
        },
    };

    swiperNostreOfferte;
 }