import { _$, _Uikit, Window } from '../../main';
import { UserService } from '../../services/user-service/user-service';
import { LocalStorage } from '../../services/local-storage/local-storage';
import { Utils } from '../../services/utils/utils';
import { AddressUser } from '../../services/user-service/user-service.interface';
import { Analytics } from 'src/services/analytics/analytics';
import { ValidationUtils } from 'src/services/utils/validation-utils';

export class ModalFatturazioneElettronica {

    public form = _$('#envoiceForm');

    constructor(
        private userService: UserService,
        private localStorage: LocalStorage,
        private utils: Utils,
        private analytics: Analytics,
        private validationUtils: ValidationUtils
    ) { }

    nkOnInit() {
        this.htmlSelectProvinceNazioniConstructor();

        _$('#envoiceSelection').val('azienda').change();
        /* Settaggio CF senza validazione per azienda */
        _$('#f_cf').attr('data-id', 'noValidation');

        _Uikit.util.on('#offcanvasFatturazioneElettronica', 'beforeshow', () => {
            this.resetSelectEnvoice();
            this.utils.initFormValidator('envoiceForm');
            //this.utils.checkFormValidator('submitEnvoiceData', 'envoiceForm');
            /* In pagina di checkout le CTA salva dei form non si disabilitano più */
            //_$('#submitEnvoiceData').prop('disabled', false);
        });

        _Uikit.util.on('#offcanvasFatturazioneElettronica', 'shown', () => {
            this.sendAnalytics('pageview');
            _$('#envoiceForm').attr('autofocus-enabled','true');
            this.utils.formRequiredFieldFocus('#f_etichetta');
        });

        _Uikit.util.on('#offcanvasFatturazioneElettronica', 'hide', () => {
        });

        _$('#envoiceSelection').on('change', () => {
            this.utils.customSelectValue('envoiceSelection', _$('#envoiceSelection').val());
            if(_$('#envoiceSelection').val() == 'azienda'){
                _$('#f_cf').attr('data-id', 'noValidation');
                _$('#offcanvasFatturazioneElettronica').find('.form-pec .opt').addClass('uk-hidden');
                _$('#offcanvasFatturazioneElettronica').find('.form-pec .ask').removeClass('uk-hidden');
                _$('#offcanvasFatturazioneElettronica').find('.form-cdestinatario .opt').addClass('uk-hidden');
                _$('#offcanvasFatturazioneElettronica').find('.form-cdestinatario .ask').removeClass('uk-hidden');
                _$('#offcanvasFatturazioneElettronica').find('.form-mail .opt').removeClass('uk-hidden');
                _$('#offcanvasFatturazioneElettronica').find('.form-mail .ask').addClass('uk-hidden');
            }else if(_$('#envoiceSelection').val() == 'persfis'){
                _$('#f_cf').attr('data-id', 'codiceFiscale');
                _$('#offcanvasFatturazioneElettronica').find('.form-pec .ask').addClass('uk-hidden');
                _$('#offcanvasFatturazioneElettronica').find('.form-pec .opt').removeClass('uk-hidden');
                _$('#offcanvasFatturazioneElettronica').find('.form-cdestinatario .ask').addClass('uk-hidden');
                _$('#offcanvasFatturazioneElettronica').find('.form-cdestinatario .opt').removeClass('uk-hidden');
                _$('#offcanvasFatturazioneElettronica').find('.form-mail .opt').addClass('uk-hidden');
                _$('#offcanvasFatturazioneElettronica').find('.form-mail .ask').removeClass('uk-hidden');
            }
        })
    }

    /**
     * Prende i dati delle province dalla chiamata del servizio e li salva nel local storage
     */
    getProvince(): Promise<void> {
        return new Promise((resolve, reject) => {
            let success = (res) => {
                if (res.data && res.data.length > 0) {
                    this.localStorage.setItem('listProvince', JSON.stringify(res.data));
                }
                resolve();
            }
            let error = (err) => {
                console.log('error: ', err);
                reject();
            };

            this.userService.getProvince(success, error);
        })
    }

    /**
     * Popola la select delle province con i dati salvati nel local storage
     */
     async setProvinceListSelect() {
        if(!this.localStorage.getFromStorage('listProvince')) {
            await this.getProvince();
        }

        //reset dynamic content
        this.form.find('#f_provincia_container').html('');

        let selectCustomHTML = 
        `<div class="select-native-ui">
            <select name="f_provincia" id="f_provincia" required onchange="ModalFatturazioneElettronica.controlValueFieldInput(this, 'f_provincia');Utils.checkMessageError(this)">
                <option value="" disabled selected>Provincia*</option>
                <!-- dynamic list -->
            </select>
            <div class="message-error uk-text-danger uk-hidden"></div>
            <div class="select-label" for="f_provincia">Provincia</div>
        </div>
        `
        
        this.form.find('#f_provincia_container').append(selectCustomHTML);

        let provinceList = this.localStorage.getFromStorage('listProvince');

        for (let i = 0; i < provinceList.length; i++) {
            let form = _$('#envoiceForm');
            //let firstElement = i === 0 ? `class="selection" selected` : '';
            let provinceHTML = `<option value="${provinceList[i].codice}">${provinceList[i].nome}</option>`;
            form.find('#f_provincia').append(provinceHTML);
        }
    }

    /**
     * Prende i dati delle nazioni dalla chiamata del servizio e li salva nel local storage
     */
     getNazioni(): Promise<void> {
        return new Promise((resolve, reject) => {
            let success = (res) => {
                if (res.data) {
                    this.localStorage.setItem('listStati', JSON.stringify(res.data));
                }
                resolve();
            }
            let error = (err) => {
                console.log('error: ', err);
                reject();
            };

            this.userService.getStati(success, error);
        })
    }

    /**
     * Popola la select delle nazioni con i dati salvati nel local storage
     */
    async setNazioniListSelect() {
        if(!this.localStorage.getFromStorage('listStati')) {
            await this.getNazioni();
        }

        //reset dynamic content
        this.form.find('#f_nazione_container').html('');

        let selectCustomHTML = 
        `<div class="select-native-ui">
            <select name="f_nazione" id="f_nazione" required onchange="ModalFatturazioneElettronica.controlValueFieldInput(this, 'f_nazione');Utils.checkMessageError(this)">
                <option value="" disabled selected>Nazione*</option>
                <!-- dynamic list -->
            </select>
            <div class="message-error uk-text-danger uk-hidden"></div>
            <div class="select-label" for="f_nazione">Nazione</div>
        </div>`;
        this.form.find('#f_nazione_container').append(selectCustomHTML);

        let nazioniList = this.localStorage.getFromStorage('listStati');
        Object.keys(nazioniList).forEach((value, index) => {
            //let firstElement = value === 'IT' ? `class="selection" selected` : '';
            let nazioniHTML = `<option value="${value}">${nazioniList[value]}</option>`;
            this.form.find('#f_nazione').append(nazioniHTML);
            // if(value === 'IT') {
            //     this.form.find('#f_nazione').val(value).change();
            // }
        });
        this.utils.customScrollbar();
    }

    /**
     * Esegue le chiamate e crea l'html per province e nazioni (province deve essere chiamato prima di nazioni) 
     */
    async htmlSelectProvinceNazioniConstructor() {
        await this.setProvinceListSelect();
        await this.setNazioniListSelect();
    }

    /**
     * Controllo gli input on keyup
     * @param element
     * @param type
     */
    controlValueFieldInput(element, type?: string) {
        
        this.validationUtils.newValidInput(element);
        let input = _$(element);

        let customInputs = ['f_provincia','f_nazione','f_defaultBillingAddress'];

        if (customInputs.indexOf(input.attr('id')) > -1) {
            if (!!input.val()) {
                input.attr('data-valid', 'true');
                input.siblings('.message-error').addClass('uk-hidden');
                input.removeClass('uk-form-danger');
            } else {
                if (input.prop('required')) {
                    input.attr('data-valid','false');
                    input.siblings('.message-error').text('Campo obbligatorio.');
                    input.siblings('.message-error').removeClass('uk-hidden');
                    input.addClass('uk-form-danger');
                }
            }
        }
        if(_$('#envoiceSelection').val() == 'azienda'){
            this.checkSdiPec();
        }
    }

    /**
     * controllo custom per required mutuamente esclusivo tra PEC e SDI per azienda
     */
    checkSdiPec(){
        let form = _$('#envoiceForm');
        if(form.find('input#f_pec').attr('data-valid') == 'true' && form.find('input#f_cdestinatario').attr('data-valid') == 'false'){
            form.find('input#f_cdestinatario').prop('required', false);
            form.find('input#f_pec').prop('required', true);
            if(form.find('input#f_cdestinatario').length){
                if(!_$(form.find('input#f_cdestinatario')[0]).val().toString().length){
                    form.find('input#f_cdestinatario').removeClass('uk-form-danger')
                    form.find('input#f_cdestinatario').parent().find('.message-error').addClass('uk-hidden');
                }
            }

        }else if(form.find('input#f_pec').attr('data-valid') == 'false' && form.find('input#f_cdestinatario').attr('data-valid') == 'true'){
            console.log('sdi valid - pec not required');
            form.find('input#f_pec').prop('required', false);
            form.find('input#f_cdestinatario').prop('required', true);

            if(form.find('input#f_pec').length){
                if(!_$(form.find('input#f_pec')[0]).val().toString().length){
                    form.find('input#f_pec').removeClass('uk-form-danger')
                    form.find('input#f_pec').parent().find('.message-error').addClass('uk-hidden');
                }
            }
        }else if(form.find('input#f_pec').attr('data-valid') == 'true' && form.find('input#f_cdestinatario').attr('data-valid') == 'true'){
            return;
        }else{
            form.find('input#f_cdestinatario').prop('required', true);
            form.find('input#f_pec').prop('required', true);
        }
    }



    /**
     * Messaggi custom per i campi
     * @param element
     * @param type
     */
    customControlErrorField(element, type: string) {
        _$(element).parent().find('.message-error').removeClass('uk-hidden');
        switch (type) {
            case 'f_cf':
                _$(element).parent().find('.message-error').html('Codice Fiscale errato');
                break;
            case 'f_piva':
                _$(element).parent().find('.message-error').html('Partita IVA errata');
                break;
            case 'f_cap':
                _$(element).parent().find('.message-error').html('Cap errato');
                break;
            case 'f_mail':
                _$(element).parent().find('.message-error').html('Email non valida');
                break;
            case 'f_pec':
                _$(element).parent().find('.message-error').html('Email non valida');
                break;
            case 'f_cell':
                _$(element).parent().find('.message-error').html('Cellulare non valido');
                break;
            case 'f_tel':
                _$(element).parent().find('.message-error').html('Telefono non valido');
                break;
            case 'f_cdestinatario':
                _$(element).parent().find('.message-error').html('Codice destinatario non valido');
                break;
        }
    }

    resetSelectEnvoice() {
        let val = _$('#envoiceSelection').val();
        let form = _$('#envoiceForm');
        

        form.find('.uk-label-float').removeClass('uk-hidden');
        form.find('.uk-label-float .opt').removeClass('uk-hidden');
        form.find('.uk-label-float .ask').addClass('uk-hidden');

        //reset required field
        form.find('input').prop('required', false);
        form.find('input#f_etichetta').prop('required', true);
        form.find('input#f_address').prop('required', true);
        form.find('input#f_cap').prop('required', true);
        form.find('input#f_city').prop('required', true);
        form.find('select#f_provincia').prop('required', true);
        form.find('select#f_nazione').prop('required', true);
        form.find('.sub-title-azienda').addClass('uk-hidden');

        if (val === 'azienda') {
            
            //azienda
            form.find('.form-piva').removeClass('uk-hidden');
            form.find('.form-pec .opt').addClass('uk-hidden');
            form.find('.form-pec .ask').removeClass('uk-hidden');
            form.find('.form-cdestinatario .opt').addClass('uk-hidden');
            form.find('.form-cdestinatario .ask').removeClass('uk-hidden');
            form.find('.form-mail .opt').removeClass('uk-hidden');
            form.find('.form-mail .ask').addClass('uk-hidden');
            form.find('.sub-title-azienda').removeClass('uk-hidden');

            form.find('input#f_name').prop('required', true);
            form.find('input#f_surname').prop('required', true);
            form.find('input#f_rsociale').prop('required', true);
            form.find('input#f_piva').prop('required', true);
            form.find('input#f_pec').prop('required', true);
            form.find('input#f_cdestinatario').prop('required', true);
            form.find('input#f_cf').prop('required', true);
            form.find('input#f_cf').attr('pattern','.+');

        } else if (val === 'persfis') {

            //persona fisica
            form.find('.form-rsociale').addClass('uk-hidden');
            form.find('.form-piva').addClass('uk-hidden');
            form.find('.form-mail .opt').addClass('uk-hidden');
            form.find('.form-mail .ask').removeClass('uk-hidden');

            form.find('.form-pec .ask').addClass('uk-hidden');
            form.find('.form-pec .opt').removeClass('uk-hidden');
            form.find('.form-cdestinatario .ask').addClass('uk-hidden');
            form.find('.form-cdestinatario .opt').removeClass('uk-hidden');

            //required field
            form.find('input#f_name').prop('required', true);
            form.find('input#f_surname').prop('required', true);
            form.find('input#f_cf').prop('required', true);
            //form.find('input#f_cf').attr('pattern','^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$');
            form.find('input#f_mail').prop('required', true);

        }
    }

    populateModalEdit(data:AddressUser) {
        let value = data.type;
        let select =_$('#envoiceSelection');
        select.val(value).trigger("change");
        this.utils.customSelectValue('envoiceSelection', value);

        this.populateValueData(data);

    }

    populateValueData(data:AddressUser) {
        console.log('data: ', data);
        let form = _$('#envoiceForm');

        this.utils.searchOptionValue('offcanvasFatturazioneElettronica','select#envoiceSelection', data.type);

        form.find('input#f_etichetta').val(data.nickname);

        form.find('input#f_rsociale').val(data.businessName);
        form.find('input#f_piva').val(data.vat);
        form.find('input#f_cf').val(data.fiscalCode);
        form.find('input#f_name').val(data.firstName);
        form.find('input#f_surname').val(data.lastName);

        form.find('input#f_address').val(data.line1);
        form.find('input#f_cap').val(data.postalCode);
        form.find('input#f_city').val(data.town);

        // Provincia
        form.find('select#f_provincia').val(data.district).trigger("change");
        this.utils.searchOptionValue('envoiceForm','select#f_provincia', data.district);
        form.find('select#f_provincia').next().addClass('uk-hidden');


        // Nazione
        form.find('select#f_nazione').val(data.countryValue).trigger("change");
        this.utils.searchOptionValue('envoiceForm','select#f_nazione', data.countryValue);
        form.find('select#f_nazione').next().addClass('uk-hidden');

        form.find('input#f_pec').val(data.pec);
        form.find('input#f_mail').val(data.email);
        form.find('input#f_mail').val(data.email);
        form.find('input#f_cell').val(data.cellphone);
        form.find('input#f_tel').val(data.phone);
        form.find('input#f_cdestinatario').val(data.sdiCode);


        let input = form.find('input#f_defaultBillingAddress');
        if(data.defaultBillingAddress) {
            input.val('on').prop('checked', true);
            let checkbox = input.parent();
            this.utils.checkBox(checkbox);
        } else {
            input.val('off').prop('checked', false);
            let checkbox = input.parent();
            this.utils.checkBox(checkbox);
        }

        //controllo della label altri dati, visibile solo se azienda o ditta ind.
        if(data.type != 'persfis') {
            form.find('.sub-title-azienda').removeClass('uk-hidden');
        } else {
            form.find('.sub-title-azienda').addClass('uk-hidden');
        }

        _Uikit.util.on('#offcanvasFatturazioneElettronica', 'shown', () => {
            let textFields = form.find('input, select');
            for (let i = 0; i < textFields.length; i++) {
                this.controlValueFieldInput(_$(textFields[i]), _$(textFields[i]).attr('id'));
            }
        });
    }

    /**
     * Pulisce le select e i suoi div
     * @param field 
     */
    clearSelectInput(field) {
        let form = _$('#envoiceForm');
        form.find(field).val('').trigger("change");
        form.find(field).removeClass('uk-form-danger');
        form.find(field).next().addClass('uk-hidden');

    }

    clearInputValue() {
        console.log('CHANGE')
        let form = _$('#envoiceForm');
        form.find('input, select').removeClass('uk-form-danger');

        form.find('input#f_etichetta').val('');

        form.find('input#f_rsociale').val('');
        form.find('input#f_piva').val('');
        form.find('input#f_cf').val('');
        form.find('input#f_name').val('');
        form.find('input#f_surname').val('');

        form.find('input#f_address').val('');
        form.find('input#f_cap').val('');
        form.find('input#f_city').val('');

        this.clearSelectInput('select#f_provincia');
        this.clearSelectInput('select#f_nazione');

        form.find('input#f_pec').val('');
        form.find('input#f_mail').val('');
        form.find('input#f_cell').val('');
        form.find('input#f_tel').val('');
        form.find('input#f_cdestinatario').val('');

        let input = form.find('input#f_defaultBillingAddress');
        input.val('off').prop('checked', false);
        let checkbox = input.parent();
        this.utils.checkBox(checkbox);
    }

    sendAnalytics(eventType){
        try {
            let analyticsData;
            let step = '';

            let importoConsegna = window['cart'] && window['cart'].totalDeliveryCost ? window['cart'].totalDeliveryCost.value : '0';
            switch (eventType) {

                case 'pageview':
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo: window['cart'] && window['cart'].subTotal ? window['cart'].subTotal.value : '0',
                            consegnaGratuita: importoConsegna == '0' ? 'SI' : 'NO',
                            importoConsegna,
                        }
                    };
                break;
                case 'chiusuraModale':
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo: window['cart'] && window['cart'].subTotal ? window['cart'].subTotal.value : '0',
                            consegnaGratuita: importoConsegna == '0' ? 'SI' : 'NO',
                            importoConsegna,
                        },
                        checkout: {
                            pagamento: {
                                metodoPagamento: ''
                            }
                        }
                    };
                break;
            }
            step = 'FUNNEL_DATA_CHECKOUT_MODAL_FATT_ELETTR';
            this.analytics.sendAnalytics(analyticsData, step, true, true);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }
}
