import { _Uikit, Window, _$ } from '../../main';

export class BannerAddress{
    constructor(){

    }

    nkOnInit(){

    }
}