import Alpine from 'alpinejs';
import JsBarcode from 'jsbarcode';
import { Coupon } from '../../../../../../api/models/Coupon';
import { FlowStore } from '../../../../../../cfamily/types';
import { formatC, i18n } from '../../../../../../libs/base-utils';

Alpine.data('popup12x5xCouponDetail', (args: Record<string, any>) => ({
    inizialized: false as boolean,
    coupon: undefined as Coupon,
    showTooltip: false,
    init() {
        this._initialize();
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    async _initialize() {
        const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
        this.coupon = flowStore?.data?.coupon;
        this._renderBarcode();
        this.inizialized = true;
    },
    _renderBarcode() {
        if (!this.coupon?.codiceCoupon) return;
        const barcodeElement = this.$refs.barcode as SVGSVGElement;
        const barcodeWithSpaces = this.coupon.codiceCoupon.split('').reduce((prev, curr) => prev + ' ' + curr, '');
        JsBarcode(barcodeElement)
            .EAN13(this.coupon.codiceCoupon, {
                flat: true,
                fontSize: 14,
                fontOptions: 'regular',
                textMargin: 7,
                height: 71,
                width: 3,
                text: barcodeWithSpaces,
            })
            .render();
    },
    // main component functions
    async cancel() {
        const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
        flowStore.data.result = {
            state: 'cancelled'
        };
        window.cFlowManager.complete();
    },
    copyToClipboard() {
        const code = this.$refs.couponCode.textContent;
        navigator.clipboard.writeText(code).then(() => {
            this.showTooltip = true;
            setTimeout(() => this.showTooltip = false, 6300);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    },
}));
