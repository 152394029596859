import { CustomerCareResponse } from "../../api/models/CustomerCareResponse";
import { DefaultService } from "../../api/services/DefaultService";

type CachedRef = {
    cacheName: string;
    cacheTTL: number;
};

type CachedData = {
    data: any;
    update: number;
};

const CACHE_REFS: { [key: string]: CachedRef; } = {
    'getApiEcommerceItItDictionaryJson': {
        cacheName: 'cached-dictionary',
        cacheTTL: 300000
    },
    'getApiMyconadItItGetCustomerCareRequestJson': {
        cacheName: 'cached-customer-care-cases',
        cacheTTL: 300000
    },
};

export class CacheService {

    public static getApiEcommerceItItDictionaryJson(forceCacheRefresh: boolean = false): Promise<{
        data?: Record<string, any>;
    }> {
        const cacheRef = CACHE_REFS['getApiEcommerceItItDictionaryJson'];
        if (!forceCacheRefresh) {
            // from Cache
            const cachedData = CacheService.getFromCache(cacheRef);
            if (cachedData)
                return new Promise((resolve, reject) => resolve(cachedData));
        }

        return DefaultService.getApiEcommerceItItDictionaryJson().then((data) => {
            CacheService.putIntoCache(data, cacheRef); // to Cache
            return new Promise((resolve, reject) => resolve(data));
        });
    }

    public static getApiMyconadItItGetCustomerCareRequestJson(userId: string, forceCacheRefresh: boolean = false): Promise<{
        data?: CustomerCareResponse;
    }> {
        const cacheRef = CACHE_REFS['getApiMyconadItItGetCustomerCareRequestJson'];
        const cacheParams = { userId: (userId || 'none') };
        if (!forceCacheRefresh) {
            // from Cache
            const cachedData = CacheService.getFromCache(cacheRef, cacheParams);
            if (cachedData)
                return new Promise((resolve, reject) => resolve(cachedData));
        }

        return DefaultService.getApiMyconadItItGetCustomerCareRequestJson().then((data) => {
            CacheService.putIntoCache(data, cacheRef, cacheParams); // to Cache
            return new Promise((resolve, reject) => resolve(data));
        });
    }

    // more functions here ...

    //////////////////////////////////////////////
    //////// CUSTOM CACHE IMPLEMENTATIONS
    //////////////////////////////////////////////

    /**
         * Provide a custom cache implementation
         * @param cacheName the cache key for the sessionStorage object
         * @param cacheTTL the cache TTL in ms (milliseconds)
         * @returns the cached data or undefined (if no value in cache)
         */
    public static getFromCacheCustomData(cacheName: string, cacheTTL: number): any | undefined {
        const customCacheRef: CachedRef = {
            cacheName: cacheName,
            cacheTTL: cacheTTL
        };
        const cachedData: CachedData = CacheService.getFromCache(customCacheRef);
        return cachedData ? cachedData : undefined;
    }

    /**
     * Provide a custom cache implementation
     * @param cacheName the cache key for the sessionStorage object
     * @param cacheTTL the cache TTL in ms (milliseconds)
     * @param data the data to save into sessionStorage cache
     */
    public static putIntoCacheCustomData(cacheName: string, cacheTTL: number, data: any) {
        const customCacheRef: CachedRef = {
            cacheName: cacheName,
            cacheTTL: cacheTTL
        };
        CacheService.putIntoCache(data, customCacheRef);
    }

    /**
     * Provide a custom cache implementation
     * !! Update cache data without change the update time
     * @param cacheName the cache key for the sessionStorage object
     * @param cacheTTL the cache TTL in ms (milliseconds)
     * @param data the data to save into sessionStorage cache
     */
    public static updateCacheCustomData(cacheName: string, cacheTTL: number, data: any) {
        const customCacheRef: CachedRef = {
            cacheName: cacheName,
            cacheTTL: cacheTTL
        };
        CacheService.updateCacheWithoutChangeUpdateTime(data, customCacheRef);
    }

    /**
     * Provide a custom cache implementation
     * @param cacheName the cache key for the sessionStorage object
     */
    public static resetCacheCustomData(cacheName: string) {
        const customCacheRef: CachedRef = {
            cacheName: cacheName,
            cacheTTL: 0
        };
        CacheService.resetCache(customCacheRef);
    }

    //////////////////////////////////////////////
    //////// PRIVATE
    //////////////////////////////////////////////

    private static getFromCache(cacheRef: CachedRef, cacheParams: { [key: string]: any } = {}): any {
        if (!cacheRef) return undefined;
        const cacheParamsId = CacheService.cacheParamsStringify(cacheParams) || '';

        const serializedData: string = window.localStorage?.getItem(cacheRef?.cacheName + cacheParamsId);
        if (serializedData) {
            const deserializedData: CachedData = JSON.parse(serializedData, CacheService.reviver);
            if (deserializedData?.update > Date.now() - cacheRef?.cacheTTL)
                return deserializedData?.data ? deserializedData.data : undefined;
        }
        return undefined;
    }

    private static putIntoCache(saveData: any, cacheRef: CachedRef, cacheParams: { [key: string]: any } = {}) {
        if (!cacheRef || !saveData) return;
        const cacheParamsId = CacheService.cacheParamsStringify(cacheParams) || '';

        const cachedData: CachedData = {
            data: saveData,
            update: Date.now()
        }
        const serializedData: string = JSON.stringify(cachedData, CacheService.replacer);
        if (serializedData) {
            window.localStorage?.setItem(cacheRef?.cacheName + cacheParamsId, serializedData);
        }
    }

    private static resetCache(cacheRef: CachedRef, cacheParams: { [key: string]: any } = {}) {
        if (!cacheRef) return;
        const cacheParamsId = CacheService.cacheParamsStringify(cacheParams) || '';
        window.localStorage?.removeItem(cacheRef?.cacheName + cacheParamsId);
    }

    private static updateCacheWithoutChangeUpdateTime(saveData: any, cacheRef: CachedRef, cacheParams: { [key: string]: any } = {}) {
        if (!cacheRef || !saveData) return;
        const cacheParamsId = CacheService.cacheParamsStringify(cacheParams) || '';

        const extractedSerializedData: string = window.localStorage?.getItem(cacheRef?.cacheName + cacheParamsId);
        if (!extractedSerializedData) return;
        const extractedDeserializedData: CachedData = JSON.parse(extractedSerializedData, CacheService.reviver);
        if (!extractedDeserializedData) return;

        const cachedData: CachedData = {
            data: saveData,
            update: extractedDeserializedData.update
        }
        const serializedData: string = JSON.stringify(cachedData, CacheService.replacer);
        if (serializedData) {
            window.localStorage?.setItem(cacheRef?.cacheName + cacheParamsId, serializedData);
        }
    }

    /**
     * Converte una mappa chiave -> valore 'semplice' in una stringa
     * 
     * { id: 5, name: 'mario' } // Output: '+id=5+name=mario'
     * { type: 7.5 } // Output: '+type=7.5'
     * 
     * @param cacheParams una mappa chiave -> valore 'semplice' generica
     * @returns una stringa generata dalla mappa
     */
    private static cacheParamsStringify(cacheParams: { [key: string]: any }): string {
        if (!cacheParams) return '';
        return Object.keys(cacheParams)
            .map(key => `+${key}=${cacheParams[key]}`)
            .join('');
    }

    private static replacer(key, value) {
        if (value instanceof Map) {
            return {
                dataType: 'Map',
                value: Array.from(value.entries()),
            };
        } else {
            return value;
        }
    }

    private static reviver(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.value);
            }
        }
        return value;
    }

}