import Alpine from 'alpinejs';
import { CustomerCareCase } from '../../../../../../api';
import { CacheService } from '../../../../../../api.utils/services/CacheService';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, i18n } from '../../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';
import { User } from '../../../../../../types/user';

// map to convert openTab value to CustomerCareCase.status
const statusMap = {
    open: 'NEW',
    takenOver: 'IN_GESTIONE',
    awaiting: 'ON_HOLD',
    closed: 'CLOSED',
};

Alpine.data('ec8x4xAssistance', () => ({
    customerCases: [] as CustomerCareCase[],
    openTab: 'all',
    currentPage: 1,
    casesPerPage: 3,
    showCardsSkeleton: false,
    isMostRecent: true,
    init() {
        sendAnalytics(BASE_ECOMMERCE_DATA.PAGE_VIEW_PA_ASSISTANCE);
        this._loadCustomerCareCases();
        this.$watch('openTab', () => {
            this.isMostRecent ? (this.currentPage = 1) : this.reverseList();
        });
        this._attachHeadingComponentDescriptionAnalytics();
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    async _loadCustomerCareCases(forceCacheRefresh: boolean = false) {
        this.showCardsSkeleton = true;
        this.customerCases = [];
        const user = <User>Alpine.store('user');
        if (!user?.utenzaId) {
            this.showCardsSkeleton = false;
            return;
        }
        try {
            const response = await CacheService.getApiMyconadItItGetCustomerCareRequestJson(
                user.utenzaId,
                forceCacheRefresh,
            );

            this.customerCases.push(...(response?.data || []));
        } catch (e) {
            console.log(e);
        } finally {
            this.showCardsSkeleton = false;
        }
    },
    // main component functions
    getCustomerCases() {
        if (this.openTab === 'all') {
            return this.customerCases;
        }

        //get filtered customerCases depending on which tab is open
        const statusToFilter = statusMap[this.openTab];
        return this.customerCases.filter((customerCase: { status: any }) => customerCase.status === statusToFilter);
    },
    getPaginatedCustomerCases() {
        const cases = this.getCustomerCases();
        const start = (this.currentPage - 1) * this.casesPerPage;
        const end = start + this.casesPerPage;
        return cases.slice(start, end);
    },
    getPages() {
        const total = this.totalPages();
        const current = this.currentPage;
        const pages = [{ type: 'page', value: 1 }];

        if (current > 3) {
            pages.push({ type: 'ellipsis', value: current - 2 });
        }

        const startPage = Math.max(2, current - 1);
        const endPage = Math.min(total - 1, current + 1);

        for (let i = startPage; i <= endPage; i++) {
            pages.push({ type: 'page', value: i });
        }

        if (current < total - 2) {
            pages.push({ type: 'ellipsis', value: current + 2 });
        }

        if (total > 1) {
            pages.push({ type: 'page', value: total });
        }

        return pages;
    },
    totalPages() {
        return Math.ceil(this.getCustomerCases().length / this.casesPerPage);
    },
    goToNextPage() {
        if (this.currentPage < this.totalPages()) {
            this.currentPage++;
        }
    },
    goToPreviousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
    },
    goToPage(pageNumber: number) {
        this.currentPage = pageNumber;
    },
    reverseList() {
        this.isMostRecent = !this.isMostRecent;
        this.customerCases.reverse();
        this.currentPage = 1;
    },
    // only analytics functions
    async _attachHeadingComponentDescriptionAnalytics() {
        // tracking for an editorial text with <a title="{track-customer-care} Apri richiesta assistenza" href=...></a>
        const TRACKING_TAG = '{track-customer-care}';
        const descriptionEl = this.$root.querySelector('div[data-id="headingComponentDescription"]');
        if (!descriptionEl || !descriptionEl.innerHTML?.includes(TRACKING_TAG)) return;
        let linkEl = descriptionEl.querySelector(`a[title*="${TRACKING_TAG}"]`);
        if (!linkEl) return;
        linkEl.setAttribute('title', linkEl.getAttribute('title').replace(TRACKING_TAG, '')); // cleans title attr. from tag
        linkEl.setAttribute('x-on:click', 'trackContactCustomerCare()'); // injects tracking trigger
    },
    trackContactCustomerCare() {
        sendAnalytics(BASE_ECOMMERCE_DATA.CLICK_PA_CONTACT_CUSTOMER_CARE_LINK);
    },
    trackCloseCustomerCareRequest(id, recordType, status) {
        let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_CLOSE_CUSTOMER_CASE_REQUEST;
        ecommerceData.funnel.idSegnalazione = id || '';
        ecommerceData.funnel.tipologiaSegnalazione = recordType || '';
        ecommerceData.funnel.statoSegnalazione = status || '';
        sendAnalytics(ecommerceData);
    }
}));
