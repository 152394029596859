import { _Uikit, Window, _$ } from '../../main';

export class BannerListingImage {
    constructor(){

    }

    nkOnInit(){

    }
}