import { _$ } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
export class ModalCart {
   
   constructor(
      private _analytics: Analytics,
   ) { }
   
   nkOnInit() { 
      _$('#modal-info-consegna').on('show', () => { this.sendAnalytics('infoConsegna'); })
   }

   radioCheck(element) {
      _$('#btn').prop('disabled', false);
      _$(element).parent().css("background-color", "#ffee9b");
   }

   selectOption(element){
      _$('#btn-conferma-opzione-sostituzione').prop('disabled', false);
      _$('#modal-opzioni-sostituzione').find('.substitution-option').removeClass('selected');
      _$(element).addClass('selected');
      _$(element).find('.uk-radio').prop('checked', true);
   }

   cardClick(element) {
      _$('input', element)[0].click();
   }

   sendAnalytics(eventType){
      try {
         let page = document.location.pathname.split('/');
         let myPage = page[page.length - 1].split('?')[0];

         switch (myPage){
            case window['urlSite'].home.split('/')[1]:
               myPage = 'Minicart';
            break;
            // case window['urlSite'].cart:
            //    myPage = 'Carrello';
            // break;
            case window['urlSite'].detailCart.split('/')[1]:
               myPage = 'Carrello';
            break;
            case window['urlSite'].checkout.split('/')[1]:
               myPage = 'Checkout';
            break;
         }

         //TO DO
         let analyticsData = {
            event: eventType,
            funnel: {
               nomeFunnel: myPage,
               stepFunnel: 'Tooltip info consegna',
            },
            carrello: {
               importo: '<importo carrello>',
               consegnaGratuita: 'SI / NO',
               importoConsegna: '<importo spese di consegna>',
               prodotti: '<JSON con tutti i prodotti>'
            }
         };
         this._analytics.sendAnalytics(analyticsData, '', false);
      } catch (error) {
         console.log(eventType + ' : ' + error);
      }
   }
}