import { _Uikit, Window, _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';

export class CardsReview{

    swiperReviewsOption: SwiperOptions = {
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        //effect: 'fade',
        //direction: 'vertical',
        loop: true,
        slidesPerView: 1,
        speed: 1,
        pagination: {
            el: '.cards-review .swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                
                let imgs = _$('.swiper-reviews').find('.swiper-slide');
                let img_src = _$(imgs[index + 1]).find('img').attr('src');
                let img_alt = _$(imgs[index + 1]).find('img').attr('alt');

                return (
                    `<div class= "ko-progress-circle ` +
                    className +
                    `" data-progress="0">
                            <img class="ko-progress-circle__content" src="` +
                    img_src +
                    `" alt="` +
                    img_alt +
                    `" />
                            <div class="ko-circle">
                            <div class="full ko-progress-circle__slice">
                                <div class="ko-progress-circle__fill"></div>
                            </div>
                            <div class="ko-progress-circle__slice">
                                <div class="ko-progress-circle__fill"></div>
                                <div class="ko-progress-circle__fill ko-progress-circle__bar"></div>
                            </div>
                            </div>
                            <div class="ko-progress-circle__overlay"></div>
                        </div>`
                );
            },
        },
    };
    swiperReviews = new Swiper('.swiper-reviews', this.swiperReviewsOption);

    constructor(){}

    nkOnInit(){
        this.swiperReviews;
    }
}