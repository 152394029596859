import Swiper, { SwiperOptions } from 'swiper';
import { Utils } from '../../services/utils/utils';
export class Navigazione {
   
    constructor(
        private _utils: Utils,
    ) { }
    
    nkOnInit() { } 

    swiperNavCatSmallOptions: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 12,
        slidesPerGroup: 1,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-off-cat',
            draggable: true,
            hide: false,
        },
        navigation: {
            nextEl: '.next-off-cat',
            prevEl: '.prev-off-cat',
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 8,
            },
            768: {
                spaceBetween: 15,
            },
            1024: {
                spaceBetween: 12,
            },
            1440: {
                spaceBetween: 24,
            },
        },
    };
    swiperNavCatSmall = new Swiper('.swiper-nav-cat-small', this.swiperNavCatSmallOptions);
 }
