import Alpine from 'alpinejs';
import { formatC, i18n } from '../../../../../../libs/base-utils';

Alpine.data('ec11x3xTextbox', () => ({
    inputFocus: false,
    value: '', // externally exposed as 'textboxValue' 
    init() {
    },
    // utils
    i18n,
    formatC,
    // internal functions
    // main component functions
}));