import Swiper, { SwiperOptions } from 'swiper';

export class OfferteInCorso {
   
    constructor() { }
    
    nkOnInit() { }   

    swiperOfferteOptions: SwiperOptions = {
        freeMode: true,
        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 10,
                slidesPerView: 1.3,
                noSwiping: false,
                allowSlideNext: true,
                allowSlidePrev: true
            },
            768: {
                spaceBetween: 15,
                slidesPerView: 2,
                noSwiping: false,
                allowSlideNext: true,
                allowSlidePrev: true
            },
            1024: {
                spaceBetween: 16,
                slidesPerView: 3,
                noSwiping: true,
                allowSlideNext: false,
                allowSlidePrev: false
            },
        },
    };
    swiperOfferteInCorso = new Swiper('.swiper-off-in-corso', this.swiperOfferteOptions);

}