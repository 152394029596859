import Swiper, { SwiperOptions } from "swiper";
import { Analytics } from "src/services/analytics/analytics";
import { Utils } from '../../services/utils/utils';
import { _$ } from "../../main";

export class Errors {
   
   constructor(
      private _analytics: Analytics,
      private _utils: Utils,
   ) { }
   
   nkOnInit() { 
      this.sendAnalytics('error404');
      for (let i = 0; i < _$('.next-last').length; i++) {
         _$('.next-last')[i].setAttribute('onclick','Errors.mySlideNext(this)');
         _$('.prev-last')[i].setAttribute('onclick','Errors.mySlidePrev(this)');            
     }
   }   

   swiperProductsOptions: SwiperOptions = {
      slidesPerView: 1.01,
      spaceBetween: 15,
      freeMode: true,
      scrollbar: {
         el: '.scrollbar-last',
         draggable: true,
         hide: false,
      },
      /* navigation: {
         nextEl: '.next-last',
         prevEl: '.prev-last',
      }, */
      pagination: {
         el: '.pagination-last',
         type: 'custom',
         renderCustom: function (swiper, current, total) {
            let slideView;
            if (_$(window).width() < 768) {
               slideView = swiper.params.breakpoints['375'].slidesPerView;
            } else if (_$(window).width() < 1024){
               slideView = swiper.params.breakpoints['768'].slidesPerView;
            } else if (_$(window).width() < 1440){
               slideView = swiper.params.breakpoints['1024'].slidesPerView;
            } else {
               slideView = swiper.params.breakpoints['1440'].slidesPerView;
            }
            return Math.ceil((current+slideView-1)/slideView) + ' di ' + Math.ceil((total+slideView-1)/slideView);    
         }
      },
      breakpointsInverse: true,
      breakpoints: {
         375:{
            slidesPerView: 1.1,
            spaceBetween: 3,
         },
         768:{
            slidesPerView: 3.01,
            spaceBetween: 15,
            /* slidesPerGroup: 3 */
         } ,
         1024:{
            slidesPerView: 4,
            spaceBetween: 16,
            /* slidesPerGroup: 4 */
         },
         1440: {
            slidesPerView: 5,
            spaceBetween: 24,
            /* slidesPerGroup: 5 */
         }
      }
   }
   swiperProducts = new Swiper('.swiper-products', this.swiperProductsOptions);

   sendAnalytics(eventType) {
      try {
         let analyticsData;
         let step = 'FUNNEL_DATA_404';
   
         analyticsData = {
            event: eventType,
            errorPage : {
               pageUrL: document.location.href.toString()
            }
         }
         this._analytics.sendAnalytics(analyticsData, step);
      } catch (error) {
         console.log(eventType + ' : ' + error);
      }
   }

   mySlideNext(item) {
      this._utils.mySlideNext(this.swiperProductsOptions, _$(item).closest('.swiper-products')[0].swiper);
  }

  mySlidePrev(item) {
      this._utils.mySlidePrev(this.swiperProductsOptions, _$(item).closest('.swiper-products')[0].swiper);
  }
}