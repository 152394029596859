import { _Uikit, Window, _$ } from '../../main';    
import Swiper, { SwiperOptions } from 'swiper';

export class CardsQuestion{

    swiperFaqOption: SwiperOptions = {
        spaceBetween: 16,
        slidesPerView: 'auto',
        freeMode: true,
        scrollbar: {
            el: '.cards-question .swiper-scrollbar',
            draggable: true,
            hide: false,
        },
        watchOverflow: true,
        navigation: {
            nextEl: '.cards-question .swiper-button-next',
            prevEl: '.cards-question .swiper-button-prev',
        },
        breakpointsInverse: true,
        breakpoints: {
            1440: {
                spaceBetween: 24,
            },
            1024: {
                spaceBetween: 16,
            },
            768: {
                spaceBetween: 16,
            },
        },
    };
    swiperFaq = new Swiper('.swiper-faq', this.swiperFaqOption);

    constructor(){}

    nkOnInit(){

    }

}