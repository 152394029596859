import Swiper, { SwiperOptions } from 'swiper';
import { _$ } from '../../main';

export class ScopriNovita {
    constructor() { }

    nkOnInit() {
    }

    swiperScopriNovitaOptions: SwiperOptions = {
        spaceBetween: 15,
        freeMode: true,
        slidesPerView: 'auto',
        navigation: {
            nextEl: '.next-scopri-novita',
            prevEl: '.prev-scopri-novita',
        },
        pagination: {
            el: '.pagination-scopri-novita',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                return current + ' di ' + total;
            },
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                spaceBetween: 15
            },
            768: {
                spaceBetween: 15,
                slidesPerGroup: 2.3
            },
            1024: {
                spaceBetween: 16,
                slidesPerGroup: 3.3
            },
            1440: {
                spaceBetween: 24,
                slidesPerGroup: 4.3
            },
        },
    };

    swiperScopriNovita = new Swiper('.swiper-scopri-novita', this.swiperScopriNovitaOptions);
}