import { _$ } from '../../main';
export class PromoBadgeCard{
    constructor(){
        for(let i = 0; i < _$('.component-PromoBadgeCard').length; i++){
            if(!_$('.component-PromoBadgeCard').eq(i).find('.tooltiptext-drop').length){
                _$('.component-PromoBadgeCard').eq(i).css('pointer-events', 'none');
            }
        }
    }

    nkOnInit(){
    }
}