import { Window, _$, _Uikit } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
import { AnalyticsUtility, BANNER_TYPES } from '../../services/analytics/analytics-utility';

export class Entrypage {

    constructor(private analytics: Analytics, private analyticsUtility: AnalyticsUtility) {}

    nkOnInit() {
        this.sendAnalytics('pageview');
        this.setBannersClickEvents();
    }

    setBannersClickEvents(){
        _$('.cards-image-top .component-card-image-top').on('click', (event) => {
            this.sendAnalytics('advClickCardImageTop', _$(event.target).closest('.component-card-image-top'));
        });
    }

    sendAnalytics(eventType, element?){
        try{
            let analyticsData;
            let step;
            let sponsor = [];
            let datapromoArray = [];
            let banner;
            let slides;
            let slide;
            let clickEvent = false;

            switch(eventType){
                case 'pageview':
                    let bannerList = [
                        BANNER_TYPES.CARDS_IMAGE_TOP];

                    bannerList.forEach((value) => {
                        [sponsor, datapromoArray] = this.analyticsUtility.getBannerPageview(value, sponsor, datapromoArray);
                    });

                    analyticsData = {
                        event: eventType,
                        sponsor
                    }
                    break;
                case 'advClickCardImageTop':
                    clickEvent = true;
                    analyticsData = this.analyticsUtility.getBannerClickEvent(BANNER_TYPES.CARDS_IMAGE_TOP, analyticsData, element);
                    break;
                default:
                    break;
            }

            step = 'FUNNEL_DATA_EP_STEP1';

            if(clickEvent){
                let dataPromo = _$(element).attr('data-promo') != null ? JSON.parse(_$(element).attr('data-promo')) : '';
                this.analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            }else{
                if (datapromoArray.length) {
                    this.analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                } else {
                    this.analytics.sendAnalytics(analyticsData, step);
                }  
            }
        }catch(error){
            console.error(error);
        }
    }
}
