import { Alpine } from 'alpinejs';
import { CChangeDetail } from '../types';

export const cInput = (Alpine: Alpine) => {
    Alpine.directive('cinput', (el: HTMLElement, { expression }, { cleanup }) => {
        const realEl = el as HTMLInputElement;

        const cScope = Alpine.reactive({ $cerrored: false });
        Alpine.addScopeToNode(el, cScope);

        const invalidHandler = () => {
            cScope.$cerrored = true;
            realEl.setCustomValidity(' ');
        };

        const getInputValidity = async () => {
            let valid = realEl.validity.valid;
            // custom validation
            if (expression) {
                const fn = window.cValidators[expression];
                if (fn) valid = valid && (await fn({ el: realEl }));
            }
            return valid;
        };

        const triggerCChanged = (valid: boolean, startup: boolean) => {
            const detail: CChangeDetail = { name: realEl.name, value: realEl.value, validity: valid, startup };
            const ev = new CustomEvent('cchange', {
                detail,
                bubbles: true,
            });
            realEl.dispatchEvent(ev);
        };

        const changeHandler = async () => {
            // reset custom validity
            realEl.setCustomValidity('');

            // check validity
            let valid = await getInputValidity();
            if (valid) {
                cScope.$cerrored = false;
            } else {
                cScope.$cerrored = true;
                realEl.setCustomValidity(' ');
            }

            // trigger changed
            triggerCChanged(valid, false);
        };

        const keyupHandler = (e: KeyboardEvent) => {
            if (cScope.$cerrored && e.key != 'Enter' && e.key != 'Tab') {
                cScope.$cerrored = false;
                realEl.setCustomValidity('');
            }
        };

        const keydownHandler = (e: KeyboardEvent) => {
            if (e.key == 'Enter') {
                e.preventDefault();
            }
        };

        el.addEventListener('invalid', invalidHandler);
        el.addEventListener('change', changeHandler);
        el.addEventListener('keyup', keyupHandler);
        el.addEventListener('keydown', keydownHandler);

        // first cchange for handling dependencies
        setTimeout(async () => {
            triggerCChanged(await getInputValidity(), true);
        }, 100);

        cleanup(() => {
            el.removeEventListener('invalid', invalidHandler);
            el.removeEventListener('change', changeHandler);
            el.removeEventListener('keyup', keyupHandler);
            el.removeEventListener('keydown', keydownHandler);
        });
    }).before('bind');
};
