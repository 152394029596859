import { Utils } from '../../services/utils/utils';
import { GoogleUtils } from '../../services/utils/google-utils';
import Swiper, { SwiperOptions } from 'swiper';
import { _Uikit, Window, _$ } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
import { BANNER_TYPES, AnalyticsUtility } from '../../services/analytics/analytics-utility';
export class Pageoffers {

    constructor(
        private _utils: Utils,
        private _google: GoogleUtils,
        private _analytics: Analytics,
        private _analyticsUtility: AnalyticsUtility
    ) { }

    nkOnInit() {
        setTimeout(() => {
            this._utils.customScrollbar();
        }, 100);
        this.sendAnalytics('pageview');
        this.setBannersClickEvents();
    }

    setBannersClickEvents(){
        _$('.component-PromoMdd .component-CardSimple').on('click', (event) => {
            this.sendAnalytics('advClickPromotionsVisibilities', _$(event.target).closest('.component-CardSimple'));
        });
    }

    async sendAnalytics(eventType, element?){
        try {
            let analyticsData;
            let step;
            let tipoServizio = window['typeOfService'] == 'ORDER_AND_COLLECT' ? 'Ordina e ritira' : 'Ordina e ricevi' ;
            let idNegozio = window['pointOfService']['name'];
            let coopNegozio = window['pointOfService']['cooperativeId'];
            let giorno = this._analytics.getDayFromTimestamp(window['selectedTimeslot']['date']);
            let fasciaOraria = window['selectedTimeslot']['startTime']['formattedHour'] + " - " + window['selectedTimeslot']['endTime']['formattedHour'];
            let sponsor = [];
            let datapromoArray = [];
            let banner;
            let slides;
            let slide;
            let clickEvent = false;
    
            switch (eventType) {
                
                case 'pageview':

                let bannerList = [BANNER_TYPES.PROMO_COMPONENT, BANNER_TYPES.STRIP_BTF];

                bannerList.forEach((value) => {
                    [sponsor, datapromoArray] = this._analyticsUtility.getBannerPageview(value, sponsor, datapromoArray);
                });

                let prodotto = []
                let productCard = _$('.component-ProductCard');

                for (var i = 0; i < productCard.length; i++) {
                    prodotto.push((await this._analytics.getProductData(productCard.eq(i))));
                }

                let productCardLastView = _$('.component-ProductCardLastView');
                for (var i = 0; i < productCardLastView.length; i++) {
                    prodotto.push((await this._analytics.getProductData(productCardLastView.eq(i))));
                }

                let cardSuggest = _$('.component-CardSuggest');
                for (var i = 0; i < cardSuggest.length; i++) {
                    prodotto.push((await this._analytics.getProductData(cardSuggest.eq(i))));
                }
                    
                analyticsData = {
                    event: eventType,
                    sponsor,
                    prodotto
                };
                break;  

                case 'advClickPromotionsVisibilities':
                    console.log(element);
                    clickEvent = true;
                    analyticsData = this._analyticsUtility.getBannerClickEvent(BANNER_TYPES.PROMOTIONS_AND_VISIBILITIES, analyticsData, element);
                    break;
                default:
                    break;
            }

            step = 'FUNNEL_DATA_OFFERS';

            if(clickEvent){
                let dataPromo = _$(element).attr('data-promo') != null &&  _$(element).attr('data-promo') != '' ? JSON.parse(_$(element).attr('data-promo')) : '';
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromo)
            }else{
                if (datapromoArray.length) {
                    this._analytics.sendAnalytics(analyticsData, step, true, true, datapromoArray);
                } else {
                    this._analytics.sendAnalytics(analyticsData, step);
                }  
            }         
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }
}