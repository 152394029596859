import { Utils } from "../../services/utils/utils";
import { _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';
import { SwiperConfigInitializer } from "../../services/utils/swiper-config-initializer";

export class CarouselBannerStripe {

    constructor(
        private _utils: Utils,
        private _swiperConfigInitializer: SwiperConfigInitializer
    ) { 
        this._utils.randomizeCards('.component-CarouselBannerStripe');
        this.initializerCarousel();
    }

    nkOnInit() {
    }

    swiperBannerStripeOptions: SwiperOptions = {
        freeMode: false,
        spaceBetween: 10,
        slidesPerGroup: 1,
        slidesPerView: 1,
        /* Pagination con le frecce */
        pagination: {
            el: '.pagination-banner-stripe',
            clickable: true,
        },
        breakpointsInverse: true,
    };

    swiperBannerStripeOptionsNoPagination: SwiperOptions = {
        freeMode: false,
        spaceBetween: 10,
        slidesPerGroup: 1,
        slidesPerView: 1,
        allowSlidePrev: false,
        allowSlideNext: false,
        breakpointsInverse: true,
    };

    initializerCarousel(){

        if(_$('.swiper-banner-stripe').length){
            _$('.swiper-banner-stripe').each((index, element) => {
                let swiperClass = 'swiper-banner-stripe-' + index;
                _$('.swiper-banner-stripe').eq(index).addClass(swiperClass);
                if(_$('.swiper-banner-stripe').find('.swiper-slide').length > 1){
                    var swiperBannerStripe = new Swiper('.' + swiperClass, this._swiperConfigInitializer.initConfig(['autoplay'], _$('.' + swiperClass).closest('.component-CarouselBannerStripe'), this.swiperBannerStripeOptions));
                }else{
                    var swiperBannerStripe = new Swiper('.' + swiperClass, this.swiperBannerStripeOptionsNoPagination);
                }
            })
        }
    }
}     