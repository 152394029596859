import { _$, _Uikit, Window } from '../../main';
import { UserService } from '../../services/user-service/user-service';
import { LOADER_POSITION, Utils } from '../../services/utils/utils';

export class ModalUserMenu {
    _userMenu: JSON;
    _name: string = '';
    _previousBalance: string = '-';
    _currentBalance: string = '-';
    _previousYear: string = '-';
    _currentYear: string = '-';

    constructor(public userService: UserService, private utils: Utils) {}

    nkOnInit() {}

    openMenuUser() {

        if (Window.user) {
            _Uikit.offcanvas('#ModalUserMenu').show();

            this.utils.showLoader(_$('.menu-user'), true, LOADER_POSITION.USER_MENU); 
            let success = (res) => {
                this._userMenu = res.data;
                if (this._userMenu) {
                    this._name = this._userMenu['name'];
                    if(this._userMenu['previousBalance'] || this._userMenu['previousBalance'] === 0) {
                        this._previousBalance = this._userMenu['previousBalance'];
                    } else {
                        _$('#ModalUserMenu').find('.balance-previous').hide();
                    }
                    if(this._userMenu['currentBalance'] || this._userMenu['currentBalance'] === 0) {
                        this._currentBalance = this._userMenu['currentBalance'];
                    } else {
                        _$('#ModalUserMenu').find('.balance-current').hide();
                    }
                    this._previousYear = this._userMenu['previousYear'] ? this._userMenu['previousYear'] : '';
                    this._currentYear = this._userMenu['currentYear'] ? this._userMenu['currentYear'] : '';
                    let menuEntries = this._userMenu['menuEntries'];

                    _$('#ModalUserMenu').find(".menu-container").html(`<ul class="uk-nav menu-user" onclick="Header.sendAnalytics('clickVoceMenu', event)"></ul>`);

                    for (let i = 0; i<menuEntries.length; i++){
                        let elem = menuEntries[i];

                        if (elem.nuovo) {
                                _$('#ModalUserMenu').find(".menu-user").append(
                                '<li><a href=' + elem.href + ' class="uk-nav-elem"><i class=' + elem.icon + '></i><span class="label uk-width-expand">' + elem.label +
                                '</span><span class="elem-new">Nuovo</span><i class="icon-chevron-right"></i></a></li>');
                        } else {
                            _$('#ModalUserMenu').find(".menu-user").append(
                                '<li><a href=' + elem.href + ' class="uk-nav-elem"><i class=' + elem.icon + '></i><span class="label uk-width-expand">' + elem.label +
                                '</span><i class="icon-chevron-right"></i></a></li>');
                        }
                    }
                    
                    _$('#ModalUserMenu').find(".menu-container").append(
                        // <i class=' + this._userMenu['exitLink'].icon + '></i>
                        '<a href="#modalUserLogout" class="uk-nav-elem logout" uk-toggle><i class="icon-download"></i>' + this._userMenu['exitLink'].label + '</a>'
                        //'<a href="javascript:;" onclick="ModalUserMenu.logoutUser()" class="uk-nav-elem logout"><i class="icon-download"></i>' + this._userMenu['exitLink'].label + '</a>'
                    );
                }
                this.utils.showLoader(_$('.menu-user'), false, LOADER_POSITION.USER_MENU);
            };
            let error = (err) => {
                console.log('error user menu: ', err);
                this.utils.showNotification('Menu user:\n' + err, 'primary');
                this.utils.showLoader(_$('.menu-user'), false, LOADER_POSITION.USER_MENU);
            };
            this.userService.getUserMenu(success, error);
        }
    } 

    logoutUser() {
        let success = (res) => {
            _Uikit.offcanvas('#ModalUserMenu').hide();
            window.location.reload();
        }
        let error = (err) => {
            _Uikit.offcanvas('#ModalUserMenu').hide();
            window.location.reload();
        }
        this.userService.doLogout(success, error);
    }
}
