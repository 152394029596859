import { Utils } from "../../services/utils/utils";
import { _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';
import { SwiperConfigInitializer } from "../../services/utils/swiper-config-initializer";

export class CarouselBannerSponsor {

    constructor(
        private _utils: Utils,
        private _swiperConfigInitializer: SwiperConfigInitializer
    ) { 
        this._utils.randomizeCards('.component-CarouselBannerSponsor');

        _$(() => {
            this.initializeCarousel();
        })
        
    }

    nkOnInit() { 
    }

    swiperBannerSponsorOptions: SwiperOptions = {
        freeMode: false,
        spaceBetween: 10,
        slidesPerGroup: 1,
        slidesPerView: 1,
        /* Pagination con le frecce */
        pagination: {
            el: '.pagination-banner-sponsor',
            clickable: true,
        },
        breakpointsInverse: true,
        on : {
            transitionStart: () => { 
                this._utils.draggedByScrollbar = true;
            },
            transitionEnd: () => {
                this._utils.draggedByScrollbar = false;
            }
        }
    };

    swiperBannerSponsorOptionsNoPagination: SwiperOptions = {
        freeMode: false,
        spaceBetween: 10,
        slidesPerGroup: 1,
        slidesPerView: 1,
        allowSlideNext: false,
        allowSlidePrev: false,
        breakpointsInverse: true
    };

    initializeCarousel(){
        if(_$('.swiper-banner-sponsor').length){
            _$('.swiper-banner-sponsor').each((index, element) => {
                let swiperClass = 'swiper-banner-sponsor-' + index;
                _$('.swiper-banner-sponsor').eq(index).addClass(swiperClass);
                if(_$(element).find('.swiper-slide').length > 1){
                    var swiperBannerSponsor = new Swiper('.' + swiperClass , this._swiperConfigInitializer.initConfig(['autoplay'], _$('.' + swiperClass).closest('.component-CarouselBannerSponsor'), this.swiperBannerSponsorOptions));
                }else{
                    _$('.'+swiperClass).addClass('swiper-carousel-borders-listingpage-without-pagination');
                    var swiperBannerSponsor = new Swiper('.' + swiperClass , this.swiperBannerSponsorOptionsNoPagination);
                } 
            })
        }
    }

    
}