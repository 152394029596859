import { Pagination } from './components/pagination/pagination';

declare global {
    interface Window {
        gpGetExtraProtectionToken: (action: string) => any;
        gpGetProtectionToken: (action: string) => any;
    }
}


export const Window = window as any;

//JS SWIPER
import 'swiper';

//External Libreries
import * as UIkit from 'uikit';
import * as Icons from 'uikit/dist/js/uikit-icons.js';
import * as Axios from 'axios';
import * as Store from 'store';
import * as $ from 'jquery';
import * as moment from 'moment';
import * as factory from 'malihu-custom-scrollbar-plugin';
import * as zoom from 'js-image-zoom';
import * as dropzone from 'dropzone';
import * as draggable from '@shopify/draggable';
import * as draggabilly from 'draggabilly';

factory($);

import 'moment/locale/it';


//Services
import { LsfService } from './services/lsf-service/lsf-service';
import { CartService } from './services/cart-service/cart-service';
import { LocalStorage } from './services/local-storage/local-storage';
import { StoreService } from './services/store-service/store-service';
import { UserService } from './services/user-service/user-service';
import { SearchService } from './services/search-service/search-service';
import { ValidationService } from './services/validation-service/validation-service';
import { Utils } from './services/utils/utils';
import { GoogleUtils } from './services/utils/google-utils';
import { BookUtils } from './services/utils/book-utils';
import { ValidationUtils } from './services/utils/validation-utils';
import { LockerUtils } from './services/utils/locker-utils';
import { Analytics } from './services/analytics/analytics';
import { AnalyticsUtility } from './services/analytics/analytics-utility';
import { GoogleAddressUtils } from './services/utils/google-address-utils';
import { PageUpdateService } from './services/page-update-service/page-update-service';
import { SwiperConfigInitializer } from './services/utils/swiper-config-initializer';
import { GoogleInputManager } from './services/utils/google-service/google-input-manager';
import { OnboardingManager } from './services/utils/onboarding-manager';
import { GoogleMapsManager } from './services/utils/google-service/google-maps-manager';
import { DynamicTsManager } from './services/utils/dynamic-ts-manager/dynamic-ts-manager';

//Pages
import { Errors } from './pages/errors/errors';
import { Landingpage } from './pages/landingpage/landingpage';
import { Brands } from './pages/brands/brands';
import { Recipes } from './pages/recipes/recipes';
import { General } from './pages/general/general';
import { ThankYou } from './pages/thank-you/thank-you';
import { DetailCart } from './pages/detail-cart/detail-cart';
import { DetailProduct } from './pages/detail-product/detail-product';
import { Listinglevel2 } from './pages/listinglevel2/listinglevel2';
import { Listinglevel1 } from './pages/listinglevel1/listinglevel1';
import { Search } from './pages/search/search';
import { Homepage } from './pages/homepage/homepage';
import { Entrypage } from './pages/entrypage/entrypage';
import { Privacy } from './pages/privacy/privacy';
import { FAQ } from './pages/faq/faq';
import { Pageoffers } from './pages/pageoffers/pageoffers';
import { DetailOrder } from './pages/detail-order/detail-order';
import { CheckoutNew } from './pages/checkout-new/checkout-new';
import { Landingidm } from './pages/landingidm/landingidm';

//Components
import { AreaUtente } from './components/area-utente/area-utente';
import { DotButton } from './components/dot-button/dot-button';
import { DotCard } from './components/dot-card/dot-card';
import { LearnMore } from './components/learn-more/learn-more';
import { BannerPromo } from './components/banner-promo/banner-promo';
import { CardCategory } from './components/card-category/card-category';
import { CardSuggest } from './components/card-suggest/card-suggest';
import { OfferCard } from './components/offer-card/offer-card';
import { BannerStripe } from './components/banner-stripe/banner-stripe';
import { BannerSponsor } from './components/banner-sponsor/banner-sponsor';
import { ModalSlotPrenotatoScadenza } from './components/modal-slot-prenotato-scadenza/modal-slot-prenotato-scadenza';
import { ModalReservedFunctionality } from './components/modal-reserved-functionality/modal-reserved-functionality';
import { ModalUserMenu } from './components/modal-user-menu/modal-user-menu';
import { FlatCategory } from './components/flat-category/flat-category';
import { PromoIdm } from './components/promo-idm/promo-idm';
import { CardSimple } from './components/card-simple/card-simple';
import { TilePartner } from './components/tile-partner/tile-partner';
import { TileBrand } from './components/tile-brand/tile-brand';
import { ModalCheckoutAddressDelivery } from './components/modal-checkout-address-delivery/modal-checkout-address-delivery';
import { ProductCardDetailCart } from './components/product-card-detail-cart/product-card-detail-cart';
import { ReplacementMode } from './components/replacement-mode/replacement-mode';
import { ModalFatturazioneElettronica } from './components/modal-fatturazione-elettronica/modal-fatturazione-elettronica';
import { ModalCart } from './components/modal-cart/modal-cart';
import { ModalProductImages } from './components/modal-product-images/modal-product-images';
import { ProductCardLastView } from './components/product-card-last-view/product-card-last-view';
import { ProductCard } from './components/product-card/product-card';
import { BadgeCard } from './components/badge-card/badge-card';
import { PromoMdd } from './components/promo-mdd/promo-mdd';
import { MarchiConad } from './components/1-4-marchi-conad/1-4-marchi-conad';
import { ProdottiEvidenza } from './components/1-3-prodotti-evidenza/1-3-prodotti-evidenza';
import { StorytellingA } from './components/1-6-storytelling-a/1-6-storytelling-a';
import { StorytellingB } from './components/1-6-storytelling-b/1-6-storytelling-b';
import { StorytellingC } from './components/1-6-storytelling-c/1-6-storytelling-c';
import { StorytellingD } from './components/1-6-storytelling-d/1-6-storytelling-d';
import { AreaOfferte } from './components/area-offerte/area-offerte';
import { OfferteScadenzaA } from './components/offerte-scadenza-a/offerte-scadenza-a';
import { OfferteScadenzaB } from './components/offerte-scadenza-b/offerte-scadenza-b';
import { IspirazionaleHotspot } from './components/1-10-ispirazionale-hotspot/1-10-ispirazionale-hotspot';
import { Ispirazionale } from './components/1-10-ispirazionale/1-10-ispirazionale';
import {SpinteMarketing } from './components/spinte-marketing/spinte-marketing';
import { Navigazione } from './components/1-13-navigazione/1-13-navigazione';
import { ShopIdm } from './components/1-14-shop-idm/1-14-shop-idm';
import { PromoComponent } from './components/1-1-promo-component/1-1-promo-component';
import { CarouselBannerSponsor } from './components/1-7-carousel-banner-sponsor/1-7-carousel-banner-sponsor';
import { CarouselBannerStripe } from './components/carousel-banner-stripe/carousel-banner-stripe';
import { ScopriNovita } from './components/scopri-novita/scopri-novita';
import { BannerListing } from './components/2-2-banner-listing-hotspot/2-2-banner-listing-hotspot';
import { NavigaPromozioni } from './components/1-13-naviga-promozioni/1-13-naviga-promozioni';
import { OfferteInCorso } from './components/offerte-in-corso/offerte-in-corso';
import { CarouselBenessere } from './components/1-15-carousel-benessere/1-15-carousel-benessere';
import { SearchRecommended } from './components/sidebar/search-recommended';
import { SearchFilter } from './components/search-filter/search-filter';
import { ModalSearchFilter } from './components/modal-search-filter/modal-search-filter';
import { Breadcrumb } from './components/breadcrumb/breadcrumb';
import { Header } from './components/header/header';
import { PreHeader } from './components/pre-header/pre-header';
import { Feedback } from './components/feedback/feedback';
import { ModalLogin } from './components/modal-login/modal-login';
import { CardHowItWork } from './components/card-how-it-work/card-how-it-work';
import { StorePinLocator } from './components/store-pin-locator/store-pin-locator';
import { CardStoreMap } from './components/card-store-map/card-store-map';
import { ModalManageCookies } from './components/modal-manage-cookies/modal-manage-cookies';
import { CardReview } from './components/card-review/card-review';
import { ModalGeneric } from './components/modal-generic/modal-generic';
import { CardDeliveryDay } from './components/card-delivery-day/card-delivery-day';
import { CardStore } from './components/card-store/card-store';
import { CardQuestion } from './components/card-question/card-question';
import { CardImageTop } from './components/card-image-top/card-image-top';
import { HeaderSemplificato } from './components/header-semplificato/header-semplificato';
import { FooterSemplificato } from './components/footer-semplificato/footer-semplificato';
import { ModalDetailProduct } from './components/modal-detail-product/modal-detail-product';
import { FaqSectionContattaci } from './components/faq-section-contattaci/faq-section-contattaci';
import { FaqSectionMain } from './components/faq-section-main/faq-section-main';
import { FaqSectionCategories } from './components/faq-section-categories/faq-section-categories';
import { FaqSectionDomande } from './components/faq-section-domande/faq-section-domande';
import { FaqSectionFormMain } from './components/faq-section-form-main/faq-section-form-main';
import { PromoBadgeCard } from './components/promo-badge-card/promo-badge-card';
import { PromoBadgePotentialDiscount } from './components/promo-badge-potential-discount/promo-badge-potential-discount';
import { ModalCheckoutErrorPopup } from './components/modal-checkout-error-popup/modal-checkout-error-popup';
import { EmptyListing } from './components/empty-listing/empty-listing';
import { BadgeStatiOrdine } from './components/badge-stati-ordine/badge-stati-ordine';
import { BannerShopInShop } from './components/banner-shop-in-shop/banner-shop-in-shop';
import { ModalAgevolazione } from './components/modal-agevolazione/modal-agevolazione';
import { ModalFaqForm } from './components/modal-faq-form/modal-faq-form';
import { AccordionTimeslotInfo } from './components/accordion-timeslot-info/accordion-timeslot-info';
import { ModalVerifyEmail } from './components/modal-verify-email/modal-verify-email';
import { BannerSponsorSearch } from './components/banner-sponsor-search/banner-sponsor-search';
import { CardsHowItWork } from './components/0-2-cards-how-it-work/0-2-cards-how-it-work';
import { EntrypageProvinces } from './components/0-6-provinces/0-6-provinces';
import { CardsImageTop } from './components/0-3-cards-image-top/0-3-cards-image-top';
import { CardsQuestion } from './components/0-4-cards-question/0-4-cards-question';
import { CardsReview } from './components/0-5-cards-review/0-5-cards-review';
import { CardStorytellingC } from './components/card-storytelling-c/card-storytelling-c';
import { ModalOnboarding } from "./components/modal-onboarding/modal-onboarding";
import { ModalTimeslot } from "./components/modal-timeslot/modal-timeslot";
import { CardOrdinaRitira } from "./components/card-ordina-ritira/card-ordina-ritira";
import { CardSpesaOnline } from "./components/card-spesa-online/card-spesa-online";
import { CardNegozio} from "./components/card-negozio/card-negozio";
import { BannerAddress } from './components/0-1-banner-address/0-1-banner-address';
import { NegoziListMap } from "./components/negozi-list-map/negozi-list-map";
import { CardTimeslot } from "./components/card-timeslot/card-timeslot";
import { CardProdottoOmaggio } from './components/card-prodotto-omaggio/card-prodotto-omaggio';
import { BannerLandingIdm } from './components/banner-landing-idm/banner-landing-idm';
import { BannerPromoLanding } from './components/1-16-banner-promo-landing/1-16-banner-promo-landing';
import { LandingTitleAndDescription } from './components/5-2-title-and-description/5-2-title-and-description';
import { ModalCheckoutDeliveryareaError } from './components/modal-checkout-deliveryarea-error/modal-checkout-deliveryarea-error';
import { ModalCheckoutPdvOnly } from './components/modal-checkout-pdv-only/modal-checkout-pdv-only';
import { BannerListingImage } from './components/2-1-banner-listing-image/2-1-banner-listing-image';

export const _Uikit = UIkit;
export const _Axios = Axios;

export const _Store = Store;
export const _$ = $;
export const _draggabilly = draggabilly;
export const _moment = moment;
export const _zoom = zoom;
export const _dropzone = dropzone;
export const _draggable = draggable;

Window.nk$ = _$;
Window.Axios = Axios;
Window.Uikit = _Uikit;
Window.Store = _Store;
Window.moment = _moment.locale('it');
Window.zoom = _zoom;
Window.dropzone = _dropzone;
Window.draggable = _draggable;
Window.draggabilly = _draggabilly;

// loads the Icon plugin
UIkit.use(Icons);

//Main Services

//Declarations
const _LocalStorage = new LocalStorage();
const _UserService = new UserService();
const _LsfService = new LsfService();
const _StoreService = new StoreService();
const _ValidationService = new ValidationService();
const _GoogleAddressUtils = new GoogleAddressUtils();
const _CartService = new CartService();
const _PageUpdateService = new PageUpdateService();
const _GoogleMapsManager = new GoogleMapsManager();

const _Analytics = new Analytics(_LocalStorage);
const _AnalyticsUtility = new AnalyticsUtility();
const _Utils = new Utils(_Analytics, _LocalStorage, _UserService, _LsfService, _StoreService, _GoogleAddressUtils);
const _DynamicTsManager = new DynamicTsManager(_Utils);
const _LockerUtils = new LockerUtils(_Utils, _CartService);
const _BookUtils = new BookUtils(_StoreService, _Utils, _LocalStorage, _LockerUtils, _CartService, _DynamicTsManager);
const _ValidationUtils = new ValidationUtils(_LocalStorage, _ValidationService);
const _SwiperConfigInitializer = new SwiperConfigInitializer();
const _OnboardingManager = new OnboardingManager(_Utils, _ValidationUtils, _BookUtils, _LocalStorage, _StoreService, _Analytics, _GoogleAddressUtils);
const _GoogleUtils = new GoogleUtils(_StoreService, _LocalStorage, _BookUtils, _Utils, _Analytics, _GoogleAddressUtils, _OnboardingManager);
const _GoogleInputManager = new GoogleInputManager(_GoogleAddressUtils, _Utils, _LocalStorage, _GoogleUtils, _OnboardingManager);

const _CarouselBenessere = new CarouselBenessere(_Utils, _SwiperConfigInitializer);
const _OfferteInCorso = new OfferteInCorso();
const _NavigaPromozioni = new NavigaPromozioni();
const _BannerListing = new BannerListing(_Analytics);
const _ScopriNovita = new ScopriNovita();
const _CarouselBannerStripe = new CarouselBannerStripe(_Utils, _SwiperConfigInitializer);
const _CarouselBannerSponsor = new CarouselBannerSponsor(_Utils, _SwiperConfigInitializer);
const _PromoComponent = new PromoComponent(_Utils, _SwiperConfigInitializer);
const _ShopIdm = new ShopIdm();
const _Navigazione = new Navigazione(_Utils);
const _SpinteMarketing = new SpinteMarketing();
const _Ispirazionale = new Ispirazionale();
const _IspirazionaleHotspot = new IspirazionaleHotspot();
const _OfferteScadenzaB = new OfferteScadenzaB();
const _OfferteScadenzaA = new OfferteScadenzaA(_Utils);
const _AreaOfferte = new AreaOfferte(_Utils);
const _StorytellingD = new StorytellingD();
const _StorytellingC = new StorytellingC();
const _StorytellingB = new StorytellingB();
const _StorytellingA = new StorytellingA(_Utils);
const _AreaUtente = new AreaUtente(_Utils, _PageUpdateService);
const _ProdottiEvidenza = new ProdottiEvidenza(_Utils);
const _PromoMdd = new PromoMdd();
const _Brands = new Brands(_Utils, _GoogleUtils, _Analytics, _AnalyticsUtility);
const _Recipes = new Recipes(_Utils, _GoogleUtils);
const _FAQ = new FAQ(_UserService, _Utils, _GoogleUtils, _Analytics, _LocalStorage);
const _Privacy = new Privacy(_Utils, _GoogleUtils);
const _LearnMore = new LearnMore();
const _BannerPromo = new BannerPromo();
const _CardCategory = new CardCategory();
const _CardSuggest = new CardSuggest(_Analytics, _Utils);
const _OfferCard = new OfferCard();
const _BannerStripe = new BannerStripe(_Analytics);
const _BannerSponsor = new BannerSponsor();
const _ModalSlotPrenotatoScadenza = new ModalSlotPrenotatoScadenza();
const _ModalReservedFunctionality = new ModalReservedFunctionality();
const _SearchService = new SearchService();
const _ModalUserMenu = new ModalUserMenu(_UserService, _Utils);
const _FlatCategory = new FlatCategory();
const _PromoIdm = new PromoIdm(_Analytics);
const _CardSimple = new CardSimple();
const _TilePartner = new TilePartner();
const _TileBrand = new TileBrand();
const _General = new General(_Utils, _GoogleUtils);
const _ModalCheckoutAddressDelivery = new ModalCheckoutAddressDelivery(_Utils);
const _ReplacementMode = new ReplacementMode();
const _ModalCart = new ModalCart(_Analytics);
const _ThankYou = new ThankYou(_Analytics, _GoogleUtils, _LocalStorage, _UserService, _Utils);
const _ModalProductImages = new ModalProductImages();
const _DetailProduct = new DetailProduct(_Utils, _LsfService, _LocalStorage, _CartService, _GoogleUtils, _Analytics, _LockerUtils);
const _Listinglevel2 = new Listinglevel2(_Analytics, _Utils, _GoogleUtils);
const _Listinglevel1 = new Listinglevel1(_Analytics, _Utils, _GoogleUtils, _AnalyticsUtility);
const _ModalDetailProduct = new ModalDetailProduct(_Utils);
const _DetailCart = new DetailCart(_Utils, _CartService, _Analytics, _GoogleUtils, _ModalDetailProduct, _LocalStorage, _BookUtils, _LockerUtils, _SwiperConfigInitializer);
const _ProductCardLastView = new ProductCardLastView(_Utils, _CartService, _DetailCart, _Analytics, _LocalStorage, _LockerUtils);
const _ProductCard = new ProductCard(_Utils, _CartService, _LocalStorage, _Analytics, _DetailCart, _LockerUtils);
const _ProductCardDetailCart = new ProductCardDetailCart(_CartService, _Utils, _DetailCart, _LockerUtils);
const _SearchRecommended = new SearchRecommended();
const _SearchFilter = new SearchFilter();
const _ModalSearchFilter = new ModalSearchFilter(_Analytics, _PageUpdateService, _Utils, _CarouselBenessere, _CarouselBannerSponsor);
const _Breadcrumb = new Breadcrumb();
const _Search = new Search(_Analytics, _Utils, _GoogleUtils);
const _PreHeader = new PreHeader();
const _Feedback = new Feedback();
const _Homepage = new Homepage(_Utils, _GoogleUtils, _Analytics, _AnalyticsUtility);
const _CardHowItWork = new CardHowItWork();
const _StorePinLocator = new StorePinLocator();
const _CardStoreMap = new CardStoreMap();
const _ModalManageCookies = new ModalManageCookies();
const _ModalFatturazioneElettronica = new ModalFatturazioneElettronica(_UserService, _LocalStorage, _Utils, _Analytics, _ValidationUtils);
const _Header = new Header(_Analytics, _Utils, _GoogleUtils, _SearchService, _LocalStorage, _CartService, _DetailCart);
const _CheckoutNew = new CheckoutNew(_Utils, _GoogleUtils, _UserService, _LocalStorage, _BookUtils, _ModalFatturazioneElettronica, _CartService, _Analytics, _ValidationUtils, _GoogleAddressUtils, _OnboardingManager);
const _ModalLogin = new ModalLogin(_Utils, _UserService, _Analytics);
const _CardReview = new CardReview();
const _ModalGeneric = new ModalGeneric();
const _CardDeliveryDay = new CardDeliveryDay();
const _CardStore = new CardStore();
const _CardQuestion = new CardQuestion();
const _CardImageTop = new CardImageTop();
const _HeaderSemplificato = new HeaderSemplificato();
const _FooterSemplificato = new FooterSemplificato(_Analytics);
const _Entrypage = new Entrypage(_Analytics, _AnalyticsUtility);
const _Landingpage = new Landingpage(_Utils, _GoogleUtils, _Analytics, _LocalStorage, _GoogleInputManager);
const _DotButton = new DotButton();
const _DotCard = new DotCard(_Analytics);
const _BadgeCard = new BadgeCard();
const _MarchiConad = new MarchiConad(_Utils);
const _Errors = new Errors(_Analytics, _Utils);
const _Pagination = new Pagination();
const _FaqSectionContattaci = new FaqSectionContattaci();
const _FaqSectionMain = new FaqSectionMain();
const _FaqSectionCategories = new FaqSectionCategories();
const _FaqSectionDomande = new FaqSectionDomande();
const _FaqSectionFormMain = new FaqSectionFormMain();
const _Pageoffers = new Pageoffers(_Utils, _GoogleUtils ,_Analytics, _AnalyticsUtility);
const _PromoBadgeCard = new PromoBadgeCard();
const _PromoBadgePotentialDiscount = new PromoBadgePotentialDiscount();
const _ModalCheckoutErrorPopup = new ModalCheckoutErrorPopup();
const _EmptyListing = new EmptyListing();
const _DetailOrder = new DetailOrder(_GoogleUtils, _LocalStorage, _BookUtils, _Utils, _CartService, _UserService, _Analytics);
const _BadgeStatiOrdine = new BadgeStatiOrdine();
const _BannerShopInShop = new BannerShopInShop(_Analytics);
const _ModalAgevolazione = new ModalAgevolazione(_Utils, _UserService, _ValidationUtils, _Analytics);
const _ModalFaqForm = new ModalFaqForm(_UserService, _Utils, _ValidationUtils);
const _AccordionTimeslotInfo = new AccordionTimeslotInfo(_Analytics);
const _ModalVerifyEmail = new ModalVerifyEmail(_Utils);
const _BannerSponsorSearch = new BannerSponsorSearch();
const _CardsHowItWork = new CardsHowItWork();
const _EntrypageProvinces = new EntrypageProvinces();
const _CardsImageTop = new CardsImageTop();
const _CardsQuestion = new CardsQuestion();
const _CardsReview = new CardsReview();
const _CardStorytellingC = new CardStorytellingC();
const _ModalOnboarding = new ModalOnboarding(_Utils, _GoogleUtils, _BookUtils, _ValidationUtils, _Analytics, _CheckoutNew, _LocalStorage);
const _ModalTimeslot = new ModalTimeslot(_Utils, _GoogleUtils, _BookUtils, _OnboardingManager, _LocalStorage);
const _CardOrdinaRitira = new CardOrdinaRitira();
const _CardSpesaOnline = new CardSpesaOnline();
const _CardNegozio = new CardNegozio();
const _BannerAddress = new BannerAddress();
const _NegoziListMap = new NegoziListMap();
const _CardTimeslot = new CardTimeslot();
const _CardProdottoOmaggio = new CardProdottoOmaggio(_CartService);
const _Landingidm = new Landingidm(_Utils, _GoogleUtils ,_Analytics, _AnalyticsUtility);
const _BannerLandingIdm= new BannerLandingIdm();
const _BannerPromoLanding = new BannerPromoLanding();
const _LandingTitleAndDescription = new LandingTitleAndDescription();
const _ModalCheckoutDeliveryareaError = new ModalCheckoutDeliveryareaError();
const _ModalCheckoutPdvOnly = new ModalCheckoutPdvOnly();
const _BannerListingImage = new BannerListingImage();

//Window Import
Window.CarouselBenessere = _CarouselBenessere;
Window.OfferteInCorso = _OfferteInCorso;
Window.NavigaPromozioni = _NavigaPromozioni;
Window.BannerListing = _BannerListing;
Window.ScopriNovita = _ScopriNovita;
Window.CarouselBannerStripe = _CarouselBannerStripe;
Window.CarouselBannerSponsor = _CarouselBannerSponsor;
Window.PromoComponent = _PromoComponent;
Window.ShopIdm = _ShopIdm;
Window.Navigazione = _Navigazione;
Window.SpinteMarketing = _SpinteMarketing;
Window.Ispirazionale = _Ispirazionale;
Window.IspirazionaleHotspot = _IspirazionaleHotspot;
Window.OfferteScadenzaB = _OfferteScadenzaB;
Window.OfferteScadenzaA = _OfferteScadenzaA;
Window.AreaOfferte = _AreaOfferte;
Window.StorytellingD = _StorytellingD;
Window.StorytellingC = _StorytellingC;
Window.StorytellingB = _StorytellingB;
Window.StorytellingA = _StorytellingA;
Window.AreaUtente = _AreaUtente;
Window.MarchiConad = _MarchiConad;
Window.ProdottiEvidenza = _ProdottiEvidenza;
Window.PromoMdd = _PromoMdd;
Window.BadgeCard = _BadgeCard;
Window.FAQ = _FAQ;
Window.Privacy = _Privacy;
Window.Errors = _Errors;
Window.DotButton = _DotButton;
Window.DotCard = _DotCard;
Window.Brands = _Brands;
Window.Recipes = _Recipes;
Window.LearnMore = _LearnMore;
Window.BannerPromo = _BannerPromo;
Window.CardCategory = _CardCategory;
Window.CardSuggest = _CardSuggest;
Window.OfferCard = _OfferCard;
Window.BannerStripe = _BannerStripe;
Window.BannerSponsor = _BannerSponsor;
Window.ModalSlotPrenotatoScadenza = _ModalSlotPrenotatoScadenza;
Window.ModalReservedFunctionality = _ModalReservedFunctionality;
Window.LsfService = _LsfService;
Window.ModalUserMenu = _ModalUserMenu;
Window.FlatCategory = _FlatCategory;
Window.PromoIdm = _PromoIdm;
Window.CardSimple = _CardSimple;
Window.Landingpage = _Landingpage;
Window.TilePartner = _TilePartner;
Window.TileBrand = _TileBrand;
Window.General = _General;
Window.Analytics = _Analytics;
Window.AnalyticsBannersUtility = _AnalyticsUtility;
Window.ModalCheckoutAddressDelivery = _ModalCheckoutAddressDelivery;
Window.ProductCardDetailCart = _ProductCardDetailCart;
Window.ReplacementMode = _ReplacementMode;
Window.ModalFatturazioneElettronica = _ModalFatturazioneElettronica;
Window.ModalCart = _ModalCart;
Window.ThankYou = _ThankYou;
Window.ModalProductImages = _ModalProductImages;
Window.DetailCart = _DetailCart;
Window.CartService = _CartService;
Window.ProductCardLastView = _ProductCardLastView;
Window.DetailProduct = _DetailProduct;
Window.Listinglevel2 = _Listinglevel2;
Window.Listinglevel1 = _Listinglevel1;
Window.ProductCard = _ProductCard;
Window.SearchRecommended = _SearchRecommended;
Window.SearchFilter = _SearchFilter;
Window.ModalSearchFilter = _ModalSearchFilter;
Window.Breadcrumb = _Breadcrumb;
Window.Search = _Search;
Window.Header = _Header;
Window.PreHeader = _PreHeader;
Window.Feedback = _Feedback;
Window.LocalStorage = _LocalStorage;
Window.Homepage = _Homepage;
Window.StoreService = _StoreService;
Window.UserService = _UserService;
Window.ModalLogin = _ModalLogin;
Window.CardHowItWork = _CardHowItWork;
Window.StorePinLocator = _StorePinLocator;
Window.CardStoreMap = _CardStoreMap;
Window.ModalManageCookies = _ModalManageCookies;
Window.Utils = _Utils;
Window.GoogleUtils = _GoogleUtils;
Window.BookUtils = _BookUtils;
Window.LockerUtils = _LockerUtils;
Window.CardReview = _CardReview;
Window.ModalGeneric = _ModalGeneric;
Window.CardDeliveryDay = _CardDeliveryDay;
Window.CardStore = _CardStore;
Window.CardQuestion = _CardQuestion;
Window.CardImageTop = _CardImageTop;
Window.HeaderSemplificato = _HeaderSemplificato;
Window.FooterSemplificato = _FooterSemplificato;
Window.Entrypage = _Entrypage;
Window.Analytics = _Analytics;
Window.ModalDetailProduct = _ModalDetailProduct;
Window.Pagination = _Pagination;
Window.FaqSectionContattaci = _FaqSectionContattaci;
Window.FaqSectionMain = _FaqSectionMain;
Window.FaqSectionCategories = _FaqSectionCategories;
Window.FaqSectionDomande = _FaqSectionDomande;
Window.FaqSectionFormMain = _FaqSectionFormMain;
Window.Pageoffers = _Pageoffers;
Window.PromoBadgeCard = _PromoBadgeCard;
Window.PromoBadgePotentialDiscount = _PromoBadgePotentialDiscount;
Window.ModalCheckoutErrorPopup = _ModalCheckoutErrorPopup;
Window.EmptyListing = _EmptyListing;
Window.DetailOrder = _DetailOrder;
Window.BadgeStatiOrdine = _BadgeStatiOrdine;
Window.BannerShopInShop = _BannerShopInShop;
Window.ModalAgevolazione = _ModalAgevolazione;
Window.ModalFaqForm = _ModalFaqForm;
Window.ValidationService = _ValidationService;
Window.ValidationUtils = _ValidationUtils;
Window.AccordionTimeslotInfo = _AccordionTimeslotInfo;
Window.ModalVerifyEmail = _ModalVerifyEmail;
Window.BannerSponsorSearch = _BannerSponsorSearch;
Window.GoogleAddressUtils = _GoogleAddressUtils;
Window.CardsHowItWork = _CardsHowItWork;
Window.EntrypageProvinces = _EntrypageProvinces;
Window.CardsImageTop = _CardsImageTop;
Window.CardsQuestion = _CardsQuestion;
Window.CardsReview = _CardsReview;
Window.CardStorytellingC = _CardStorytellingC;
Window.ModalOnboarding=_ModalOnboarding;
Window.ModalTimeslot=_ModalTimeslot;
Window.CardSpesaOnline = _CardSpesaOnline;
Window.CardOrdinaRitira = _CardOrdinaRitira;
Window.CardNegozio = _CardNegozio;
Window.BannerAddress = _BannerAddress;
Window.GoogleInputManager = _GoogleInputManager;
Window.NegoziListMap = _NegoziListMap;
Window.OnboardingManager = _OnboardingManager;
Window.CardTimeslot = _CardTimeslot;
Window.CardProdottoOmaggio = _CardProdottoOmaggio;
Window.SwiperConfigInitializer = _SwiperConfigInitializer;
Window.GoogleMapsManager = _GoogleMapsManager;
Window.DynamicTsManager = _DynamicTsManager;
//Window.ModalPaymentCard = _ModalPaymentCard;
//Window.ModalPaymentCardConad = _ModalPaymentCardConad;
Window.CheckoutNew = _CheckoutNew;
Window.Landingidm = _Landingidm;
Window.BannerLandingIdm = _BannerLandingIdm;
Window.BannerPromoLanding = _BannerPromoLanding;
Window.LandingTitleAndDescription = _LandingTitleAndDescription;
Window.ModalCheckoutDeliveryareaError = _ModalCheckoutDeliveryareaError;
Window.ModalCheckoutPdvOnly = _ModalCheckoutPdvOnly;
Window.BannerListingImage = _BannerListingImage;

if (_$('body').hasClass('AEM-Editor-Mode')) {
    console.log('aem editor mode');
}

// When the page has loaded
/*_$(window).on('load', () => {
    _$('body').addClass('loaded');
});*/

if (_$('.scrollUp').length) {
    let lineFoldCrossed = false; 
    let lineFoldPos = window.scrollY + window.innerHeight;
    let headerHeight;
    
    if (_$(".component-header").length) {
        headerHeight = _$(".component-header").innerHeight();
        lineFoldPos = lineFoldPos - headerHeight;
    }

    if(_$('#d1').length) {
        _$(window).scroll(() => {
            let firstSection = _$('#d1').position().top - 75;
            if (_$(window).width() >= 768) {
                firstSection = _$('#d1').position().top - 150;
            }
            if (_$(window).scrollTop() >= firstSection) {
                _$('.scrollUp').fadeIn();
            } else {
                _$('.scrollUp').fadeOut();
            }
        });
    } else {
        _$(window).on('scroll', () => {
            if (_$(window).scrollTop() > lineFoldPos) {
                _$('.scrollUp').fadeIn();
                lineFoldCrossed = true;
            } else if (lineFoldCrossed){
                _$('.scrollUp').fadeOut();
                if (_$(window).scrollTop() <= lineFoldPos) {
                    lineFoldCrossed = false;
                }
            }
        });
    }
}

// var elem = _$("orderRitira");
// if(elem.length) {
//     elem.onkeyup = function(e){
//         if(e.key == '13'){
//             alert("Here");
//         }
//     }
// }

/* verifica la presenza di un carrello, dopo il caricamento del bundle */
_BookUtils.checkCartEcAccess();