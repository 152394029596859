import { _$, _Uikit, Window } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
import { GoogleUtils } from '../../services/utils/google-utils';
import { LocalStorage } from '../../services/local-storage/local-storage';
import { Utils } from "../../services/utils/utils";
import { UserService } from "../../services/user-service/user-service";
export class ThankYou {
   
   constructor(
      private _analytics: Analytics,
      private _google: GoogleUtils,
      private localStorage: LocalStorage,
      private _userService: UserService,
      private _utils: Utils
   ) { }
   
   private dataOrder;
   
   nkOnInit() {
      this.sendAnalytics('pageview');

      if(_$('#modalVerifyEmail').length){
         _$('#modalVerifyEmail').find('button[nkButton="sendEmailOrder"]').attr('onclick', 'ThankYou.verifyEmail();');
      }

      if(!Window.user && _$('.section-thank-you').attr('data-thank-you') != null && _$('.section-thank-you').attr('data-thank-you') != ''){
         this.dataOrder = JSON.parse(_$('.section-thank-you').attr('data-thank-you'));
         let orderTimestamp = this.dataOrder['orderTimestamp'];
         
         if(orderTimestamp != null && orderTimestamp != ''){
            _Uikit.countdown('.temporization-scadenza', {
               date: new Date((parseInt(orderTimestamp) + (60000 * 5)))
            });
      
            let timeTillReload = (parseInt(orderTimestamp) + (60000 * 5)) - Date.now()
            if(timeTillReload > 0){
               setTimeout(() => {
                  window.location.reload();
               }, timeTillReload)
            }else{
               window.location.reload();
            }
         }else{
            console.log('order timestamp not found');
         }

         let emailFiltered = this.dataOrder['email'];

         if(emailFiltered != '' && emailFiltered != null && _$('#modalVerifyEmail .email-filtered').length){
            _$('#modalVerifyEmail .email-filtered').html(emailFiltered);
         }
      }
      
   }  

   sendAnalytics(eventType) {
      try {
         let data = this.localStorage.getFromStorage('trackingThankYou');
         if(data){
            let checkoutData = data.checkout ? data.checkout : '';
            let cartData = data.carrello ? data.carrello : '';
            let funnelData = data.funnel ? data.funnel : '';
            let analyticsData;
            let importo = cartData.importo;
            let importoConsegna = cartData.importoConsegna;
            let consegnaGratuita = cartData.consegnaGratuita;
            let tipoServizio = funnelData.tipoServizio;
            let idNegozio = funnelData.sceltaNegozio.idNegozio;
            let cooperativaNegozio =  funnelData.sceltaNegozio.cooperativaNegozio;
            let giorno = funnelData.sceltaSlot.giorno;
            let fasciaOraria = funnelData.sceltaSlot.fasciaOraria;
            let slotDinamico = funnelData.sceltaSlot.slotDinamico != null ? funnelData.sceltaSlot.slotDinamico : null;
            let slotDinamicoScontato = funnelData.sceltaSlot.slotDinamicoScontato != null ? funnelData.sceltaSlot.slotDinamicoScontato : null;
   
            //window['selectedTimeslot'] ? window['selectedTimeslot']['startTime']['formattedHour'] + " - " + window['selectedTimeslot']['endTime']['formattedHour'] : ''
   
            analyticsData = {
               event: eventType,
               IDpromozioni: this._analytics.getIDpromozioni(),
               carrello: {
                  importo,
                  consegnaGratuita,
                  importoConsegna,
               },
               checkout: {
                  pagamento: checkoutData.pagamento,
                  fatturazione: checkoutData.fatturazione
               },
               ordine: {
                  numeroOrdine: _$(".order-number").length ? _$(".order-number").find("span").html() : '',
                  prodotti: cartData['prodotti']
               },
               // 4.4.1
               funnel:{
                  nomeFunnel: 'Thank you page',
                  tipoServizio,
                  sceltaNegozio: {
                     idNegozio,
                     cooperativaNegozio
                  }
               }
            }

            if (giorno && fasciaOraria) {
               analyticsData['funnel']['sceltaSlot'] = {
                     giorno: giorno,
                     fasciaOraria: fasciaOraria,
               }
            }

            if(slotDinamico != null && slotDinamicoScontato != null){
               analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamico;
               analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontato;
            }
   
            switch (eventType) {
               case 'pageview':
                  //4.5.1
                  analyticsData.funnel['nomeFunnel'] = "Thank you page";
                  analyticsData.funnel['stepFunnel'] = _$(".order-number").length ? "Ordine effettuato" : "Ordine non effettuato";
                  break;
               case 'dettaglioOrdine':
                  //4.4.2
                  analyticsData.funnel['nomeFunnel'] = "Thank you page";
                  analyticsData.funnel['stepFunnel'] = "CTA dettaglio ordine";
                  break;
               case 'tornaHomepage':
                  //4.4.3
                  analyticsData.funnel['nomeFunnel'] = "Thank you page";
                  analyticsData.funnel['stepFunnel'] = "CTA torna in homepage";
                  break;
               case 'registratiTYP':
                  //4.4.4
                  analyticsData.funnel['nomeFunnel'] = "Thank you page";
                  analyticsData.funnel['stepFunnel'] = "CTA registrati";
                  break;
               case 'clickQuartoConsenso':
                  analyticsData.funnel['nomeFunnel'] = "Quarto consenso";
                  analyticsData.funnel['stepFunnel'] = "Click quarto consenso";
                  break;

            }
            this._analytics.sendAnalytics(analyticsData, null, false);
         }else{
            console.log('no data found');
         }
      } catch (error) {
         console.log(eventType + ' : ' + error);
      }
   }
   
   verifyEmail(){
      if(this.dataOrder['becommerce'] != null && this.dataOrder['code'] != null){

         let success = (res) => {
            _$("#email-error-1").addClass("uk-hidden");
            if(window['urlSite'] != null && window['urlSite'].orderDetail != null){
               let orderDetail = Window.urlSite.orderDetail ? Window.urlSite.orderDetail : '/order-detail';
               Window.location.href = orderDetail + '?orderId=' + this.dataOrder['code'] + '&bEcommerce=' + this.dataOrder['becommerce'];
            }else{
               console.log('No urlSite.orderDetail found');
               this._utils.showLoader("", false);
            }
         }

         let error = (err) => {
            this._utils.showLoader('', false);
            _$("#email-error-1").removeClass("uk-hidden");
         }

         let body = {
            "email": _$("#modalVerifyEmail #emailOrder") && _$("#modalVerifyEmail #emailOrder").val() ? _$("#modalVerifyEmail #emailOrder").val() : "",
            "orderCode": this.dataOrder['code'],
            "becommerce": this.dataOrder['becommerce']
         }

         this._utils.showLoader('', true);
         this._userService.verifyModifyGuestOrderEmail(success, error, body);
      }
   }
}
