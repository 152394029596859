/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ManageWishlistsServletRequest = {
    action: ManageWishlistsServletRequest.action;
    productId?: string;
    wishlistId?: string;
    wishlistName?: string;
};
export namespace ManageWishlistsServletRequest {
    export enum action {
        CREATE_WISHLIST = 'CREATE_WISHLIST',
        DELETE_WISHLIST = 'DELETE_WISHLIST',
        RENAME_WISHLIST = 'RENAME_WISHLIST',
        ADD_PRODUCT = 'ADD_PRODUCT',
        REMOVE_PRODUCT = 'REMOVE_PRODUCT',
        ADD_WISHLIST_TO_CART = 'ADD_WISHLIST_TO_CART',
    }
}

