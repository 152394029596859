import { _$, _Uikit, Window } from '../../main';
import { Utils } from '../../services/utils/utils';

export class ModalVerifyEmail {

    constructor(
        private _utils: Utils
     ) {}

     nkOnInit(){
        _$("#modalVerifyEmail").on("show", () => {
            _$("#email-error-1").addClass("uk-hidden");
        });
     }

     checkValueFieldEmail(event, type: string) {
        this.setValueFieldForm(event, type);
        
        this._utils.hideShowFormError(type, this[type], _$(event).attr('pattern') ? _$(event).attr('pattern') : '');

        const formButton = _$('[nkButton="sendEmailOrder"]')[0];
        if (this._utils.isValidEmail(event.value)) {
            formButton.removeAttribute('disabled');
        }
        else {
            !formButton.hasAttribute('disabled') && formButton.setAttribute('disabled', 'true');
        }
    }

    /**
     * Sets value
     * @param event
     * @param type
     */
     setValueFieldForm(event, type) {
        this[type] = event.value;
    }
}