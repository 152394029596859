import { _Uikit, Window, _$ } from '../../main';
import Swiper, { SwiperOptions } from 'swiper';

export class BannerPromoLanding{

    constructor(){

    }

    nkOnInit(){
        
    }
}