import { Analytics } from 'src/services/analytics/analytics';
import { _$ } from '../../main';
export class AccordionTimeslotInfo {
   
    constructor(private _analytics: Analytics) { }
    
    nkOnInit() {

        /* Blocca la propagazione dell'evento shown che scatenava la pageview della modal order receive */
        _$('.component-AccordionTimeslotInfo').on('shown', () => {
            event.stopImmediatePropagation();
        });
        
        _$('.component-AccordionTimeslotInfo .accordion-first').on('click', () => { 
            this.sendAnalytics('clickAccordionInfoPrenotazioneSlot');
        })
        _$('.component-AccordionTimeslotInfo .accordion-second').on('click', () => { 
            this.sendAnalytics('clickAccordionInfoPrenotazioneSlot');
        })
    }   

    sendAnalytics(eventType) {
        try {
            //7.2.1
            let analyticsData = {
                event: eventType,
                funnel: {}
            }
            let step = 'FUNNEL_DATA_CLICK_ACCORDION_INFO_PRENOTAZIONE_SLOT';

            this._analytics.sendAnalytics(analyticsData, step, true, false);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }
 }