import { _Uikit, _$ } from '../../main'; 
import { CartService } from 'src/services/cart-service/cart-service';
export class CardProdottoOmaggio {
   
    constructor(
        private _cartService: CartService
    ) {
        /* settaggio data-code per l'offcanvas dettaglio prodotto */
        this.setDataCode();
     }
    
    nkOnInit() { }  
    
    toggleActive(e: object){
        let newValue = _$(e).parent().attr('data-active') && _$(e).parent().attr('data-active') == 'true' ? false : true;
        let success = (res) => {
            _$(e).closest('.omaggio-checkbox').attr('data-active', newValue.toString());
        }

        let error = (err) => {
            if(newValue){
                _$(e).find('input').siblings('.checkmark').removeClass('selected');
                _$(e).find('input').prop('checked', false);
            }else{
                _$(e).find('input').siblings('.checkmark').addClass('selected');
                _$(e).find('input').prop('checked', true);
            }
        }

        let body = {
            "productId": _$(e).closest('.component-CardProdottoOmaggio').find('.a-wrapper').length ? _$(e).closest('.component-CardProdottoOmaggio').find('.a-wrapper').attr('data-code') : '',
            "active": newValue
        }

        if(newValue){
            _$(e).find('input').siblings('.checkmark').addClass('selected');
        }else{
            _$(e).find('input').siblings('.checkmark').removeClass('selected');
        }

        this._cartService.addRemoveItemToCart(success, error, body);
    }

    setDataCode(){
        _$('.component-CardProdottoOmaggio').each((index, value) => {
            if(_$(value).attr('data-product') != null && _$(value).attr('data-product').toString() != null && JSON.parse(_$(value).attr('data-product').toString()) != null){
                let dataProduct = JSON.parse(_$(value).attr('data-product').toString());
                _$(value).find('.a-wrapper').attr('data-code', dataProduct.code != null ? dataProduct.code : '');
                _$(value).find('.omaggio-checkbox').attr('data-active', dataProduct.active != null ? dataProduct.active : false);
            }
        })
    }
 }