import Alpine from 'alpinejs';

const LIVECOPY_NAME_REGEX = /\/content\/conad-ecommerce\/it\/it\/([a-z]{3,7})\/.*/;
const PRODUCT_IMAGE_PATH_RM = '/assets/products/{productCode}/ID-Shot.jpeg/renditions/{rendition}.jpeg';

// e.g. ('{0} dogs, {1} cats', 5, 4) => '5 dogs, 4 cats'
export function formatC(s: string, ...args: string[]): string {
    if (!s) return s;
    return s.replace(/\{([0-9]+)\}/g, function (match: string, index: number) {
        return typeof args[index] == 'undefined' ? match : args[index];
    });
};

export function i18n(defaultString: string) {
    if (!defaultString) return defaultString;
    const dict = Alpine.store('dictionary');
    if (!dict) return defaultString;
    return dict[defaultString] || defaultString;
};

export function notUndefinedAndTrue(el: any) {
    return (typeof el != 'undefined') && el != null && el;
}

export function renderHrefWithAdditionalParams(href: string, params: {}) {
    if ((typeof href == 'undefined') || !href) return 'javascript:;';
    if (!params) return href;
    const url = href.startsWith('/') ? new URL(href, window.location.origin) : new URL(href); // not exactly correct
    for (const key in params) {
        if (!key) continue;
        if (params.hasOwnProperty(key)) {
            url.searchParams.append(key, params[key]);
        }
    }
    return url.toString();
}

export function throttle(func, limitMs) {
    let inThrottle;
    return function (...args) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => inThrottle = false, limitMs);
        }
    };
}

export function getStandardContentRedirectDirective(): string {
    let coopName = 'default';
    if (location?.host == 'localhost:4502' && location?.pathname) {
        let match = location.pathname.match(LIVECOPY_NAME_REGEX);
        if (match && match[1]) coopName = match[1];
        return `/content/conad-ecommerce/it/it/${coopName}`;
    }
    return '';
}

export function getProductImageUrl(productCode: string, rendition: string = 'medium'): string {
    if (!productCode) return '';
    return PRODUCT_IMAGE_PATH_RM.replace('{productCode}', productCode.toLowerCase()).replace('{rendition}', rendition);
}

export function fromResponseToCards(wishlistDocument: Document): Node[] {
    if (!wishlistDocument) return [];
    return Array.from(wishlistDocument.body.childNodes).filter(node =>
        // exlude text nodes with only whitespace
        !(node.nodeType === Node.TEXT_NODE && !node.textContent.trim()) && (node instanceof Element && node.hasAttribute('x-data'))
    );
}

export function getQueryParamFromUrl(key: string, fallback: string = undefined): string {
    try {
        const url = new URL(window.location.href);
        const value = new URLSearchParams(url.search).get(key);
        return value ? value : fallback;
    } catch (e) {
        console.warn(`Impossible to retrieve '${key}' from url`);
        return fallback;
    }
}

export function formatCurrency(value: number): string {
    if (value == undefined) return '';
    let valueStr = value.toString();
    const regex = /^\d+(\.\d{1,2})?$/;
    if (!regex.test(valueStr)) return valueStr;

    let formattedValue = value.toFixed(2).replace('.', ',');
    return `${formattedValue}€`;
}

export function getCommaDataAttributeAsArray(element: HTMLElement, dataAttributeKey: string): string[] {
    if (!element) return [];
    if (!element?.dataset || !element?.dataset[dataAttributeKey]) return [];
    return element.dataset.value.split(',');
}
